@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 400;
  src: local('RobotoDraft'), local('RobotoDraft-Regular'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/robotodraft/v1/0xES5Sl_v6oyT7dAKuoni4gp9Q8gbYrhqGlRav_IXfk.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/0xES5Sl_v6oyT7dAKuoni7rIa-7acMAeDBVuclsi6Gc.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 500;
  src: local('RobotoDraft Medium'), local('RobotoDraft-Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwXJuJo8UJJfpGKt7pXjBv4s.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwaTA90I55Xt7owhZwpPnMsc.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 700;
  src: local('RobotoDraft Bold'), local('RobotoDraft-Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-Vwf79_ZuUxCigM2DespTnFaw.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwRbnBKKEOwRKgsHDreGcocg.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: italic;
  font-weight: 400;
  src: local('RobotoDraft Italic'), local('RobotoDraft-Italic'), local('Roboto-Italic'), url(https://fonts.gstatic.com/s/robotodraft/v1/er-TIW55l9KWsTS1x9bTfgeOulFbQKHxPa89BaxZzA0.woff2) format('woff2'), url(https://fonts.gstatic.com/s/robotodraft/v1/er-TIW55l9KWsTS1x9bTfoo3ZslTYfJv0R05CazkwN8.woff) format('woff');
}
/*

To get this list of colors inject jQuery at http://www.google.com/design/spec/style/color.html#color-color-palette

Then, run this script to get the list.


(function() {
  var colors = {}, main = {};
  $(".color-group").each(function() {
    var color = $(this).find(".name").text().trim().toLowerCase().replace(" ", "-");
    colors[color] = {};

    $(this).find(".color").not(".main-color").each(function() {
      var shade = $(this).find(".shade").text().trim(),
          hex   = $(this).find(".hex").text().trim();

      colors[color][shade] = hex;
    });
    main[color] = color + "-" + $(this).find(".main-color .shade").text().trim();

  });
  var LESS = "";
  $.each(colors, function(name, shades) {
    LESS += "\n\n";
    $.each(shades, function(shade, hex) {
      LESS += "@" + name + "-" + shade + ": " + hex + ";\n";
    });
    if (main[name]) {
      LESS += "@" + name + ": " + main[name] + ";\n";
    }
  });
  console.log(LESS);
})();


*/
@font-face {
  font-family: "Material-Design-Icons";
  src: url("../fonts/Material-Design-Icons.eot?-g7cqhn");
  src: url("../fonts/Material-Design-Icons.eot?#iefix-g7cqhn") format("embedded-opentype"), url("../fonts/Material-Design-Icons.woff?-g7cqhn") format("woff"), url("../fonts/Material-Design-Icons.ttf?-g7cqhn") format("truetype"), url("../fonts/Material-Design-Icons.svg?-g7cqhn#Material-Design-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="mdi-"],
[class*=" mdi-"] {
  font-family: "Material-Design-Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mdi-action-3d-rotation:before {
  content: "\e600";
}
.mdi-action-accessibility:before {
  content: "\e601";
}
.mdi-action-account-balance:before {
  content: "\e602";
}
.mdi-action-account-balance-wallet:before {
  content: "\e603";
}
.mdi-action-account-box:before {
  content: "\e604";
}
.mdi-action-account-child:before {
  content: "\e605";
}
.mdi-action-account-circle:before {
  content: "\e606";
}
.mdi-action-add-shopping-cart:before {
  content: "\e607";
}
.mdi-action-alarm:before {
  content: "\e608";
}
.mdi-action-alarm-add:before {
  content: "\e609";
}
.mdi-action-alarm-off:before {
  content: "\e60a";
}
.mdi-action-alarm-on:before {
  content: "\e60b";
}
.mdi-action-android:before {
  content: "\e60c";
}
.mdi-action-announcement:before {
  content: "\e60d";
}
.mdi-action-aspect-ratio:before {
  content: "\e60e";
}
.mdi-action-assessment:before {
  content: "\e60f";
}
.mdi-action-assignment:before {
  content: "\e610";
}
.mdi-action-assignment-ind:before {
  content: "\e611";
}
.mdi-action-assignment-late:before {
  content: "\e612";
}
.mdi-action-assignment-return:before {
  content: "\e613";
}
.mdi-action-assignment-returned:before {
  content: "\e614";
}
.mdi-action-assignment-turned-in:before {
  content: "\e615";
}
.mdi-action-autorenew:before {
  content: "\e616";
}
.mdi-action-backup:before {
  content: "\e617";
}
.mdi-action-book:before {
  content: "\e618";
}
.mdi-action-bookmark:before {
  content: "\e619";
}
.mdi-action-bookmark-outline:before {
  content: "\e61a";
}
.mdi-action-bug-report:before {
  content: "\e61b";
}
.mdi-action-cached:before {
  content: "\e61c";
}
.mdi-action-class:before {
  content: "\e61d";
}
.mdi-action-credit-card:before {
  content: "\e61e";
}
.mdi-action-dashboard:before {
  content: "\e61f";
}
.mdi-action-delete:before {
  content: "\e620";
}
.mdi-action-description:before {
  content: "\e621";
}
.mdi-action-dns:before {
  content: "\e622";
}
.mdi-action-done:before {
  content: "\e623";
}
.mdi-action-done-all:before {
  content: "\e624";
}
.mdi-action-event:before {
  content: "\e625";
}
.mdi-action-exit-to-app:before {
  content: "\e626";
}
.mdi-action-explore:before {
  content: "\e627";
}
.mdi-action-extension:before {
  content: "\e628";
}
.mdi-action-face-unlock:before {
  content: "\e629";
}
.mdi-action-favorite:before {
  content: "\e62a";
}
.mdi-action-favorite-outline:before {
  content: "\e62b";
}
.mdi-action-find-in-page:before {
  content: "\e62c";
}
.mdi-action-find-replace:before {
  content: "\e62d";
}
.mdi-action-flip-to-back:before {
  content: "\e62e";
}
.mdi-action-flip-to-front:before {
  content: "\e62f";
}
.mdi-action-get-app:before {
  content: "\e630";
}
.mdi-action-grade:before {
  content: "\e631";
}
.mdi-action-group-work:before {
  content: "\e632";
}
.mdi-action-help:before {
  content: "\e633";
}
.mdi-action-highlight-remove:before {
  content: "\e634";
}
.mdi-action-history:before {
  content: "\e635";
}
.mdi-action-home:before {
  content: "\e636";
}
.mdi-action-https:before {
  content: "\e637";
}
.mdi-action-info:before {
  content: "\e638";
}
.mdi-action-info-outline:before {
  content: "\e639";
}
.mdi-action-input:before {
  content: "\e63a";
}
.mdi-action-invert-colors:before {
  content: "\e63b";
}
.mdi-action-label:before {
  content: "\e63c";
}
.mdi-action-label-outline:before {
  content: "\e63d";
}
.mdi-action-language:before {
  content: "\e63e";
}
.mdi-action-launch:before {
  content: "\e63f";
}
.mdi-action-list:before {
  content: "\e640";
}
.mdi-action-lock:before {
  content: "\e641";
}
.mdi-action-lock-open:before {
  content: "\e642";
}
.mdi-action-lock-outline:before {
  content: "\e643";
}
.mdi-action-loyalty:before {
  content: "\e644";
}
.mdi-action-markunread-mailbox:before {
  content: "\e645";
}
.mdi-action-note-add:before {
  content: "\e646";
}
.mdi-action-open-in-browser:before {
  content: "\e647";
}
.mdi-action-open-in-new:before {
  content: "\e648";
}
.mdi-action-open-with:before {
  content: "\e649";
}
.mdi-action-pageview:before {
  content: "\e64a";
}
.mdi-action-payment:before {
  content: "\e64b";
}
.mdi-action-perm-camera-mic:before {
  content: "\e64c";
}
.mdi-action-perm-contact-cal:before {
  content: "\e64d";
}
.mdi-action-perm-data-setting:before {
  content: "\e64e";
}
.mdi-action-perm-device-info:before {
  content: "\e64f";
}
.mdi-action-perm-identity:before {
  content: "\e650";
}
.mdi-action-perm-media:before {
  content: "\e651";
}
.mdi-action-perm-phone-msg:before {
  content: "\e652";
}
.mdi-action-perm-scan-wifi:before {
  content: "\e653";
}
.mdi-action-picture-in-picture:before {
  content: "\e654";
}
.mdi-action-polymer:before {
  content: "\e655";
}
.mdi-action-print:before {
  content: "\e656";
}
.mdi-action-query-builder:before {
  content: "\e657";
}
.mdi-action-question-answer:before {
  content: "\e658";
}
.mdi-action-receipt:before {
  content: "\e659";
}
.mdi-action-redeem:before {
  content: "\e65a";
}
.mdi-action-report-problem:before {
  content: "\e65b";
}
.mdi-action-restore:before {
  content: "\e65c";
}
.mdi-action-room:before {
  content: "\e65d";
}
.mdi-action-schedule:before {
  content: "\e65e";
}
.mdi-action-search:before {
  content: "\e65f";
}
.mdi-action-settings:before {
  content: "\e660";
}
.mdi-action-settings-applications:before {
  content: "\e661";
}
.mdi-action-settings-backup-restore:before {
  content: "\e662";
}
.mdi-action-settings-bluetooth:before {
  content: "\e663";
}
.mdi-action-settings-cell:before {
  content: "\e664";
}
.mdi-action-settings-display:before {
  content: "\e665";
}
.mdi-action-settings-ethernet:before {
  content: "\e666";
}
.mdi-action-settings-input-antenna:before {
  content: "\e667";
}
.mdi-action-settings-input-component:before {
  content: "\e668";
}
.mdi-action-settings-input-composite:before {
  content: "\e669";
}
.mdi-action-settings-input-hdmi:before {
  content: "\e66a";
}
.mdi-action-settings-input-svideo:before {
  content: "\e66b";
}
.mdi-action-settings-overscan:before {
  content: "\e66c";
}
.mdi-action-settings-phone:before {
  content: "\e66d";
}
.mdi-action-settings-power:before {
  content: "\e66e";
}
.mdi-action-settings-remote:before {
  content: "\e66f";
}
.mdi-action-settings-voice:before {
  content: "\e670";
}
.mdi-action-shop:before {
  content: "\e671";
}
.mdi-action-shopping-basket:before {
  content: "\e672";
}
.mdi-action-shopping-cart:before {
  content: "\e673";
}
.mdi-action-shop-two:before {
  content: "\e674";
}
.mdi-action-speaker-notes:before {
  content: "\e675";
}
.mdi-action-spellcheck:before {
  content: "\e676";
}
.mdi-action-star-rate:before {
  content: "\e677";
}
.mdi-action-stars:before {
  content: "\e678";
}
.mdi-action-store:before {
  content: "\e679";
}
.mdi-action-subject:before {
  content: "\e67a";
}
.mdi-action-swap-horiz:before {
  content: "\e67b";
}
.mdi-action-swap-vert:before {
  content: "\e67c";
}
.mdi-action-swap-vert-circle:before {
  content: "\e67d";
}
.mdi-action-system-update-tv:before {
  content: "\e67e";
}
.mdi-action-tab:before {
  content: "\e67f";
}
.mdi-action-tab-unselected:before {
  content: "\e680";
}
.mdi-action-theaters:before {
  content: "\e681";
}
.mdi-action-thumb-down:before {
  content: "\e682";
}
.mdi-action-thumbs-up-down:before {
  content: "\e683";
}
.mdi-action-thumb-up:before {
  content: "\e684";
}
.mdi-action-toc:before {
  content: "\e685";
}
.mdi-action-today:before {
  content: "\e686";
}
.mdi-action-track-changes:before {
  content: "\e687";
}
.mdi-action-translate:before {
  content: "\e688";
}
.mdi-action-trending-down:before {
  content: "\e689";
}
.mdi-action-trending-neutral:before {
  content: "\e68a";
}
.mdi-action-trending-up:before {
  content: "\e68b";
}
.mdi-action-turned-in:before {
  content: "\e68c";
}
.mdi-action-turned-in-not:before {
  content: "\e68d";
}
.mdi-action-verified-user:before {
  content: "\e68e";
}
.mdi-action-view-agenda:before {
  content: "\e68f";
}
.mdi-action-view-array:before {
  content: "\e690";
}
.mdi-action-view-carousel:before {
  content: "\e691";
}
.mdi-action-view-column:before {
  content: "\e692";
}
.mdi-action-view-day:before {
  content: "\e693";
}
.mdi-action-view-headline:before {
  content: "\e694";
}
.mdi-action-view-list:before {
  content: "\e695";
}
.mdi-action-view-module:before {
  content: "\e696";
}
.mdi-action-view-quilt:before {
  content: "\e697";
}
.mdi-action-view-stream:before {
  content: "\e698";
}
.mdi-action-view-week:before {
  content: "\e699";
}
.mdi-action-visibility:before {
  content: "\e69a";
}
.mdi-action-visibility-off:before {
  content: "\e69b";
}
.mdi-action-wallet-giftcard:before {
  content: "\e69c";
}
.mdi-action-wallet-membership:before {
  content: "\e69d";
}
.mdi-action-wallet-travel:before {
  content: "\e69e";
}
.mdi-action-work:before {
  content: "\e69f";
}
.mdi-alert-error:before {
  content: "\e6a0";
}
.mdi-alert-warning:before {
  content: "\e6a1";
}
.mdi-av-album:before {
  content: "\e6a2";
}
.mdi-av-timer:before {
  content: "\e6a3";
}
.mdi-av-closed-caption:before {
  content: "\e6a4";
}
.mdi-av-equalizer:before {
  content: "\e6a5";
}
.mdi-av-explicit:before {
  content: "\e6a6";
}
.mdi-av-fast-forward:before {
  content: "\e6a7";
}
.mdi-av-fast-rewind:before {
  content: "\e6a8";
}
.mdi-av-games:before {
  content: "\e6a9";
}
.mdi-av-hearing:before {
  content: "\e6aa";
}
.mdi-av-high-quality:before {
  content: "\e6ab";
}
.mdi-av-loop:before {
  content: "\e6ac";
}
.mdi-av-mic:before {
  content: "\e6ad";
}
.mdi-av-mic-none:before {
  content: "\e6ae";
}
.mdi-av-mic-off:before {
  content: "\e6af";
}
.mdi-av-movie:before {
  content: "\e6b0";
}
.mdi-av-my-library-add:before {
  content: "\e6b1";
}
.mdi-av-my-library-books:before {
  content: "\e6b2";
}
.mdi-av-my-library-music:before {
  content: "\e6b3";
}
.mdi-av-new-releases:before {
  content: "\e6b4";
}
.mdi-av-not-interested:before {
  content: "\e6b5";
}
.mdi-av-pause:before {
  content: "\e6b6";
}
.mdi-av-pause-circle-fill:before {
  content: "\e6b7";
}
.mdi-av-pause-circle-outline:before {
  content: "\e6b8";
}
.mdi-av-play-arrow:before {
  content: "\e6b9";
}
.mdi-av-play-circle-fill:before {
  content: "\e6ba";
}
.mdi-av-play-circle-outline:before {
  content: "\e6bb";
}
.mdi-av-playlist-add:before {
  content: "\e6bc";
}
.mdi-av-play-shopping-bag:before {
  content: "\e6bd";
}
.mdi-av-queue:before {
  content: "\e6be";
}
.mdi-av-queue-music:before {
  content: "\e6bf";
}
.mdi-av-radio:before {
  content: "\e6c0";
}
.mdi-av-recent-actors:before {
  content: "\e6c1";
}
.mdi-av-repeat:before {
  content: "\e6c2";
}
.mdi-av-repeat-one:before {
  content: "\e6c3";
}
.mdi-av-replay:before {
  content: "\e6c4";
}
.mdi-av-shuffle:before {
  content: "\e6c5";
}
.mdi-av-skip-next:before {
  content: "\e6c6";
}
.mdi-av-skip-previous:before {
  content: "\e6c7";
}
.mdi-av-snooze:before {
  content: "\e6c8";
}
.mdi-av-stop:before {
  content: "\e6c9";
}
.mdi-av-subtitles:before {
  content: "\e6ca";
}
.mdi-av-surround-sound:before {
  content: "\e6cb";
}
.mdi-av-videocam:before {
  content: "\e6cc";
}
.mdi-av-videocam-off:before {
  content: "\e6cd";
}
.mdi-av-video-collection:before {
  content: "\e6ce";
}
.mdi-av-volume-down:before {
  content: "\e6cf";
}
.mdi-av-volume-mute:before {
  content: "\e6d0";
}
.mdi-av-volume-off:before {
  content: "\e6d1";
}
.mdi-av-volume-up:before {
  content: "\e6d2";
}
.mdi-av-web:before {
  content: "\e6d3";
}
.mdi-communication-business:before {
  content: "\e6d4";
}
.mdi-communication-call:before {
  content: "\e6d5";
}
.mdi-communication-call-end:before {
  content: "\e6d6";
}
.mdi-communication-call-made:before {
  content: "\e6d7";
}
.mdi-communication-call-merge:before {
  content: "\e6d8";
}
.mdi-communication-call-missed:before {
  content: "\e6d9";
}
.mdi-communication-call-received:before {
  content: "\e6da";
}
.mdi-communication-call-split:before {
  content: "\e6db";
}
.mdi-communication-chat:before {
  content: "\e6dc";
}
.mdi-communication-clear-all:before {
  content: "\e6dd";
}
.mdi-communication-comment:before {
  content: "\e6de";
}
.mdi-communication-contacts:before {
  content: "\e6df";
}
.mdi-communication-dialer-sip:before {
  content: "\e6e0";
}
.mdi-communication-dialpad:before {
  content: "\e6e1";
}
.mdi-communication-dnd-on:before {
  content: "\e6e2";
}
.mdi-communication-email:before {
  content: "\e6e3";
}
.mdi-communication-forum:before {
  content: "\e6e4";
}
.mdi-communication-import-export:before {
  content: "\e6e5";
}
.mdi-communication-invert-colors-off:before {
  content: "\e6e6";
}
.mdi-communication-invert-colors-on:before {
  content: "\e6e7";
}
.mdi-communication-live-help:before {
  content: "\e6e8";
}
.mdi-communication-location-off:before {
  content: "\e6e9";
}
.mdi-communication-location-on:before {
  content: "\e6ea";
}
.mdi-communication-message:before {
  content: "\e6eb";
}
.mdi-communication-messenger:before {
  content: "\e6ec";
}
.mdi-communication-no-sim:before {
  content: "\e6ed";
}
.mdi-communication-phone:before {
  content: "\e6ee";
}
.mdi-communication-portable-wifi-off:before {
  content: "\e6ef";
}
.mdi-communication-quick-contacts-dialer:before {
  content: "\e6f0";
}
.mdi-communication-quick-contacts-mail:before {
  content: "\e6f1";
}
.mdi-communication-ring-volume:before {
  content: "\e6f2";
}
.mdi-communication-stay-current-landscape:before {
  content: "\e6f3";
}
.mdi-communication-stay-current-portrait:before {
  content: "\e6f4";
}
.mdi-communication-stay-primary-landscape:before {
  content: "\e6f5";
}
.mdi-communication-stay-primary-portrait:before {
  content: "\e6f6";
}
.mdi-communication-swap-calls:before {
  content: "\e6f7";
}
.mdi-communication-textsms:before {
  content: "\e6f8";
}
.mdi-communication-voicemail:before {
  content: "\e6f9";
}
.mdi-communication-vpn-key:before {
  content: "\e6fa";
}
.mdi-content-add:before {
  content: "\e6fb";
}
.mdi-content-add-box:before {
  content: "\e6fc";
}
.mdi-content-add-circle:before {
  content: "\e6fd";
}
.mdi-content-add-circle-outline:before {
  content: "\e6fe";
}
.mdi-content-archive:before {
  content: "\e6ff";
}
.mdi-content-backspace:before {
  content: "\e700";
}
.mdi-content-block:before {
  content: "\e701";
}
.mdi-content-clear:before {
  content: "\e702";
}
.mdi-content-content-copy:before {
  content: "\e703";
}
.mdi-content-content-cut:before {
  content: "\e704";
}
.mdi-content-content-paste:before {
  content: "\e705";
}
.mdi-content-create:before {
  content: "\e706";
}
.mdi-content-drafts:before {
  content: "\e707";
}
.mdi-content-filter-list:before {
  content: "\e708";
}
.mdi-content-flag:before {
  content: "\e709";
}
.mdi-content-forward:before {
  content: "\e70a";
}
.mdi-content-gesture:before {
  content: "\e70b";
}
.mdi-content-inbox:before {
  content: "\e70c";
}
.mdi-content-link:before {
  content: "\e70d";
}
.mdi-content-mail:before {
  content: "\e70e";
}
.mdi-content-markunread:before {
  content: "\e70f";
}
.mdi-content-redo:before {
  content: "\e710";
}
.mdi-content-remove:before {
  content: "\e711";
}
.mdi-content-remove-circle:before {
  content: "\e712";
}
.mdi-content-remove-circle-outline:before {
  content: "\e713";
}
.mdi-content-reply:before {
  content: "\e714";
}
.mdi-content-reply-all:before {
  content: "\e715";
}
.mdi-content-report:before {
  content: "\e716";
}
.mdi-content-save:before {
  content: "\e717";
}
.mdi-content-select-all:before {
  content: "\e718";
}
.mdi-content-send:before {
  content: "\e719";
}
.mdi-content-sort:before {
  content: "\e71a";
}
.mdi-content-text-format:before {
  content: "\e71b";
}
.mdi-content-undo:before {
  content: "\e71c";
}
.mdi-device-access-alarm:before {
  content: "\e71d";
}
.mdi-device-access-alarms:before {
  content: "\e71e";
}
.mdi-device-access-time:before {
  content: "\e71f";
}
.mdi-device-add-alarm:before {
  content: "\e720";
}
.mdi-device-airplanemode-off:before {
  content: "\e721";
}
.mdi-device-airplanemode-on:before {
  content: "\e722";
}
.mdi-device-battery-20:before {
  content: "\e723";
}
.mdi-device-battery-30:before {
  content: "\e724";
}
.mdi-device-battery-50:before {
  content: "\e725";
}
.mdi-device-battery-60:before {
  content: "\e726";
}
.mdi-device-battery-80:before {
  content: "\e727";
}
.mdi-device-battery-90:before {
  content: "\e728";
}
.mdi-device-battery-alert:before {
  content: "\e729";
}
.mdi-device-battery-charging-20:before {
  content: "\e72a";
}
.mdi-device-battery-charging-30:before {
  content: "\e72b";
}
.mdi-device-battery-charging-50:before {
  content: "\e72c";
}
.mdi-device-battery-charging-60:before {
  content: "\e72d";
}
.mdi-device-battery-charging-80:before {
  content: "\e72e";
}
.mdi-device-battery-charging-90:before {
  content: "\e72f";
}
.mdi-device-battery-charging-full:before {
  content: "\e730";
}
.mdi-device-battery-full:before {
  content: "\e731";
}
.mdi-device-battery-std:before {
  content: "\e732";
}
.mdi-device-battery-unknown:before {
  content: "\e733";
}
.mdi-device-bluetooth:before {
  content: "\e734";
}
.mdi-device-bluetooth-connected:before {
  content: "\e735";
}
.mdi-device-bluetooth-disabled:before {
  content: "\e736";
}
.mdi-device-bluetooth-searching:before {
  content: "\e737";
}
.mdi-device-brightness-auto:before {
  content: "\e738";
}
.mdi-device-brightness-high:before {
  content: "\e739";
}
.mdi-device-brightness-low:before {
  content: "\e73a";
}
.mdi-device-brightness-medium:before {
  content: "\e73b";
}
.mdi-device-data-usage:before {
  content: "\e73c";
}
.mdi-device-developer-mode:before {
  content: "\e73d";
}
.mdi-device-devices:before {
  content: "\e73e";
}
.mdi-device-dvr:before {
  content: "\e73f";
}
.mdi-device-gps-fixed:before {
  content: "\e740";
}
.mdi-device-gps-not-fixed:before {
  content: "\e741";
}
.mdi-device-gps-off:before {
  content: "\e742";
}
.mdi-device-location-disabled:before {
  content: "\e743";
}
.mdi-device-location-searching:before {
  content: "\e744";
}
.mdi-device-multitrack-audio:before {
  content: "\e745";
}
.mdi-device-network-cell:before {
  content: "\e746";
}
.mdi-device-network-wifi:before {
  content: "\e747";
}
.mdi-device-nfc:before {
  content: "\e748";
}
.mdi-device-now-wallpaper:before {
  content: "\e749";
}
.mdi-device-now-widgets:before {
  content: "\e74a";
}
.mdi-device-screen-lock-landscape:before {
  content: "\e74b";
}
.mdi-device-screen-lock-portrait:before {
  content: "\e74c";
}
.mdi-device-screen-lock-rotation:before {
  content: "\e74d";
}
.mdi-device-screen-rotation:before {
  content: "\e74e";
}
.mdi-device-sd-storage:before {
  content: "\e74f";
}
.mdi-device-settings-system-daydream:before {
  content: "\e750";
}
.mdi-device-signal-cellular-0-bar:before {
  content: "\e751";
}
.mdi-device-signal-cellular-1-bar:before {
  content: "\e752";
}
.mdi-device-signal-cellular-2-bar:before {
  content: "\e753";
}
.mdi-device-signal-cellular-3-bar:before {
  content: "\e754";
}
.mdi-device-signal-cellular-4-bar:before {
  content: "\e755";
}
.mdi-device-signal-cellular-connected-no-internet-0-bar:before {
  content: "\e756";
}
.mdi-device-signal-cellular-connected-no-internet-1-bar:before {
  content: "\e757";
}
.mdi-device-signal-cellular-connected-no-internet-2-bar:before {
  content: "\e758";
}
.mdi-device-signal-cellular-connected-no-internet-3-bar:before {
  content: "\e759";
}
.mdi-device-signal-cellular-connected-no-internet-4-bar:before {
  content: "\e75a";
}
.mdi-device-signal-cellular-no-sim:before {
  content: "\e75b";
}
.mdi-device-signal-cellular-null:before {
  content: "\e75c";
}
.mdi-device-signal-cellular-off:before {
  content: "\e75d";
}
.mdi-device-signal-wifi-0-bar:before {
  content: "\e75e";
}
.mdi-device-signal-wifi-1-bar:before {
  content: "\e75f";
}
.mdi-device-signal-wifi-2-bar:before {
  content: "\e760";
}
.mdi-device-signal-wifi-3-bar:before {
  content: "\e761";
}
.mdi-device-signal-wifi-4-bar:before {
  content: "\e762";
}
.mdi-device-signal-wifi-off:before {
  content: "\e763";
}
.mdi-device-storage:before {
  content: "\e764";
}
.mdi-device-usb:before {
  content: "\e765";
}
.mdi-device-wifi-lock:before {
  content: "\e766";
}
.mdi-device-wifi-tethering:before {
  content: "\e767";
}
.mdi-editor-attach-file:before {
  content: "\e768";
}
.mdi-editor-attach-money:before {
  content: "\e769";
}
.mdi-editor-border-all:before {
  content: "\e76a";
}
.mdi-editor-border-bottom:before {
  content: "\e76b";
}
.mdi-editor-border-clear:before {
  content: "\e76c";
}
.mdi-editor-border-color:before {
  content: "\e76d";
}
.mdi-editor-border-horizontal:before {
  content: "\e76e";
}
.mdi-editor-border-inner:before {
  content: "\e76f";
}
.mdi-editor-border-left:before {
  content: "\e770";
}
.mdi-editor-border-outer:before {
  content: "\e771";
}
.mdi-editor-border-right:before {
  content: "\e772";
}
.mdi-editor-border-style:before {
  content: "\e773";
}
.mdi-editor-border-top:before {
  content: "\e774";
}
.mdi-editor-border-vertical:before {
  content: "\e775";
}
.mdi-editor-format-align-center:before {
  content: "\e776";
}
.mdi-editor-format-align-justify:before {
  content: "\e777";
}
.mdi-editor-format-align-left:before {
  content: "\e778";
}
.mdi-editor-format-align-right:before {
  content: "\e779";
}
.mdi-editor-format-bold:before {
  content: "\e77a";
}
.mdi-editor-format-clear:before {
  content: "\e77b";
}
.mdi-editor-format-color-fill:before {
  content: "\e77c";
}
.mdi-editor-format-color-reset:before {
  content: "\e77d";
}
.mdi-editor-format-color-text:before {
  content: "\e77e";
}
.mdi-editor-format-indent-decrease:before {
  content: "\e77f";
}
.mdi-editor-format-indent-increase:before {
  content: "\e780";
}
.mdi-editor-format-italic:before {
  content: "\e781";
}
.mdi-editor-format-line-spacing:before {
  content: "\e782";
}
.mdi-editor-format-list-bulleted:before {
  content: "\e783";
}
.mdi-editor-format-list-numbered:before {
  content: "\e784";
}
.mdi-editor-format-paint:before {
  content: "\e785";
}
.mdi-editor-format-quote:before {
  content: "\e786";
}
.mdi-editor-format-size:before {
  content: "\e787";
}
.mdi-editor-format-strikethrough:before {
  content: "\e788";
}
.mdi-editor-format-textdirection-l-to-r:before {
  content: "\e789";
}
.mdi-editor-format-textdirection-r-to-l:before {
  content: "\e78a";
}
.mdi-editor-format-underline:before {
  content: "\e78b";
}
.mdi-editor-functions:before {
  content: "\e78c";
}
.mdi-editor-insert-chart:before {
  content: "\e78d";
}
.mdi-editor-insert-comment:before {
  content: "\e78e";
}
.mdi-editor-insert-drive-file:before {
  content: "\e78f";
}
.mdi-editor-insert-emoticon:before {
  content: "\e790";
}
.mdi-editor-insert-invitation:before {
  content: "\e791";
}
.mdi-editor-insert-link:before {
  content: "\e792";
}
.mdi-editor-insert-photo:before {
  content: "\e793";
}
.mdi-editor-merge-type:before {
  content: "\e794";
}
.mdi-editor-mode-comment:before {
  content: "\e795";
}
.mdi-editor-mode-edit:before {
  content: "\e796";
}
.mdi-editor-publish:before {
  content: "\e797";
}
.mdi-editor-vertical-align-bottom:before {
  content: "\e798";
}
.mdi-editor-vertical-align-center:before {
  content: "\e799";
}
.mdi-editor-vertical-align-top:before {
  content: "\e79a";
}
.mdi-editor-wrap-text:before {
  content: "\e79b";
}
.mdi-file-attachment:before {
  content: "\e79c";
}
.mdi-file-cloud:before {
  content: "\e79d";
}
.mdi-file-cloud-circle:before {
  content: "\e79e";
}
.mdi-file-cloud-done:before {
  content: "\e79f";
}
.mdi-file-cloud-download:before {
  content: "\e7a0";
}
.mdi-file-cloud-off:before {
  content: "\e7a1";
}
.mdi-file-cloud-queue:before {
  content: "\e7a2";
}
.mdi-file-cloud-upload:before {
  content: "\e7a3";
}
.mdi-file-file-download:before {
  content: "\e7a4";
}
.mdi-file-file-upload:before {
  content: "\e7a5";
}
.mdi-file-folder:before {
  content: "\e7a6";
}
.mdi-file-folder-open:before {
  content: "\e7a7";
}
.mdi-file-folder-shared:before {
  content: "\e7a8";
}
.mdi-hardware-cast:before {
  content: "\e7a9";
}
.mdi-hardware-cast-connected:before {
  content: "\e7aa";
}
.mdi-hardware-computer:before {
  content: "\e7ab";
}
.mdi-hardware-desktop-mac:before {
  content: "\e7ac";
}
.mdi-hardware-desktop-windows:before {
  content: "\e7ad";
}
.mdi-hardware-dock:before {
  content: "\e7ae";
}
.mdi-hardware-gamepad:before {
  content: "\e7af";
}
.mdi-hardware-headset:before {
  content: "\e7b0";
}
.mdi-hardware-headset-mic:before {
  content: "\e7b1";
}
.mdi-hardware-keyboard:before {
  content: "\e7b2";
}
.mdi-hardware-keyboard-alt:before {
  content: "\e7b3";
}
.mdi-hardware-keyboard-arrow-down:before {
  content: "\e7b4";
}
.mdi-hardware-keyboard-arrow-left:before {
  content: "\e7b5";
}
.mdi-hardware-keyboard-arrow-right:before {
  content: "\e7b6";
}
.mdi-hardware-keyboard-arrow-up:before {
  content: "\e7b7";
}
.mdi-hardware-keyboard-backspace:before {
  content: "\e7b8";
}
.mdi-hardware-keyboard-capslock:before {
  content: "\e7b9";
}
.mdi-hardware-keyboard-control:before {
  content: "\e7ba";
}
.mdi-hardware-keyboard-hide:before {
  content: "\e7bb";
}
.mdi-hardware-keyboard-return:before {
  content: "\e7bc";
}
.mdi-hardware-keyboard-tab:before {
  content: "\e7bd";
}
.mdi-hardware-keyboard-voice:before {
  content: "\e7be";
}
.mdi-hardware-laptop:before {
  content: "\e7bf";
}
.mdi-hardware-laptop-chromebook:before {
  content: "\e7c0";
}
.mdi-hardware-laptop-mac:before {
  content: "\e7c1";
}
.mdi-hardware-laptop-windows:before {
  content: "\e7c2";
}
.mdi-hardware-memory:before {
  content: "\e7c3";
}
.mdi-hardware-mouse:before {
  content: "\e7c4";
}
.mdi-hardware-phone-android:before {
  content: "\e7c5";
}
.mdi-hardware-phone-iphone:before {
  content: "\e7c6";
}
.mdi-hardware-phonelink:before {
  content: "\e7c7";
}
.mdi-hardware-phonelink-off:before {
  content: "\e7c8";
}
.mdi-hardware-security:before {
  content: "\e7c9";
}
.mdi-hardware-sim-card:before {
  content: "\e7ca";
}
.mdi-hardware-smartphone:before {
  content: "\e7cb";
}
.mdi-hardware-speaker:before {
  content: "\e7cc";
}
.mdi-hardware-tablet:before {
  content: "\e7cd";
}
.mdi-hardware-tablet-android:before {
  content: "\e7ce";
}
.mdi-hardware-tablet-mac:before {
  content: "\e7cf";
}
.mdi-hardware-tv:before {
  content: "\e7d0";
}
.mdi-hardware-watch:before {
  content: "\e7d1";
}
.mdi-image-add-to-photos:before {
  content: "\e7d2";
}
.mdi-image-adjust:before {
  content: "\e7d3";
}
.mdi-image-assistant-photo:before {
  content: "\e7d4";
}
.mdi-image-audiotrack:before {
  content: "\e7d5";
}
.mdi-image-blur-circular:before {
  content: "\e7d6";
}
.mdi-image-blur-linear:before {
  content: "\e7d7";
}
.mdi-image-blur-off:before {
  content: "\e7d8";
}
.mdi-image-blur-on:before {
  content: "\e7d9";
}
.mdi-image-brightness-1:before {
  content: "\e7da";
}
.mdi-image-brightness-2:before {
  content: "\e7db";
}
.mdi-image-brightness-3:before {
  content: "\e7dc";
}
.mdi-image-brightness-4:before {
  content: "\e7dd";
}
.mdi-image-brightness-5:before {
  content: "\e7de";
}
.mdi-image-brightness-6:before {
  content: "\e7df";
}
.mdi-image-brightness-7:before {
  content: "\e7e0";
}
.mdi-image-brush:before {
  content: "\e7e1";
}
.mdi-image-camera:before {
  content: "\e7e2";
}
.mdi-image-camera-alt:before {
  content: "\e7e3";
}
.mdi-image-camera-front:before {
  content: "\e7e4";
}
.mdi-image-camera-rear:before {
  content: "\e7e5";
}
.mdi-image-camera-roll:before {
  content: "\e7e6";
}
.mdi-image-center-focus-strong:before {
  content: "\e7e7";
}
.mdi-image-center-focus-weak:before {
  content: "\e7e8";
}
.mdi-image-collections:before {
  content: "\e7e9";
}
.mdi-image-colorize:before {
  content: "\e7ea";
}
.mdi-image-color-lens:before {
  content: "\e7eb";
}
.mdi-image-compare:before {
  content: "\e7ec";
}
.mdi-image-control-point:before {
  content: "\e7ed";
}
.mdi-image-control-point-duplicate:before {
  content: "\e7ee";
}
.mdi-image-crop:before {
  content: "\e7ef";
}
.mdi-image-crop-3-2:before {
  content: "\e7f0";
}
.mdi-image-crop-5-4:before {
  content: "\e7f1";
}
.mdi-image-crop-7-5:before {
  content: "\e7f2";
}
.mdi-image-crop-16-9:before {
  content: "\e7f3";
}
.mdi-image-crop-din:before {
  content: "\e7f4";
}
.mdi-image-crop-free:before {
  content: "\e7f5";
}
.mdi-image-crop-landscape:before {
  content: "\e7f6";
}
.mdi-image-crop-original:before {
  content: "\e7f7";
}
.mdi-image-crop-portrait:before {
  content: "\e7f8";
}
.mdi-image-crop-square:before {
  content: "\e7f9";
}
.mdi-image-dehaze:before {
  content: "\e7fa";
}
.mdi-image-details:before {
  content: "\e7fb";
}
.mdi-image-edit:before {
  content: "\e7fc";
}
.mdi-image-exposure:before {
  content: "\e7fd";
}
.mdi-image-exposure-minus-1:before {
  content: "\e7fe";
}
.mdi-image-exposure-minus-2:before {
  content: "\e7ff";
}
.mdi-image-exposure-plus-1:before {
  content: "\e800";
}
.mdi-image-exposure-plus-2:before {
  content: "\e801";
}
.mdi-image-exposure-zero:before {
  content: "\e802";
}
.mdi-image-filter:before {
  content: "\e803";
}
.mdi-image-filter-1:before {
  content: "\e804";
}
.mdi-image-filter-2:before {
  content: "\e805";
}
.mdi-image-filter-3:before {
  content: "\e806";
}
.mdi-image-filter-4:before {
  content: "\e807";
}
.mdi-image-filter-5:before {
  content: "\e808";
}
.mdi-image-filter-6:before {
  content: "\e809";
}
.mdi-image-filter-7:before {
  content: "\e80a";
}
.mdi-image-filter-8:before {
  content: "\e80b";
}
.mdi-image-filter-9:before {
  content: "\e80c";
}
.mdi-image-filter-9-plus:before {
  content: "\e80d";
}
.mdi-image-filter-b-and-w:before {
  content: "\e80e";
}
.mdi-image-filter-center-focus:before {
  content: "\e80f";
}
.mdi-image-filter-drama:before {
  content: "\e810";
}
.mdi-image-filter-frames:before {
  content: "\e811";
}
.mdi-image-filter-hdr:before {
  content: "\e812";
}
.mdi-image-filter-none:before {
  content: "\e813";
}
.mdi-image-filter-tilt-shift:before {
  content: "\e814";
}
.mdi-image-filter-vintage:before {
  content: "\e815";
}
.mdi-image-flare:before {
  content: "\e816";
}
.mdi-image-flash-auto:before {
  content: "\e817";
}
.mdi-image-flash-off:before {
  content: "\e818";
}
.mdi-image-flash-on:before {
  content: "\e819";
}
.mdi-image-flip:before {
  content: "\e81a";
}
.mdi-image-gradient:before {
  content: "\e81b";
}
.mdi-image-grain:before {
  content: "\e81c";
}
.mdi-image-grid-off:before {
  content: "\e81d";
}
.mdi-image-grid-on:before {
  content: "\e81e";
}
.mdi-image-hdr-off:before {
  content: "\e81f";
}
.mdi-image-hdr-on:before {
  content: "\e820";
}
.mdi-image-hdr-strong:before {
  content: "\e821";
}
.mdi-image-hdr-weak:before {
  content: "\e822";
}
.mdi-image-healing:before {
  content: "\e823";
}
.mdi-image-image:before {
  content: "\e824";
}
.mdi-image-image-aspect-ratio:before {
  content: "\e825";
}
.mdi-image-iso:before {
  content: "\e826";
}
.mdi-image-landscape:before {
  content: "\e827";
}
.mdi-image-leak-add:before {
  content: "\e828";
}
.mdi-image-leak-remove:before {
  content: "\e829";
}
.mdi-image-lens:before {
  content: "\e82a";
}
.mdi-image-looks:before {
  content: "\e82b";
}
.mdi-image-looks-3:before {
  content: "\e82c";
}
.mdi-image-looks-4:before {
  content: "\e82d";
}
.mdi-image-looks-5:before {
  content: "\e82e";
}
.mdi-image-looks-6:before {
  content: "\e82f";
}
.mdi-image-looks-one:before {
  content: "\e830";
}
.mdi-image-looks-two:before {
  content: "\e831";
}
.mdi-image-loupe:before {
  content: "\e832";
}
.mdi-image-movie-creation:before {
  content: "\e833";
}
.mdi-image-nature:before {
  content: "\e834";
}
.mdi-image-nature-people:before {
  content: "\e835";
}
.mdi-image-navigate-before:before {
  content: "\e836";
}
.mdi-image-navigate-next:before {
  content: "\e837";
}
.mdi-image-palette:before {
  content: "\e838";
}
.mdi-image-panorama:before {
  content: "\e839";
}
.mdi-image-panorama-fisheye:before {
  content: "\e83a";
}
.mdi-image-panorama-horizontal:before {
  content: "\e83b";
}
.mdi-image-panorama-vertical:before {
  content: "\e83c";
}
.mdi-image-panorama-wide-angle:before {
  content: "\e83d";
}
.mdi-image-photo:before {
  content: "\e83e";
}
.mdi-image-photo-album:before {
  content: "\e83f";
}
.mdi-image-photo-camera:before {
  content: "\e840";
}
.mdi-image-photo-library:before {
  content: "\e841";
}
.mdi-image-portrait:before {
  content: "\e842";
}
.mdi-image-remove-red-eye:before {
  content: "\e843";
}
.mdi-image-rotate-left:before {
  content: "\e844";
}
.mdi-image-rotate-right:before {
  content: "\e845";
}
.mdi-image-slideshow:before {
  content: "\e846";
}
.mdi-image-straighten:before {
  content: "\e847";
}
.mdi-image-style:before {
  content: "\e848";
}
.mdi-image-switch-camera:before {
  content: "\e849";
}
.mdi-image-switch-video:before {
  content: "\e84a";
}
.mdi-image-tag-faces:before {
  content: "\e84b";
}
.mdi-image-texture:before {
  content: "\e84c";
}
.mdi-image-timelapse:before {
  content: "\e84d";
}
.mdi-image-timer:before {
  content: "\e84e";
}
.mdi-image-timer-3:before {
  content: "\e84f";
}
.mdi-image-timer-10:before {
  content: "\e850";
}
.mdi-image-timer-auto:before {
  content: "\e851";
}
.mdi-image-timer-off:before {
  content: "\e852";
}
.mdi-image-tonality:before {
  content: "\e853";
}
.mdi-image-transform:before {
  content: "\e854";
}
.mdi-image-tune:before {
  content: "\e855";
}
.mdi-image-wb-auto:before {
  content: "\e856";
}
.mdi-image-wb-cloudy:before {
  content: "\e857";
}
.mdi-image-wb-incandescent:before {
  content: "\e858";
}
.mdi-image-wb-irradescent:before {
  content: "\e859";
}
.mdi-image-wb-sunny:before {
  content: "\e85a";
}
.mdi-maps-beenhere:before {
  content: "\e85b";
}
.mdi-maps-directions:before {
  content: "\e85c";
}
.mdi-maps-directions-bike:before {
  content: "\e85d";
}
.mdi-maps-directions-bus:before {
  content: "\e85e";
}
.mdi-maps-directions-car:before {
  content: "\e85f";
}
.mdi-maps-directions-ferry:before {
  content: "\e860";
}
.mdi-maps-directions-subway:before {
  content: "\e861";
}
.mdi-maps-directions-train:before {
  content: "\e862";
}
.mdi-maps-directions-transit:before {
  content: "\e863";
}
.mdi-maps-directions-walk:before {
  content: "\e864";
}
.mdi-maps-flight:before {
  content: "\e865";
}
.mdi-maps-hotel:before {
  content: "\e866";
}
.mdi-maps-layers:before {
  content: "\e867";
}
.mdi-maps-layers-clear:before {
  content: "\e868";
}
.mdi-maps-local-airport:before {
  content: "\e869";
}
.mdi-maps-local-atm:before {
  content: "\e86a";
}
.mdi-maps-local-attraction:before {
  content: "\e86b";
}
.mdi-maps-local-bar:before {
  content: "\e86c";
}
.mdi-maps-local-cafe:before {
  content: "\e86d";
}
.mdi-maps-local-car-wash:before {
  content: "\e86e";
}
.mdi-maps-local-convenience-store:before {
  content: "\e86f";
}
.mdi-maps-local-drink:before {
  content: "\e870";
}
.mdi-maps-local-florist:before {
  content: "\e871";
}
.mdi-maps-local-gas-station:before {
  content: "\e872";
}
.mdi-maps-local-grocery-store:before {
  content: "\e873";
}
.mdi-maps-local-hospital:before {
  content: "\e874";
}
.mdi-maps-local-hotel:before {
  content: "\e875";
}
.mdi-maps-local-laundry-service:before {
  content: "\e876";
}
.mdi-maps-local-library:before {
  content: "\e877";
}
.mdi-maps-local-mall:before {
  content: "\e878";
}
.mdi-maps-local-movies:before {
  content: "\e879";
}
.mdi-maps-local-offer:before {
  content: "\e87a";
}
.mdi-maps-local-parking:before {
  content: "\e87b";
}
.mdi-maps-local-pharmacy:before {
  content: "\e87c";
}
.mdi-maps-local-phone:before {
  content: "\e87d";
}
.mdi-maps-local-pizza:before {
  content: "\e87e";
}
.mdi-maps-local-play:before {
  content: "\e87f";
}
.mdi-maps-local-post-office:before {
  content: "\e880";
}
.mdi-maps-local-print-shop:before {
  content: "\e881";
}
.mdi-maps-local-restaurant:before {
  content: "\e882";
}
.mdi-maps-local-see:before {
  content: "\e883";
}
.mdi-maps-local-shipping:before {
  content: "\e884";
}
.mdi-maps-local-taxi:before {
  content: "\e885";
}
.mdi-maps-location-history:before {
  content: "\e886";
}
.mdi-maps-map:before {
  content: "\e887";
}
.mdi-maps-my-location:before {
  content: "\e888";
}
.mdi-maps-navigation:before {
  content: "\e889";
}
.mdi-maps-pin-drop:before {
  content: "\e88a";
}
.mdi-maps-place:before {
  content: "\e88b";
}
.mdi-maps-rate-review:before {
  content: "\e88c";
}
.mdi-maps-restaurant-menu:before {
  content: "\e88d";
}
.mdi-maps-satellite:before {
  content: "\e88e";
}
.mdi-maps-store-mall-directory:before {
  content: "\e88f";
}
.mdi-maps-terrain:before {
  content: "\e890";
}
.mdi-maps-traffic:before {
  content: "\e891";
}
.mdi-navigation-apps:before {
  content: "\e892";
}
.mdi-navigation-arrow-back:before {
  content: "\e893";
}
.mdi-navigation-arrow-drop-down:before {
  content: "\e894";
}
.mdi-navigation-arrow-drop-down-circle:before {
  content: "\e895";
}
.mdi-navigation-arrow-drop-up:before {
  content: "\e896";
}
.mdi-navigation-arrow-forward:before {
  content: "\e897";
}
.mdi-navigation-cancel:before {
  content: "\e898";
}
.mdi-navigation-check:before {
  content: "\e899";
}
.mdi-navigation-chevron-left:before {
  content: "\e89a";
}
.mdi-navigation-chevron-right:before {
  content: "\e89b";
}
.mdi-navigation-close:before {
  content: "\e89c";
}
.mdi-navigation-expand-less:before {
  content: "\e89d";
}
.mdi-navigation-expand-more:before {
  content: "\e89e";
}
.mdi-navigation-fullscreen:before {
  content: "\e89f";
}
.mdi-navigation-fullscreen-exit:before {
  content: "\e8a0";
}
.mdi-navigation-menu:before {
  content: "\e8a1";
}
.mdi-navigation-more-horiz:before {
  content: "\e8a2";
}
.mdi-navigation-more-vert:before {
  content: "\e8a3";
}
.mdi-navigation-refresh:before {
  content: "\e8a4";
}
.mdi-navigation-unfold-less:before {
  content: "\e8a5";
}
.mdi-navigation-unfold-more:before {
  content: "\e8a6";
}
.mdi-notification-adb:before {
  content: "\e8a7";
}
.mdi-notification-bluetooth-audio:before {
  content: "\e8a8";
}
.mdi-notification-disc-full:before {
  content: "\e8a9";
}
.mdi-notification-dnd-forwardslash:before {
  content: "\e8aa";
}
.mdi-notification-do-not-disturb:before {
  content: "\e8ab";
}
.mdi-notification-drive-eta:before {
  content: "\e8ac";
}
.mdi-notification-event-available:before {
  content: "\e8ad";
}
.mdi-notification-event-busy:before {
  content: "\e8ae";
}
.mdi-notification-event-note:before {
  content: "\e8af";
}
.mdi-notification-folder-special:before {
  content: "\e8b0";
}
.mdi-notification-mms:before {
  content: "\e8b1";
}
.mdi-notification-more:before {
  content: "\e8b2";
}
.mdi-notification-network-locked:before {
  content: "\e8b3";
}
.mdi-notification-phone-bluetooth-speaker:before {
  content: "\e8b4";
}
.mdi-notification-phone-forwarded:before {
  content: "\e8b5";
}
.mdi-notification-phone-in-talk:before {
  content: "\e8b6";
}
.mdi-notification-phone-locked:before {
  content: "\e8b7";
}
.mdi-notification-phone-missed:before {
  content: "\e8b8";
}
.mdi-notification-phone-paused:before {
  content: "\e8b9";
}
.mdi-notification-play-download:before {
  content: "\e8ba";
}
.mdi-notification-play-install:before {
  content: "\e8bb";
}
.mdi-notification-sd-card:before {
  content: "\e8bc";
}
.mdi-notification-sim-card-alert:before {
  content: "\e8bd";
}
.mdi-notification-sms:before {
  content: "\e8be";
}
.mdi-notification-sms-failed:before {
  content: "\e8bf";
}
.mdi-notification-sync:before {
  content: "\e8c0";
}
.mdi-notification-sync-disabled:before {
  content: "\e8c1";
}
.mdi-notification-sync-problem:before {
  content: "\e8c2";
}
.mdi-notification-system-update:before {
  content: "\e8c3";
}
.mdi-notification-tap-and-play:before {
  content: "\e8c4";
}
.mdi-notification-time-to-leave:before {
  content: "\e8c5";
}
.mdi-notification-vibration:before {
  content: "\e8c6";
}
.mdi-notification-voice-chat:before {
  content: "\e8c7";
}
.mdi-notification-vpn-lock:before {
  content: "\e8c8";
}
.mdi-social-cake:before {
  content: "\e8c9";
}
.mdi-social-domain:before {
  content: "\e8ca";
}
.mdi-social-group:before {
  content: "\e8cb";
}
.mdi-social-group-add:before {
  content: "\e8cc";
}
.mdi-social-location-city:before {
  content: "\e8cd";
}
.mdi-social-mood:before {
  content: "\e8ce";
}
.mdi-social-notifications:before {
  content: "\e8cf";
}
.mdi-social-notifications-none:before {
  content: "\e8d0";
}
.mdi-social-notifications-off:before {
  content: "\e8d1";
}
.mdi-social-notifications-on:before {
  content: "\e8d2";
}
.mdi-social-notifications-paused:before {
  content: "\e8d3";
}
.mdi-social-pages:before {
  content: "\e8d4";
}
.mdi-social-party-mode:before {
  content: "\e8d5";
}
.mdi-social-people:before {
  content: "\e8d6";
}
.mdi-social-people-outline:before {
  content: "\e8d7";
}
.mdi-social-person:before {
  content: "\e8d8";
}
.mdi-social-person-add:before {
  content: "\e8d9";
}
.mdi-social-person-outline:before {
  content: "\e8da";
}
.mdi-social-plus-one:before {
  content: "\e8db";
}
.mdi-social-poll:before {
  content: "\e8dc";
}
.mdi-social-public:before {
  content: "\e8dd";
}
.mdi-social-school:before {
  content: "\e8de";
}
.mdi-social-share:before {
  content: "\e8df";
}
.mdi-social-whatshot:before {
  content: "\e8e0";
}
.mdi-toggle-check-box:before {
  content: "\e8e1";
}
.mdi-toggle-check-box-outline-blank:before {
  content: "\e8e2";
}
.mdi-toggle-radio-button-off:before {
  content: "\e8e3";
}
.mdi-toggle-radio-button-on:before {
  content: "\e8e4";
}
.shadow-z-1 {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
}
.shadow-z-2 {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
}
.shadow-z-2-hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
}
.shadow-z-3 {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
}
.shadow-z-4 {
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.22), 0 14px 45px rgba(0, 0, 0, 0.25);
}
.shadow-z-5 {
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.22), 0 19px 60px rgba(0, 0, 0, 0.3);
}
body {
  background-color: #EEEEEE;
}
body.inverse {
  background: #333333;
}
body.inverse,
body.inverse .form-control {
  color: rgba(255, 255, 255, 0.84);
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: "RobotoDraft", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}
h5,
h6 {
  font-weight: 400;
}
a,
a:hover,
a:focus {
  color: #009587;
}
body .well,
.container .well,
.container-fluid .well,
body .well:not([class^="well well-material-"]),
.container .well:not([class^="well well-material-"]),
.container-fluid .well:not([class^="well well-material-"]),
body .well .form-control,
.container .well .form-control,
.container-fluid .well .form-control,
body .well:not([class^="well well-material-"]) .form-control,
.container .well:not([class^="well well-material-"]) .form-control,
.container-fluid .well:not([class^="well well-material-"]) .form-control {
  color: rgba(0, 0, 0, 0.84);
}
body .well .floating-label,
.container .well .floating-label,
.container-fluid .well .floating-label,
body .well:not([class^="well well-material-"]) .floating-label,
.container .well:not([class^="well well-material-"]) .floating-label,
.container-fluid .well:not([class^="well well-material-"]) .floating-label {
  color: #7e7e7e;
}
body .well .form-control,
.container .well .form-control,
.container-fluid .well .form-control,
body .well:not([class^="well well-material-"]) .form-control,
.container .well:not([class^="well well-material-"]) .form-control,
.container-fluid .well:not([class^="well well-material-"]) .form-control {
  border-bottom-color: #7e7e7e;
}
body .well .form-control::-webkit-input-placeholder,
.container .well .form-control::-webkit-input-placeholder,
.container-fluid .well .form-control::-webkit-input-placeholder,
body .well:not([class^="well well-material-"]) .form-control::-webkit-input-placeholder,
.container .well:not([class^="well well-material-"]) .form-control::-webkit-input-placeholder,
.container-fluid .well:not([class^="well well-material-"]) .form-control::-webkit-input-placeholder {
  color: #7e7e7e;
}
body .well .form-control::-moz-placeholder,
.container .well .form-control::-moz-placeholder,
.container-fluid .well .form-control::-moz-placeholder,
body .well:not([class^="well well-material-"]) .form-control::-moz-placeholder,
.container .well:not([class^="well well-material-"]) .form-control::-moz-placeholder,
.container-fluid .well:not([class^="well well-material-"]) .form-control::-moz-placeholder {
  color: #7e7e7e;
  opacity: 1;
}
body .well .form-control:-ms-input-placeholder,
.container .well .form-control:-ms-input-placeholder,
.container-fluid .well .form-control:-ms-input-placeholder,
body .well:not([class^="well well-material-"]) .form-control:-ms-input-placeholder,
.container .well:not([class^="well well-material-"]) .form-control:-ms-input-placeholder,
.container-fluid .well:not([class^="well well-material-"]) .form-control:-ms-input-placeholder {
  color: #7e7e7e;
}
body .well .option,
.container .well .option,
.container-fluid .well .option,
body .well:not([class^="well well-material-"]) .option,
.container .well:not([class^="well well-material-"]) .option,
.container-fluid .well:not([class^="well well-material-"]) .option,
body .well .create,
.container .well .create,
.container-fluid .well .create,
body .well:not([class^="well well-material-"]) .create,
.container .well:not([class^="well well-material-"]) .create,
.container-fluid .well:not([class^="well well-material-"]) .create {
  color: rgba(0, 0, 0, 0.84);
}
body .well.well-sm,
.container .well.well-sm,
.container-fluid .well.well-sm {
  padding: 10px;
}
body .well.well-lg,
.container .well.well-lg,
.container-fluid .well.well-lg {
  padding: 26px;
}
body [class^="well well-material-"],
.container [class^="well well-material-"],
.container-fluid [class^="well well-material-"],
body [class^="well well-material-"] .form-control,
.container [class^="well well-material-"] .form-control,
.container-fluid [class^="well well-material-"] .form-control,
body [class^="well well-material-"] .floating-label,
.container [class^="well well-material-"] .floating-label,
.container-fluid [class^="well well-material-"] .floating-label {
  color: rgba(255, 255, 255, 0.84);
}
body [class^="well well-material-"] .form-control,
.container [class^="well well-material-"] .form-control,
.container-fluid [class^="well well-material-"] .form-control {
  border-bottom-color: rgba(255, 255, 255, 0.84);
}
body [class^="well well-material-"] .form-control::-webkit-input-placeholder,
.container [class^="well well-material-"] .form-control::-webkit-input-placeholder,
.container-fluid [class^="well well-material-"] .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
body [class^="well well-material-"] .form-control::-moz-placeholder,
.container [class^="well well-material-"] .form-control::-moz-placeholder,
.container-fluid [class^="well well-material-"] .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
  opacity: 1;
}
body [class^="well well-material-"] .form-control:-ms-input-placeholder,
.container [class^="well well-material-"] .form-control:-ms-input-placeholder,
.container-fluid [class^="well well-material-"] .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
body [class^="well well-material-"] .option,
.container [class^="well well-material-"] .option,
.container-fluid [class^="well well-material-"] .option,
body [class^="well well-material-"] .create,
.container [class^="well well-material-"] .create,
.container-fluid [class^="well well-material-"] .create {
  color: rgba(0, 0, 0, 0.84);
}
body .well,
.container .well,
.container-fluid .well,
body .jumbotron,
.container .jumbotron,
.container-fluid .jumbotron {
  background-color: #fff;
  padding: 19px;
  margin-bottom: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  border: 0;
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
body .well p,
.container .well p,
.container-fluid .well p,
body .jumbotron p,
.container .jumbotron p,
.container-fluid .jumbotron p {
  font-weight: 300;
}
body .well,
.container .well,
.container-fluid .well,
body .jumbotron,
.container .jumbotron,
.container-fluid .jumbotron,
body .well-default,
.container .well-default,
.container-fluid .well-default,
body .jumbotron-default,
.container .jumbotron-default,
.container-fluid .jumbotron-default {
  background-color: #ffffff;
}
body .well-primary,
.container .well-primary,
.container-fluid .well-primary,
body .jumbotron-primary,
.container .jumbotron-primary,
.container-fluid .jumbotron-primary {
  background-color: #009587;
}
body .well-success,
.container .well-success,
.container-fluid .well-success,
body .jumbotron-success,
.container .jumbotron-success,
.container-fluid .jumbotron-success {
  background-color: #0f9d58;
}
body .well-info,
.container .well-info,
.container-fluid .well-info,
body .jumbotron-info,
.container .jumbotron-info,
.container-fluid .jumbotron-info {
  background-color: #03a9f4;
}
body .well-warning,
.container .well-warning,
.container-fluid .well-warning,
body .jumbotron-warning,
.container .jumbotron-warning,
.container-fluid .jumbotron-warning {
  background-color: #ff5722;
}
body .well-danger,
.container .well-danger,
.container-fluid .well-danger,
body .jumbotron-danger,
.container .jumbotron-danger,
.container-fluid .jumbotron-danger {
  background-color: #f44336;
}
body .well-material-red,
.container .well-material-red,
.container-fluid .well-material-red,
body .jumbotron-material-red,
.container .jumbotron-material-red,
.container-fluid .jumbotron-material-red {
  background-color: #f44336;
}
body .well-material-red-50,
.container .well-material-red-50,
.container-fluid .well-material-red-50,
body .jumbotron-material-red-50,
.container .jumbotron-material-red-50,
.container-fluid .jumbotron-material-red-50 {
  background-color: #ffebee;
}
body .well-material-red-100,
.container .well-material-red-100,
.container-fluid .well-material-red-100,
body .jumbotron-material-red-100,
.container .jumbotron-material-red-100,
.container-fluid .jumbotron-material-red-100 {
  background-color: #ffcdd2;
}
body .well-material-red-200,
.container .well-material-red-200,
.container-fluid .well-material-red-200,
body .jumbotron-material-red-200,
.container .jumbotron-material-red-200,
.container-fluid .jumbotron-material-red-200 {
  background-color: #ef9a9a;
}
body .well-material-red-300,
.container .well-material-red-300,
.container-fluid .well-material-red-300,
body .jumbotron-material-red-300,
.container .jumbotron-material-red-300,
.container-fluid .jumbotron-material-red-300 {
  background-color: #e57373;
}
body .well-material-red-400,
.container .well-material-red-400,
.container-fluid .well-material-red-400,
body .jumbotron-material-red-400,
.container .jumbotron-material-red-400,
.container-fluid .jumbotron-material-red-400 {
  background-color: #ef5350;
}
body .well-material-red-500,
.container .well-material-red-500,
.container-fluid .well-material-red-500,
body .jumbotron-material-red-500,
.container .jumbotron-material-red-500,
.container-fluid .jumbotron-material-red-500 {
  background-color: #f44336;
}
body .well-material-red-600,
.container .well-material-red-600,
.container-fluid .well-material-red-600,
body .jumbotron-material-red-600,
.container .jumbotron-material-red-600,
.container-fluid .jumbotron-material-red-600 {
  background-color: #e53935;
}
body .well-material-red-700,
.container .well-material-red-700,
.container-fluid .well-material-red-700,
body .jumbotron-material-red-700,
.container .jumbotron-material-red-700,
.container-fluid .jumbotron-material-red-700 {
  background-color: #d32f2f;
}
body .well-material-red-800,
.container .well-material-red-800,
.container-fluid .well-material-red-800,
body .jumbotron-material-red-800,
.container .jumbotron-material-red-800,
.container-fluid .jumbotron-material-red-800 {
  background-color: #c62828;
}
body .well-material-red-900,
.container .well-material-red-900,
.container-fluid .well-material-red-900,
body .jumbotron-material-red-900,
.container .jumbotron-material-red-900,
.container-fluid .jumbotron-material-red-900 {
  background-color: #b71c1c;
}
body .well-material-red-A100,
.container .well-material-red-A100,
.container-fluid .well-material-red-A100,
body .jumbotron-material-red-A100,
.container .jumbotron-material-red-A100,
.container-fluid .jumbotron-material-red-A100 {
  background-color: #ff8a80;
}
body .well-material-red-A200,
.container .well-material-red-A200,
.container-fluid .well-material-red-A200,
body .jumbotron-material-red-A200,
.container .jumbotron-material-red-A200,
.container-fluid .jumbotron-material-red-A200 {
  background-color: #ff5252;
}
body .well-material-red-A400,
.container .well-material-red-A400,
.container-fluid .well-material-red-A400,
body .jumbotron-material-red-A400,
.container .jumbotron-material-red-A400,
.container-fluid .jumbotron-material-red-A400 {
  background-color: #ff1744;
}
body .well-material-red-A700,
.container .well-material-red-A700,
.container-fluid .well-material-red-A700,
body .jumbotron-material-red-A700,
.container .jumbotron-material-red-A700,
.container-fluid .jumbotron-material-red-A700 {
  background-color: #d50000;
}
body .well-material-pink,
.container .well-material-pink,
.container-fluid .well-material-pink,
body .jumbotron-material-pink,
.container .jumbotron-material-pink,
.container-fluid .jumbotron-material-pink {
  background-color: #e91e63;
}
body .well-material-pink-50,
.container .well-material-pink-50,
.container-fluid .well-material-pink-50,
body .jumbotron-material-pink-50,
.container .jumbotron-material-pink-50,
.container-fluid .jumbotron-material-pink-50 {
  background-color: #fce4ec;
}
body .well-material-pink-100,
.container .well-material-pink-100,
.container-fluid .well-material-pink-100,
body .jumbotron-material-pink-100,
.container .jumbotron-material-pink-100,
.container-fluid .jumbotron-material-pink-100 {
  background-color: #f8bbd0;
}
body .well-material-pink-200,
.container .well-material-pink-200,
.container-fluid .well-material-pink-200,
body .jumbotron-material-pink-200,
.container .jumbotron-material-pink-200,
.container-fluid .jumbotron-material-pink-200 {
  background-color: #f48fb1;
}
body .well-material-pink-300,
.container .well-material-pink-300,
.container-fluid .well-material-pink-300,
body .jumbotron-material-pink-300,
.container .jumbotron-material-pink-300,
.container-fluid .jumbotron-material-pink-300 {
  background-color: #f06292;
}
body .well-material-pink-400,
.container .well-material-pink-400,
.container-fluid .well-material-pink-400,
body .jumbotron-material-pink-400,
.container .jumbotron-material-pink-400,
.container-fluid .jumbotron-material-pink-400 {
  background-color: #ec407a;
}
body .well-material-pink-500,
.container .well-material-pink-500,
.container-fluid .well-material-pink-500,
body .jumbotron-material-pink-500,
.container .jumbotron-material-pink-500,
.container-fluid .jumbotron-material-pink-500 {
  background-color: #e91e63;
}
body .well-material-pink-600,
.container .well-material-pink-600,
.container-fluid .well-material-pink-600,
body .jumbotron-material-pink-600,
.container .jumbotron-material-pink-600,
.container-fluid .jumbotron-material-pink-600 {
  background-color: #d81b60;
}
body .well-material-pink-700,
.container .well-material-pink-700,
.container-fluid .well-material-pink-700,
body .jumbotron-material-pink-700,
.container .jumbotron-material-pink-700,
.container-fluid .jumbotron-material-pink-700 {
  background-color: #c2185b;
}
body .well-material-pink-800,
.container .well-material-pink-800,
.container-fluid .well-material-pink-800,
body .jumbotron-material-pink-800,
.container .jumbotron-material-pink-800,
.container-fluid .jumbotron-material-pink-800 {
  background-color: #ad1457;
}
body .well-material-pink-900,
.container .well-material-pink-900,
.container-fluid .well-material-pink-900,
body .jumbotron-material-pink-900,
.container .jumbotron-material-pink-900,
.container-fluid .jumbotron-material-pink-900 {
  background-color: #880e4f;
}
body .well-material-pink-A100,
.container .well-material-pink-A100,
.container-fluid .well-material-pink-A100,
body .jumbotron-material-pink-A100,
.container .jumbotron-material-pink-A100,
.container-fluid .jumbotron-material-pink-A100 {
  background-color: #ff80ab;
}
body .well-material-pink-A200,
.container .well-material-pink-A200,
.container-fluid .well-material-pink-A200,
body .jumbotron-material-pink-A200,
.container .jumbotron-material-pink-A200,
.container-fluid .jumbotron-material-pink-A200 {
  background-color: #ff4081;
}
body .well-material-pink-A400,
.container .well-material-pink-A400,
.container-fluid .well-material-pink-A400,
body .jumbotron-material-pink-A400,
.container .jumbotron-material-pink-A400,
.container-fluid .jumbotron-material-pink-A400 {
  background-color: #f50057;
}
body .well-material-pink-A700,
.container .well-material-pink-A700,
.container-fluid .well-material-pink-A700,
body .jumbotron-material-pink-A700,
.container .jumbotron-material-pink-A700,
.container-fluid .jumbotron-material-pink-A700 {
  background-color: #c51162;
}
body .well-material-purple,
.container .well-material-purple,
.container-fluid .well-material-purple,
body .jumbotron-material-purple,
.container .jumbotron-material-purple,
.container-fluid .jumbotron-material-purple {
  background-color: #9c27b0;
}
body .well-material-purple-50,
.container .well-material-purple-50,
.container-fluid .well-material-purple-50,
body .jumbotron-material-purple-50,
.container .jumbotron-material-purple-50,
.container-fluid .jumbotron-material-purple-50 {
  background-color: #f3e5f5;
}
body .well-material-purple-100,
.container .well-material-purple-100,
.container-fluid .well-material-purple-100,
body .jumbotron-material-purple-100,
.container .jumbotron-material-purple-100,
.container-fluid .jumbotron-material-purple-100 {
  background-color: #e1bee7;
}
body .well-material-purple-200,
.container .well-material-purple-200,
.container-fluid .well-material-purple-200,
body .jumbotron-material-purple-200,
.container .jumbotron-material-purple-200,
.container-fluid .jumbotron-material-purple-200 {
  background-color: #ce93d8;
}
body .well-material-purple-300,
.container .well-material-purple-300,
.container-fluid .well-material-purple-300,
body .jumbotron-material-purple-300,
.container .jumbotron-material-purple-300,
.container-fluid .jumbotron-material-purple-300 {
  background-color: #ba68c8;
}
body .well-material-purple-400,
.container .well-material-purple-400,
.container-fluid .well-material-purple-400,
body .jumbotron-material-purple-400,
.container .jumbotron-material-purple-400,
.container-fluid .jumbotron-material-purple-400 {
  background-color: #ab47bc;
}
body .well-material-purple-500,
.container .well-material-purple-500,
.container-fluid .well-material-purple-500,
body .jumbotron-material-purple-500,
.container .jumbotron-material-purple-500,
.container-fluid .jumbotron-material-purple-500 {
  background-color: #9c27b0;
}
body .well-material-purple-600,
.container .well-material-purple-600,
.container-fluid .well-material-purple-600,
body .jumbotron-material-purple-600,
.container .jumbotron-material-purple-600,
.container-fluid .jumbotron-material-purple-600 {
  background-color: #8e24aa;
}
body .well-material-purple-700,
.container .well-material-purple-700,
.container-fluid .well-material-purple-700,
body .jumbotron-material-purple-700,
.container .jumbotron-material-purple-700,
.container-fluid .jumbotron-material-purple-700 {
  background-color: #7b1fa2;
}
body .well-material-purple-800,
.container .well-material-purple-800,
.container-fluid .well-material-purple-800,
body .jumbotron-material-purple-800,
.container .jumbotron-material-purple-800,
.container-fluid .jumbotron-material-purple-800 {
  background-color: #6a1b9a;
}
body .well-material-purple-900,
.container .well-material-purple-900,
.container-fluid .well-material-purple-900,
body .jumbotron-material-purple-900,
.container .jumbotron-material-purple-900,
.container-fluid .jumbotron-material-purple-900 {
  background-color: #4a148c;
}
body .well-material-purple-A100,
.container .well-material-purple-A100,
.container-fluid .well-material-purple-A100,
body .jumbotron-material-purple-A100,
.container .jumbotron-material-purple-A100,
.container-fluid .jumbotron-material-purple-A100 {
  background-color: #ea80fc;
}
body .well-material-purple-A200,
.container .well-material-purple-A200,
.container-fluid .well-material-purple-A200,
body .jumbotron-material-purple-A200,
.container .jumbotron-material-purple-A200,
.container-fluid .jumbotron-material-purple-A200 {
  background-color: #e040fb;
}
body .well-material-purple-A400,
.container .well-material-purple-A400,
.container-fluid .well-material-purple-A400,
body .jumbotron-material-purple-A400,
.container .jumbotron-material-purple-A400,
.container-fluid .jumbotron-material-purple-A400 {
  background-color: #d500f9;
}
body .well-material-purple-A700,
.container .well-material-purple-A700,
.container-fluid .well-material-purple-A700,
body .jumbotron-material-purple-A700,
.container .jumbotron-material-purple-A700,
.container-fluid .jumbotron-material-purple-A700 {
  background-color: #aa00ff;
}
body .well-material-deep-purple,
.container .well-material-deep-purple,
.container-fluid .well-material-deep-purple,
body .jumbotron-material-deep-purple,
.container .jumbotron-material-deep-purple,
.container-fluid .jumbotron-material-deep-purple {
  background-color: #673ab7;
}
body .well-material-deep-purple-50,
.container .well-material-deep-purple-50,
.container-fluid .well-material-deep-purple-50,
body .jumbotron-material-deep-purple-50,
.container .jumbotron-material-deep-purple-50,
.container-fluid .jumbotron-material-deep-purple-50 {
  background-color: #ede7f6;
}
body .well-material-deep-purple-100,
.container .well-material-deep-purple-100,
.container-fluid .well-material-deep-purple-100,
body .jumbotron-material-deep-purple-100,
.container .jumbotron-material-deep-purple-100,
.container-fluid .jumbotron-material-deep-purple-100 {
  background-color: #d1c4e9;
}
body .well-material-deep-purple-200,
.container .well-material-deep-purple-200,
.container-fluid .well-material-deep-purple-200,
body .jumbotron-material-deep-purple-200,
.container .jumbotron-material-deep-purple-200,
.container-fluid .jumbotron-material-deep-purple-200 {
  background-color: #b39ddb;
}
body .well-material-deep-purple-300,
.container .well-material-deep-purple-300,
.container-fluid .well-material-deep-purple-300,
body .jumbotron-material-deep-purple-300,
.container .jumbotron-material-deep-purple-300,
.container-fluid .jumbotron-material-deep-purple-300 {
  background-color: #9575cd;
}
body .well-material-deep-purple-400,
.container .well-material-deep-purple-400,
.container-fluid .well-material-deep-purple-400,
body .jumbotron-material-deep-purple-400,
.container .jumbotron-material-deep-purple-400,
.container-fluid .jumbotron-material-deep-purple-400 {
  background-color: #7e57c2;
}
body .well-material-deep-purple-500,
.container .well-material-deep-purple-500,
.container-fluid .well-material-deep-purple-500,
body .jumbotron-material-deep-purple-500,
.container .jumbotron-material-deep-purple-500,
.container-fluid .jumbotron-material-deep-purple-500 {
  background-color: #673ab7;
}
body .well-material-deep-purple-600,
.container .well-material-deep-purple-600,
.container-fluid .well-material-deep-purple-600,
body .jumbotron-material-deep-purple-600,
.container .jumbotron-material-deep-purple-600,
.container-fluid .jumbotron-material-deep-purple-600 {
  background-color: #5e35b1;
}
body .well-material-deep-purple-700,
.container .well-material-deep-purple-700,
.container-fluid .well-material-deep-purple-700,
body .jumbotron-material-deep-purple-700,
.container .jumbotron-material-deep-purple-700,
.container-fluid .jumbotron-material-deep-purple-700 {
  background-color: #512da8;
}
body .well-material-deep-purple-800,
.container .well-material-deep-purple-800,
.container-fluid .well-material-deep-purple-800,
body .jumbotron-material-deep-purple-800,
.container .jumbotron-material-deep-purple-800,
.container-fluid .jumbotron-material-deep-purple-800 {
  background-color: #4527a0;
}
body .well-material-deep-purple-900,
.container .well-material-deep-purple-900,
.container-fluid .well-material-deep-purple-900,
body .jumbotron-material-deep-purple-900,
.container .jumbotron-material-deep-purple-900,
.container-fluid .jumbotron-material-deep-purple-900 {
  background-color: #311b92;
}
body .well-material-deep-purple-A100,
.container .well-material-deep-purple-A100,
.container-fluid .well-material-deep-purple-A100,
body .jumbotron-material-deep-purple-A100,
.container .jumbotron-material-deep-purple-A100,
.container-fluid .jumbotron-material-deep-purple-A100 {
  background-color: #b388ff;
}
body .well-material-deep-purple-A200,
.container .well-material-deep-purple-A200,
.container-fluid .well-material-deep-purple-A200,
body .jumbotron-material-deep-purple-A200,
.container .jumbotron-material-deep-purple-A200,
.container-fluid .jumbotron-material-deep-purple-A200 {
  background-color: #7c4dff;
}
body .well-material-deep-purple-A400,
.container .well-material-deep-purple-A400,
.container-fluid .well-material-deep-purple-A400,
body .jumbotron-material-deep-purple-A400,
.container .jumbotron-material-deep-purple-A400,
.container-fluid .jumbotron-material-deep-purple-A400 {
  background-color: #651fff;
}
body .well-material-deep-purple-A700,
.container .well-material-deep-purple-A700,
.container-fluid .well-material-deep-purple-A700,
body .jumbotron-material-deep-purple-A700,
.container .jumbotron-material-deep-purple-A700,
.container-fluid .jumbotron-material-deep-purple-A700 {
  background-color: #6200ea;
}
body .well-material-indigo,
.container .well-material-indigo,
.container-fluid .well-material-indigo,
body .jumbotron-material-indigo,
.container .jumbotron-material-indigo,
.container-fluid .jumbotron-material-indigo {
  background-color: #3f51b5;
}
body .well-material-indigo-50,
.container .well-material-indigo-50,
.container-fluid .well-material-indigo-50,
body .jumbotron-material-indigo-50,
.container .jumbotron-material-indigo-50,
.container-fluid .jumbotron-material-indigo-50 {
  background-color: #e8eaf6;
}
body .well-material-indigo-100,
.container .well-material-indigo-100,
.container-fluid .well-material-indigo-100,
body .jumbotron-material-indigo-100,
.container .jumbotron-material-indigo-100,
.container-fluid .jumbotron-material-indigo-100 {
  background-color: #c5cae9;
}
body .well-material-indigo-200,
.container .well-material-indigo-200,
.container-fluid .well-material-indigo-200,
body .jumbotron-material-indigo-200,
.container .jumbotron-material-indigo-200,
.container-fluid .jumbotron-material-indigo-200 {
  background-color: #9fa8da;
}
body .well-material-indigo-300,
.container .well-material-indigo-300,
.container-fluid .well-material-indigo-300,
body .jumbotron-material-indigo-300,
.container .jumbotron-material-indigo-300,
.container-fluid .jumbotron-material-indigo-300 {
  background-color: #7986cb;
}
body .well-material-indigo-400,
.container .well-material-indigo-400,
.container-fluid .well-material-indigo-400,
body .jumbotron-material-indigo-400,
.container .jumbotron-material-indigo-400,
.container-fluid .jumbotron-material-indigo-400 {
  background-color: #5c6bc0;
}
body .well-material-indigo-500,
.container .well-material-indigo-500,
.container-fluid .well-material-indigo-500,
body .jumbotron-material-indigo-500,
.container .jumbotron-material-indigo-500,
.container-fluid .jumbotron-material-indigo-500 {
  background-color: #3f51b5;
}
body .well-material-indigo-600,
.container .well-material-indigo-600,
.container-fluid .well-material-indigo-600,
body .jumbotron-material-indigo-600,
.container .jumbotron-material-indigo-600,
.container-fluid .jumbotron-material-indigo-600 {
  background-color: #3949ab;
}
body .well-material-indigo-700,
.container .well-material-indigo-700,
.container-fluid .well-material-indigo-700,
body .jumbotron-material-indigo-700,
.container .jumbotron-material-indigo-700,
.container-fluid .jumbotron-material-indigo-700 {
  background-color: #303f9f;
}
body .well-material-indigo-800,
.container .well-material-indigo-800,
.container-fluid .well-material-indigo-800,
body .jumbotron-material-indigo-800,
.container .jumbotron-material-indigo-800,
.container-fluid .jumbotron-material-indigo-800 {
  background-color: #283593;
}
body .well-material-indigo-900,
.container .well-material-indigo-900,
.container-fluid .well-material-indigo-900,
body .jumbotron-material-indigo-900,
.container .jumbotron-material-indigo-900,
.container-fluid .jumbotron-material-indigo-900 {
  background-color: #1a237e;
}
body .well-material-indigo-A100,
.container .well-material-indigo-A100,
.container-fluid .well-material-indigo-A100,
body .jumbotron-material-indigo-A100,
.container .jumbotron-material-indigo-A100,
.container-fluid .jumbotron-material-indigo-A100 {
  background-color: #8c9eff;
}
body .well-material-indigo-A200,
.container .well-material-indigo-A200,
.container-fluid .well-material-indigo-A200,
body .jumbotron-material-indigo-A200,
.container .jumbotron-material-indigo-A200,
.container-fluid .jumbotron-material-indigo-A200 {
  background-color: #536dfe;
}
body .well-material-indigo-A400,
.container .well-material-indigo-A400,
.container-fluid .well-material-indigo-A400,
body .jumbotron-material-indigo-A400,
.container .jumbotron-material-indigo-A400,
.container-fluid .jumbotron-material-indigo-A400 {
  background-color: #3d5afe;
}
body .well-material-indigo-A700,
.container .well-material-indigo-A700,
.container-fluid .well-material-indigo-A700,
body .jumbotron-material-indigo-A700,
.container .jumbotron-material-indigo-A700,
.container-fluid .jumbotron-material-indigo-A700 {
  background-color: #304ffe;
}
body .well-material-blue,
.container .well-material-blue,
.container-fluid .well-material-blue,
body .jumbotron-material-blue,
.container .jumbotron-material-blue,
.container-fluid .jumbotron-material-blue {
  background-color: #2196f3;
}
body .well-material-blue-50,
.container .well-material-blue-50,
.container-fluid .well-material-blue-50,
body .jumbotron-material-blue-50,
.container .jumbotron-material-blue-50,
.container-fluid .jumbotron-material-blue-50 {
  background-color: #e3f2fd;
}
body .well-material-blue-100,
.container .well-material-blue-100,
.container-fluid .well-material-blue-100,
body .jumbotron-material-blue-100,
.container .jumbotron-material-blue-100,
.container-fluid .jumbotron-material-blue-100 {
  background-color: #bbdefb;
}
body .well-material-blue-200,
.container .well-material-blue-200,
.container-fluid .well-material-blue-200,
body .jumbotron-material-blue-200,
.container .jumbotron-material-blue-200,
.container-fluid .jumbotron-material-blue-200 {
  background-color: #90caf9;
}
body .well-material-blue-300,
.container .well-material-blue-300,
.container-fluid .well-material-blue-300,
body .jumbotron-material-blue-300,
.container .jumbotron-material-blue-300,
.container-fluid .jumbotron-material-blue-300 {
  background-color: #64b5f6;
}
body .well-material-blue-400,
.container .well-material-blue-400,
.container-fluid .well-material-blue-400,
body .jumbotron-material-blue-400,
.container .jumbotron-material-blue-400,
.container-fluid .jumbotron-material-blue-400 {
  background-color: #42a5f5;
}
body .well-material-blue-500,
.container .well-material-blue-500,
.container-fluid .well-material-blue-500,
body .jumbotron-material-blue-500,
.container .jumbotron-material-blue-500,
.container-fluid .jumbotron-material-blue-500 {
  background-color: #2196f3;
}
body .well-material-blue-600,
.container .well-material-blue-600,
.container-fluid .well-material-blue-600,
body .jumbotron-material-blue-600,
.container .jumbotron-material-blue-600,
.container-fluid .jumbotron-material-blue-600 {
  background-color: #1e88e5;
}
body .well-material-blue-700,
.container .well-material-blue-700,
.container-fluid .well-material-blue-700,
body .jumbotron-material-blue-700,
.container .jumbotron-material-blue-700,
.container-fluid .jumbotron-material-blue-700 {
  background-color: #1976d2;
}
body .well-material-blue-800,
.container .well-material-blue-800,
.container-fluid .well-material-blue-800,
body .jumbotron-material-blue-800,
.container .jumbotron-material-blue-800,
.container-fluid .jumbotron-material-blue-800 {
  background-color: #1565c0;
}
body .well-material-blue-900,
.container .well-material-blue-900,
.container-fluid .well-material-blue-900,
body .jumbotron-material-blue-900,
.container .jumbotron-material-blue-900,
.container-fluid .jumbotron-material-blue-900 {
  background-color: #0d47a1;
}
body .well-material-blue-A100,
.container .well-material-blue-A100,
.container-fluid .well-material-blue-A100,
body .jumbotron-material-blue-A100,
.container .jumbotron-material-blue-A100,
.container-fluid .jumbotron-material-blue-A100 {
  background-color: #82b1ff;
}
body .well-material-blue-A200,
.container .well-material-blue-A200,
.container-fluid .well-material-blue-A200,
body .jumbotron-material-blue-A200,
.container .jumbotron-material-blue-A200,
.container-fluid .jumbotron-material-blue-A200 {
  background-color: #448aff;
}
body .well-material-blue-A400,
.container .well-material-blue-A400,
.container-fluid .well-material-blue-A400,
body .jumbotron-material-blue-A400,
.container .jumbotron-material-blue-A400,
.container-fluid .jumbotron-material-blue-A400 {
  background-color: #2979ff;
}
body .well-material-blue-A700,
.container .well-material-blue-A700,
.container-fluid .well-material-blue-A700,
body .jumbotron-material-blue-A700,
.container .jumbotron-material-blue-A700,
.container-fluid .jumbotron-material-blue-A700 {
  background-color: #2962ff;
}
body .well-material-light-blue,
.container .well-material-light-blue,
.container-fluid .well-material-light-blue,
body .jumbotron-material-light-blue,
.container .jumbotron-material-light-blue,
.container-fluid .jumbotron-material-light-blue {
  background-color: #03a9f4;
}
body .well-material-light-blue-50,
.container .well-material-light-blue-50,
.container-fluid .well-material-light-blue-50,
body .jumbotron-material-light-blue-50,
.container .jumbotron-material-light-blue-50,
.container-fluid .jumbotron-material-light-blue-50 {
  background-color: #e1f5fe;
}
body .well-material-light-blue-100,
.container .well-material-light-blue-100,
.container-fluid .well-material-light-blue-100,
body .jumbotron-material-light-blue-100,
.container .jumbotron-material-light-blue-100,
.container-fluid .jumbotron-material-light-blue-100 {
  background-color: #b3e5fc;
}
body .well-material-light-blue-200,
.container .well-material-light-blue-200,
.container-fluid .well-material-light-blue-200,
body .jumbotron-material-light-blue-200,
.container .jumbotron-material-light-blue-200,
.container-fluid .jumbotron-material-light-blue-200 {
  background-color: #81d4fa;
}
body .well-material-light-blue-300,
.container .well-material-light-blue-300,
.container-fluid .well-material-light-blue-300,
body .jumbotron-material-light-blue-300,
.container .jumbotron-material-light-blue-300,
.container-fluid .jumbotron-material-light-blue-300 {
  background-color: #4fc3f7;
}
body .well-material-light-blue-400,
.container .well-material-light-blue-400,
.container-fluid .well-material-light-blue-400,
body .jumbotron-material-light-blue-400,
.container .jumbotron-material-light-blue-400,
.container-fluid .jumbotron-material-light-blue-400 {
  background-color: #29b6f6;
}
body .well-material-light-blue-500,
.container .well-material-light-blue-500,
.container-fluid .well-material-light-blue-500,
body .jumbotron-material-light-blue-500,
.container .jumbotron-material-light-blue-500,
.container-fluid .jumbotron-material-light-blue-500 {
  background-color: #03a9f4;
}
body .well-material-light-blue-600,
.container .well-material-light-blue-600,
.container-fluid .well-material-light-blue-600,
body .jumbotron-material-light-blue-600,
.container .jumbotron-material-light-blue-600,
.container-fluid .jumbotron-material-light-blue-600 {
  background-color: #039be5;
}
body .well-material-light-blue-700,
.container .well-material-light-blue-700,
.container-fluid .well-material-light-blue-700,
body .jumbotron-material-light-blue-700,
.container .jumbotron-material-light-blue-700,
.container-fluid .jumbotron-material-light-blue-700 {
  background-color: #0288d1;
}
body .well-material-light-blue-800,
.container .well-material-light-blue-800,
.container-fluid .well-material-light-blue-800,
body .jumbotron-material-light-blue-800,
.container .jumbotron-material-light-blue-800,
.container-fluid .jumbotron-material-light-blue-800 {
  background-color: #0277bd;
}
body .well-material-light-blue-900,
.container .well-material-light-blue-900,
.container-fluid .well-material-light-blue-900,
body .jumbotron-material-light-blue-900,
.container .jumbotron-material-light-blue-900,
.container-fluid .jumbotron-material-light-blue-900 {
  background-color: #01579b;
}
body .well-material-light-blue-A100,
.container .well-material-light-blue-A100,
.container-fluid .well-material-light-blue-A100,
body .jumbotron-material-light-blue-A100,
.container .jumbotron-material-light-blue-A100,
.container-fluid .jumbotron-material-light-blue-A100 {
  background-color: #80d8ff;
}
body .well-material-light-blue-A200,
.container .well-material-light-blue-A200,
.container-fluid .well-material-light-blue-A200,
body .jumbotron-material-light-blue-A200,
.container .jumbotron-material-light-blue-A200,
.container-fluid .jumbotron-material-light-blue-A200 {
  background-color: #40c4ff;
}
body .well-material-light-blue-A400,
.container .well-material-light-blue-A400,
.container-fluid .well-material-light-blue-A400,
body .jumbotron-material-light-blue-A400,
.container .jumbotron-material-light-blue-A400,
.container-fluid .jumbotron-material-light-blue-A400 {
  background-color: #00b0ff;
}
body .well-material-light-blue-A700,
.container .well-material-light-blue-A700,
.container-fluid .well-material-light-blue-A700,
body .jumbotron-material-light-blue-A700,
.container .jumbotron-material-light-blue-A700,
.container-fluid .jumbotron-material-light-blue-A700 {
  background-color: #0091ea;
}
body .well-material-cyan,
.container .well-material-cyan,
.container-fluid .well-material-cyan,
body .jumbotron-material-cyan,
.container .jumbotron-material-cyan,
.container-fluid .jumbotron-material-cyan {
  background-color: #00bcd4;
}
body .well-material-cyan-50,
.container .well-material-cyan-50,
.container-fluid .well-material-cyan-50,
body .jumbotron-material-cyan-50,
.container .jumbotron-material-cyan-50,
.container-fluid .jumbotron-material-cyan-50 {
  background-color: #e0f7fa;
}
body .well-material-cyan-100,
.container .well-material-cyan-100,
.container-fluid .well-material-cyan-100,
body .jumbotron-material-cyan-100,
.container .jumbotron-material-cyan-100,
.container-fluid .jumbotron-material-cyan-100 {
  background-color: #b2ebf2;
}
body .well-material-cyan-200,
.container .well-material-cyan-200,
.container-fluid .well-material-cyan-200,
body .jumbotron-material-cyan-200,
.container .jumbotron-material-cyan-200,
.container-fluid .jumbotron-material-cyan-200 {
  background-color: #80deea;
}
body .well-material-cyan-300,
.container .well-material-cyan-300,
.container-fluid .well-material-cyan-300,
body .jumbotron-material-cyan-300,
.container .jumbotron-material-cyan-300,
.container-fluid .jumbotron-material-cyan-300 {
  background-color: #4dd0e1;
}
body .well-material-cyan-400,
.container .well-material-cyan-400,
.container-fluid .well-material-cyan-400,
body .jumbotron-material-cyan-400,
.container .jumbotron-material-cyan-400,
.container-fluid .jumbotron-material-cyan-400 {
  background-color: #26c6da;
}
body .well-material-cyan-500,
.container .well-material-cyan-500,
.container-fluid .well-material-cyan-500,
body .jumbotron-material-cyan-500,
.container .jumbotron-material-cyan-500,
.container-fluid .jumbotron-material-cyan-500 {
  background-color: #00bcd4;
}
body .well-material-cyan-600,
.container .well-material-cyan-600,
.container-fluid .well-material-cyan-600,
body .jumbotron-material-cyan-600,
.container .jumbotron-material-cyan-600,
.container-fluid .jumbotron-material-cyan-600 {
  background-color: #00acc1;
}
body .well-material-cyan-700,
.container .well-material-cyan-700,
.container-fluid .well-material-cyan-700,
body .jumbotron-material-cyan-700,
.container .jumbotron-material-cyan-700,
.container-fluid .jumbotron-material-cyan-700 {
  background-color: #0097a7;
}
body .well-material-cyan-800,
.container .well-material-cyan-800,
.container-fluid .well-material-cyan-800,
body .jumbotron-material-cyan-800,
.container .jumbotron-material-cyan-800,
.container-fluid .jumbotron-material-cyan-800 {
  background-color: #00838f;
}
body .well-material-cyan-900,
.container .well-material-cyan-900,
.container-fluid .well-material-cyan-900,
body .jumbotron-material-cyan-900,
.container .jumbotron-material-cyan-900,
.container-fluid .jumbotron-material-cyan-900 {
  background-color: #006064;
}
body .well-material-cyan-A100,
.container .well-material-cyan-A100,
.container-fluid .well-material-cyan-A100,
body .jumbotron-material-cyan-A100,
.container .jumbotron-material-cyan-A100,
.container-fluid .jumbotron-material-cyan-A100 {
  background-color: #84ffff;
}
body .well-material-cyan-A200,
.container .well-material-cyan-A200,
.container-fluid .well-material-cyan-A200,
body .jumbotron-material-cyan-A200,
.container .jumbotron-material-cyan-A200,
.container-fluid .jumbotron-material-cyan-A200 {
  background-color: #18ffff;
}
body .well-material-cyan-A400,
.container .well-material-cyan-A400,
.container-fluid .well-material-cyan-A400,
body .jumbotron-material-cyan-A400,
.container .jumbotron-material-cyan-A400,
.container-fluid .jumbotron-material-cyan-A400 {
  background-color: #00e5ff;
}
body .well-material-cyan-A700,
.container .well-material-cyan-A700,
.container-fluid .well-material-cyan-A700,
body .jumbotron-material-cyan-A700,
.container .jumbotron-material-cyan-A700,
.container-fluid .jumbotron-material-cyan-A700 {
  background-color: #00b8d4;
}
body .well-material-teal,
.container .well-material-teal,
.container-fluid .well-material-teal,
body .jumbotron-material-teal,
.container .jumbotron-material-teal,
.container-fluid .jumbotron-material-teal {
  background-color: #009688;
}
body .well-material-teal-50,
.container .well-material-teal-50,
.container-fluid .well-material-teal-50,
body .jumbotron-material-teal-50,
.container .jumbotron-material-teal-50,
.container-fluid .jumbotron-material-teal-50 {
  background-color: #e0f2f1;
}
body .well-material-teal-100,
.container .well-material-teal-100,
.container-fluid .well-material-teal-100,
body .jumbotron-material-teal-100,
.container .jumbotron-material-teal-100,
.container-fluid .jumbotron-material-teal-100 {
  background-color: #b2dfdb;
}
body .well-material-teal-200,
.container .well-material-teal-200,
.container-fluid .well-material-teal-200,
body .jumbotron-material-teal-200,
.container .jumbotron-material-teal-200,
.container-fluid .jumbotron-material-teal-200 {
  background-color: #80cbc4;
}
body .well-material-teal-300,
.container .well-material-teal-300,
.container-fluid .well-material-teal-300,
body .jumbotron-material-teal-300,
.container .jumbotron-material-teal-300,
.container-fluid .jumbotron-material-teal-300 {
  background-color: #4db6ac;
}
body .well-material-teal-400,
.container .well-material-teal-400,
.container-fluid .well-material-teal-400,
body .jumbotron-material-teal-400,
.container .jumbotron-material-teal-400,
.container-fluid .jumbotron-material-teal-400 {
  background-color: #26a69a;
}
body .well-material-teal-500,
.container .well-material-teal-500,
.container-fluid .well-material-teal-500,
body .jumbotron-material-teal-500,
.container .jumbotron-material-teal-500,
.container-fluid .jumbotron-material-teal-500 {
  background-color: #009688;
}
body .well-material-teal-600,
.container .well-material-teal-600,
.container-fluid .well-material-teal-600,
body .jumbotron-material-teal-600,
.container .jumbotron-material-teal-600,
.container-fluid .jumbotron-material-teal-600 {
  background-color: #00897b;
}
body .well-material-teal-700,
.container .well-material-teal-700,
.container-fluid .well-material-teal-700,
body .jumbotron-material-teal-700,
.container .jumbotron-material-teal-700,
.container-fluid .jumbotron-material-teal-700 {
  background-color: #00796b;
}
body .well-material-teal-800,
.container .well-material-teal-800,
.container-fluid .well-material-teal-800,
body .jumbotron-material-teal-800,
.container .jumbotron-material-teal-800,
.container-fluid .jumbotron-material-teal-800 {
  background-color: #00695c;
}
body .well-material-teal-900,
.container .well-material-teal-900,
.container-fluid .well-material-teal-900,
body .jumbotron-material-teal-900,
.container .jumbotron-material-teal-900,
.container-fluid .jumbotron-material-teal-900 {
  background-color: #004d40;
}
body .well-material-teal-A100,
.container .well-material-teal-A100,
.container-fluid .well-material-teal-A100,
body .jumbotron-material-teal-A100,
.container .jumbotron-material-teal-A100,
.container-fluid .jumbotron-material-teal-A100 {
  background-color: #a7ffeb;
}
body .well-material-teal-A200,
.container .well-material-teal-A200,
.container-fluid .well-material-teal-A200,
body .jumbotron-material-teal-A200,
.container .jumbotron-material-teal-A200,
.container-fluid .jumbotron-material-teal-A200 {
  background-color: #64ffda;
}
body .well-material-teal-A400,
.container .well-material-teal-A400,
.container-fluid .well-material-teal-A400,
body .jumbotron-material-teal-A400,
.container .jumbotron-material-teal-A400,
.container-fluid .jumbotron-material-teal-A400 {
  background-color: #1de9b6;
}
body .well-material-teal-A700,
.container .well-material-teal-A700,
.container-fluid .well-material-teal-A700,
body .jumbotron-material-teal-A700,
.container .jumbotron-material-teal-A700,
.container-fluid .jumbotron-material-teal-A700 {
  background-color: #00bfa5;
}
body .well-material-green,
.container .well-material-green,
.container-fluid .well-material-green,
body .jumbotron-material-green,
.container .jumbotron-material-green,
.container-fluid .jumbotron-material-green {
  background-color: #4caf50;
}
body .well-material-green-50,
.container .well-material-green-50,
.container-fluid .well-material-green-50,
body .jumbotron-material-green-50,
.container .jumbotron-material-green-50,
.container-fluid .jumbotron-material-green-50 {
  background-color: #e8f5e9;
}
body .well-material-green-100,
.container .well-material-green-100,
.container-fluid .well-material-green-100,
body .jumbotron-material-green-100,
.container .jumbotron-material-green-100,
.container-fluid .jumbotron-material-green-100 {
  background-color: #c8e6c9;
}
body .well-material-green-200,
.container .well-material-green-200,
.container-fluid .well-material-green-200,
body .jumbotron-material-green-200,
.container .jumbotron-material-green-200,
.container-fluid .jumbotron-material-green-200 {
  background-color: #a5d6a7;
}
body .well-material-green-300,
.container .well-material-green-300,
.container-fluid .well-material-green-300,
body .jumbotron-material-green-300,
.container .jumbotron-material-green-300,
.container-fluid .jumbotron-material-green-300 {
  background-color: #81c784;
}
body .well-material-green-400,
.container .well-material-green-400,
.container-fluid .well-material-green-400,
body .jumbotron-material-green-400,
.container .jumbotron-material-green-400,
.container-fluid .jumbotron-material-green-400 {
  background-color: #66bb6a;
}
body .well-material-green-500,
.container .well-material-green-500,
.container-fluid .well-material-green-500,
body .jumbotron-material-green-500,
.container .jumbotron-material-green-500,
.container-fluid .jumbotron-material-green-500 {
  background-color: #4caf50;
}
body .well-material-green-600,
.container .well-material-green-600,
.container-fluid .well-material-green-600,
body .jumbotron-material-green-600,
.container .jumbotron-material-green-600,
.container-fluid .jumbotron-material-green-600 {
  background-color: #43a047;
}
body .well-material-green-700,
.container .well-material-green-700,
.container-fluid .well-material-green-700,
body .jumbotron-material-green-700,
.container .jumbotron-material-green-700,
.container-fluid .jumbotron-material-green-700 {
  background-color: #388e3c;
}
body .well-material-green-800,
.container .well-material-green-800,
.container-fluid .well-material-green-800,
body .jumbotron-material-green-800,
.container .jumbotron-material-green-800,
.container-fluid .jumbotron-material-green-800 {
  background-color: #2e7d32;
}
body .well-material-green-900,
.container .well-material-green-900,
.container-fluid .well-material-green-900,
body .jumbotron-material-green-900,
.container .jumbotron-material-green-900,
.container-fluid .jumbotron-material-green-900 {
  background-color: #1b5e20;
}
body .well-material-green-A100,
.container .well-material-green-A100,
.container-fluid .well-material-green-A100,
body .jumbotron-material-green-A100,
.container .jumbotron-material-green-A100,
.container-fluid .jumbotron-material-green-A100 {
  background-color: #b9f6ca;
}
body .well-material-green-A200,
.container .well-material-green-A200,
.container-fluid .well-material-green-A200,
body .jumbotron-material-green-A200,
.container .jumbotron-material-green-A200,
.container-fluid .jumbotron-material-green-A200 {
  background-color: #69f0ae;
}
body .well-material-green-A400,
.container .well-material-green-A400,
.container-fluid .well-material-green-A400,
body .jumbotron-material-green-A400,
.container .jumbotron-material-green-A400,
.container-fluid .jumbotron-material-green-A400 {
  background-color: #00e676;
}
body .well-material-green-A700,
.container .well-material-green-A700,
.container-fluid .well-material-green-A700,
body .jumbotron-material-green-A700,
.container .jumbotron-material-green-A700,
.container-fluid .jumbotron-material-green-A700 {
  background-color: #00c853;
}
body .well-material-light-green,
.container .well-material-light-green,
.container-fluid .well-material-light-green,
body .jumbotron-material-light-green,
.container .jumbotron-material-light-green,
.container-fluid .jumbotron-material-light-green {
  background-color: #8bc34a;
}
body .well-material-light-green-50,
.container .well-material-light-green-50,
.container-fluid .well-material-light-green-50,
body .jumbotron-material-light-green-50,
.container .jumbotron-material-light-green-50,
.container-fluid .jumbotron-material-light-green-50 {
  background-color: #f1f8e9;
}
body .well-material-light-green-100,
.container .well-material-light-green-100,
.container-fluid .well-material-light-green-100,
body .jumbotron-material-light-green-100,
.container .jumbotron-material-light-green-100,
.container-fluid .jumbotron-material-light-green-100 {
  background-color: #dcedc8;
}
body .well-material-light-green-200,
.container .well-material-light-green-200,
.container-fluid .well-material-light-green-200,
body .jumbotron-material-light-green-200,
.container .jumbotron-material-light-green-200,
.container-fluid .jumbotron-material-light-green-200 {
  background-color: #c5e1a5;
}
body .well-material-light-green-300,
.container .well-material-light-green-300,
.container-fluid .well-material-light-green-300,
body .jumbotron-material-light-green-300,
.container .jumbotron-material-light-green-300,
.container-fluid .jumbotron-material-light-green-300 {
  background-color: #aed581;
}
body .well-material-light-green-400,
.container .well-material-light-green-400,
.container-fluid .well-material-light-green-400,
body .jumbotron-material-light-green-400,
.container .jumbotron-material-light-green-400,
.container-fluid .jumbotron-material-light-green-400 {
  background-color: #9ccc65;
}
body .well-material-light-green-500,
.container .well-material-light-green-500,
.container-fluid .well-material-light-green-500,
body .jumbotron-material-light-green-500,
.container .jumbotron-material-light-green-500,
.container-fluid .jumbotron-material-light-green-500 {
  background-color: #8bc34a;
}
body .well-material-light-green-600,
.container .well-material-light-green-600,
.container-fluid .well-material-light-green-600,
body .jumbotron-material-light-green-600,
.container .jumbotron-material-light-green-600,
.container-fluid .jumbotron-material-light-green-600 {
  background-color: #7cb342;
}
body .well-material-light-green-700,
.container .well-material-light-green-700,
.container-fluid .well-material-light-green-700,
body .jumbotron-material-light-green-700,
.container .jumbotron-material-light-green-700,
.container-fluid .jumbotron-material-light-green-700 {
  background-color: #689f38;
}
body .well-material-light-green-800,
.container .well-material-light-green-800,
.container-fluid .well-material-light-green-800,
body .jumbotron-material-light-green-800,
.container .jumbotron-material-light-green-800,
.container-fluid .jumbotron-material-light-green-800 {
  background-color: #558b2f;
}
body .well-material-light-green-900,
.container .well-material-light-green-900,
.container-fluid .well-material-light-green-900,
body .jumbotron-material-light-green-900,
.container .jumbotron-material-light-green-900,
.container-fluid .jumbotron-material-light-green-900 {
  background-color: #33691e;
}
body .well-material-light-green-A100,
.container .well-material-light-green-A100,
.container-fluid .well-material-light-green-A100,
body .jumbotron-material-light-green-A100,
.container .jumbotron-material-light-green-A100,
.container-fluid .jumbotron-material-light-green-A100 {
  background-color: #ccff90;
}
body .well-material-light-green-A200,
.container .well-material-light-green-A200,
.container-fluid .well-material-light-green-A200,
body .jumbotron-material-light-green-A200,
.container .jumbotron-material-light-green-A200,
.container-fluid .jumbotron-material-light-green-A200 {
  background-color: #b2ff59;
}
body .well-material-light-green-A400,
.container .well-material-light-green-A400,
.container-fluid .well-material-light-green-A400,
body .jumbotron-material-light-green-A400,
.container .jumbotron-material-light-green-A400,
.container-fluid .jumbotron-material-light-green-A400 {
  background-color: #76ff03;
}
body .well-material-light-green-A700,
.container .well-material-light-green-A700,
.container-fluid .well-material-light-green-A700,
body .jumbotron-material-light-green-A700,
.container .jumbotron-material-light-green-A700,
.container-fluid .jumbotron-material-light-green-A700 {
  background-color: #64dd17;
}
body .well-material-lime,
.container .well-material-lime,
.container-fluid .well-material-lime,
body .jumbotron-material-lime,
.container .jumbotron-material-lime,
.container-fluid .jumbotron-material-lime {
  background-color: #cddc39;
}
body .well-material-lime-50,
.container .well-material-lime-50,
.container-fluid .well-material-lime-50,
body .jumbotron-material-lime-50,
.container .jumbotron-material-lime-50,
.container-fluid .jumbotron-material-lime-50 {
  background-color: #f9fbe7;
}
body .well-material-lime-100,
.container .well-material-lime-100,
.container-fluid .well-material-lime-100,
body .jumbotron-material-lime-100,
.container .jumbotron-material-lime-100,
.container-fluid .jumbotron-material-lime-100 {
  background-color: #f0f4c3;
}
body .well-material-lime-200,
.container .well-material-lime-200,
.container-fluid .well-material-lime-200,
body .jumbotron-material-lime-200,
.container .jumbotron-material-lime-200,
.container-fluid .jumbotron-material-lime-200 {
  background-color: #e6ee9c;
}
body .well-material-lime-300,
.container .well-material-lime-300,
.container-fluid .well-material-lime-300,
body .jumbotron-material-lime-300,
.container .jumbotron-material-lime-300,
.container-fluid .jumbotron-material-lime-300 {
  background-color: #dce775;
}
body .well-material-lime-400,
.container .well-material-lime-400,
.container-fluid .well-material-lime-400,
body .jumbotron-material-lime-400,
.container .jumbotron-material-lime-400,
.container-fluid .jumbotron-material-lime-400 {
  background-color: #d4e157;
}
body .well-material-lime-500,
.container .well-material-lime-500,
.container-fluid .well-material-lime-500,
body .jumbotron-material-lime-500,
.container .jumbotron-material-lime-500,
.container-fluid .jumbotron-material-lime-500 {
  background-color: #cddc39;
}
body .well-material-lime-600,
.container .well-material-lime-600,
.container-fluid .well-material-lime-600,
body .jumbotron-material-lime-600,
.container .jumbotron-material-lime-600,
.container-fluid .jumbotron-material-lime-600 {
  background-color: #c0ca33;
}
body .well-material-lime-700,
.container .well-material-lime-700,
.container-fluid .well-material-lime-700,
body .jumbotron-material-lime-700,
.container .jumbotron-material-lime-700,
.container-fluid .jumbotron-material-lime-700 {
  background-color: #afb42b;
}
body .well-material-lime-800,
.container .well-material-lime-800,
.container-fluid .well-material-lime-800,
body .jumbotron-material-lime-800,
.container .jumbotron-material-lime-800,
.container-fluid .jumbotron-material-lime-800 {
  background-color: #9e9d24;
}
body .well-material-lime-900,
.container .well-material-lime-900,
.container-fluid .well-material-lime-900,
body .jumbotron-material-lime-900,
.container .jumbotron-material-lime-900,
.container-fluid .jumbotron-material-lime-900 {
  background-color: #827717;
}
body .well-material-lime-A100,
.container .well-material-lime-A100,
.container-fluid .well-material-lime-A100,
body .jumbotron-material-lime-A100,
.container .jumbotron-material-lime-A100,
.container-fluid .jumbotron-material-lime-A100 {
  background-color: #f4ff81;
}
body .well-material-lime-A200,
.container .well-material-lime-A200,
.container-fluid .well-material-lime-A200,
body .jumbotron-material-lime-A200,
.container .jumbotron-material-lime-A200,
.container-fluid .jumbotron-material-lime-A200 {
  background-color: #eeff41;
}
body .well-material-lime-A400,
.container .well-material-lime-A400,
.container-fluid .well-material-lime-A400,
body .jumbotron-material-lime-A400,
.container .jumbotron-material-lime-A400,
.container-fluid .jumbotron-material-lime-A400 {
  background-color: #c6ff00;
}
body .well-material-lime-A700,
.container .well-material-lime-A700,
.container-fluid .well-material-lime-A700,
body .jumbotron-material-lime-A700,
.container .jumbotron-material-lime-A700,
.container-fluid .jumbotron-material-lime-A700 {
  background-color: #aeea00;
}
body .well-material-yellow,
.container .well-material-yellow,
.container-fluid .well-material-yellow,
body .jumbotron-material-yellow,
.container .jumbotron-material-yellow,
.container-fluid .jumbotron-material-yellow {
  background-color: #ffeb3b;
}
body .well-material-yellow-50,
.container .well-material-yellow-50,
.container-fluid .well-material-yellow-50,
body .jumbotron-material-yellow-50,
.container .jumbotron-material-yellow-50,
.container-fluid .jumbotron-material-yellow-50 {
  background-color: #fffde7;
}
body .well-material-yellow-100,
.container .well-material-yellow-100,
.container-fluid .well-material-yellow-100,
body .jumbotron-material-yellow-100,
.container .jumbotron-material-yellow-100,
.container-fluid .jumbotron-material-yellow-100 {
  background-color: #fff9c4;
}
body .well-material-yellow-200,
.container .well-material-yellow-200,
.container-fluid .well-material-yellow-200,
body .jumbotron-material-yellow-200,
.container .jumbotron-material-yellow-200,
.container-fluid .jumbotron-material-yellow-200 {
  background-color: #fff59d;
}
body .well-material-yellow-300,
.container .well-material-yellow-300,
.container-fluid .well-material-yellow-300,
body .jumbotron-material-yellow-300,
.container .jumbotron-material-yellow-300,
.container-fluid .jumbotron-material-yellow-300 {
  background-color: #fff176;
}
body .well-material-yellow-400,
.container .well-material-yellow-400,
.container-fluid .well-material-yellow-400,
body .jumbotron-material-yellow-400,
.container .jumbotron-material-yellow-400,
.container-fluid .jumbotron-material-yellow-400 {
  background-color: #ffee58;
}
body .well-material-yellow-500,
.container .well-material-yellow-500,
.container-fluid .well-material-yellow-500,
body .jumbotron-material-yellow-500,
.container .jumbotron-material-yellow-500,
.container-fluid .jumbotron-material-yellow-500 {
  background-color: #ffeb3b;
}
body .well-material-yellow-600,
.container .well-material-yellow-600,
.container-fluid .well-material-yellow-600,
body .jumbotron-material-yellow-600,
.container .jumbotron-material-yellow-600,
.container-fluid .jumbotron-material-yellow-600 {
  background-color: #fdd835;
}
body .well-material-yellow-700,
.container .well-material-yellow-700,
.container-fluid .well-material-yellow-700,
body .jumbotron-material-yellow-700,
.container .jumbotron-material-yellow-700,
.container-fluid .jumbotron-material-yellow-700 {
  background-color: #fbc02d;
}
body .well-material-yellow-800,
.container .well-material-yellow-800,
.container-fluid .well-material-yellow-800,
body .jumbotron-material-yellow-800,
.container .jumbotron-material-yellow-800,
.container-fluid .jumbotron-material-yellow-800 {
  background-color: #f9a825;
}
body .well-material-yellow-900,
.container .well-material-yellow-900,
.container-fluid .well-material-yellow-900,
body .jumbotron-material-yellow-900,
.container .jumbotron-material-yellow-900,
.container-fluid .jumbotron-material-yellow-900 {
  background-color: #f57f17;
}
body .well-material-yellow-A100,
.container .well-material-yellow-A100,
.container-fluid .well-material-yellow-A100,
body .jumbotron-material-yellow-A100,
.container .jumbotron-material-yellow-A100,
.container-fluid .jumbotron-material-yellow-A100 {
  background-color: #ffff8d;
}
body .well-material-yellow-A200,
.container .well-material-yellow-A200,
.container-fluid .well-material-yellow-A200,
body .jumbotron-material-yellow-A200,
.container .jumbotron-material-yellow-A200,
.container-fluid .jumbotron-material-yellow-A200 {
  background-color: #ffff00;
}
body .well-material-yellow-A400,
.container .well-material-yellow-A400,
.container-fluid .well-material-yellow-A400,
body .jumbotron-material-yellow-A400,
.container .jumbotron-material-yellow-A400,
.container-fluid .jumbotron-material-yellow-A400 {
  background-color: #ffea00;
}
body .well-material-yellow-A700,
.container .well-material-yellow-A700,
.container-fluid .well-material-yellow-A700,
body .jumbotron-material-yellow-A700,
.container .jumbotron-material-yellow-A700,
.container-fluid .jumbotron-material-yellow-A700 {
  background-color: #ffd600;
}
body .well-material-amber,
.container .well-material-amber,
.container-fluid .well-material-amber,
body .jumbotron-material-amber,
.container .jumbotron-material-amber,
.container-fluid .jumbotron-material-amber {
  background-color: #ffc107;
}
body .well-material-amber-50,
.container .well-material-amber-50,
.container-fluid .well-material-amber-50,
body .jumbotron-material-amber-50,
.container .jumbotron-material-amber-50,
.container-fluid .jumbotron-material-amber-50 {
  background-color: #fff8e1;
}
body .well-material-amber-100,
.container .well-material-amber-100,
.container-fluid .well-material-amber-100,
body .jumbotron-material-amber-100,
.container .jumbotron-material-amber-100,
.container-fluid .jumbotron-material-amber-100 {
  background-color: #ffecb3;
}
body .well-material-amber-200,
.container .well-material-amber-200,
.container-fluid .well-material-amber-200,
body .jumbotron-material-amber-200,
.container .jumbotron-material-amber-200,
.container-fluid .jumbotron-material-amber-200 {
  background-color: #ffe082;
}
body .well-material-amber-300,
.container .well-material-amber-300,
.container-fluid .well-material-amber-300,
body .jumbotron-material-amber-300,
.container .jumbotron-material-amber-300,
.container-fluid .jumbotron-material-amber-300 {
  background-color: #ffd54f;
}
body .well-material-amber-400,
.container .well-material-amber-400,
.container-fluid .well-material-amber-400,
body .jumbotron-material-amber-400,
.container .jumbotron-material-amber-400,
.container-fluid .jumbotron-material-amber-400 {
  background-color: #ffca28;
}
body .well-material-amber-500,
.container .well-material-amber-500,
.container-fluid .well-material-amber-500,
body .jumbotron-material-amber-500,
.container .jumbotron-material-amber-500,
.container-fluid .jumbotron-material-amber-500 {
  background-color: #ffc107;
}
body .well-material-amber-600,
.container .well-material-amber-600,
.container-fluid .well-material-amber-600,
body .jumbotron-material-amber-600,
.container .jumbotron-material-amber-600,
.container-fluid .jumbotron-material-amber-600 {
  background-color: #ffb300;
}
body .well-material-amber-700,
.container .well-material-amber-700,
.container-fluid .well-material-amber-700,
body .jumbotron-material-amber-700,
.container .jumbotron-material-amber-700,
.container-fluid .jumbotron-material-amber-700 {
  background-color: #ffa000;
}
body .well-material-amber-800,
.container .well-material-amber-800,
.container-fluid .well-material-amber-800,
body .jumbotron-material-amber-800,
.container .jumbotron-material-amber-800,
.container-fluid .jumbotron-material-amber-800 {
  background-color: #ff8f00;
}
body .well-material-amber-900,
.container .well-material-amber-900,
.container-fluid .well-material-amber-900,
body .jumbotron-material-amber-900,
.container .jumbotron-material-amber-900,
.container-fluid .jumbotron-material-amber-900 {
  background-color: #ff6f00;
}
body .well-material-amber-A100,
.container .well-material-amber-A100,
.container-fluid .well-material-amber-A100,
body .jumbotron-material-amber-A100,
.container .jumbotron-material-amber-A100,
.container-fluid .jumbotron-material-amber-A100 {
  background-color: #ffe57f;
}
body .well-material-amber-A200,
.container .well-material-amber-A200,
.container-fluid .well-material-amber-A200,
body .jumbotron-material-amber-A200,
.container .jumbotron-material-amber-A200,
.container-fluid .jumbotron-material-amber-A200 {
  background-color: #ffd740;
}
body .well-material-amber-A400,
.container .well-material-amber-A400,
.container-fluid .well-material-amber-A400,
body .jumbotron-material-amber-A400,
.container .jumbotron-material-amber-A400,
.container-fluid .jumbotron-material-amber-A400 {
  background-color: #ffc400;
}
body .well-material-amber-A700,
.container .well-material-amber-A700,
.container-fluid .well-material-amber-A700,
body .jumbotron-material-amber-A700,
.container .jumbotron-material-amber-A700,
.container-fluid .jumbotron-material-amber-A700 {
  background-color: #ffab00;
}
body .well-material-orange,
.container .well-material-orange,
.container-fluid .well-material-orange,
body .jumbotron-material-orange,
.container .jumbotron-material-orange,
.container-fluid .jumbotron-material-orange {
  background-color: #ff9800;
}
body .well-material-orange-50,
.container .well-material-orange-50,
.container-fluid .well-material-orange-50,
body .jumbotron-material-orange-50,
.container .jumbotron-material-orange-50,
.container-fluid .jumbotron-material-orange-50 {
  background-color: #fff3e0;
}
body .well-material-orange-100,
.container .well-material-orange-100,
.container-fluid .well-material-orange-100,
body .jumbotron-material-orange-100,
.container .jumbotron-material-orange-100,
.container-fluid .jumbotron-material-orange-100 {
  background-color: #ffe0b2;
}
body .well-material-orange-200,
.container .well-material-orange-200,
.container-fluid .well-material-orange-200,
body .jumbotron-material-orange-200,
.container .jumbotron-material-orange-200,
.container-fluid .jumbotron-material-orange-200 {
  background-color: #ffcc80;
}
body .well-material-orange-300,
.container .well-material-orange-300,
.container-fluid .well-material-orange-300,
body .jumbotron-material-orange-300,
.container .jumbotron-material-orange-300,
.container-fluid .jumbotron-material-orange-300 {
  background-color: #ffb74d;
}
body .well-material-orange-400,
.container .well-material-orange-400,
.container-fluid .well-material-orange-400,
body .jumbotron-material-orange-400,
.container .jumbotron-material-orange-400,
.container-fluid .jumbotron-material-orange-400 {
  background-color: #ffa726;
}
body .well-material-orange-500,
.container .well-material-orange-500,
.container-fluid .well-material-orange-500,
body .jumbotron-material-orange-500,
.container .jumbotron-material-orange-500,
.container-fluid .jumbotron-material-orange-500 {
  background-color: #ff9800;
}
body .well-material-orange-600,
.container .well-material-orange-600,
.container-fluid .well-material-orange-600,
body .jumbotron-material-orange-600,
.container .jumbotron-material-orange-600,
.container-fluid .jumbotron-material-orange-600 {
  background-color: #fb8c00;
}
body .well-material-orange-700,
.container .well-material-orange-700,
.container-fluid .well-material-orange-700,
body .jumbotron-material-orange-700,
.container .jumbotron-material-orange-700,
.container-fluid .jumbotron-material-orange-700 {
  background-color: #f57c00;
}
body .well-material-orange-800,
.container .well-material-orange-800,
.container-fluid .well-material-orange-800,
body .jumbotron-material-orange-800,
.container .jumbotron-material-orange-800,
.container-fluid .jumbotron-material-orange-800 {
  background-color: #ef6c00;
}
body .well-material-orange-900,
.container .well-material-orange-900,
.container-fluid .well-material-orange-900,
body .jumbotron-material-orange-900,
.container .jumbotron-material-orange-900,
.container-fluid .jumbotron-material-orange-900 {
  background-color: #e65100;
}
body .well-material-orange-A100,
.container .well-material-orange-A100,
.container-fluid .well-material-orange-A100,
body .jumbotron-material-orange-A100,
.container .jumbotron-material-orange-A100,
.container-fluid .jumbotron-material-orange-A100 {
  background-color: #ffd180;
}
body .well-material-orange-A200,
.container .well-material-orange-A200,
.container-fluid .well-material-orange-A200,
body .jumbotron-material-orange-A200,
.container .jumbotron-material-orange-A200,
.container-fluid .jumbotron-material-orange-A200 {
  background-color: #ffab40;
}
body .well-material-orange-A400,
.container .well-material-orange-A400,
.container-fluid .well-material-orange-A400,
body .jumbotron-material-orange-A400,
.container .jumbotron-material-orange-A400,
.container-fluid .jumbotron-material-orange-A400 {
  background-color: #ff9100;
}
body .well-material-orange-A700,
.container .well-material-orange-A700,
.container-fluid .well-material-orange-A700,
body .jumbotron-material-orange-A700,
.container .jumbotron-material-orange-A700,
.container-fluid .jumbotron-material-orange-A700 {
  background-color: #ff6d00;
}
body .well-material-deep-orange,
.container .well-material-deep-orange,
.container-fluid .well-material-deep-orange,
body .jumbotron-material-deep-orange,
.container .jumbotron-material-deep-orange,
.container-fluid .jumbotron-material-deep-orange {
  background-color: #ff5722;
}
body .well-material-deep-orange-50,
.container .well-material-deep-orange-50,
.container-fluid .well-material-deep-orange-50,
body .jumbotron-material-deep-orange-50,
.container .jumbotron-material-deep-orange-50,
.container-fluid .jumbotron-material-deep-orange-50 {
  background-color: #fbe9e7;
}
body .well-material-deep-orange-100,
.container .well-material-deep-orange-100,
.container-fluid .well-material-deep-orange-100,
body .jumbotron-material-deep-orange-100,
.container .jumbotron-material-deep-orange-100,
.container-fluid .jumbotron-material-deep-orange-100 {
  background-color: #ffccbc;
}
body .well-material-deep-orange-200,
.container .well-material-deep-orange-200,
.container-fluid .well-material-deep-orange-200,
body .jumbotron-material-deep-orange-200,
.container .jumbotron-material-deep-orange-200,
.container-fluid .jumbotron-material-deep-orange-200 {
  background-color: #ffab91;
}
body .well-material-deep-orange-300,
.container .well-material-deep-orange-300,
.container-fluid .well-material-deep-orange-300,
body .jumbotron-material-deep-orange-300,
.container .jumbotron-material-deep-orange-300,
.container-fluid .jumbotron-material-deep-orange-300 {
  background-color: #ff8a65;
}
body .well-material-deep-orange-400,
.container .well-material-deep-orange-400,
.container-fluid .well-material-deep-orange-400,
body .jumbotron-material-deep-orange-400,
.container .jumbotron-material-deep-orange-400,
.container-fluid .jumbotron-material-deep-orange-400 {
  background-color: #ff7043;
}
body .well-material-deep-orange-500,
.container .well-material-deep-orange-500,
.container-fluid .well-material-deep-orange-500,
body .jumbotron-material-deep-orange-500,
.container .jumbotron-material-deep-orange-500,
.container-fluid .jumbotron-material-deep-orange-500 {
  background-color: #ff5722;
}
body .well-material-deep-orange-600,
.container .well-material-deep-orange-600,
.container-fluid .well-material-deep-orange-600,
body .jumbotron-material-deep-orange-600,
.container .jumbotron-material-deep-orange-600,
.container-fluid .jumbotron-material-deep-orange-600 {
  background-color: #f4511e;
}
body .well-material-deep-orange-700,
.container .well-material-deep-orange-700,
.container-fluid .well-material-deep-orange-700,
body .jumbotron-material-deep-orange-700,
.container .jumbotron-material-deep-orange-700,
.container-fluid .jumbotron-material-deep-orange-700 {
  background-color: #e64a19;
}
body .well-material-deep-orange-800,
.container .well-material-deep-orange-800,
.container-fluid .well-material-deep-orange-800,
body .jumbotron-material-deep-orange-800,
.container .jumbotron-material-deep-orange-800,
.container-fluid .jumbotron-material-deep-orange-800 {
  background-color: #d84315;
}
body .well-material-deep-orange-900,
.container .well-material-deep-orange-900,
.container-fluid .well-material-deep-orange-900,
body .jumbotron-material-deep-orange-900,
.container .jumbotron-material-deep-orange-900,
.container-fluid .jumbotron-material-deep-orange-900 {
  background-color: #bf360c;
}
body .well-material-deep-orange-A100,
.container .well-material-deep-orange-A100,
.container-fluid .well-material-deep-orange-A100,
body .jumbotron-material-deep-orange-A100,
.container .jumbotron-material-deep-orange-A100,
.container-fluid .jumbotron-material-deep-orange-A100 {
  background-color: #ff9e80;
}
body .well-material-deep-orange-A200,
.container .well-material-deep-orange-A200,
.container-fluid .well-material-deep-orange-A200,
body .jumbotron-material-deep-orange-A200,
.container .jumbotron-material-deep-orange-A200,
.container-fluid .jumbotron-material-deep-orange-A200 {
  background-color: #ff6e40;
}
body .well-material-deep-orange-A400,
.container .well-material-deep-orange-A400,
.container-fluid .well-material-deep-orange-A400,
body .jumbotron-material-deep-orange-A400,
.container .jumbotron-material-deep-orange-A400,
.container-fluid .jumbotron-material-deep-orange-A400 {
  background-color: #ff3d00;
}
body .well-material-deep-orange-A700,
.container .well-material-deep-orange-A700,
.container-fluid .well-material-deep-orange-A700,
body .jumbotron-material-deep-orange-A700,
.container .jumbotron-material-deep-orange-A700,
.container-fluid .jumbotron-material-deep-orange-A700 {
  background-color: #dd2c00;
}
body .well-material-brown,
.container .well-material-brown,
.container-fluid .well-material-brown,
body .jumbotron-material-brown,
.container .jumbotron-material-brown,
.container-fluid .jumbotron-material-brown {
  background-color: #795548;
}
body .well-material-brown-50,
.container .well-material-brown-50,
.container-fluid .well-material-brown-50,
body .jumbotron-material-brown-50,
.container .jumbotron-material-brown-50,
.container-fluid .jumbotron-material-brown-50 {
  background-color: #efebe9;
}
body .well-material-brown-100,
.container .well-material-brown-100,
.container-fluid .well-material-brown-100,
body .jumbotron-material-brown-100,
.container .jumbotron-material-brown-100,
.container-fluid .jumbotron-material-brown-100 {
  background-color: #d7ccc8;
}
body .well-material-brown-200,
.container .well-material-brown-200,
.container-fluid .well-material-brown-200,
body .jumbotron-material-brown-200,
.container .jumbotron-material-brown-200,
.container-fluid .jumbotron-material-brown-200 {
  background-color: #bcaaa4;
}
body .well-material-brown-300,
.container .well-material-brown-300,
.container-fluid .well-material-brown-300,
body .jumbotron-material-brown-300,
.container .jumbotron-material-brown-300,
.container-fluid .jumbotron-material-brown-300 {
  background-color: #a1887f;
}
body .well-material-brown-400,
.container .well-material-brown-400,
.container-fluid .well-material-brown-400,
body .jumbotron-material-brown-400,
.container .jumbotron-material-brown-400,
.container-fluid .jumbotron-material-brown-400 {
  background-color: #8d6e63;
}
body .well-material-brown-500,
.container .well-material-brown-500,
.container-fluid .well-material-brown-500,
body .jumbotron-material-brown-500,
.container .jumbotron-material-brown-500,
.container-fluid .jumbotron-material-brown-500 {
  background-color: #795548;
}
body .well-material-brown-600,
.container .well-material-brown-600,
.container-fluid .well-material-brown-600,
body .jumbotron-material-brown-600,
.container .jumbotron-material-brown-600,
.container-fluid .jumbotron-material-brown-600 {
  background-color: #6d4c41;
}
body .well-material-brown-700,
.container .well-material-brown-700,
.container-fluid .well-material-brown-700,
body .jumbotron-material-brown-700,
.container .jumbotron-material-brown-700,
.container-fluid .jumbotron-material-brown-700 {
  background-color: #5d4037;
}
body .well-material-brown-800,
.container .well-material-brown-800,
.container-fluid .well-material-brown-800,
body .jumbotron-material-brown-800,
.container .jumbotron-material-brown-800,
.container-fluid .jumbotron-material-brown-800 {
  background-color: #4e342e;
}
body .well-material-brown-900,
.container .well-material-brown-900,
.container-fluid .well-material-brown-900,
body .jumbotron-material-brown-900,
.container .jumbotron-material-brown-900,
.container-fluid .jumbotron-material-brown-900 {
  background-color: #3e2723;
}
body .well-material-brown-A100,
.container .well-material-brown-A100,
.container-fluid .well-material-brown-A100,
body .jumbotron-material-brown-A100,
.container .jumbotron-material-brown-A100,
.container-fluid .jumbotron-material-brown-A100 {
  background-color: #d7ccc8;
}
body .well-material-brown-A200,
.container .well-material-brown-A200,
.container-fluid .well-material-brown-A200,
body .jumbotron-material-brown-A200,
.container .jumbotron-material-brown-A200,
.container-fluid .jumbotron-material-brown-A200 {
  background-color: #bcaaa4;
}
body .well-material-brown-A400,
.container .well-material-brown-A400,
.container-fluid .well-material-brown-A400,
body .jumbotron-material-brown-A400,
.container .jumbotron-material-brown-A400,
.container-fluid .jumbotron-material-brown-A400 {
  background-color: #8d6e63;
}
body .well-material-brown-A700,
.container .well-material-brown-A700,
.container-fluid .well-material-brown-A700,
body .jumbotron-material-brown-A700,
.container .jumbotron-material-brown-A700,
.container-fluid .jumbotron-material-brown-A700 {
  background-color: #5d4037;
}
body .well-material-grey,
.container .well-material-grey,
.container-fluid .well-material-grey,
body .jumbotron-material-grey,
.container .jumbotron-material-grey,
.container-fluid .jumbotron-material-grey {
  background-color: #9e9e9e;
}
body .well-material-grey-50,
.container .well-material-grey-50,
.container-fluid .well-material-grey-50,
body .jumbotron-material-grey-50,
.container .jumbotron-material-grey-50,
.container-fluid .jumbotron-material-grey-50 {
  background-color: #fafafa;
}
body .well-material-grey-100,
.container .well-material-grey-100,
.container-fluid .well-material-grey-100,
body .jumbotron-material-grey-100,
.container .jumbotron-material-grey-100,
.container-fluid .jumbotron-material-grey-100 {
  background-color: #f5f5f5;
}
body .well-material-grey-200,
.container .well-material-grey-200,
.container-fluid .well-material-grey-200,
body .jumbotron-material-grey-200,
.container .jumbotron-material-grey-200,
.container-fluid .jumbotron-material-grey-200 {
  background-color: #eeeeee;
}
body .well-material-grey-300,
.container .well-material-grey-300,
.container-fluid .well-material-grey-300,
body .jumbotron-material-grey-300,
.container .jumbotron-material-grey-300,
.container-fluid .jumbotron-material-grey-300 {
  background-color: #e0e0e0;
}
body .well-material-grey-400,
.container .well-material-grey-400,
.container-fluid .well-material-grey-400,
body .jumbotron-material-grey-400,
.container .jumbotron-material-grey-400,
.container-fluid .jumbotron-material-grey-400 {
  background-color: #bdbdbd;
}
body .well-material-grey-500,
.container .well-material-grey-500,
.container-fluid .well-material-grey-500,
body .jumbotron-material-grey-500,
.container .jumbotron-material-grey-500,
.container-fluid .jumbotron-material-grey-500 {
  background-color: #9e9e9e;
}
body .well-material-grey-600,
.container .well-material-grey-600,
.container-fluid .well-material-grey-600,
body .jumbotron-material-grey-600,
.container .jumbotron-material-grey-600,
.container-fluid .jumbotron-material-grey-600 {
  background-color: #757575;
}
body .well-material-grey-700,
.container .well-material-grey-700,
.container-fluid .well-material-grey-700,
body .jumbotron-material-grey-700,
.container .jumbotron-material-grey-700,
.container-fluid .jumbotron-material-grey-700 {
  background-color: #616161;
}
body .well-material-grey-800,
.container .well-material-grey-800,
.container-fluid .well-material-grey-800,
body .jumbotron-material-grey-800,
.container .jumbotron-material-grey-800,
.container-fluid .jumbotron-material-grey-800 {
  background-color: #424242;
}
body .well-material-grey-900,
.container .well-material-grey-900,
.container-fluid .well-material-grey-900,
body .jumbotron-material-grey-900,
.container .jumbotron-material-grey-900,
.container-fluid .jumbotron-material-grey-900 {
  background-color: #212121;
}
body .well-material-grey-A100,
.container .well-material-grey-A100,
.container-fluid .well-material-grey-A100,
body .jumbotron-material-grey-A100,
.container .jumbotron-material-grey-A100,
.container-fluid .jumbotron-material-grey-A100 {
  background-color: #f5f5f5;
}
body .well-material-grey-A200,
.container .well-material-grey-A200,
.container-fluid .well-material-grey-A200,
body .jumbotron-material-grey-A200,
.container .jumbotron-material-grey-A200,
.container-fluid .jumbotron-material-grey-A200 {
  background-color: #eeeeee;
}
body .well-material-grey-A400,
.container .well-material-grey-A400,
.container-fluid .well-material-grey-A400,
body .jumbotron-material-grey-A400,
.container .jumbotron-material-grey-A400,
.container-fluid .jumbotron-material-grey-A400 {
  background-color: #bdbdbd;
}
body .well-material-grey-A700,
.container .well-material-grey-A700,
.container-fluid .well-material-grey-A700,
body .jumbotron-material-grey-A700,
.container .jumbotron-material-grey-A700,
.container-fluid .jumbotron-material-grey-A700 {
  background-color: #616161;
}
body .well-material-blue,
.container .well-material-blue,
.container-fluid .well-material-blue,
body .jumbotron-material-blue,
.container .jumbotron-material-blue,
.container-fluid .jumbotron-material-blue {
  background-color: #607d8b;
}
body .well-material-blue-50,
.container .well-material-blue-50,
.container-fluid .well-material-blue-50,
body .jumbotron-material-blue-50,
.container .jumbotron-material-blue-50,
.container-fluid .jumbotron-material-blue-50 {
  background-color: #eceff1;
}
body .well-material-blue-100,
.container .well-material-blue-100,
.container-fluid .well-material-blue-100,
body .jumbotron-material-blue-100,
.container .jumbotron-material-blue-100,
.container-fluid .jumbotron-material-blue-100 {
  background-color: #cfd8dc;
}
body .well-material-blue-200,
.container .well-material-blue-200,
.container-fluid .well-material-blue-200,
body .jumbotron-material-blue-200,
.container .jumbotron-material-blue-200,
.container-fluid .jumbotron-material-blue-200 {
  background-color: #b0bec5;
}
body .well-material-blue-300,
.container .well-material-blue-300,
.container-fluid .well-material-blue-300,
body .jumbotron-material-blue-300,
.container .jumbotron-material-blue-300,
.container-fluid .jumbotron-material-blue-300 {
  background-color: #90a4ae;
}
body .well-material-blue-400,
.container .well-material-blue-400,
.container-fluid .well-material-blue-400,
body .jumbotron-material-blue-400,
.container .jumbotron-material-blue-400,
.container-fluid .jumbotron-material-blue-400 {
  background-color: #78909c;
}
body .well-material-blue-500,
.container .well-material-blue-500,
.container-fluid .well-material-blue-500,
body .jumbotron-material-blue-500,
.container .jumbotron-material-blue-500,
.container-fluid .jumbotron-material-blue-500 {
  background-color: #607d8b;
}
body .well-material-blue-600,
.container .well-material-blue-600,
.container-fluid .well-material-blue-600,
body .jumbotron-material-blue-600,
.container .jumbotron-material-blue-600,
.container-fluid .jumbotron-material-blue-600 {
  background-color: #546e7a;
}
body .well-material-blue-700,
.container .well-material-blue-700,
.container-fluid .well-material-blue-700,
body .jumbotron-material-blue-700,
.container .jumbotron-material-blue-700,
.container-fluid .jumbotron-material-blue-700 {
  background-color: #455a64;
}
body .well-material-blue-800,
.container .well-material-blue-800,
.container-fluid .well-material-blue-800,
body .jumbotron-material-blue-800,
.container .jumbotron-material-blue-800,
.container-fluid .jumbotron-material-blue-800 {
  background-color: #37474f;
}
body .well-material-blue-900,
.container .well-material-blue-900,
.container-fluid .well-material-blue-900,
body .jumbotron-material-blue-900,
.container .jumbotron-material-blue-900,
.container-fluid .jumbotron-material-blue-900 {
  background-color: #263238;
}
body .well-material-blue-A100,
.container .well-material-blue-A100,
.container-fluid .well-material-blue-A100,
body .jumbotron-material-blue-A100,
.container .jumbotron-material-blue-A100,
.container-fluid .jumbotron-material-blue-A100 {
  background-color: #cfd8dc;
}
body .well-material-blue-A200,
.container .well-material-blue-A200,
.container-fluid .well-material-blue-A200,
body .jumbotron-material-blue-A200,
.container .jumbotron-material-blue-A200,
.container-fluid .jumbotron-material-blue-A200 {
  background-color: #b0bec5;
}
body .well-material-blue-A400,
.container .well-material-blue-A400,
.container-fluid .well-material-blue-A400,
body .jumbotron-material-blue-A400,
.container .jumbotron-material-blue-A400,
.container-fluid .jumbotron-material-blue-A400 {
  background-color: #78909c;
}
body .well-material-blue-A700,
.container .well-material-blue-A700,
.container-fluid .well-material-blue-A700,
body .jumbotron-material-blue-A700,
.container .jumbotron-material-blue-A700,
.container-fluid .jumbotron-material-blue-A700 {
  background-color: #455a64;
}
.btn {
  position: relative;
  padding: 8px 30px;
  border: 0;
  margin: 10px 1px;
  cursor: pointer;
  border-radius: 2px;
  text-transform: uppercase;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.84);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none !important;
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.btn:hover:not(.btn-link):not(.btn-flat) {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
}
.btn:active:not(.btn-link):not(.btn-flat) {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
}
.btn.btn-flat:not(.btn-link),
.btn-default.btn-flat:not(.btn-link) {
  color: rgba(0, 0, 0, 0.84);
}
.btn-primary.btn-flat:not(.btn-link) {
  color: #009587;
}
.btn-success.btn-flat:not(.btn-link) {
  color: #0f9d58;
}
.btn-info.btn-flat:not(.btn-link) {
  color: #03a9f4;
}
.btn-warning.btn-flat:not(.btn-link) {
  color: #ff5722;
}
.btn-danger.btn-flat:not(.btn-link) {
  color: #f44336;
}
.btn-material-red.btn-flat:not(.btn-link) {
  color: #f44336;
}
.btn-material-red-50.btn-flat:not(.btn-link) {
  color: #ffebee;
}
.btn-material-red-100.btn-flat:not(.btn-link) {
  color: #ffcdd2;
}
.btn-material-red-200.btn-flat:not(.btn-link) {
  color: #ef9a9a;
}
.btn-material-red-300.btn-flat:not(.btn-link) {
  color: #e57373;
}
.btn-material-red-400.btn-flat:not(.btn-link) {
  color: #ef5350;
}
.btn-material-red-500.btn-flat:not(.btn-link) {
  color: #f44336;
}
.btn-material-red-600.btn-flat:not(.btn-link) {
  color: #e53935;
}
.btn-material-red-700.btn-flat:not(.btn-link) {
  color: #d32f2f;
}
.btn-material-red-800.btn-flat:not(.btn-link) {
  color: #c62828;
}
.btn-material-red-900.btn-flat:not(.btn-link) {
  color: #b71c1c;
}
.btn-material-red-A100.btn-flat:not(.btn-link) {
  color: #ff8a80;
}
.btn-material-red-A200.btn-flat:not(.btn-link) {
  color: #ff5252;
}
.btn-material-red-A400.btn-flat:not(.btn-link) {
  color: #ff1744;
}
.btn-material-red-A700.btn-flat:not(.btn-link) {
  color: #d50000;
}
.btn-material-pink.btn-flat:not(.btn-link) {
  color: #e91e63;
}
.btn-material-pink-50.btn-flat:not(.btn-link) {
  color: #fce4ec;
}
.btn-material-pink-100.btn-flat:not(.btn-link) {
  color: #f8bbd0;
}
.btn-material-pink-200.btn-flat:not(.btn-link) {
  color: #f48fb1;
}
.btn-material-pink-300.btn-flat:not(.btn-link) {
  color: #f06292;
}
.btn-material-pink-400.btn-flat:not(.btn-link) {
  color: #ec407a;
}
.btn-material-pink-500.btn-flat:not(.btn-link) {
  color: #e91e63;
}
.btn-material-pink-600.btn-flat:not(.btn-link) {
  color: #d81b60;
}
.btn-material-pink-700.btn-flat:not(.btn-link) {
  color: #c2185b;
}
.btn-material-pink-800.btn-flat:not(.btn-link) {
  color: #ad1457;
}
.btn-material-pink-900.btn-flat:not(.btn-link) {
  color: #880e4f;
}
.btn-material-pink-A100.btn-flat:not(.btn-link) {
  color: #ff80ab;
}
.btn-material-pink-A200.btn-flat:not(.btn-link) {
  color: #ff4081;
}
.btn-material-pink-A400.btn-flat:not(.btn-link) {
  color: #f50057;
}
.btn-material-pink-A700.btn-flat:not(.btn-link) {
  color: #c51162;
}
.btn-material-purple.btn-flat:not(.btn-link) {
  color: #9c27b0;
}
.btn-material-purple-50.btn-flat:not(.btn-link) {
  color: #f3e5f5;
}
.btn-material-purple-100.btn-flat:not(.btn-link) {
  color: #e1bee7;
}
.btn-material-purple-200.btn-flat:not(.btn-link) {
  color: #ce93d8;
}
.btn-material-purple-300.btn-flat:not(.btn-link) {
  color: #ba68c8;
}
.btn-material-purple-400.btn-flat:not(.btn-link) {
  color: #ab47bc;
}
.btn-material-purple-500.btn-flat:not(.btn-link) {
  color: #9c27b0;
}
.btn-material-purple-600.btn-flat:not(.btn-link) {
  color: #8e24aa;
}
.btn-material-purple-700.btn-flat:not(.btn-link) {
  color: #7b1fa2;
}
.btn-material-purple-800.btn-flat:not(.btn-link) {
  color: #6a1b9a;
}
.btn-material-purple-900.btn-flat:not(.btn-link) {
  color: #4a148c;
}
.btn-material-purple-A100.btn-flat:not(.btn-link) {
  color: #ea80fc;
}
.btn-material-purple-A200.btn-flat:not(.btn-link) {
  color: #e040fb;
}
.btn-material-purple-A400.btn-flat:not(.btn-link) {
  color: #d500f9;
}
.btn-material-purple-A700.btn-flat:not(.btn-link) {
  color: #aa00ff;
}
.btn-material-deep-purple.btn-flat:not(.btn-link) {
  color: #673ab7;
}
.btn-material-deep-purple-50.btn-flat:not(.btn-link) {
  color: #ede7f6;
}
.btn-material-deep-purple-100.btn-flat:not(.btn-link) {
  color: #d1c4e9;
}
.btn-material-deep-purple-200.btn-flat:not(.btn-link) {
  color: #b39ddb;
}
.btn-material-deep-purple-300.btn-flat:not(.btn-link) {
  color: #9575cd;
}
.btn-material-deep-purple-400.btn-flat:not(.btn-link) {
  color: #7e57c2;
}
.btn-material-deep-purple-500.btn-flat:not(.btn-link) {
  color: #673ab7;
}
.btn-material-deep-purple-600.btn-flat:not(.btn-link) {
  color: #5e35b1;
}
.btn-material-deep-purple-700.btn-flat:not(.btn-link) {
  color: #512da8;
}
.btn-material-deep-purple-800.btn-flat:not(.btn-link) {
  color: #4527a0;
}
.btn-material-deep-purple-900.btn-flat:not(.btn-link) {
  color: #311b92;
}
.btn-material-deep-purple-A100.btn-flat:not(.btn-link) {
  color: #b388ff;
}
.btn-material-deep-purple-A200.btn-flat:not(.btn-link) {
  color: #7c4dff;
}
.btn-material-deep-purple-A400.btn-flat:not(.btn-link) {
  color: #651fff;
}
.btn-material-deep-purple-A700.btn-flat:not(.btn-link) {
  color: #6200ea;
}
.btn-material-indigo.btn-flat:not(.btn-link) {
  color: #3f51b5;
}
.btn-material-indigo-50.btn-flat:not(.btn-link) {
  color: #e8eaf6;
}
.btn-material-indigo-100.btn-flat:not(.btn-link) {
  color: #c5cae9;
}
.btn-material-indigo-200.btn-flat:not(.btn-link) {
  color: #9fa8da;
}
.btn-material-indigo-300.btn-flat:not(.btn-link) {
  color: #7986cb;
}
.btn-material-indigo-400.btn-flat:not(.btn-link) {
  color: #5c6bc0;
}
.btn-material-indigo-500.btn-flat:not(.btn-link) {
  color: #3f51b5;
}
.btn-material-indigo-600.btn-flat:not(.btn-link) {
  color: #3949ab;
}
.btn-material-indigo-700.btn-flat:not(.btn-link) {
  color: #303f9f;
}
.btn-material-indigo-800.btn-flat:not(.btn-link) {
  color: #283593;
}
.btn-material-indigo-900.btn-flat:not(.btn-link) {
  color: #1a237e;
}
.btn-material-indigo-A100.btn-flat:not(.btn-link) {
  color: #8c9eff;
}
.btn-material-indigo-A200.btn-flat:not(.btn-link) {
  color: #536dfe;
}
.btn-material-indigo-A400.btn-flat:not(.btn-link) {
  color: #3d5afe;
}
.btn-material-indigo-A700.btn-flat:not(.btn-link) {
  color: #304ffe;
}
.btn-material-blue.btn-flat:not(.btn-link) {
  color: #2196f3;
}
.btn-material-blue-50.btn-flat:not(.btn-link) {
  color: #e3f2fd;
}
.btn-material-blue-100.btn-flat:not(.btn-link) {
  color: #bbdefb;
}
.btn-material-blue-200.btn-flat:not(.btn-link) {
  color: #90caf9;
}
.btn-material-blue-300.btn-flat:not(.btn-link) {
  color: #64b5f6;
}
.btn-material-blue-400.btn-flat:not(.btn-link) {
  color: #42a5f5;
}
.btn-material-blue-500.btn-flat:not(.btn-link) {
  color: #2196f3;
}
.btn-material-blue-600.btn-flat:not(.btn-link) {
  color: #1e88e5;
}
.btn-material-blue-700.btn-flat:not(.btn-link) {
  color: #1976d2;
}
.btn-material-blue-800.btn-flat:not(.btn-link) {
  color: #1565c0;
}
.btn-material-blue-900.btn-flat:not(.btn-link) {
  color: #0d47a1;
}
.btn-material-blue-A100.btn-flat:not(.btn-link) {
  color: #82b1ff;
}
.btn-material-blue-A200.btn-flat:not(.btn-link) {
  color: #448aff;
}
.btn-material-blue-A400.btn-flat:not(.btn-link) {
  color: #2979ff;
}
.btn-material-blue-A700.btn-flat:not(.btn-link) {
  color: #2962ff;
}
.btn-material-light-blue.btn-flat:not(.btn-link) {
  color: #03a9f4;
}
.btn-material-light-blue-50.btn-flat:not(.btn-link) {
  color: #e1f5fe;
}
.btn-material-light-blue-100.btn-flat:not(.btn-link) {
  color: #b3e5fc;
}
.btn-material-light-blue-200.btn-flat:not(.btn-link) {
  color: #81d4fa;
}
.btn-material-light-blue-300.btn-flat:not(.btn-link) {
  color: #4fc3f7;
}
.btn-material-light-blue-400.btn-flat:not(.btn-link) {
  color: #29b6f6;
}
.btn-material-light-blue-500.btn-flat:not(.btn-link) {
  color: #03a9f4;
}
.btn-material-light-blue-600.btn-flat:not(.btn-link) {
  color: #039be5;
}
.btn-material-light-blue-700.btn-flat:not(.btn-link) {
  color: #0288d1;
}
.btn-material-light-blue-800.btn-flat:not(.btn-link) {
  color: #0277bd;
}
.btn-material-light-blue-900.btn-flat:not(.btn-link) {
  color: #01579b;
}
.btn-material-light-blue-A100.btn-flat:not(.btn-link) {
  color: #80d8ff;
}
.btn-material-light-blue-A200.btn-flat:not(.btn-link) {
  color: #40c4ff;
}
.btn-material-light-blue-A400.btn-flat:not(.btn-link) {
  color: #00b0ff;
}
.btn-material-light-blue-A700.btn-flat:not(.btn-link) {
  color: #0091ea;
}
.btn-material-cyan.btn-flat:not(.btn-link) {
  color: #00bcd4;
}
.btn-material-cyan-50.btn-flat:not(.btn-link) {
  color: #e0f7fa;
}
.btn-material-cyan-100.btn-flat:not(.btn-link) {
  color: #b2ebf2;
}
.btn-material-cyan-200.btn-flat:not(.btn-link) {
  color: #80deea;
}
.btn-material-cyan-300.btn-flat:not(.btn-link) {
  color: #4dd0e1;
}
.btn-material-cyan-400.btn-flat:not(.btn-link) {
  color: #26c6da;
}
.btn-material-cyan-500.btn-flat:not(.btn-link) {
  color: #00bcd4;
}
.btn-material-cyan-600.btn-flat:not(.btn-link) {
  color: #00acc1;
}
.btn-material-cyan-700.btn-flat:not(.btn-link) {
  color: #0097a7;
}
.btn-material-cyan-800.btn-flat:not(.btn-link) {
  color: #00838f;
}
.btn-material-cyan-900.btn-flat:not(.btn-link) {
  color: #006064;
}
.btn-material-cyan-A100.btn-flat:not(.btn-link) {
  color: #84ffff;
}
.btn-material-cyan-A200.btn-flat:not(.btn-link) {
  color: #18ffff;
}
.btn-material-cyan-A400.btn-flat:not(.btn-link) {
  color: #00e5ff;
}
.btn-material-cyan-A700.btn-flat:not(.btn-link) {
  color: #00b8d4;
}
.btn-material-teal.btn-flat:not(.btn-link) {
  color: #009688;
}
.btn-material-teal-50.btn-flat:not(.btn-link) {
  color: #e0f2f1;
}
.btn-material-teal-100.btn-flat:not(.btn-link) {
  color: #b2dfdb;
}
.btn-material-teal-200.btn-flat:not(.btn-link) {
  color: #80cbc4;
}
.btn-material-teal-300.btn-flat:not(.btn-link) {
  color: #4db6ac;
}
.btn-material-teal-400.btn-flat:not(.btn-link) {
  color: #26a69a;
}
.btn-material-teal-500.btn-flat:not(.btn-link) {
  color: #009688;
}
.btn-material-teal-600.btn-flat:not(.btn-link) {
  color: #00897b;
}
.btn-material-teal-700.btn-flat:not(.btn-link) {
  color: #00796b;
}
.btn-material-teal-800.btn-flat:not(.btn-link) {
  color: #00695c;
}
.btn-material-teal-900.btn-flat:not(.btn-link) {
  color: #004d40;
}
.btn-material-teal-A100.btn-flat:not(.btn-link) {
  color: #a7ffeb;
}
.btn-material-teal-A200.btn-flat:not(.btn-link) {
  color: #64ffda;
}
.btn-material-teal-A400.btn-flat:not(.btn-link) {
  color: #1de9b6;
}
.btn-material-teal-A700.btn-flat:not(.btn-link) {
  color: #00bfa5;
}
.btn-material-green.btn-flat:not(.btn-link) {
  color: #4caf50;
}
.btn-material-green-50.btn-flat:not(.btn-link) {
  color: #e8f5e9;
}
.btn-material-green-100.btn-flat:not(.btn-link) {
  color: #c8e6c9;
}
.btn-material-green-200.btn-flat:not(.btn-link) {
  color: #a5d6a7;
}
.btn-material-green-300.btn-flat:not(.btn-link) {
  color: #81c784;
}
.btn-material-green-400.btn-flat:not(.btn-link) {
  color: #66bb6a;
}
.btn-material-green-500.btn-flat:not(.btn-link) {
  color: #4caf50;
}
.btn-material-green-600.btn-flat:not(.btn-link) {
  color: #43a047;
}
.btn-material-green-700.btn-flat:not(.btn-link) {
  color: #388e3c;
}
.btn-material-green-800.btn-flat:not(.btn-link) {
  color: #2e7d32;
}
.btn-material-green-900.btn-flat:not(.btn-link) {
  color: #1b5e20;
}
.btn-material-green-A100.btn-flat:not(.btn-link) {
  color: #b9f6ca;
}
.btn-material-green-A200.btn-flat:not(.btn-link) {
  color: #69f0ae;
}
.btn-material-green-A400.btn-flat:not(.btn-link) {
  color: #00e676;
}
.btn-material-green-A700.btn-flat:not(.btn-link) {
  color: #00c853;
}
.btn-material-light-green.btn-flat:not(.btn-link) {
  color: #8bc34a;
}
.btn-material-light-green-50.btn-flat:not(.btn-link) {
  color: #f1f8e9;
}
.btn-material-light-green-100.btn-flat:not(.btn-link) {
  color: #dcedc8;
}
.btn-material-light-green-200.btn-flat:not(.btn-link) {
  color: #c5e1a5;
}
.btn-material-light-green-300.btn-flat:not(.btn-link) {
  color: #aed581;
}
.btn-material-light-green-400.btn-flat:not(.btn-link) {
  color: #9ccc65;
}
.btn-material-light-green-500.btn-flat:not(.btn-link) {
  color: #8bc34a;
}
.btn-material-light-green-600.btn-flat:not(.btn-link) {
  color: #7cb342;
}
.btn-material-light-green-700.btn-flat:not(.btn-link) {
  color: #689f38;
}
.btn-material-light-green-800.btn-flat:not(.btn-link) {
  color: #558b2f;
}
.btn-material-light-green-900.btn-flat:not(.btn-link) {
  color: #33691e;
}
.btn-material-light-green-A100.btn-flat:not(.btn-link) {
  color: #ccff90;
}
.btn-material-light-green-A200.btn-flat:not(.btn-link) {
  color: #b2ff59;
}
.btn-material-light-green-A400.btn-flat:not(.btn-link) {
  color: #76ff03;
}
.btn-material-light-green-A700.btn-flat:not(.btn-link) {
  color: #64dd17;
}
.btn-material-lime.btn-flat:not(.btn-link) {
  color: #cddc39;
}
.btn-material-lime-50.btn-flat:not(.btn-link) {
  color: #f9fbe7;
}
.btn-material-lime-100.btn-flat:not(.btn-link) {
  color: #f0f4c3;
}
.btn-material-lime-200.btn-flat:not(.btn-link) {
  color: #e6ee9c;
}
.btn-material-lime-300.btn-flat:not(.btn-link) {
  color: #dce775;
}
.btn-material-lime-400.btn-flat:not(.btn-link) {
  color: #d4e157;
}
.btn-material-lime-500.btn-flat:not(.btn-link) {
  color: #cddc39;
}
.btn-material-lime-600.btn-flat:not(.btn-link) {
  color: #c0ca33;
}
.btn-material-lime-700.btn-flat:not(.btn-link) {
  color: #afb42b;
}
.btn-material-lime-800.btn-flat:not(.btn-link) {
  color: #9e9d24;
}
.btn-material-lime-900.btn-flat:not(.btn-link) {
  color: #827717;
}
.btn-material-lime-A100.btn-flat:not(.btn-link) {
  color: #f4ff81;
}
.btn-material-lime-A200.btn-flat:not(.btn-link) {
  color: #eeff41;
}
.btn-material-lime-A400.btn-flat:not(.btn-link) {
  color: #c6ff00;
}
.btn-material-lime-A700.btn-flat:not(.btn-link) {
  color: #aeea00;
}
.btn-material-yellow.btn-flat:not(.btn-link) {
  color: #ffeb3b;
}
.btn-material-yellow-50.btn-flat:not(.btn-link) {
  color: #fffde7;
}
.btn-material-yellow-100.btn-flat:not(.btn-link) {
  color: #fff9c4;
}
.btn-material-yellow-200.btn-flat:not(.btn-link) {
  color: #fff59d;
}
.btn-material-yellow-300.btn-flat:not(.btn-link) {
  color: #fff176;
}
.btn-material-yellow-400.btn-flat:not(.btn-link) {
  color: #ffee58;
}
.btn-material-yellow-500.btn-flat:not(.btn-link) {
  color: #ffeb3b;
}
.btn-material-yellow-600.btn-flat:not(.btn-link) {
  color: #fdd835;
}
.btn-material-yellow-700.btn-flat:not(.btn-link) {
  color: #fbc02d;
}
.btn-material-yellow-800.btn-flat:not(.btn-link) {
  color: #f9a825;
}
.btn-material-yellow-900.btn-flat:not(.btn-link) {
  color: #f57f17;
}
.btn-material-yellow-A100.btn-flat:not(.btn-link) {
  color: #ffff8d;
}
.btn-material-yellow-A200.btn-flat:not(.btn-link) {
  color: #ffff00;
}
.btn-material-yellow-A400.btn-flat:not(.btn-link) {
  color: #ffea00;
}
.btn-material-yellow-A700.btn-flat:not(.btn-link) {
  color: #ffd600;
}
.btn-material-amber.btn-flat:not(.btn-link) {
  color: #ffc107;
}
.btn-material-amber-50.btn-flat:not(.btn-link) {
  color: #fff8e1;
}
.btn-material-amber-100.btn-flat:not(.btn-link) {
  color: #ffecb3;
}
.btn-material-amber-200.btn-flat:not(.btn-link) {
  color: #ffe082;
}
.btn-material-amber-300.btn-flat:not(.btn-link) {
  color: #ffd54f;
}
.btn-material-amber-400.btn-flat:not(.btn-link) {
  color: #ffca28;
}
.btn-material-amber-500.btn-flat:not(.btn-link) {
  color: #ffc107;
}
.btn-material-amber-600.btn-flat:not(.btn-link) {
  color: #ffb300;
}
.btn-material-amber-700.btn-flat:not(.btn-link) {
  color: #ffa000;
}
.btn-material-amber-800.btn-flat:not(.btn-link) {
  color: #ff8f00;
}
.btn-material-amber-900.btn-flat:not(.btn-link) {
  color: #ff6f00;
}
.btn-material-amber-A100.btn-flat:not(.btn-link) {
  color: #ffe57f;
}
.btn-material-amber-A200.btn-flat:not(.btn-link) {
  color: #ffd740;
}
.btn-material-amber-A400.btn-flat:not(.btn-link) {
  color: #ffc400;
}
.btn-material-amber-A700.btn-flat:not(.btn-link) {
  color: #ffab00;
}
.btn-material-orange.btn-flat:not(.btn-link) {
  color: #ff9800;
}
.btn-material-orange-50.btn-flat:not(.btn-link) {
  color: #fff3e0;
}
.btn-material-orange-100.btn-flat:not(.btn-link) {
  color: #ffe0b2;
}
.btn-material-orange-200.btn-flat:not(.btn-link) {
  color: #ffcc80;
}
.btn-material-orange-300.btn-flat:not(.btn-link) {
  color: #ffb74d;
}
.btn-material-orange-400.btn-flat:not(.btn-link) {
  color: #ffa726;
}
.btn-material-orange-500.btn-flat:not(.btn-link) {
  color: #ff9800;
}
.btn-material-orange-600.btn-flat:not(.btn-link) {
  color: #fb8c00;
}
.btn-material-orange-700.btn-flat:not(.btn-link) {
  color: #f57c00;
}
.btn-material-orange-800.btn-flat:not(.btn-link) {
  color: #ef6c00;
}
.btn-material-orange-900.btn-flat:not(.btn-link) {
  color: #e65100;
}
.btn-material-orange-A100.btn-flat:not(.btn-link) {
  color: #ffd180;
}
.btn-material-orange-A200.btn-flat:not(.btn-link) {
  color: #ffab40;
}
.btn-material-orange-A400.btn-flat:not(.btn-link) {
  color: #ff9100;
}
.btn-material-orange-A700.btn-flat:not(.btn-link) {
  color: #ff6d00;
}
.btn-material-deep-orange.btn-flat:not(.btn-link) {
  color: #ff5722;
}
.btn-material-deep-orange-50.btn-flat:not(.btn-link) {
  color: #fbe9e7;
}
.btn-material-deep-orange-100.btn-flat:not(.btn-link) {
  color: #ffccbc;
}
.btn-material-deep-orange-200.btn-flat:not(.btn-link) {
  color: #ffab91;
}
.btn-material-deep-orange-300.btn-flat:not(.btn-link) {
  color: #ff8a65;
}
.btn-material-deep-orange-400.btn-flat:not(.btn-link) {
  color: #ff7043;
}
.btn-material-deep-orange-500.btn-flat:not(.btn-link) {
  color: #ff5722;
}
.btn-material-deep-orange-600.btn-flat:not(.btn-link) {
  color: #f4511e;
}
.btn-material-deep-orange-700.btn-flat:not(.btn-link) {
  color: #e64a19;
}
.btn-material-deep-orange-800.btn-flat:not(.btn-link) {
  color: #d84315;
}
.btn-material-deep-orange-900.btn-flat:not(.btn-link) {
  color: #bf360c;
}
.btn-material-deep-orange-A100.btn-flat:not(.btn-link) {
  color: #ff9e80;
}
.btn-material-deep-orange-A200.btn-flat:not(.btn-link) {
  color: #ff6e40;
}
.btn-material-deep-orange-A400.btn-flat:not(.btn-link) {
  color: #ff3d00;
}
.btn-material-deep-orange-A700.btn-flat:not(.btn-link) {
  color: #dd2c00;
}
.btn-material-brown.btn-flat:not(.btn-link) {
  color: #795548;
}
.btn-material-brown-50.btn-flat:not(.btn-link) {
  color: #efebe9;
}
.btn-material-brown-100.btn-flat:not(.btn-link) {
  color: #d7ccc8;
}
.btn-material-brown-200.btn-flat:not(.btn-link) {
  color: #bcaaa4;
}
.btn-material-brown-300.btn-flat:not(.btn-link) {
  color: #a1887f;
}
.btn-material-brown-400.btn-flat:not(.btn-link) {
  color: #8d6e63;
}
.btn-material-brown-500.btn-flat:not(.btn-link) {
  color: #795548;
}
.btn-material-brown-600.btn-flat:not(.btn-link) {
  color: #6d4c41;
}
.btn-material-brown-700.btn-flat:not(.btn-link) {
  color: #5d4037;
}
.btn-material-brown-800.btn-flat:not(.btn-link) {
  color: #4e342e;
}
.btn-material-brown-900.btn-flat:not(.btn-link) {
  color: #3e2723;
}
.btn-material-brown-A100.btn-flat:not(.btn-link) {
  color: #d7ccc8;
}
.btn-material-brown-A200.btn-flat:not(.btn-link) {
  color: #bcaaa4;
}
.btn-material-brown-A400.btn-flat:not(.btn-link) {
  color: #8d6e63;
}
.btn-material-brown-A700.btn-flat:not(.btn-link) {
  color: #5d4037;
}
.btn-material-grey.btn-flat:not(.btn-link) {
  color: #9e9e9e;
}
.btn-material-grey-50.btn-flat:not(.btn-link) {
  color: #fafafa;
}
.btn-material-grey-100.btn-flat:not(.btn-link) {
  color: #f5f5f5;
}
.btn-material-grey-200.btn-flat:not(.btn-link) {
  color: #eeeeee;
}
.btn-material-grey-300.btn-flat:not(.btn-link) {
  color: #e0e0e0;
}
.btn-material-grey-400.btn-flat:not(.btn-link) {
  color: #bdbdbd;
}
.btn-material-grey-500.btn-flat:not(.btn-link) {
  color: #9e9e9e;
}
.btn-material-grey-600.btn-flat:not(.btn-link) {
  color: #757575;
}
.btn-material-grey-700.btn-flat:not(.btn-link) {
  color: #616161;
}
.btn-material-grey-800.btn-flat:not(.btn-link) {
  color: #424242;
}
.btn-material-grey-900.btn-flat:not(.btn-link) {
  color: #212121;
}
.btn-material-grey-A100.btn-flat:not(.btn-link) {
  color: #f5f5f5;
}
.btn-material-grey-A200.btn-flat:not(.btn-link) {
  color: #eeeeee;
}
.btn-material-grey-A400.btn-flat:not(.btn-link) {
  color: #bdbdbd;
}
.btn-material-grey-A700.btn-flat:not(.btn-link) {
  color: #616161;
}
.btn-material-blue.btn-flat:not(.btn-link) {
  color: #607d8b;
}
.btn-material-blue-50.btn-flat:not(.btn-link) {
  color: #eceff1;
}
.btn-material-blue-100.btn-flat:not(.btn-link) {
  color: #cfd8dc;
}
.btn-material-blue-200.btn-flat:not(.btn-link) {
  color: #b0bec5;
}
.btn-material-blue-300.btn-flat:not(.btn-link) {
  color: #90a4ae;
}
.btn-material-blue-400.btn-flat:not(.btn-link) {
  color: #78909c;
}
.btn-material-blue-500.btn-flat:not(.btn-link) {
  color: #607d8b;
}
.btn-material-blue-600.btn-flat:not(.btn-link) {
  color: #546e7a;
}
.btn-material-blue-700.btn-flat:not(.btn-link) {
  color: #455a64;
}
.btn-material-blue-800.btn-flat:not(.btn-link) {
  color: #37474f;
}
.btn-material-blue-900.btn-flat:not(.btn-link) {
  color: #263238;
}
.btn-material-blue-A100.btn-flat:not(.btn-link) {
  color: #cfd8dc;
}
.btn-material-blue-A200.btn-flat:not(.btn-link) {
  color: #b0bec5;
}
.btn-material-blue-A400.btn-flat:not(.btn-link) {
  color: #78909c;
}
.btn-material-blue-A700.btn-flat:not(.btn-link) {
  color: #455a64;
}
.btn:not(.btn-link):not(.btn-flat),
.btn-default:not(.btn-link):not(.btn-flat) {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.84);
}
.btn-primary:not(.btn-link):not(.btn-flat) {
  background-color: #009587;
  color: rgba(255, 255, 255, 0.84);
}
.btn-success:not(.btn-link):not(.btn-flat) {
  background-color: #0f9d58;
  color: rgba(255, 255, 255, 0.84);
}
.btn-info:not(.btn-link):not(.btn-flat) {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-warning:not(.btn-link):not(.btn-flat) {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.btn-danger:not(.btn-link):not(.btn-flat) {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red:not(.btn-link):not(.btn-flat) {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-50:not(.btn-link):not(.btn-flat) {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-200:not(.btn-link):not(.btn-flat) {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-300:not(.btn-link):not(.btn-flat) {
  background-color: #e57373;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-400:not(.btn-link):not(.btn-flat) {
  background-color: #ef5350;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-500:not(.btn-link):not(.btn-flat) {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-600:not(.btn-link):not(.btn-flat) {
  background-color: #e53935;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-700:not(.btn-link):not(.btn-flat) {
  background-color: #d32f2f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-800:not(.btn-link):not(.btn-flat) {
  background-color: #c62828;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-900:not(.btn-link):not(.btn-flat) {
  background-color: #b71c1c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ff1744;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-A700:not(.btn-link):not(.btn-flat) {
  background-color: #d50000;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink:not(.btn-link):not(.btn-flat) {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-50:not(.btn-link):not(.btn-flat) {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-pink-100:not(.btn-link):not(.btn-flat) {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-pink-200:not(.btn-link):not(.btn-flat) {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-pink-300:not(.btn-link):not(.btn-flat) {
  background-color: #f06292;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-400:not(.btn-link):not(.btn-flat) {
  background-color: #ec407a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-500:not(.btn-link):not(.btn-flat) {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-600:not(.btn-link):not(.btn-flat) {
  background-color: #d81b60;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-700:not(.btn-link):not(.btn-flat) {
  background-color: #c2185b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-800:not(.btn-link):not(.btn-flat) {
  background-color: #ad1457;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-900:not(.btn-link):not(.btn-flat) {
  background-color: #880e4f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ff80ab;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ff4081;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A400:not(.btn-link):not(.btn-flat) {
  background-color: #f50057;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A700:not(.btn-link):not(.btn-flat) {
  background-color: #c51162;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple:not(.btn-link):not(.btn-flat) {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-50:not(.btn-link):not(.btn-flat) {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-purple-100:not(.btn-link):not(.btn-flat) {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-purple-200:not(.btn-link):not(.btn-flat) {
  background-color: #ce93d8;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-300:not(.btn-link):not(.btn-flat) {
  background-color: #ba68c8;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-400:not(.btn-link):not(.btn-flat) {
  background-color: #ab47bc;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-500:not(.btn-link):not(.btn-flat) {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-600:not(.btn-link):not(.btn-flat) {
  background-color: #8e24aa;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-700:not(.btn-link):not(.btn-flat) {
  background-color: #7b1fa2;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-800:not(.btn-link):not(.btn-flat) {
  background-color: #6a1b9a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-900:not(.btn-link):not(.btn-flat) {
  background-color: #4a148c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ea80fc;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A200:not(.btn-link):not(.btn-flat) {
  background-color: #e040fb;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A400:not(.btn-link):not(.btn-flat) {
  background-color: #d500f9;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A700:not(.btn-link):not(.btn-flat) {
  background-color: #aa00ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple:not(.btn-link):not(.btn-flat) {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-50:not(.btn-link):not(.btn-flat) {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-purple-100:not(.btn-link):not(.btn-flat) {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-purple-200:not(.btn-link):not(.btn-flat) {
  background-color: #b39ddb;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-300:not(.btn-link):not(.btn-flat) {
  background-color: #9575cd;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-400:not(.btn-link):not(.btn-flat) {
  background-color: #7e57c2;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-500:not(.btn-link):not(.btn-flat) {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-600:not(.btn-link):not(.btn-flat) {
  background-color: #5e35b1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-700:not(.btn-link):not(.btn-flat) {
  background-color: #512da8;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-800:not(.btn-link):not(.btn-flat) {
  background-color: #4527a0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-900:not(.btn-link):not(.btn-flat) {
  background-color: #311b92;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A100:not(.btn-link):not(.btn-flat) {
  background-color: #b388ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A200:not(.btn-link):not(.btn-flat) {
  background-color: #7c4dff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A400:not(.btn-link):not(.btn-flat) {
  background-color: #651fff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A700:not(.btn-link):not(.btn-flat) {
  background-color: #6200ea;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo:not(.btn-link):not(.btn-flat) {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-50:not(.btn-link):not(.btn-flat) {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-indigo-100:not(.btn-link):not(.btn-flat) {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-indigo-200:not(.btn-link):not(.btn-flat) {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-indigo-300:not(.btn-link):not(.btn-flat) {
  background-color: #7986cb;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-400:not(.btn-link):not(.btn-flat) {
  background-color: #5c6bc0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-500:not(.btn-link):not(.btn-flat) {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-600:not(.btn-link):not(.btn-flat) {
  background-color: #3949ab;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-700:not(.btn-link):not(.btn-flat) {
  background-color: #303f9f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-800:not(.btn-link):not(.btn-flat) {
  background-color: #283593;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-900:not(.btn-link):not(.btn-flat) {
  background-color: #1a237e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A100:not(.btn-link):not(.btn-flat) {
  background-color: #8c9eff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A200:not(.btn-link):not(.btn-flat) {
  background-color: #536dfe;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A400:not(.btn-link):not(.btn-flat) {
  background-color: #3d5afe;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A700:not(.btn-link):not(.btn-flat) {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue:not(.btn-link):not(.btn-flat) {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-50:not(.btn-link):not(.btn-flat) {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-100:not(.btn-link):not(.btn-flat) {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-200:not(.btn-link):not(.btn-flat) {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-300:not(.btn-link):not(.btn-flat) {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-400:not(.btn-link):not(.btn-flat) {
  background-color: #42a5f5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-500:not(.btn-link):not(.btn-flat) {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-600:not(.btn-link):not(.btn-flat) {
  background-color: #1e88e5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-700:not(.btn-link):not(.btn-flat) {
  background-color: #1976d2;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-800:not(.btn-link):not(.btn-flat) {
  background-color: #1565c0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-900:not(.btn-link):not(.btn-flat) {
  background-color: #0d47a1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A100:not(.btn-link):not(.btn-flat) {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-A200:not(.btn-link):not(.btn-flat) {
  background-color: #448aff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A400:not(.btn-link):not(.btn-flat) {
  background-color: #2979ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A700:not(.btn-link):not(.btn-flat) {
  background-color: #2962ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue:not(.btn-link):not(.btn-flat) {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-50:not(.btn-link):not(.btn-flat) {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-100:not(.btn-link):not(.btn-flat) {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-200:not(.btn-link):not(.btn-flat) {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-300:not(.btn-link):not(.btn-flat) {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-400:not(.btn-link):not(.btn-flat) {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-500:not(.btn-link):not(.btn-flat) {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-600:not(.btn-link):not(.btn-flat) {
  background-color: #039be5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-700:not(.btn-link):not(.btn-flat) {
  background-color: #0288d1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-800:not(.btn-link):not(.btn-flat) {
  background-color: #0277bd;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-900:not(.btn-link):not(.btn-flat) {
  background-color: #01579b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-A100:not(.btn-link):not(.btn-flat) {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-A200:not(.btn-link):not(.btn-flat) {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-A400:not(.btn-link):not(.btn-flat) {
  background-color: #00b0ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-A700:not(.btn-link):not(.btn-flat) {
  background-color: #0091ea;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan:not(.btn-link):not(.btn-flat) {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-50:not(.btn-link):not(.btn-flat) {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-100:not(.btn-link):not(.btn-flat) {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-200:not(.btn-link):not(.btn-flat) {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-300:not(.btn-link):not(.btn-flat) {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-400:not(.btn-link):not(.btn-flat) {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-500:not(.btn-link):not(.btn-flat) {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-600:not(.btn-link):not(.btn-flat) {
  background-color: #00acc1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-700:not(.btn-link):not(.btn-flat) {
  background-color: #0097a7;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-800:not(.btn-link):not(.btn-flat) {
  background-color: #00838f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-900:not(.btn-link):not(.btn-flat) {
  background-color: #006064;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-A100:not(.btn-link):not(.btn-flat) {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-A200:not(.btn-link):not(.btn-flat) {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-A400:not(.btn-link):not(.btn-flat) {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-A700:not(.btn-link):not(.btn-flat) {
  background-color: #00b8d4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal:not(.btn-link):not(.btn-flat) {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-50:not(.btn-link):not(.btn-flat) {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-100:not(.btn-link):not(.btn-flat) {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-200:not(.btn-link):not(.btn-flat) {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-300:not(.btn-link):not(.btn-flat) {
  background-color: #4db6ac;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-400:not(.btn-link):not(.btn-flat) {
  background-color: #26a69a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-500:not(.btn-link):not(.btn-flat) {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-600:not(.btn-link):not(.btn-flat) {
  background-color: #00897b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-700:not(.btn-link):not(.btn-flat) {
  background-color: #00796b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-800:not(.btn-link):not(.btn-flat) {
  background-color: #00695c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-900:not(.btn-link):not(.btn-flat) {
  background-color: #004d40;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-A100:not(.btn-link):not(.btn-flat) {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-A200:not(.btn-link):not(.btn-flat) {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-A400:not(.btn-link):not(.btn-flat) {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-A700:not(.btn-link):not(.btn-flat) {
  background-color: #00bfa5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green:not(.btn-link):not(.btn-flat) {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-50:not(.btn-link):not(.btn-flat) {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-100:not(.btn-link):not(.btn-flat) {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-200:not(.btn-link):not(.btn-flat) {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-300:not(.btn-link):not(.btn-flat) {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-400:not(.btn-link):not(.btn-flat) {
  background-color: #66bb6a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-500:not(.btn-link):not(.btn-flat) {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-600:not(.btn-link):not(.btn-flat) {
  background-color: #43a047;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-700:not(.btn-link):not(.btn-flat) {
  background-color: #388e3c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-800:not(.btn-link):not(.btn-flat) {
  background-color: #2e7d32;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-900:not(.btn-link):not(.btn-flat) {
  background-color: #1b5e20;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-A100:not(.btn-link):not(.btn-flat) {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-A200:not(.btn-link):not(.btn-flat) {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-A400:not(.btn-link):not(.btn-flat) {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-A700:not(.btn-link):not(.btn-flat) {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green:not(.btn-link):not(.btn-flat) {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-50:not(.btn-link):not(.btn-flat) {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-100:not(.btn-link):not(.btn-flat) {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-200:not(.btn-link):not(.btn-flat) {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-300:not(.btn-link):not(.btn-flat) {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-400:not(.btn-link):not(.btn-flat) {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-500:not(.btn-link):not(.btn-flat) {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-600:not(.btn-link):not(.btn-flat) {
  background-color: #7cb342;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-700:not(.btn-link):not(.btn-flat) {
  background-color: #689f38;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-800:not(.btn-link):not(.btn-flat) {
  background-color: #558b2f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-900:not(.btn-link):not(.btn-flat) {
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-A200:not(.btn-link):not(.btn-flat) {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-A400:not(.btn-link):not(.btn-flat) {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-A700:not(.btn-link):not(.btn-flat) {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime:not(.btn-link):not(.btn-flat) {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-50:not(.btn-link):not(.btn-flat) {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-100:not(.btn-link):not(.btn-flat) {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-200:not(.btn-link):not(.btn-flat) {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-300:not(.btn-link):not(.btn-flat) {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-400:not(.btn-link):not(.btn-flat) {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-500:not(.btn-link):not(.btn-flat) {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-600:not(.btn-link):not(.btn-flat) {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-700:not(.btn-link):not(.btn-flat) {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-800:not(.btn-link):not(.btn-flat) {
  background-color: #9e9d24;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-lime-900:not(.btn-link):not(.btn-flat) {
  background-color: #827717;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-lime-A100:not(.btn-link):not(.btn-flat) {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-A200:not(.btn-link):not(.btn-flat) {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-A400:not(.btn-link):not(.btn-flat) {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-A700:not(.btn-link):not(.btn-flat) {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow:not(.btn-link):not(.btn-flat) {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-50:not(.btn-link):not(.btn-flat) {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-100:not(.btn-link):not(.btn-flat) {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-200:not(.btn-link):not(.btn-flat) {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-300:not(.btn-link):not(.btn-flat) {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-400:not(.btn-link):not(.btn-flat) {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-500:not(.btn-link):not(.btn-flat) {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-600:not(.btn-link):not(.btn-flat) {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-700:not(.btn-link):not(.btn-flat) {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-800:not(.btn-link):not(.btn-flat) {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-900:not(.btn-link):not(.btn-flat) {
  background-color: #f57f17;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-yellow-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-A700:not(.btn-link):not(.btn-flat) {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber:not(.btn-link):not(.btn-flat) {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-50:not(.btn-link):not(.btn-flat) {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-200:not(.btn-link):not(.btn-flat) {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-300:not(.btn-link):not(.btn-flat) {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-400:not(.btn-link):not(.btn-flat) {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-500:not(.btn-link):not(.btn-flat) {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-600:not(.btn-link):not(.btn-flat) {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-700:not(.btn-link):not(.btn-flat) {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-800:not(.btn-link):not(.btn-flat) {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-900:not(.btn-link):not(.btn-flat) {
  background-color: #ff6f00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-amber-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-A700:not(.btn-link):not(.btn-flat) {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange:not(.btn-link):not(.btn-flat) {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-50:not(.btn-link):not(.btn-flat) {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-200:not(.btn-link):not(.btn-flat) {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-300:not(.btn-link):not(.btn-flat) {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-400:not(.btn-link):not(.btn-flat) {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-500:not(.btn-link):not(.btn-flat) {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-600:not(.btn-link):not(.btn-flat) {
  background-color: #fb8c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-700:not(.btn-link):not(.btn-flat) {
  background-color: #f57c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-800:not(.btn-link):not(.btn-flat) {
  background-color: #ef6c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-900:not(.btn-link):not(.btn-flat) {
  background-color: #e65100;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-A700:not(.btn-link):not(.btn-flat) {
  background-color: #ff6d00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange:not(.btn-link):not(.btn-flat) {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-50:not(.btn-link):not(.btn-flat) {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-200:not(.btn-link):not(.btn-flat) {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-300:not(.btn-link):not(.btn-flat) {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-400:not(.btn-link):not(.btn-flat) {
  background-color: #ff7043;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-500:not(.btn-link):not(.btn-flat) {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-600:not(.btn-link):not(.btn-flat) {
  background-color: #f4511e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-700:not(.btn-link):not(.btn-flat) {
  background-color: #e64a19;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-800:not(.btn-link):not(.btn-flat) {
  background-color: #d84315;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-900:not(.btn-link):not(.btn-flat) {
  background-color: #bf360c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e40;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ff3d00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-A700:not(.btn-link):not(.btn-flat) {
  background-color: #dd2c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown:not(.btn-link):not(.btn-flat) {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-50:not(.btn-link):not(.btn-flat) {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-100:not(.btn-link):not(.btn-flat) {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-200:not(.btn-link):not(.btn-flat) {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-300:not(.btn-link):not(.btn-flat) {
  background-color: #a1887f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-400:not(.btn-link):not(.btn-flat) {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-500:not(.btn-link):not(.btn-flat) {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-600:not(.btn-link):not(.btn-flat) {
  background-color: #6d4c41;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-700:not(.btn-link):not(.btn-flat) {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-800:not(.btn-link):not(.btn-flat) {
  background-color: #4e342e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-900:not(.btn-link):not(.btn-flat) {
  background-color: #3e2723;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-A100:not(.btn-link):not(.btn-flat) {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-A200:not(.btn-link):not(.btn-flat) {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-A400:not(.btn-link):not(.btn-flat) {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-A700:not(.btn-link):not(.btn-flat) {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey:not(.btn-link):not(.btn-flat) {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-50:not(.btn-link):not(.btn-flat) {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-100:not(.btn-link):not(.btn-flat) {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-200:not(.btn-link):not(.btn-flat) {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-300:not(.btn-link):not(.btn-flat) {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-400:not(.btn-link):not(.btn-flat) {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-500:not(.btn-link):not(.btn-flat) {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-600:not(.btn-link):not(.btn-flat) {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-700:not(.btn-link):not(.btn-flat) {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-800:not(.btn-link):not(.btn-flat) {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-900:not(.btn-link):not(.btn-flat) {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-A100:not(.btn-link):not(.btn-flat) {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-A200:not(.btn-link):not(.btn-flat) {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-A400:not(.btn-link):not(.btn-flat) {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-A700:not(.btn-link):not(.btn-flat) {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue:not(.btn-link):not(.btn-flat) {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-50:not(.btn-link):not(.btn-flat) {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-100:not(.btn-link):not(.btn-flat) {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-200:not(.btn-link):not(.btn-flat) {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-300:not(.btn-link):not(.btn-flat) {
  background-color: #90a4ae;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-400:not(.btn-link):not(.btn-flat) {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-500:not(.btn-link):not(.btn-flat) {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-600:not(.btn-link):not(.btn-flat) {
  background-color: #546e7a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-700:not(.btn-link):not(.btn-flat) {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-800:not(.btn-link):not(.btn-flat) {
  background-color: #37474f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-900:not(.btn-link):not(.btn-flat) {
  background-color: #263238;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A100:not(.btn-link):not(.btn-flat) {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-A200:not(.btn-link):not(.btn-flat) {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-A400:not(.btn-link):not(.btn-flat) {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A700:not(.btn-link):not(.btn-flat) {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.btn.btn-flat {
  background: none;
  box-shadow: none;
  font-weight: 500;
}
.btn.btn-flat:disabled {
  color: #a8a8a8 !important;
}
.btn.btn-raised {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn.btn-raised:active:not(.btn-link) {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
}
.btn.btn-fab {
  margin: 0;
  padding: 15px;
  font-size: 26px;
  width: 56px;
  height: 56px;
}
.btn.btn-fab,
.btn.btn-fab:hover {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.btn.btn-fab,
.btn.btn-fab:hover,
.btn.btn-fab-default,
.btn.btn-fab:hover-default {
  background-color: transparent;
}
.btn.btn-fab-primary,
.btn.btn-fab:hover-primary {
  background-color: #009587;
}
.btn.btn-fab-success,
.btn.btn-fab:hover-success {
  background-color: #0f9d58;
}
.btn.btn-fab-info,
.btn.btn-fab:hover-info {
  background-color: #03a9f4;
}
.btn.btn-fab-warning,
.btn.btn-fab:hover-warning {
  background-color: #ff5722;
}
.btn.btn-fab-danger,
.btn.btn-fab:hover-danger {
  background-color: #f44336;
}
.btn.btn-fab-material-red,
.btn.btn-fab:hover-material-red {
  background-color: #f44336;
}
.btn.btn-fab-material-red-50,
.btn.btn-fab:hover-material-red-50 {
  background-color: #ffebee;
}
.btn.btn-fab-material-red-100,
.btn.btn-fab:hover-material-red-100 {
  background-color: #ffcdd2;
}
.btn.btn-fab-material-red-200,
.btn.btn-fab:hover-material-red-200 {
  background-color: #ef9a9a;
}
.btn.btn-fab-material-red-300,
.btn.btn-fab:hover-material-red-300 {
  background-color: #e57373;
}
.btn.btn-fab-material-red-400,
.btn.btn-fab:hover-material-red-400 {
  background-color: #ef5350;
}
.btn.btn-fab-material-red-500,
.btn.btn-fab:hover-material-red-500 {
  background-color: #f44336;
}
.btn.btn-fab-material-red-600,
.btn.btn-fab:hover-material-red-600 {
  background-color: #e53935;
}
.btn.btn-fab-material-red-700,
.btn.btn-fab:hover-material-red-700 {
  background-color: #d32f2f;
}
.btn.btn-fab-material-red-800,
.btn.btn-fab:hover-material-red-800 {
  background-color: #c62828;
}
.btn.btn-fab-material-red-900,
.btn.btn-fab:hover-material-red-900 {
  background-color: #b71c1c;
}
.btn.btn-fab-material-red-A100,
.btn.btn-fab:hover-material-red-A100 {
  background-color: #ff8a80;
}
.btn.btn-fab-material-red-A200,
.btn.btn-fab:hover-material-red-A200 {
  background-color: #ff5252;
}
.btn.btn-fab-material-red-A400,
.btn.btn-fab:hover-material-red-A400 {
  background-color: #ff1744;
}
.btn.btn-fab-material-red-A700,
.btn.btn-fab:hover-material-red-A700 {
  background-color: #d50000;
}
.btn.btn-fab-material-pink,
.btn.btn-fab:hover-material-pink {
  background-color: #e91e63;
}
.btn.btn-fab-material-pink-50,
.btn.btn-fab:hover-material-pink-50 {
  background-color: #fce4ec;
}
.btn.btn-fab-material-pink-100,
.btn.btn-fab:hover-material-pink-100 {
  background-color: #f8bbd0;
}
.btn.btn-fab-material-pink-200,
.btn.btn-fab:hover-material-pink-200 {
  background-color: #f48fb1;
}
.btn.btn-fab-material-pink-300,
.btn.btn-fab:hover-material-pink-300 {
  background-color: #f06292;
}
.btn.btn-fab-material-pink-400,
.btn.btn-fab:hover-material-pink-400 {
  background-color: #ec407a;
}
.btn.btn-fab-material-pink-500,
.btn.btn-fab:hover-material-pink-500 {
  background-color: #e91e63;
}
.btn.btn-fab-material-pink-600,
.btn.btn-fab:hover-material-pink-600 {
  background-color: #d81b60;
}
.btn.btn-fab-material-pink-700,
.btn.btn-fab:hover-material-pink-700 {
  background-color: #c2185b;
}
.btn.btn-fab-material-pink-800,
.btn.btn-fab:hover-material-pink-800 {
  background-color: #ad1457;
}
.btn.btn-fab-material-pink-900,
.btn.btn-fab:hover-material-pink-900 {
  background-color: #880e4f;
}
.btn.btn-fab-material-pink-A100,
.btn.btn-fab:hover-material-pink-A100 {
  background-color: #ff80ab;
}
.btn.btn-fab-material-pink-A200,
.btn.btn-fab:hover-material-pink-A200 {
  background-color: #ff4081;
}
.btn.btn-fab-material-pink-A400,
.btn.btn-fab:hover-material-pink-A400 {
  background-color: #f50057;
}
.btn.btn-fab-material-pink-A700,
.btn.btn-fab:hover-material-pink-A700 {
  background-color: #c51162;
}
.btn.btn-fab-material-purple,
.btn.btn-fab:hover-material-purple {
  background-color: #9c27b0;
}
.btn.btn-fab-material-purple-50,
.btn.btn-fab:hover-material-purple-50 {
  background-color: #f3e5f5;
}
.btn.btn-fab-material-purple-100,
.btn.btn-fab:hover-material-purple-100 {
  background-color: #e1bee7;
}
.btn.btn-fab-material-purple-200,
.btn.btn-fab:hover-material-purple-200 {
  background-color: #ce93d8;
}
.btn.btn-fab-material-purple-300,
.btn.btn-fab:hover-material-purple-300 {
  background-color: #ba68c8;
}
.btn.btn-fab-material-purple-400,
.btn.btn-fab:hover-material-purple-400 {
  background-color: #ab47bc;
}
.btn.btn-fab-material-purple-500,
.btn.btn-fab:hover-material-purple-500 {
  background-color: #9c27b0;
}
.btn.btn-fab-material-purple-600,
.btn.btn-fab:hover-material-purple-600 {
  background-color: #8e24aa;
}
.btn.btn-fab-material-purple-700,
.btn.btn-fab:hover-material-purple-700 {
  background-color: #7b1fa2;
}
.btn.btn-fab-material-purple-800,
.btn.btn-fab:hover-material-purple-800 {
  background-color: #6a1b9a;
}
.btn.btn-fab-material-purple-900,
.btn.btn-fab:hover-material-purple-900 {
  background-color: #4a148c;
}
.btn.btn-fab-material-purple-A100,
.btn.btn-fab:hover-material-purple-A100 {
  background-color: #ea80fc;
}
.btn.btn-fab-material-purple-A200,
.btn.btn-fab:hover-material-purple-A200 {
  background-color: #e040fb;
}
.btn.btn-fab-material-purple-A400,
.btn.btn-fab:hover-material-purple-A400 {
  background-color: #d500f9;
}
.btn.btn-fab-material-purple-A700,
.btn.btn-fab:hover-material-purple-A700 {
  background-color: #aa00ff;
}
.btn.btn-fab-material-deep-purple,
.btn.btn-fab:hover-material-deep-purple {
  background-color: #673ab7;
}
.btn.btn-fab-material-deep-purple-50,
.btn.btn-fab:hover-material-deep-purple-50 {
  background-color: #ede7f6;
}
.btn.btn-fab-material-deep-purple-100,
.btn.btn-fab:hover-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.btn.btn-fab-material-deep-purple-200,
.btn.btn-fab:hover-material-deep-purple-200 {
  background-color: #b39ddb;
}
.btn.btn-fab-material-deep-purple-300,
.btn.btn-fab:hover-material-deep-purple-300 {
  background-color: #9575cd;
}
.btn.btn-fab-material-deep-purple-400,
.btn.btn-fab:hover-material-deep-purple-400 {
  background-color: #7e57c2;
}
.btn.btn-fab-material-deep-purple-500,
.btn.btn-fab:hover-material-deep-purple-500 {
  background-color: #673ab7;
}
.btn.btn-fab-material-deep-purple-600,
.btn.btn-fab:hover-material-deep-purple-600 {
  background-color: #5e35b1;
}
.btn.btn-fab-material-deep-purple-700,
.btn.btn-fab:hover-material-deep-purple-700 {
  background-color: #512da8;
}
.btn.btn-fab-material-deep-purple-800,
.btn.btn-fab:hover-material-deep-purple-800 {
  background-color: #4527a0;
}
.btn.btn-fab-material-deep-purple-900,
.btn.btn-fab:hover-material-deep-purple-900 {
  background-color: #311b92;
}
.btn.btn-fab-material-deep-purple-A100,
.btn.btn-fab:hover-material-deep-purple-A100 {
  background-color: #b388ff;
}
.btn.btn-fab-material-deep-purple-A200,
.btn.btn-fab:hover-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.btn.btn-fab-material-deep-purple-A400,
.btn.btn-fab:hover-material-deep-purple-A400 {
  background-color: #651fff;
}
.btn.btn-fab-material-deep-purple-A700,
.btn.btn-fab:hover-material-deep-purple-A700 {
  background-color: #6200ea;
}
.btn.btn-fab-material-indigo,
.btn.btn-fab:hover-material-indigo {
  background-color: #3f51b5;
}
.btn.btn-fab-material-indigo-50,
.btn.btn-fab:hover-material-indigo-50 {
  background-color: #e8eaf6;
}
.btn.btn-fab-material-indigo-100,
.btn.btn-fab:hover-material-indigo-100 {
  background-color: #c5cae9;
}
.btn.btn-fab-material-indigo-200,
.btn.btn-fab:hover-material-indigo-200 {
  background-color: #9fa8da;
}
.btn.btn-fab-material-indigo-300,
.btn.btn-fab:hover-material-indigo-300 {
  background-color: #7986cb;
}
.btn.btn-fab-material-indigo-400,
.btn.btn-fab:hover-material-indigo-400 {
  background-color: #5c6bc0;
}
.btn.btn-fab-material-indigo-500,
.btn.btn-fab:hover-material-indigo-500 {
  background-color: #3f51b5;
}
.btn.btn-fab-material-indigo-600,
.btn.btn-fab:hover-material-indigo-600 {
  background-color: #3949ab;
}
.btn.btn-fab-material-indigo-700,
.btn.btn-fab:hover-material-indigo-700 {
  background-color: #303f9f;
}
.btn.btn-fab-material-indigo-800,
.btn.btn-fab:hover-material-indigo-800 {
  background-color: #283593;
}
.btn.btn-fab-material-indigo-900,
.btn.btn-fab:hover-material-indigo-900 {
  background-color: #1a237e;
}
.btn.btn-fab-material-indigo-A100,
.btn.btn-fab:hover-material-indigo-A100 {
  background-color: #8c9eff;
}
.btn.btn-fab-material-indigo-A200,
.btn.btn-fab:hover-material-indigo-A200 {
  background-color: #536dfe;
}
.btn.btn-fab-material-indigo-A400,
.btn.btn-fab:hover-material-indigo-A400 {
  background-color: #3d5afe;
}
.btn.btn-fab-material-indigo-A700,
.btn.btn-fab:hover-material-indigo-A700 {
  background-color: #304ffe;
}
.btn.btn-fab-material-blue,
.btn.btn-fab:hover-material-blue {
  background-color: #2196f3;
}
.btn.btn-fab-material-blue-50,
.btn.btn-fab:hover-material-blue-50 {
  background-color: #e3f2fd;
}
.btn.btn-fab-material-blue-100,
.btn.btn-fab:hover-material-blue-100 {
  background-color: #bbdefb;
}
.btn.btn-fab-material-blue-200,
.btn.btn-fab:hover-material-blue-200 {
  background-color: #90caf9;
}
.btn.btn-fab-material-blue-300,
.btn.btn-fab:hover-material-blue-300 {
  background-color: #64b5f6;
}
.btn.btn-fab-material-blue-400,
.btn.btn-fab:hover-material-blue-400 {
  background-color: #42a5f5;
}
.btn.btn-fab-material-blue-500,
.btn.btn-fab:hover-material-blue-500 {
  background-color: #2196f3;
}
.btn.btn-fab-material-blue-600,
.btn.btn-fab:hover-material-blue-600 {
  background-color: #1e88e5;
}
.btn.btn-fab-material-blue-700,
.btn.btn-fab:hover-material-blue-700 {
  background-color: #1976d2;
}
.btn.btn-fab-material-blue-800,
.btn.btn-fab:hover-material-blue-800 {
  background-color: #1565c0;
}
.btn.btn-fab-material-blue-900,
.btn.btn-fab:hover-material-blue-900 {
  background-color: #0d47a1;
}
.btn.btn-fab-material-blue-A100,
.btn.btn-fab:hover-material-blue-A100 {
  background-color: #82b1ff;
}
.btn.btn-fab-material-blue-A200,
.btn.btn-fab:hover-material-blue-A200 {
  background-color: #448aff;
}
.btn.btn-fab-material-blue-A400,
.btn.btn-fab:hover-material-blue-A400 {
  background-color: #2979ff;
}
.btn.btn-fab-material-blue-A700,
.btn.btn-fab:hover-material-blue-A700 {
  background-color: #2962ff;
}
.btn.btn-fab-material-light-blue,
.btn.btn-fab:hover-material-light-blue {
  background-color: #03a9f4;
}
.btn.btn-fab-material-light-blue-50,
.btn.btn-fab:hover-material-light-blue-50 {
  background-color: #e1f5fe;
}
.btn.btn-fab-material-light-blue-100,
.btn.btn-fab:hover-material-light-blue-100 {
  background-color: #b3e5fc;
}
.btn.btn-fab-material-light-blue-200,
.btn.btn-fab:hover-material-light-blue-200 {
  background-color: #81d4fa;
}
.btn.btn-fab-material-light-blue-300,
.btn.btn-fab:hover-material-light-blue-300 {
  background-color: #4fc3f7;
}
.btn.btn-fab-material-light-blue-400,
.btn.btn-fab:hover-material-light-blue-400 {
  background-color: #29b6f6;
}
.btn.btn-fab-material-light-blue-500,
.btn.btn-fab:hover-material-light-blue-500 {
  background-color: #03a9f4;
}
.btn.btn-fab-material-light-blue-600,
.btn.btn-fab:hover-material-light-blue-600 {
  background-color: #039be5;
}
.btn.btn-fab-material-light-blue-700,
.btn.btn-fab:hover-material-light-blue-700 {
  background-color: #0288d1;
}
.btn.btn-fab-material-light-blue-800,
.btn.btn-fab:hover-material-light-blue-800 {
  background-color: #0277bd;
}
.btn.btn-fab-material-light-blue-900,
.btn.btn-fab:hover-material-light-blue-900 {
  background-color: #01579b;
}
.btn.btn-fab-material-light-blue-A100,
.btn.btn-fab:hover-material-light-blue-A100 {
  background-color: #80d8ff;
}
.btn.btn-fab-material-light-blue-A200,
.btn.btn-fab:hover-material-light-blue-A200 {
  background-color: #40c4ff;
}
.btn.btn-fab-material-light-blue-A400,
.btn.btn-fab:hover-material-light-blue-A400 {
  background-color: #00b0ff;
}
.btn.btn-fab-material-light-blue-A700,
.btn.btn-fab:hover-material-light-blue-A700 {
  background-color: #0091ea;
}
.btn.btn-fab-material-cyan,
.btn.btn-fab:hover-material-cyan {
  background-color: #00bcd4;
}
.btn.btn-fab-material-cyan-50,
.btn.btn-fab:hover-material-cyan-50 {
  background-color: #e0f7fa;
}
.btn.btn-fab-material-cyan-100,
.btn.btn-fab:hover-material-cyan-100 {
  background-color: #b2ebf2;
}
.btn.btn-fab-material-cyan-200,
.btn.btn-fab:hover-material-cyan-200 {
  background-color: #80deea;
}
.btn.btn-fab-material-cyan-300,
.btn.btn-fab:hover-material-cyan-300 {
  background-color: #4dd0e1;
}
.btn.btn-fab-material-cyan-400,
.btn.btn-fab:hover-material-cyan-400 {
  background-color: #26c6da;
}
.btn.btn-fab-material-cyan-500,
.btn.btn-fab:hover-material-cyan-500 {
  background-color: #00bcd4;
}
.btn.btn-fab-material-cyan-600,
.btn.btn-fab:hover-material-cyan-600 {
  background-color: #00acc1;
}
.btn.btn-fab-material-cyan-700,
.btn.btn-fab:hover-material-cyan-700 {
  background-color: #0097a7;
}
.btn.btn-fab-material-cyan-800,
.btn.btn-fab:hover-material-cyan-800 {
  background-color: #00838f;
}
.btn.btn-fab-material-cyan-900,
.btn.btn-fab:hover-material-cyan-900 {
  background-color: #006064;
}
.btn.btn-fab-material-cyan-A100,
.btn.btn-fab:hover-material-cyan-A100 {
  background-color: #84ffff;
}
.btn.btn-fab-material-cyan-A200,
.btn.btn-fab:hover-material-cyan-A200 {
  background-color: #18ffff;
}
.btn.btn-fab-material-cyan-A400,
.btn.btn-fab:hover-material-cyan-A400 {
  background-color: #00e5ff;
}
.btn.btn-fab-material-cyan-A700,
.btn.btn-fab:hover-material-cyan-A700 {
  background-color: #00b8d4;
}
.btn.btn-fab-material-teal,
.btn.btn-fab:hover-material-teal {
  background-color: #009688;
}
.btn.btn-fab-material-teal-50,
.btn.btn-fab:hover-material-teal-50 {
  background-color: #e0f2f1;
}
.btn.btn-fab-material-teal-100,
.btn.btn-fab:hover-material-teal-100 {
  background-color: #b2dfdb;
}
.btn.btn-fab-material-teal-200,
.btn.btn-fab:hover-material-teal-200 {
  background-color: #80cbc4;
}
.btn.btn-fab-material-teal-300,
.btn.btn-fab:hover-material-teal-300 {
  background-color: #4db6ac;
}
.btn.btn-fab-material-teal-400,
.btn.btn-fab:hover-material-teal-400 {
  background-color: #26a69a;
}
.btn.btn-fab-material-teal-500,
.btn.btn-fab:hover-material-teal-500 {
  background-color: #009688;
}
.btn.btn-fab-material-teal-600,
.btn.btn-fab:hover-material-teal-600 {
  background-color: #00897b;
}
.btn.btn-fab-material-teal-700,
.btn.btn-fab:hover-material-teal-700 {
  background-color: #00796b;
}
.btn.btn-fab-material-teal-800,
.btn.btn-fab:hover-material-teal-800 {
  background-color: #00695c;
}
.btn.btn-fab-material-teal-900,
.btn.btn-fab:hover-material-teal-900 {
  background-color: #004d40;
}
.btn.btn-fab-material-teal-A100,
.btn.btn-fab:hover-material-teal-A100 {
  background-color: #a7ffeb;
}
.btn.btn-fab-material-teal-A200,
.btn.btn-fab:hover-material-teal-A200 {
  background-color: #64ffda;
}
.btn.btn-fab-material-teal-A400,
.btn.btn-fab:hover-material-teal-A400 {
  background-color: #1de9b6;
}
.btn.btn-fab-material-teal-A700,
.btn.btn-fab:hover-material-teal-A700 {
  background-color: #00bfa5;
}
.btn.btn-fab-material-green,
.btn.btn-fab:hover-material-green {
  background-color: #4caf50;
}
.btn.btn-fab-material-green-50,
.btn.btn-fab:hover-material-green-50 {
  background-color: #e8f5e9;
}
.btn.btn-fab-material-green-100,
.btn.btn-fab:hover-material-green-100 {
  background-color: #c8e6c9;
}
.btn.btn-fab-material-green-200,
.btn.btn-fab:hover-material-green-200 {
  background-color: #a5d6a7;
}
.btn.btn-fab-material-green-300,
.btn.btn-fab:hover-material-green-300 {
  background-color: #81c784;
}
.btn.btn-fab-material-green-400,
.btn.btn-fab:hover-material-green-400 {
  background-color: #66bb6a;
}
.btn.btn-fab-material-green-500,
.btn.btn-fab:hover-material-green-500 {
  background-color: #4caf50;
}
.btn.btn-fab-material-green-600,
.btn.btn-fab:hover-material-green-600 {
  background-color: #43a047;
}
.btn.btn-fab-material-green-700,
.btn.btn-fab:hover-material-green-700 {
  background-color: #388e3c;
}
.btn.btn-fab-material-green-800,
.btn.btn-fab:hover-material-green-800 {
  background-color: #2e7d32;
}
.btn.btn-fab-material-green-900,
.btn.btn-fab:hover-material-green-900 {
  background-color: #1b5e20;
}
.btn.btn-fab-material-green-A100,
.btn.btn-fab:hover-material-green-A100 {
  background-color: #b9f6ca;
}
.btn.btn-fab-material-green-A200,
.btn.btn-fab:hover-material-green-A200 {
  background-color: #69f0ae;
}
.btn.btn-fab-material-green-A400,
.btn.btn-fab:hover-material-green-A400 {
  background-color: #00e676;
}
.btn.btn-fab-material-green-A700,
.btn.btn-fab:hover-material-green-A700 {
  background-color: #00c853;
}
.btn.btn-fab-material-light-green,
.btn.btn-fab:hover-material-light-green {
  background-color: #8bc34a;
}
.btn.btn-fab-material-light-green-50,
.btn.btn-fab:hover-material-light-green-50 {
  background-color: #f1f8e9;
}
.btn.btn-fab-material-light-green-100,
.btn.btn-fab:hover-material-light-green-100 {
  background-color: #dcedc8;
}
.btn.btn-fab-material-light-green-200,
.btn.btn-fab:hover-material-light-green-200 {
  background-color: #c5e1a5;
}
.btn.btn-fab-material-light-green-300,
.btn.btn-fab:hover-material-light-green-300 {
  background-color: #aed581;
}
.btn.btn-fab-material-light-green-400,
.btn.btn-fab:hover-material-light-green-400 {
  background-color: #9ccc65;
}
.btn.btn-fab-material-light-green-500,
.btn.btn-fab:hover-material-light-green-500 {
  background-color: #8bc34a;
}
.btn.btn-fab-material-light-green-600,
.btn.btn-fab:hover-material-light-green-600 {
  background-color: #7cb342;
}
.btn.btn-fab-material-light-green-700,
.btn.btn-fab:hover-material-light-green-700 {
  background-color: #689f38;
}
.btn.btn-fab-material-light-green-800,
.btn.btn-fab:hover-material-light-green-800 {
  background-color: #558b2f;
}
.btn.btn-fab-material-light-green-900,
.btn.btn-fab:hover-material-light-green-900 {
  background-color: #33691e;
}
.btn.btn-fab-material-light-green-A100,
.btn.btn-fab:hover-material-light-green-A100 {
  background-color: #ccff90;
}
.btn.btn-fab-material-light-green-A200,
.btn.btn-fab:hover-material-light-green-A200 {
  background-color: #b2ff59;
}
.btn.btn-fab-material-light-green-A400,
.btn.btn-fab:hover-material-light-green-A400 {
  background-color: #76ff03;
}
.btn.btn-fab-material-light-green-A700,
.btn.btn-fab:hover-material-light-green-A700 {
  background-color: #64dd17;
}
.btn.btn-fab-material-lime,
.btn.btn-fab:hover-material-lime {
  background-color: #cddc39;
}
.btn.btn-fab-material-lime-50,
.btn.btn-fab:hover-material-lime-50 {
  background-color: #f9fbe7;
}
.btn.btn-fab-material-lime-100,
.btn.btn-fab:hover-material-lime-100 {
  background-color: #f0f4c3;
}
.btn.btn-fab-material-lime-200,
.btn.btn-fab:hover-material-lime-200 {
  background-color: #e6ee9c;
}
.btn.btn-fab-material-lime-300,
.btn.btn-fab:hover-material-lime-300 {
  background-color: #dce775;
}
.btn.btn-fab-material-lime-400,
.btn.btn-fab:hover-material-lime-400 {
  background-color: #d4e157;
}
.btn.btn-fab-material-lime-500,
.btn.btn-fab:hover-material-lime-500 {
  background-color: #cddc39;
}
.btn.btn-fab-material-lime-600,
.btn.btn-fab:hover-material-lime-600 {
  background-color: #c0ca33;
}
.btn.btn-fab-material-lime-700,
.btn.btn-fab:hover-material-lime-700 {
  background-color: #afb42b;
}
.btn.btn-fab-material-lime-800,
.btn.btn-fab:hover-material-lime-800 {
  background-color: #9e9d24;
}
.btn.btn-fab-material-lime-900,
.btn.btn-fab:hover-material-lime-900 {
  background-color: #827717;
}
.btn.btn-fab-material-lime-A100,
.btn.btn-fab:hover-material-lime-A100 {
  background-color: #f4ff81;
}
.btn.btn-fab-material-lime-A200,
.btn.btn-fab:hover-material-lime-A200 {
  background-color: #eeff41;
}
.btn.btn-fab-material-lime-A400,
.btn.btn-fab:hover-material-lime-A400 {
  background-color: #c6ff00;
}
.btn.btn-fab-material-lime-A700,
.btn.btn-fab:hover-material-lime-A700 {
  background-color: #aeea00;
}
.btn.btn-fab-material-yellow,
.btn.btn-fab:hover-material-yellow {
  background-color: #ffeb3b;
}
.btn.btn-fab-material-yellow-50,
.btn.btn-fab:hover-material-yellow-50 {
  background-color: #fffde7;
}
.btn.btn-fab-material-yellow-100,
.btn.btn-fab:hover-material-yellow-100 {
  background-color: #fff9c4;
}
.btn.btn-fab-material-yellow-200,
.btn.btn-fab:hover-material-yellow-200 {
  background-color: #fff59d;
}
.btn.btn-fab-material-yellow-300,
.btn.btn-fab:hover-material-yellow-300 {
  background-color: #fff176;
}
.btn.btn-fab-material-yellow-400,
.btn.btn-fab:hover-material-yellow-400 {
  background-color: #ffee58;
}
.btn.btn-fab-material-yellow-500,
.btn.btn-fab:hover-material-yellow-500 {
  background-color: #ffeb3b;
}
.btn.btn-fab-material-yellow-600,
.btn.btn-fab:hover-material-yellow-600 {
  background-color: #fdd835;
}
.btn.btn-fab-material-yellow-700,
.btn.btn-fab:hover-material-yellow-700 {
  background-color: #fbc02d;
}
.btn.btn-fab-material-yellow-800,
.btn.btn-fab:hover-material-yellow-800 {
  background-color: #f9a825;
}
.btn.btn-fab-material-yellow-900,
.btn.btn-fab:hover-material-yellow-900 {
  background-color: #f57f17;
}
.btn.btn-fab-material-yellow-A100,
.btn.btn-fab:hover-material-yellow-A100 {
  background-color: #ffff8d;
}
.btn.btn-fab-material-yellow-A200,
.btn.btn-fab:hover-material-yellow-A200 {
  background-color: #ffff00;
}
.btn.btn-fab-material-yellow-A400,
.btn.btn-fab:hover-material-yellow-A400 {
  background-color: #ffea00;
}
.btn.btn-fab-material-yellow-A700,
.btn.btn-fab:hover-material-yellow-A700 {
  background-color: #ffd600;
}
.btn.btn-fab-material-amber,
.btn.btn-fab:hover-material-amber {
  background-color: #ffc107;
}
.btn.btn-fab-material-amber-50,
.btn.btn-fab:hover-material-amber-50 {
  background-color: #fff8e1;
}
.btn.btn-fab-material-amber-100,
.btn.btn-fab:hover-material-amber-100 {
  background-color: #ffecb3;
}
.btn.btn-fab-material-amber-200,
.btn.btn-fab:hover-material-amber-200 {
  background-color: #ffe082;
}
.btn.btn-fab-material-amber-300,
.btn.btn-fab:hover-material-amber-300 {
  background-color: #ffd54f;
}
.btn.btn-fab-material-amber-400,
.btn.btn-fab:hover-material-amber-400 {
  background-color: #ffca28;
}
.btn.btn-fab-material-amber-500,
.btn.btn-fab:hover-material-amber-500 {
  background-color: #ffc107;
}
.btn.btn-fab-material-amber-600,
.btn.btn-fab:hover-material-amber-600 {
  background-color: #ffb300;
}
.btn.btn-fab-material-amber-700,
.btn.btn-fab:hover-material-amber-700 {
  background-color: #ffa000;
}
.btn.btn-fab-material-amber-800,
.btn.btn-fab:hover-material-amber-800 {
  background-color: #ff8f00;
}
.btn.btn-fab-material-amber-900,
.btn.btn-fab:hover-material-amber-900 {
  background-color: #ff6f00;
}
.btn.btn-fab-material-amber-A100,
.btn.btn-fab:hover-material-amber-A100 {
  background-color: #ffe57f;
}
.btn.btn-fab-material-amber-A200,
.btn.btn-fab:hover-material-amber-A200 {
  background-color: #ffd740;
}
.btn.btn-fab-material-amber-A400,
.btn.btn-fab:hover-material-amber-A400 {
  background-color: #ffc400;
}
.btn.btn-fab-material-amber-A700,
.btn.btn-fab:hover-material-amber-A700 {
  background-color: #ffab00;
}
.btn.btn-fab-material-orange,
.btn.btn-fab:hover-material-orange {
  background-color: #ff9800;
}
.btn.btn-fab-material-orange-50,
.btn.btn-fab:hover-material-orange-50 {
  background-color: #fff3e0;
}
.btn.btn-fab-material-orange-100,
.btn.btn-fab:hover-material-orange-100 {
  background-color: #ffe0b2;
}
.btn.btn-fab-material-orange-200,
.btn.btn-fab:hover-material-orange-200 {
  background-color: #ffcc80;
}
.btn.btn-fab-material-orange-300,
.btn.btn-fab:hover-material-orange-300 {
  background-color: #ffb74d;
}
.btn.btn-fab-material-orange-400,
.btn.btn-fab:hover-material-orange-400 {
  background-color: #ffa726;
}
.btn.btn-fab-material-orange-500,
.btn.btn-fab:hover-material-orange-500 {
  background-color: #ff9800;
}
.btn.btn-fab-material-orange-600,
.btn.btn-fab:hover-material-orange-600 {
  background-color: #fb8c00;
}
.btn.btn-fab-material-orange-700,
.btn.btn-fab:hover-material-orange-700 {
  background-color: #f57c00;
}
.btn.btn-fab-material-orange-800,
.btn.btn-fab:hover-material-orange-800 {
  background-color: #ef6c00;
}
.btn.btn-fab-material-orange-900,
.btn.btn-fab:hover-material-orange-900 {
  background-color: #e65100;
}
.btn.btn-fab-material-orange-A100,
.btn.btn-fab:hover-material-orange-A100 {
  background-color: #ffd180;
}
.btn.btn-fab-material-orange-A200,
.btn.btn-fab:hover-material-orange-A200 {
  background-color: #ffab40;
}
.btn.btn-fab-material-orange-A400,
.btn.btn-fab:hover-material-orange-A400 {
  background-color: #ff9100;
}
.btn.btn-fab-material-orange-A700,
.btn.btn-fab:hover-material-orange-A700 {
  background-color: #ff6d00;
}
.btn.btn-fab-material-deep-orange,
.btn.btn-fab:hover-material-deep-orange {
  background-color: #ff5722;
}
.btn.btn-fab-material-deep-orange-50,
.btn.btn-fab:hover-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.btn.btn-fab-material-deep-orange-100,
.btn.btn-fab:hover-material-deep-orange-100 {
  background-color: #ffccbc;
}
.btn.btn-fab-material-deep-orange-200,
.btn.btn-fab:hover-material-deep-orange-200 {
  background-color: #ffab91;
}
.btn.btn-fab-material-deep-orange-300,
.btn.btn-fab:hover-material-deep-orange-300 {
  background-color: #ff8a65;
}
.btn.btn-fab-material-deep-orange-400,
.btn.btn-fab:hover-material-deep-orange-400 {
  background-color: #ff7043;
}
.btn.btn-fab-material-deep-orange-500,
.btn.btn-fab:hover-material-deep-orange-500 {
  background-color: #ff5722;
}
.btn.btn-fab-material-deep-orange-600,
.btn.btn-fab:hover-material-deep-orange-600 {
  background-color: #f4511e;
}
.btn.btn-fab-material-deep-orange-700,
.btn.btn-fab:hover-material-deep-orange-700 {
  background-color: #e64a19;
}
.btn.btn-fab-material-deep-orange-800,
.btn.btn-fab:hover-material-deep-orange-800 {
  background-color: #d84315;
}
.btn.btn-fab-material-deep-orange-900,
.btn.btn-fab:hover-material-deep-orange-900 {
  background-color: #bf360c;
}
.btn.btn-fab-material-deep-orange-A100,
.btn.btn-fab:hover-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.btn.btn-fab-material-deep-orange-A200,
.btn.btn-fab:hover-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.btn.btn-fab-material-deep-orange-A400,
.btn.btn-fab:hover-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.btn.btn-fab-material-deep-orange-A700,
.btn.btn-fab:hover-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.btn.btn-fab-material-brown,
.btn.btn-fab:hover-material-brown {
  background-color: #795548;
}
.btn.btn-fab-material-brown-50,
.btn.btn-fab:hover-material-brown-50 {
  background-color: #efebe9;
}
.btn.btn-fab-material-brown-100,
.btn.btn-fab:hover-material-brown-100 {
  background-color: #d7ccc8;
}
.btn.btn-fab-material-brown-200,
.btn.btn-fab:hover-material-brown-200 {
  background-color: #bcaaa4;
}
.btn.btn-fab-material-brown-300,
.btn.btn-fab:hover-material-brown-300 {
  background-color: #a1887f;
}
.btn.btn-fab-material-brown-400,
.btn.btn-fab:hover-material-brown-400 {
  background-color: #8d6e63;
}
.btn.btn-fab-material-brown-500,
.btn.btn-fab:hover-material-brown-500 {
  background-color: #795548;
}
.btn.btn-fab-material-brown-600,
.btn.btn-fab:hover-material-brown-600 {
  background-color: #6d4c41;
}
.btn.btn-fab-material-brown-700,
.btn.btn-fab:hover-material-brown-700 {
  background-color: #5d4037;
}
.btn.btn-fab-material-brown-800,
.btn.btn-fab:hover-material-brown-800 {
  background-color: #4e342e;
}
.btn.btn-fab-material-brown-900,
.btn.btn-fab:hover-material-brown-900 {
  background-color: #3e2723;
}
.btn.btn-fab-material-brown-A100,
.btn.btn-fab:hover-material-brown-A100 {
  background-color: #d7ccc8;
}
.btn.btn-fab-material-brown-A200,
.btn.btn-fab:hover-material-brown-A200 {
  background-color: #bcaaa4;
}
.btn.btn-fab-material-brown-A400,
.btn.btn-fab:hover-material-brown-A400 {
  background-color: #8d6e63;
}
.btn.btn-fab-material-brown-A700,
.btn.btn-fab:hover-material-brown-A700 {
  background-color: #5d4037;
}
.btn.btn-fab-material-grey,
.btn.btn-fab:hover-material-grey {
  background-color: #9e9e9e;
}
.btn.btn-fab-material-grey-50,
.btn.btn-fab:hover-material-grey-50 {
  background-color: #fafafa;
}
.btn.btn-fab-material-grey-100,
.btn.btn-fab:hover-material-grey-100 {
  background-color: #f5f5f5;
}
.btn.btn-fab-material-grey-200,
.btn.btn-fab:hover-material-grey-200 {
  background-color: #eeeeee;
}
.btn.btn-fab-material-grey-300,
.btn.btn-fab:hover-material-grey-300 {
  background-color: #e0e0e0;
}
.btn.btn-fab-material-grey-400,
.btn.btn-fab:hover-material-grey-400 {
  background-color: #bdbdbd;
}
.btn.btn-fab-material-grey-500,
.btn.btn-fab:hover-material-grey-500 {
  background-color: #9e9e9e;
}
.btn.btn-fab-material-grey-600,
.btn.btn-fab:hover-material-grey-600 {
  background-color: #757575;
}
.btn.btn-fab-material-grey-700,
.btn.btn-fab:hover-material-grey-700 {
  background-color: #616161;
}
.btn.btn-fab-material-grey-800,
.btn.btn-fab:hover-material-grey-800 {
  background-color: #424242;
}
.btn.btn-fab-material-grey-900,
.btn.btn-fab:hover-material-grey-900 {
  background-color: #212121;
}
.btn.btn-fab-material-grey-A100,
.btn.btn-fab:hover-material-grey-A100 {
  background-color: #f5f5f5;
}
.btn.btn-fab-material-grey-A200,
.btn.btn-fab:hover-material-grey-A200 {
  background-color: #eeeeee;
}
.btn.btn-fab-material-grey-A400,
.btn.btn-fab:hover-material-grey-A400 {
  background-color: #bdbdbd;
}
.btn.btn-fab-material-grey-A700,
.btn.btn-fab:hover-material-grey-A700 {
  background-color: #616161;
}
.btn.btn-fab-material-blue,
.btn.btn-fab:hover-material-blue {
  background-color: #607d8b;
}
.btn.btn-fab-material-blue-50,
.btn.btn-fab:hover-material-blue-50 {
  background-color: #eceff1;
}
.btn.btn-fab-material-blue-100,
.btn.btn-fab:hover-material-blue-100 {
  background-color: #cfd8dc;
}
.btn.btn-fab-material-blue-200,
.btn.btn-fab:hover-material-blue-200 {
  background-color: #b0bec5;
}
.btn.btn-fab-material-blue-300,
.btn.btn-fab:hover-material-blue-300 {
  background-color: #90a4ae;
}
.btn.btn-fab-material-blue-400,
.btn.btn-fab:hover-material-blue-400 {
  background-color: #78909c;
}
.btn.btn-fab-material-blue-500,
.btn.btn-fab:hover-material-blue-500 {
  background-color: #607d8b;
}
.btn.btn-fab-material-blue-600,
.btn.btn-fab:hover-material-blue-600 {
  background-color: #546e7a;
}
.btn.btn-fab-material-blue-700,
.btn.btn-fab:hover-material-blue-700 {
  background-color: #455a64;
}
.btn.btn-fab-material-blue-800,
.btn.btn-fab:hover-material-blue-800 {
  background-color: #37474f;
}
.btn.btn-fab-material-blue-900,
.btn.btn-fab:hover-material-blue-900 {
  background-color: #263238;
}
.btn.btn-fab-material-blue-A100,
.btn.btn-fab:hover-material-blue-A100 {
  background-color: #cfd8dc;
}
.btn.btn-fab-material-blue-A200,
.btn.btn-fab:hover-material-blue-A200 {
  background-color: #b0bec5;
}
.btn.btn-fab-material-blue-A400,
.btn.btn-fab:hover-material-blue-A400 {
  background-color: #78909c;
}
.btn.btn-fab-material-blue-A700,
.btn.btn-fab:hover-material-blue-A700 {
  background-color: #455a64;
}
.btn.btn-fab,
.btn.btn-fab .ripple-wrapper {
  border-radius: 100%;
}
.btn.btn-fab.btn-fab-mini {
  width: 40px;
  height: 40px;
  padding: 13px;
  font-size: 15px;
}
.btn.btn-fab i {
  position: relative;
  top: -5px;
}
.btn-link,
.btn:not([class^="btn btn-"]),
.btn-default {
  color: rgba(0, 0, 0, 0.84);
}
.btn-link:hover,
.btn:not([class^="btn btn-"]):hover,
.btn-default:hover {
  color: rgba(0, 0, 0, 0.84);
}
.btn:not([class^="btn btn-"]):hover,
.btn-default:hover,
.btn-flat:not(.btn-link):hover,
.btn:not([class^="btn btn-"]).active,
.btn-default.active,
.btn-flat:not(.btn-link).active {
  background-color: rgba(255, 255, 255, 0.5);
}
.open > .dropdown-toggle.btn {
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.open > .dropdown-toggle.btn,
.open > .dropdown-toggle.btn-default {
  background-color: transparent;
}
.open > .dropdown-toggle.btn-primary {
  background-color: #009587;
}
.open > .dropdown-toggle.btn-success {
  background-color: #0f9d58;
}
.open > .dropdown-toggle.btn-info {
  background-color: #03a9f4;
}
.open > .dropdown-toggle.btn-warning {
  background-color: #ff5722;
}
.open > .dropdown-toggle.btn-danger {
  background-color: #f44336;
}
.open > .dropdown-toggle.btn-material-red {
  background-color: #f44336;
}
.open > .dropdown-toggle.btn-material-red-50 {
  background-color: #ffebee;
}
.open > .dropdown-toggle.btn-material-red-100 {
  background-color: #ffcdd2;
}
.open > .dropdown-toggle.btn-material-red-200 {
  background-color: #ef9a9a;
}
.open > .dropdown-toggle.btn-material-red-300 {
  background-color: #e57373;
}
.open > .dropdown-toggle.btn-material-red-400 {
  background-color: #ef5350;
}
.open > .dropdown-toggle.btn-material-red-500 {
  background-color: #f44336;
}
.open > .dropdown-toggle.btn-material-red-600 {
  background-color: #e53935;
}
.open > .dropdown-toggle.btn-material-red-700 {
  background-color: #d32f2f;
}
.open > .dropdown-toggle.btn-material-red-800 {
  background-color: #c62828;
}
.open > .dropdown-toggle.btn-material-red-900 {
  background-color: #b71c1c;
}
.open > .dropdown-toggle.btn-material-red-A100 {
  background-color: #ff8a80;
}
.open > .dropdown-toggle.btn-material-red-A200 {
  background-color: #ff5252;
}
.open > .dropdown-toggle.btn-material-red-A400 {
  background-color: #ff1744;
}
.open > .dropdown-toggle.btn-material-red-A700 {
  background-color: #d50000;
}
.open > .dropdown-toggle.btn-material-pink {
  background-color: #e91e63;
}
.open > .dropdown-toggle.btn-material-pink-50 {
  background-color: #fce4ec;
}
.open > .dropdown-toggle.btn-material-pink-100 {
  background-color: #f8bbd0;
}
.open > .dropdown-toggle.btn-material-pink-200 {
  background-color: #f48fb1;
}
.open > .dropdown-toggle.btn-material-pink-300 {
  background-color: #f06292;
}
.open > .dropdown-toggle.btn-material-pink-400 {
  background-color: #ec407a;
}
.open > .dropdown-toggle.btn-material-pink-500 {
  background-color: #e91e63;
}
.open > .dropdown-toggle.btn-material-pink-600 {
  background-color: #d81b60;
}
.open > .dropdown-toggle.btn-material-pink-700 {
  background-color: #c2185b;
}
.open > .dropdown-toggle.btn-material-pink-800 {
  background-color: #ad1457;
}
.open > .dropdown-toggle.btn-material-pink-900 {
  background-color: #880e4f;
}
.open > .dropdown-toggle.btn-material-pink-A100 {
  background-color: #ff80ab;
}
.open > .dropdown-toggle.btn-material-pink-A200 {
  background-color: #ff4081;
}
.open > .dropdown-toggle.btn-material-pink-A400 {
  background-color: #f50057;
}
.open > .dropdown-toggle.btn-material-pink-A700 {
  background-color: #c51162;
}
.open > .dropdown-toggle.btn-material-purple {
  background-color: #9c27b0;
}
.open > .dropdown-toggle.btn-material-purple-50 {
  background-color: #f3e5f5;
}
.open > .dropdown-toggle.btn-material-purple-100 {
  background-color: #e1bee7;
}
.open > .dropdown-toggle.btn-material-purple-200 {
  background-color: #ce93d8;
}
.open > .dropdown-toggle.btn-material-purple-300 {
  background-color: #ba68c8;
}
.open > .dropdown-toggle.btn-material-purple-400 {
  background-color: #ab47bc;
}
.open > .dropdown-toggle.btn-material-purple-500 {
  background-color: #9c27b0;
}
.open > .dropdown-toggle.btn-material-purple-600 {
  background-color: #8e24aa;
}
.open > .dropdown-toggle.btn-material-purple-700 {
  background-color: #7b1fa2;
}
.open > .dropdown-toggle.btn-material-purple-800 {
  background-color: #6a1b9a;
}
.open > .dropdown-toggle.btn-material-purple-900 {
  background-color: #4a148c;
}
.open > .dropdown-toggle.btn-material-purple-A100 {
  background-color: #ea80fc;
}
.open > .dropdown-toggle.btn-material-purple-A200 {
  background-color: #e040fb;
}
.open > .dropdown-toggle.btn-material-purple-A400 {
  background-color: #d500f9;
}
.open > .dropdown-toggle.btn-material-purple-A700 {
  background-color: #aa00ff;
}
.open > .dropdown-toggle.btn-material-deep-purple {
  background-color: #673ab7;
}
.open > .dropdown-toggle.btn-material-deep-purple-50 {
  background-color: #ede7f6;
}
.open > .dropdown-toggle.btn-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.open > .dropdown-toggle.btn-material-deep-purple-200 {
  background-color: #b39ddb;
}
.open > .dropdown-toggle.btn-material-deep-purple-300 {
  background-color: #9575cd;
}
.open > .dropdown-toggle.btn-material-deep-purple-400 {
  background-color: #7e57c2;
}
.open > .dropdown-toggle.btn-material-deep-purple-500 {
  background-color: #673ab7;
}
.open > .dropdown-toggle.btn-material-deep-purple-600 {
  background-color: #5e35b1;
}
.open > .dropdown-toggle.btn-material-deep-purple-700 {
  background-color: #512da8;
}
.open > .dropdown-toggle.btn-material-deep-purple-800 {
  background-color: #4527a0;
}
.open > .dropdown-toggle.btn-material-deep-purple-900 {
  background-color: #311b92;
}
.open > .dropdown-toggle.btn-material-deep-purple-A100 {
  background-color: #b388ff;
}
.open > .dropdown-toggle.btn-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.open > .dropdown-toggle.btn-material-deep-purple-A400 {
  background-color: #651fff;
}
.open > .dropdown-toggle.btn-material-deep-purple-A700 {
  background-color: #6200ea;
}
.open > .dropdown-toggle.btn-material-indigo {
  background-color: #3f51b5;
}
.open > .dropdown-toggle.btn-material-indigo-50 {
  background-color: #e8eaf6;
}
.open > .dropdown-toggle.btn-material-indigo-100 {
  background-color: #c5cae9;
}
.open > .dropdown-toggle.btn-material-indigo-200 {
  background-color: #9fa8da;
}
.open > .dropdown-toggle.btn-material-indigo-300 {
  background-color: #7986cb;
}
.open > .dropdown-toggle.btn-material-indigo-400 {
  background-color: #5c6bc0;
}
.open > .dropdown-toggle.btn-material-indigo-500 {
  background-color: #3f51b5;
}
.open > .dropdown-toggle.btn-material-indigo-600 {
  background-color: #3949ab;
}
.open > .dropdown-toggle.btn-material-indigo-700 {
  background-color: #303f9f;
}
.open > .dropdown-toggle.btn-material-indigo-800 {
  background-color: #283593;
}
.open > .dropdown-toggle.btn-material-indigo-900 {
  background-color: #1a237e;
}
.open > .dropdown-toggle.btn-material-indigo-A100 {
  background-color: #8c9eff;
}
.open > .dropdown-toggle.btn-material-indigo-A200 {
  background-color: #536dfe;
}
.open > .dropdown-toggle.btn-material-indigo-A400 {
  background-color: #3d5afe;
}
.open > .dropdown-toggle.btn-material-indigo-A700 {
  background-color: #304ffe;
}
.open > .dropdown-toggle.btn-material-blue {
  background-color: #2196f3;
}
.open > .dropdown-toggle.btn-material-blue-50 {
  background-color: #e3f2fd;
}
.open > .dropdown-toggle.btn-material-blue-100 {
  background-color: #bbdefb;
}
.open > .dropdown-toggle.btn-material-blue-200 {
  background-color: #90caf9;
}
.open > .dropdown-toggle.btn-material-blue-300 {
  background-color: #64b5f6;
}
.open > .dropdown-toggle.btn-material-blue-400 {
  background-color: #42a5f5;
}
.open > .dropdown-toggle.btn-material-blue-500 {
  background-color: #2196f3;
}
.open > .dropdown-toggle.btn-material-blue-600 {
  background-color: #1e88e5;
}
.open > .dropdown-toggle.btn-material-blue-700 {
  background-color: #1976d2;
}
.open > .dropdown-toggle.btn-material-blue-800 {
  background-color: #1565c0;
}
.open > .dropdown-toggle.btn-material-blue-900 {
  background-color: #0d47a1;
}
.open > .dropdown-toggle.btn-material-blue-A100 {
  background-color: #82b1ff;
}
.open > .dropdown-toggle.btn-material-blue-A200 {
  background-color: #448aff;
}
.open > .dropdown-toggle.btn-material-blue-A400 {
  background-color: #2979ff;
}
.open > .dropdown-toggle.btn-material-blue-A700 {
  background-color: #2962ff;
}
.open > .dropdown-toggle.btn-material-light-blue {
  background-color: #03a9f4;
}
.open > .dropdown-toggle.btn-material-light-blue-50 {
  background-color: #e1f5fe;
}
.open > .dropdown-toggle.btn-material-light-blue-100 {
  background-color: #b3e5fc;
}
.open > .dropdown-toggle.btn-material-light-blue-200 {
  background-color: #81d4fa;
}
.open > .dropdown-toggle.btn-material-light-blue-300 {
  background-color: #4fc3f7;
}
.open > .dropdown-toggle.btn-material-light-blue-400 {
  background-color: #29b6f6;
}
.open > .dropdown-toggle.btn-material-light-blue-500 {
  background-color: #03a9f4;
}
.open > .dropdown-toggle.btn-material-light-blue-600 {
  background-color: #039be5;
}
.open > .dropdown-toggle.btn-material-light-blue-700 {
  background-color: #0288d1;
}
.open > .dropdown-toggle.btn-material-light-blue-800 {
  background-color: #0277bd;
}
.open > .dropdown-toggle.btn-material-light-blue-900 {
  background-color: #01579b;
}
.open > .dropdown-toggle.btn-material-light-blue-A100 {
  background-color: #80d8ff;
}
.open > .dropdown-toggle.btn-material-light-blue-A200 {
  background-color: #40c4ff;
}
.open > .dropdown-toggle.btn-material-light-blue-A400 {
  background-color: #00b0ff;
}
.open > .dropdown-toggle.btn-material-light-blue-A700 {
  background-color: #0091ea;
}
.open > .dropdown-toggle.btn-material-cyan {
  background-color: #00bcd4;
}
.open > .dropdown-toggle.btn-material-cyan-50 {
  background-color: #e0f7fa;
}
.open > .dropdown-toggle.btn-material-cyan-100 {
  background-color: #b2ebf2;
}
.open > .dropdown-toggle.btn-material-cyan-200 {
  background-color: #80deea;
}
.open > .dropdown-toggle.btn-material-cyan-300 {
  background-color: #4dd0e1;
}
.open > .dropdown-toggle.btn-material-cyan-400 {
  background-color: #26c6da;
}
.open > .dropdown-toggle.btn-material-cyan-500 {
  background-color: #00bcd4;
}
.open > .dropdown-toggle.btn-material-cyan-600 {
  background-color: #00acc1;
}
.open > .dropdown-toggle.btn-material-cyan-700 {
  background-color: #0097a7;
}
.open > .dropdown-toggle.btn-material-cyan-800 {
  background-color: #00838f;
}
.open > .dropdown-toggle.btn-material-cyan-900 {
  background-color: #006064;
}
.open > .dropdown-toggle.btn-material-cyan-A100 {
  background-color: #84ffff;
}
.open > .dropdown-toggle.btn-material-cyan-A200 {
  background-color: #18ffff;
}
.open > .dropdown-toggle.btn-material-cyan-A400 {
  background-color: #00e5ff;
}
.open > .dropdown-toggle.btn-material-cyan-A700 {
  background-color: #00b8d4;
}
.open > .dropdown-toggle.btn-material-teal {
  background-color: #009688;
}
.open > .dropdown-toggle.btn-material-teal-50 {
  background-color: #e0f2f1;
}
.open > .dropdown-toggle.btn-material-teal-100 {
  background-color: #b2dfdb;
}
.open > .dropdown-toggle.btn-material-teal-200 {
  background-color: #80cbc4;
}
.open > .dropdown-toggle.btn-material-teal-300 {
  background-color: #4db6ac;
}
.open > .dropdown-toggle.btn-material-teal-400 {
  background-color: #26a69a;
}
.open > .dropdown-toggle.btn-material-teal-500 {
  background-color: #009688;
}
.open > .dropdown-toggle.btn-material-teal-600 {
  background-color: #00897b;
}
.open > .dropdown-toggle.btn-material-teal-700 {
  background-color: #00796b;
}
.open > .dropdown-toggle.btn-material-teal-800 {
  background-color: #00695c;
}
.open > .dropdown-toggle.btn-material-teal-900 {
  background-color: #004d40;
}
.open > .dropdown-toggle.btn-material-teal-A100 {
  background-color: #a7ffeb;
}
.open > .dropdown-toggle.btn-material-teal-A200 {
  background-color: #64ffda;
}
.open > .dropdown-toggle.btn-material-teal-A400 {
  background-color: #1de9b6;
}
.open > .dropdown-toggle.btn-material-teal-A700 {
  background-color: #00bfa5;
}
.open > .dropdown-toggle.btn-material-green {
  background-color: #4caf50;
}
.open > .dropdown-toggle.btn-material-green-50 {
  background-color: #e8f5e9;
}
.open > .dropdown-toggle.btn-material-green-100 {
  background-color: #c8e6c9;
}
.open > .dropdown-toggle.btn-material-green-200 {
  background-color: #a5d6a7;
}
.open > .dropdown-toggle.btn-material-green-300 {
  background-color: #81c784;
}
.open > .dropdown-toggle.btn-material-green-400 {
  background-color: #66bb6a;
}
.open > .dropdown-toggle.btn-material-green-500 {
  background-color: #4caf50;
}
.open > .dropdown-toggle.btn-material-green-600 {
  background-color: #43a047;
}
.open > .dropdown-toggle.btn-material-green-700 {
  background-color: #388e3c;
}
.open > .dropdown-toggle.btn-material-green-800 {
  background-color: #2e7d32;
}
.open > .dropdown-toggle.btn-material-green-900 {
  background-color: #1b5e20;
}
.open > .dropdown-toggle.btn-material-green-A100 {
  background-color: #b9f6ca;
}
.open > .dropdown-toggle.btn-material-green-A200 {
  background-color: #69f0ae;
}
.open > .dropdown-toggle.btn-material-green-A400 {
  background-color: #00e676;
}
.open > .dropdown-toggle.btn-material-green-A700 {
  background-color: #00c853;
}
.open > .dropdown-toggle.btn-material-light-green {
  background-color: #8bc34a;
}
.open > .dropdown-toggle.btn-material-light-green-50 {
  background-color: #f1f8e9;
}
.open > .dropdown-toggle.btn-material-light-green-100 {
  background-color: #dcedc8;
}
.open > .dropdown-toggle.btn-material-light-green-200 {
  background-color: #c5e1a5;
}
.open > .dropdown-toggle.btn-material-light-green-300 {
  background-color: #aed581;
}
.open > .dropdown-toggle.btn-material-light-green-400 {
  background-color: #9ccc65;
}
.open > .dropdown-toggle.btn-material-light-green-500 {
  background-color: #8bc34a;
}
.open > .dropdown-toggle.btn-material-light-green-600 {
  background-color: #7cb342;
}
.open > .dropdown-toggle.btn-material-light-green-700 {
  background-color: #689f38;
}
.open > .dropdown-toggle.btn-material-light-green-800 {
  background-color: #558b2f;
}
.open > .dropdown-toggle.btn-material-light-green-900 {
  background-color: #33691e;
}
.open > .dropdown-toggle.btn-material-light-green-A100 {
  background-color: #ccff90;
}
.open > .dropdown-toggle.btn-material-light-green-A200 {
  background-color: #b2ff59;
}
.open > .dropdown-toggle.btn-material-light-green-A400 {
  background-color: #76ff03;
}
.open > .dropdown-toggle.btn-material-light-green-A700 {
  background-color: #64dd17;
}
.open > .dropdown-toggle.btn-material-lime {
  background-color: #cddc39;
}
.open > .dropdown-toggle.btn-material-lime-50 {
  background-color: #f9fbe7;
}
.open > .dropdown-toggle.btn-material-lime-100 {
  background-color: #f0f4c3;
}
.open > .dropdown-toggle.btn-material-lime-200 {
  background-color: #e6ee9c;
}
.open > .dropdown-toggle.btn-material-lime-300 {
  background-color: #dce775;
}
.open > .dropdown-toggle.btn-material-lime-400 {
  background-color: #d4e157;
}
.open > .dropdown-toggle.btn-material-lime-500 {
  background-color: #cddc39;
}
.open > .dropdown-toggle.btn-material-lime-600 {
  background-color: #c0ca33;
}
.open > .dropdown-toggle.btn-material-lime-700 {
  background-color: #afb42b;
}
.open > .dropdown-toggle.btn-material-lime-800 {
  background-color: #9e9d24;
}
.open > .dropdown-toggle.btn-material-lime-900 {
  background-color: #827717;
}
.open > .dropdown-toggle.btn-material-lime-A100 {
  background-color: #f4ff81;
}
.open > .dropdown-toggle.btn-material-lime-A200 {
  background-color: #eeff41;
}
.open > .dropdown-toggle.btn-material-lime-A400 {
  background-color: #c6ff00;
}
.open > .dropdown-toggle.btn-material-lime-A700 {
  background-color: #aeea00;
}
.open > .dropdown-toggle.btn-material-yellow {
  background-color: #ffeb3b;
}
.open > .dropdown-toggle.btn-material-yellow-50 {
  background-color: #fffde7;
}
.open > .dropdown-toggle.btn-material-yellow-100 {
  background-color: #fff9c4;
}
.open > .dropdown-toggle.btn-material-yellow-200 {
  background-color: #fff59d;
}
.open > .dropdown-toggle.btn-material-yellow-300 {
  background-color: #fff176;
}
.open > .dropdown-toggle.btn-material-yellow-400 {
  background-color: #ffee58;
}
.open > .dropdown-toggle.btn-material-yellow-500 {
  background-color: #ffeb3b;
}
.open > .dropdown-toggle.btn-material-yellow-600 {
  background-color: #fdd835;
}
.open > .dropdown-toggle.btn-material-yellow-700 {
  background-color: #fbc02d;
}
.open > .dropdown-toggle.btn-material-yellow-800 {
  background-color: #f9a825;
}
.open > .dropdown-toggle.btn-material-yellow-900 {
  background-color: #f57f17;
}
.open > .dropdown-toggle.btn-material-yellow-A100 {
  background-color: #ffff8d;
}
.open > .dropdown-toggle.btn-material-yellow-A200 {
  background-color: #ffff00;
}
.open > .dropdown-toggle.btn-material-yellow-A400 {
  background-color: #ffea00;
}
.open > .dropdown-toggle.btn-material-yellow-A700 {
  background-color: #ffd600;
}
.open > .dropdown-toggle.btn-material-amber {
  background-color: #ffc107;
}
.open > .dropdown-toggle.btn-material-amber-50 {
  background-color: #fff8e1;
}
.open > .dropdown-toggle.btn-material-amber-100 {
  background-color: #ffecb3;
}
.open > .dropdown-toggle.btn-material-amber-200 {
  background-color: #ffe082;
}
.open > .dropdown-toggle.btn-material-amber-300 {
  background-color: #ffd54f;
}
.open > .dropdown-toggle.btn-material-amber-400 {
  background-color: #ffca28;
}
.open > .dropdown-toggle.btn-material-amber-500 {
  background-color: #ffc107;
}
.open > .dropdown-toggle.btn-material-amber-600 {
  background-color: #ffb300;
}
.open > .dropdown-toggle.btn-material-amber-700 {
  background-color: #ffa000;
}
.open > .dropdown-toggle.btn-material-amber-800 {
  background-color: #ff8f00;
}
.open > .dropdown-toggle.btn-material-amber-900 {
  background-color: #ff6f00;
}
.open > .dropdown-toggle.btn-material-amber-A100 {
  background-color: #ffe57f;
}
.open > .dropdown-toggle.btn-material-amber-A200 {
  background-color: #ffd740;
}
.open > .dropdown-toggle.btn-material-amber-A400 {
  background-color: #ffc400;
}
.open > .dropdown-toggle.btn-material-amber-A700 {
  background-color: #ffab00;
}
.open > .dropdown-toggle.btn-material-orange {
  background-color: #ff9800;
}
.open > .dropdown-toggle.btn-material-orange-50 {
  background-color: #fff3e0;
}
.open > .dropdown-toggle.btn-material-orange-100 {
  background-color: #ffe0b2;
}
.open > .dropdown-toggle.btn-material-orange-200 {
  background-color: #ffcc80;
}
.open > .dropdown-toggle.btn-material-orange-300 {
  background-color: #ffb74d;
}
.open > .dropdown-toggle.btn-material-orange-400 {
  background-color: #ffa726;
}
.open > .dropdown-toggle.btn-material-orange-500 {
  background-color: #ff9800;
}
.open > .dropdown-toggle.btn-material-orange-600 {
  background-color: #fb8c00;
}
.open > .dropdown-toggle.btn-material-orange-700 {
  background-color: #f57c00;
}
.open > .dropdown-toggle.btn-material-orange-800 {
  background-color: #ef6c00;
}
.open > .dropdown-toggle.btn-material-orange-900 {
  background-color: #e65100;
}
.open > .dropdown-toggle.btn-material-orange-A100 {
  background-color: #ffd180;
}
.open > .dropdown-toggle.btn-material-orange-A200 {
  background-color: #ffab40;
}
.open > .dropdown-toggle.btn-material-orange-A400 {
  background-color: #ff9100;
}
.open > .dropdown-toggle.btn-material-orange-A700 {
  background-color: #ff6d00;
}
.open > .dropdown-toggle.btn-material-deep-orange {
  background-color: #ff5722;
}
.open > .dropdown-toggle.btn-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.open > .dropdown-toggle.btn-material-deep-orange-100 {
  background-color: #ffccbc;
}
.open > .dropdown-toggle.btn-material-deep-orange-200 {
  background-color: #ffab91;
}
.open > .dropdown-toggle.btn-material-deep-orange-300 {
  background-color: #ff8a65;
}
.open > .dropdown-toggle.btn-material-deep-orange-400 {
  background-color: #ff7043;
}
.open > .dropdown-toggle.btn-material-deep-orange-500 {
  background-color: #ff5722;
}
.open > .dropdown-toggle.btn-material-deep-orange-600 {
  background-color: #f4511e;
}
.open > .dropdown-toggle.btn-material-deep-orange-700 {
  background-color: #e64a19;
}
.open > .dropdown-toggle.btn-material-deep-orange-800 {
  background-color: #d84315;
}
.open > .dropdown-toggle.btn-material-deep-orange-900 {
  background-color: #bf360c;
}
.open > .dropdown-toggle.btn-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.open > .dropdown-toggle.btn-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.open > .dropdown-toggle.btn-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.open > .dropdown-toggle.btn-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.open > .dropdown-toggle.btn-material-brown {
  background-color: #795548;
}
.open > .dropdown-toggle.btn-material-brown-50 {
  background-color: #efebe9;
}
.open > .dropdown-toggle.btn-material-brown-100 {
  background-color: #d7ccc8;
}
.open > .dropdown-toggle.btn-material-brown-200 {
  background-color: #bcaaa4;
}
.open > .dropdown-toggle.btn-material-brown-300 {
  background-color: #a1887f;
}
.open > .dropdown-toggle.btn-material-brown-400 {
  background-color: #8d6e63;
}
.open > .dropdown-toggle.btn-material-brown-500 {
  background-color: #795548;
}
.open > .dropdown-toggle.btn-material-brown-600 {
  background-color: #6d4c41;
}
.open > .dropdown-toggle.btn-material-brown-700 {
  background-color: #5d4037;
}
.open > .dropdown-toggle.btn-material-brown-800 {
  background-color: #4e342e;
}
.open > .dropdown-toggle.btn-material-brown-900 {
  background-color: #3e2723;
}
.open > .dropdown-toggle.btn-material-brown-A100 {
  background-color: #d7ccc8;
}
.open > .dropdown-toggle.btn-material-brown-A200 {
  background-color: #bcaaa4;
}
.open > .dropdown-toggle.btn-material-brown-A400 {
  background-color: #8d6e63;
}
.open > .dropdown-toggle.btn-material-brown-A700 {
  background-color: #5d4037;
}
.open > .dropdown-toggle.btn-material-grey {
  background-color: #9e9e9e;
}
.open > .dropdown-toggle.btn-material-grey-50 {
  background-color: #fafafa;
}
.open > .dropdown-toggle.btn-material-grey-100 {
  background-color: #f5f5f5;
}
.open > .dropdown-toggle.btn-material-grey-200 {
  background-color: #eeeeee;
}
.open > .dropdown-toggle.btn-material-grey-300 {
  background-color: #e0e0e0;
}
.open > .dropdown-toggle.btn-material-grey-400 {
  background-color: #bdbdbd;
}
.open > .dropdown-toggle.btn-material-grey-500 {
  background-color: #9e9e9e;
}
.open > .dropdown-toggle.btn-material-grey-600 {
  background-color: #757575;
}
.open > .dropdown-toggle.btn-material-grey-700 {
  background-color: #616161;
}
.open > .dropdown-toggle.btn-material-grey-800 {
  background-color: #424242;
}
.open > .dropdown-toggle.btn-material-grey-900 {
  background-color: #212121;
}
.open > .dropdown-toggle.btn-material-grey-A100 {
  background-color: #f5f5f5;
}
.open > .dropdown-toggle.btn-material-grey-A200 {
  background-color: #eeeeee;
}
.open > .dropdown-toggle.btn-material-grey-A400 {
  background-color: #bdbdbd;
}
.open > .dropdown-toggle.btn-material-grey-A700 {
  background-color: #616161;
}
.open > .dropdown-toggle.btn-material-blue {
  background-color: #607d8b;
}
.open > .dropdown-toggle.btn-material-blue-50 {
  background-color: #eceff1;
}
.open > .dropdown-toggle.btn-material-blue-100 {
  background-color: #cfd8dc;
}
.open > .dropdown-toggle.btn-material-blue-200 {
  background-color: #b0bec5;
}
.open > .dropdown-toggle.btn-material-blue-300 {
  background-color: #90a4ae;
}
.open > .dropdown-toggle.btn-material-blue-400 {
  background-color: #78909c;
}
.open > .dropdown-toggle.btn-material-blue-500 {
  background-color: #607d8b;
}
.open > .dropdown-toggle.btn-material-blue-600 {
  background-color: #546e7a;
}
.open > .dropdown-toggle.btn-material-blue-700 {
  background-color: #455a64;
}
.open > .dropdown-toggle.btn-material-blue-800 {
  background-color: #37474f;
}
.open > .dropdown-toggle.btn-material-blue-900 {
  background-color: #263238;
}
.open > .dropdown-toggle.btn-material-blue-A100 {
  background-color: #cfd8dc;
}
.open > .dropdown-toggle.btn-material-blue-A200 {
  background-color: #b0bec5;
}
.open > .dropdown-toggle.btn-material-blue-A400 {
  background-color: #78909c;
}
.open > .dropdown-toggle.btn-material-blue-A700 {
  background-color: #455a64;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: 0;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  border-radius: 2px;
  margin: 10px 1px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-group:active:not(.btn-link),
.btn-group-vertical:active:not(.btn-link) {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
}
.btn-group.open .dropdown-toggle,
.btn-group-vertical.open .dropdown-toggle {
  box-shadow: none;
}
.btn-group.btn-group-raised,
.btn-group-vertical.btn-group-raised {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-group.btn-group-raised:active:not(.btn-link),
.btn-group-vertical.btn-group-raised:active:not(.btn-link) {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
}
.btn-group .btn,
.btn-group-vertical .btn,
.btn-group .btn:active,
.btn-group-vertical .btn:active,
.btn-group .btn-group,
.btn-group-vertical .btn-group {
  box-shadow: none !important;
  margin: 0;
}
.btn-group-flat {
  box-shadow: none !important;
}
.form-horizontal .checkbox {
  padding-top: 20px;
}
.checkbox {
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.checkbox label {
  padding-left: 10px;
  cursor: pointer;
  position: relative;
}
.checkbox label span {
  display: block;
  position: absolute;
  left: 0px;
  transition-duration: 0.2s;
}
.checkbox label .ripple {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  left: -8px;
  top: -11px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
}
.checkbox label input[type=checkbox]:not(:checked) ~ .ripple {
  animation: rippleOff 500ms;
}
.checkbox label input[type=checkbox]:checked ~ .ripple {
  animation: rippleOn 500ms;
}
.checkbox label .check {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  top: 5px;
  left: -3px;
  margin-right: 11px;
}
.checkbox label .check:after {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  left: -5px;
  top: -15px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
}
.checkbox label .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  box-shadow: 0 0 0 0,
                0 0 0 0,
                0 0 0 0,
                0 0 0 0,
                0 0 0 0,
                0 0 0 0,
                0px 0px 0 0px inset;
  animation: checkbox-off 0.3s linear forwards;
}
.checkbox input[type=checkbox] {
  opacity: 0;
}
.checkbox input[type=checkbox]:focus ~ .check:after {
  opacity: 0.2;
}
.checkbox input[type=checkbox]:checked ~ .check:before {
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  animation: checkbox-on 0.3s linear forwards;
}
.checkbox input[type=checkbox]:not(:checked) ~ .check:after {
  animation: rippleOff 500ms linear forwards;
}
.checkbox input[type=checkbox]:checked ~ .check:after {
  animation: rippleOn 500ms linear forwards;
}
.checkbox:not(:hover) input[type=checkbox] ~ .check:before,
.checkbox:not(:hover) input[type=checkbox] ~ .check:after {
  animation-duration: 1ms;
}
.checkbox input[type=checkbox][disabled]:not(:checked) ~ .check:before,
.checkbox input[type=checkbox][disabled] ~ .circle {
  opacity: 0.5;
}
.checkbox input[type=checkbox][disabled] ~ .check:after {
  background-color: rgba(0, 0, 0, 0.84);
  transform: rotate(-45deg);
}
.checkbox input[type=checkbox]:checked ~ .check:after,
.checkbox-default input[type=checkbox]:checked ~ .check:after {
  background-color: #0f9d58;
}
.checkbox-primary input[type=checkbox]:checked ~ .check:after {
  background-color: #009587;
}
.checkbox-success input[type=checkbox]:checked ~ .check:after {
  background-color: #0f9d58;
}
.checkbox-info input[type=checkbox]:checked ~ .check:after {
  background-color: #03a9f4;
}
.checkbox-warning input[type=checkbox]:checked ~ .check:after {
  background-color: #ff5722;
}
.checkbox-danger input[type=checkbox]:checked ~ .check:after {
  background-color: #f44336;
}
.checkbox-material-red input[type=checkbox]:checked ~ .check:after {
  background-color: #f44336;
}
.checkbox-material-red-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffebee;
}
.checkbox-material-red-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffcdd2;
}
.checkbox-material-red-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ef9a9a;
}
.checkbox-material-red-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #e57373;
}
.checkbox-material-red-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ef5350;
}
.checkbox-material-red-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #f44336;
}
.checkbox-material-red-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #e53935;
}
.checkbox-material-red-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #d32f2f;
}
.checkbox-material-red-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #c62828;
}
.checkbox-material-red-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #b71c1c;
}
.checkbox-material-red-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff8a80;
}
.checkbox-material-red-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff5252;
}
.checkbox-material-red-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff1744;
}
.checkbox-material-red-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #d50000;
}
.checkbox-material-pink input[type=checkbox]:checked ~ .check:after {
  background-color: #e91e63;
}
.checkbox-material-pink-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #fce4ec;
}
.checkbox-material-pink-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #f8bbd0;
}
.checkbox-material-pink-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #f48fb1;
}
.checkbox-material-pink-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #f06292;
}
.checkbox-material-pink-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ec407a;
}
.checkbox-material-pink-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #e91e63;
}
.checkbox-material-pink-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #d81b60;
}
.checkbox-material-pink-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #c2185b;
}
.checkbox-material-pink-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #ad1457;
}
.checkbox-material-pink-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #880e4f;
}
.checkbox-material-pink-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff80ab;
}
.checkbox-material-pink-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff4081;
}
.checkbox-material-pink-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #f50057;
}
.checkbox-material-pink-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #c51162;
}
.checkbox-material-purple input[type=checkbox]:checked ~ .check:after {
  background-color: #9c27b0;
}
.checkbox-material-purple-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #f3e5f5;
}
.checkbox-material-purple-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #e1bee7;
}
.checkbox-material-purple-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ce93d8;
}
.checkbox-material-purple-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #ba68c8;
}
.checkbox-material-purple-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ab47bc;
}
.checkbox-material-purple-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #9c27b0;
}
.checkbox-material-purple-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #8e24aa;
}
.checkbox-material-purple-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #7b1fa2;
}
.checkbox-material-purple-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #6a1b9a;
}
.checkbox-material-purple-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #4a148c;
}
.checkbox-material-purple-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ea80fc;
}
.checkbox-material-purple-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #e040fb;
}
.checkbox-material-purple-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #d500f9;
}
.checkbox-material-purple-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #aa00ff;
}
.checkbox-material-deep-purple input[type=checkbox]:checked ~ .check:after {
  background-color: #673ab7;
}
.checkbox-material-deep-purple-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #ede7f6;
}
.checkbox-material-deep-purple-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #d1c4e9;
}
.checkbox-material-deep-purple-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #b39ddb;
}
.checkbox-material-deep-purple-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #9575cd;
}
.checkbox-material-deep-purple-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #7e57c2;
}
.checkbox-material-deep-purple-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #673ab7;
}
.checkbox-material-deep-purple-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #5e35b1;
}
.checkbox-material-deep-purple-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #512da8;
}
.checkbox-material-deep-purple-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #4527a0;
}
.checkbox-material-deep-purple-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #311b92;
}
.checkbox-material-deep-purple-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #b388ff;
}
.checkbox-material-deep-purple-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #7c4dff;
}
.checkbox-material-deep-purple-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #651fff;
}
.checkbox-material-deep-purple-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #6200ea;
}
.checkbox-material-indigo input[type=checkbox]:checked ~ .check:after {
  background-color: #3f51b5;
}
.checkbox-material-indigo-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #e8eaf6;
}
.checkbox-material-indigo-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #c5cae9;
}
.checkbox-material-indigo-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #9fa8da;
}
.checkbox-material-indigo-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #7986cb;
}
.checkbox-material-indigo-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #5c6bc0;
}
.checkbox-material-indigo-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #3f51b5;
}
.checkbox-material-indigo-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #3949ab;
}
.checkbox-material-indigo-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #303f9f;
}
.checkbox-material-indigo-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #283593;
}
.checkbox-material-indigo-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #1a237e;
}
.checkbox-material-indigo-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #8c9eff;
}
.checkbox-material-indigo-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #536dfe;
}
.checkbox-material-indigo-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #3d5afe;
}
.checkbox-material-indigo-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #304ffe;
}
.checkbox-material-blue input[type=checkbox]:checked ~ .check:after {
  background-color: #2196f3;
}
.checkbox-material-blue-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #e3f2fd;
}
.checkbox-material-blue-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #bbdefb;
}
.checkbox-material-blue-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #90caf9;
}
.checkbox-material-blue-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #64b5f6;
}
.checkbox-material-blue-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #42a5f5;
}
.checkbox-material-blue-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #2196f3;
}
.checkbox-material-blue-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #1e88e5;
}
.checkbox-material-blue-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #1976d2;
}
.checkbox-material-blue-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #1565c0;
}
.checkbox-material-blue-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #0d47a1;
}
.checkbox-material-blue-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #82b1ff;
}
.checkbox-material-blue-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #448aff;
}
.checkbox-material-blue-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #2979ff;
}
.checkbox-material-blue-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #2962ff;
}
.checkbox-material-light-blue input[type=checkbox]:checked ~ .check:after {
  background-color: #03a9f4;
}
.checkbox-material-light-blue-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #e1f5fe;
}
.checkbox-material-light-blue-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #b3e5fc;
}
.checkbox-material-light-blue-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #81d4fa;
}
.checkbox-material-light-blue-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #4fc3f7;
}
.checkbox-material-light-blue-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #29b6f6;
}
.checkbox-material-light-blue-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #03a9f4;
}
.checkbox-material-light-blue-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #039be5;
}
.checkbox-material-light-blue-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #0288d1;
}
.checkbox-material-light-blue-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #0277bd;
}
.checkbox-material-light-blue-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #01579b;
}
.checkbox-material-light-blue-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #80d8ff;
}
.checkbox-material-light-blue-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #40c4ff;
}
.checkbox-material-light-blue-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #00b0ff;
}
.checkbox-material-light-blue-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #0091ea;
}
.checkbox-material-cyan input[type=checkbox]:checked ~ .check:after {
  background-color: #00bcd4;
}
.checkbox-material-cyan-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #e0f7fa;
}
.checkbox-material-cyan-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #b2ebf2;
}
.checkbox-material-cyan-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #80deea;
}
.checkbox-material-cyan-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #4dd0e1;
}
.checkbox-material-cyan-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #26c6da;
}
.checkbox-material-cyan-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #00bcd4;
}
.checkbox-material-cyan-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #00acc1;
}
.checkbox-material-cyan-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #0097a7;
}
.checkbox-material-cyan-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #00838f;
}
.checkbox-material-cyan-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #006064;
}
.checkbox-material-cyan-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #84ffff;
}
.checkbox-material-cyan-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #18ffff;
}
.checkbox-material-cyan-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #00e5ff;
}
.checkbox-material-cyan-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #00b8d4;
}
.checkbox-material-teal input[type=checkbox]:checked ~ .check:after {
  background-color: #009688;
}
.checkbox-material-teal-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #e0f2f1;
}
.checkbox-material-teal-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #b2dfdb;
}
.checkbox-material-teal-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #80cbc4;
}
.checkbox-material-teal-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #4db6ac;
}
.checkbox-material-teal-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #26a69a;
}
.checkbox-material-teal-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #009688;
}
.checkbox-material-teal-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #00897b;
}
.checkbox-material-teal-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #00796b;
}
.checkbox-material-teal-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #00695c;
}
.checkbox-material-teal-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #004d40;
}
.checkbox-material-teal-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #a7ffeb;
}
.checkbox-material-teal-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #64ffda;
}
.checkbox-material-teal-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #1de9b6;
}
.checkbox-material-teal-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #00bfa5;
}
.checkbox-material-green input[type=checkbox]:checked ~ .check:after {
  background-color: #4caf50;
}
.checkbox-material-green-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #e8f5e9;
}
.checkbox-material-green-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #c8e6c9;
}
.checkbox-material-green-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #a5d6a7;
}
.checkbox-material-green-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #81c784;
}
.checkbox-material-green-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #66bb6a;
}
.checkbox-material-green-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #4caf50;
}
.checkbox-material-green-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #43a047;
}
.checkbox-material-green-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #388e3c;
}
.checkbox-material-green-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #2e7d32;
}
.checkbox-material-green-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #1b5e20;
}
.checkbox-material-green-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #b9f6ca;
}
.checkbox-material-green-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #69f0ae;
}
.checkbox-material-green-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #00e676;
}
.checkbox-material-green-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #00c853;
}
.checkbox-material-light-green input[type=checkbox]:checked ~ .check:after {
  background-color: #8bc34a;
}
.checkbox-material-light-green-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #f1f8e9;
}
.checkbox-material-light-green-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #dcedc8;
}
.checkbox-material-light-green-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #c5e1a5;
}
.checkbox-material-light-green-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #aed581;
}
.checkbox-material-light-green-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #9ccc65;
}
.checkbox-material-light-green-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #8bc34a;
}
.checkbox-material-light-green-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #7cb342;
}
.checkbox-material-light-green-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #689f38;
}
.checkbox-material-light-green-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #558b2f;
}
.checkbox-material-light-green-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #33691e;
}
.checkbox-material-light-green-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ccff90;
}
.checkbox-material-light-green-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #b2ff59;
}
.checkbox-material-light-green-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #76ff03;
}
.checkbox-material-light-green-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #64dd17;
}
.checkbox-material-lime input[type=checkbox]:checked ~ .check:after {
  background-color: #cddc39;
}
.checkbox-material-lime-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #f9fbe7;
}
.checkbox-material-lime-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #f0f4c3;
}
.checkbox-material-lime-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #e6ee9c;
}
.checkbox-material-lime-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #dce775;
}
.checkbox-material-lime-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #d4e157;
}
.checkbox-material-lime-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #cddc39;
}
.checkbox-material-lime-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #c0ca33;
}
.checkbox-material-lime-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #afb42b;
}
.checkbox-material-lime-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #9e9d24;
}
.checkbox-material-lime-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #827717;
}
.checkbox-material-lime-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #f4ff81;
}
.checkbox-material-lime-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #eeff41;
}
.checkbox-material-lime-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #c6ff00;
}
.checkbox-material-lime-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #aeea00;
}
.checkbox-material-yellow input[type=checkbox]:checked ~ .check:after {
  background-color: #ffeb3b;
}
.checkbox-material-yellow-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #fffde7;
}
.checkbox-material-yellow-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #fff9c4;
}
.checkbox-material-yellow-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #fff59d;
}
.checkbox-material-yellow-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #fff176;
}
.checkbox-material-yellow-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffee58;
}
.checkbox-material-yellow-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffeb3b;
}
.checkbox-material-yellow-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #fdd835;
}
.checkbox-material-yellow-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #fbc02d;
}
.checkbox-material-yellow-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #f9a825;
}
.checkbox-material-yellow-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #f57f17;
}
.checkbox-material-yellow-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffff8d;
}
.checkbox-material-yellow-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffff00;
}
.checkbox-material-yellow-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffea00;
}
.checkbox-material-yellow-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffd600;
}
.checkbox-material-amber input[type=checkbox]:checked ~ .check:after {
  background-color: #ffc107;
}
.checkbox-material-amber-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #fff8e1;
}
.checkbox-material-amber-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffecb3;
}
.checkbox-material-amber-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffe082;
}
.checkbox-material-amber-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffd54f;
}
.checkbox-material-amber-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffca28;
}
.checkbox-material-amber-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffc107;
}
.checkbox-material-amber-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffb300;
}
.checkbox-material-amber-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffa000;
}
.checkbox-material-amber-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff8f00;
}
.checkbox-material-amber-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff6f00;
}
.checkbox-material-amber-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffe57f;
}
.checkbox-material-amber-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffd740;
}
.checkbox-material-amber-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffc400;
}
.checkbox-material-amber-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffab00;
}
.checkbox-material-orange input[type=checkbox]:checked ~ .check:after {
  background-color: #ff9800;
}
.checkbox-material-orange-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #fff3e0;
}
.checkbox-material-orange-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffe0b2;
}
.checkbox-material-orange-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffcc80;
}
.checkbox-material-orange-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffb74d;
}
.checkbox-material-orange-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffa726;
}
.checkbox-material-orange-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff9800;
}
.checkbox-material-orange-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #fb8c00;
}
.checkbox-material-orange-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #f57c00;
}
.checkbox-material-orange-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #ef6c00;
}
.checkbox-material-orange-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #e65100;
}
.checkbox-material-orange-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffd180;
}
.checkbox-material-orange-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffab40;
}
.checkbox-material-orange-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff9100;
}
.checkbox-material-orange-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff6d00;
}
.checkbox-material-deep-orange input[type=checkbox]:checked ~ .check:after {
  background-color: #ff5722;
}
.checkbox-material-deep-orange-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #fbe9e7;
}
.checkbox-material-deep-orange-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffccbc;
}
.checkbox-material-deep-orange-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ffab91;
}
.checkbox-material-deep-orange-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff8a65;
}
.checkbox-material-deep-orange-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff7043;
}
.checkbox-material-deep-orange-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff5722;
}
.checkbox-material-deep-orange-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #f4511e;
}
.checkbox-material-deep-orange-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #e64a19;
}
.checkbox-material-deep-orange-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #d84315;
}
.checkbox-material-deep-orange-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #bf360c;
}
.checkbox-material-deep-orange-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff9e80;
}
.checkbox-material-deep-orange-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff6e40;
}
.checkbox-material-deep-orange-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #ff3d00;
}
.checkbox-material-deep-orange-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #dd2c00;
}
.checkbox-material-brown input[type=checkbox]:checked ~ .check:after {
  background-color: #795548;
}
.checkbox-material-brown-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #efebe9;
}
.checkbox-material-brown-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #d7ccc8;
}
.checkbox-material-brown-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #bcaaa4;
}
.checkbox-material-brown-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #a1887f;
}
.checkbox-material-brown-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #8d6e63;
}
.checkbox-material-brown-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #795548;
}
.checkbox-material-brown-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #6d4c41;
}
.checkbox-material-brown-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #5d4037;
}
.checkbox-material-brown-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #4e342e;
}
.checkbox-material-brown-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #3e2723;
}
.checkbox-material-brown-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #d7ccc8;
}
.checkbox-material-brown-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #bcaaa4;
}
.checkbox-material-brown-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #8d6e63;
}
.checkbox-material-brown-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #5d4037;
}
.checkbox-material-grey input[type=checkbox]:checked ~ .check:after {
  background-color: #9e9e9e;
}
.checkbox-material-grey-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #fafafa;
}
.checkbox-material-grey-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #f5f5f5;
}
.checkbox-material-grey-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #eeeeee;
}
.checkbox-material-grey-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #e0e0e0;
}
.checkbox-material-grey-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #bdbdbd;
}
.checkbox-material-grey-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #9e9e9e;
}
.checkbox-material-grey-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #757575;
}
.checkbox-material-grey-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #616161;
}
.checkbox-material-grey-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #424242;
}
.checkbox-material-grey-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #212121;
}
.checkbox-material-grey-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #f5f5f5;
}
.checkbox-material-grey-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #eeeeee;
}
.checkbox-material-grey-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #bdbdbd;
}
.checkbox-material-grey-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #616161;
}
.checkbox-material-blue input[type=checkbox]:checked ~ .check:after {
  background-color: #607d8b;
}
.checkbox-material-blue-50 input[type=checkbox]:checked ~ .check:after {
  background-color: #eceff1;
}
.checkbox-material-blue-100 input[type=checkbox]:checked ~ .check:after {
  background-color: #cfd8dc;
}
.checkbox-material-blue-200 input[type=checkbox]:checked ~ .check:after {
  background-color: #b0bec5;
}
.checkbox-material-blue-300 input[type=checkbox]:checked ~ .check:after {
  background-color: #90a4ae;
}
.checkbox-material-blue-400 input[type=checkbox]:checked ~ .check:after {
  background-color: #78909c;
}
.checkbox-material-blue-500 input[type=checkbox]:checked ~ .check:after {
  background-color: #607d8b;
}
.checkbox-material-blue-600 input[type=checkbox]:checked ~ .check:after {
  background-color: #546e7a;
}
.checkbox-material-blue-700 input[type=checkbox]:checked ~ .check:after {
  background-color: #455a64;
}
.checkbox-material-blue-800 input[type=checkbox]:checked ~ .check:after {
  background-color: #37474f;
}
.checkbox-material-blue-900 input[type=checkbox]:checked ~ .check:after {
  background-color: #263238;
}
.checkbox-material-blue-A100 input[type=checkbox]:checked ~ .check:after {
  background-color: #cfd8dc;
}
.checkbox-material-blue-A200 input[type=checkbox]:checked ~ .check:after {
  background-color: #b0bec5;
}
.checkbox-material-blue-A400 input[type=checkbox]:checked ~ .check:after {
  background-color: #78909c;
}
.checkbox-material-blue-A700 input[type=checkbox]:checked ~ .check:after {
  background-color: #455a64;
}
.checkbox input[type=checkbox]:checked ~ .check:before,
.checkbox-default input[type=checkbox]:checked ~ .check:before {
  color: #4caf50;
}
.checkbox-primary input[type=checkbox]:checked ~ .check:before {
  color: #009587;
}
.checkbox-success input[type=checkbox]:checked ~ .check:before {
  color: #0f9d58;
}
.checkbox-info input[type=checkbox]:checked ~ .check:before {
  color: #03a9f4;
}
.checkbox-warning input[type=checkbox]:checked ~ .check:before {
  color: #ff5722;
}
.checkbox-danger input[type=checkbox]:checked ~ .check:before {
  color: #f44336;
}
.checkbox-material-red input[type=checkbox]:checked ~ .check:before {
  color: #f44336;
}
.checkbox-material-red-50 input[type=checkbox]:checked ~ .check:before {
  color: #ffebee;
}
.checkbox-material-red-100 input[type=checkbox]:checked ~ .check:before {
  color: #ffcdd2;
}
.checkbox-material-red-200 input[type=checkbox]:checked ~ .check:before {
  color: #ef9a9a;
}
.checkbox-material-red-300 input[type=checkbox]:checked ~ .check:before {
  color: #e57373;
}
.checkbox-material-red-400 input[type=checkbox]:checked ~ .check:before {
  color: #ef5350;
}
.checkbox-material-red-500 input[type=checkbox]:checked ~ .check:before {
  color: #f44336;
}
.checkbox-material-red-600 input[type=checkbox]:checked ~ .check:before {
  color: #e53935;
}
.checkbox-material-red-700 input[type=checkbox]:checked ~ .check:before {
  color: #d32f2f;
}
.checkbox-material-red-800 input[type=checkbox]:checked ~ .check:before {
  color: #c62828;
}
.checkbox-material-red-900 input[type=checkbox]:checked ~ .check:before {
  color: #b71c1c;
}
.checkbox-material-red-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ff8a80;
}
.checkbox-material-red-A200 input[type=checkbox]:checked ~ .check:before {
  color: #ff5252;
}
.checkbox-material-red-A400 input[type=checkbox]:checked ~ .check:before {
  color: #ff1744;
}
.checkbox-material-red-A700 input[type=checkbox]:checked ~ .check:before {
  color: #d50000;
}
.checkbox-material-pink input[type=checkbox]:checked ~ .check:before {
  color: #e91e63;
}
.checkbox-material-pink-50 input[type=checkbox]:checked ~ .check:before {
  color: #fce4ec;
}
.checkbox-material-pink-100 input[type=checkbox]:checked ~ .check:before {
  color: #f8bbd0;
}
.checkbox-material-pink-200 input[type=checkbox]:checked ~ .check:before {
  color: #f48fb1;
}
.checkbox-material-pink-300 input[type=checkbox]:checked ~ .check:before {
  color: #f06292;
}
.checkbox-material-pink-400 input[type=checkbox]:checked ~ .check:before {
  color: #ec407a;
}
.checkbox-material-pink-500 input[type=checkbox]:checked ~ .check:before {
  color: #e91e63;
}
.checkbox-material-pink-600 input[type=checkbox]:checked ~ .check:before {
  color: #d81b60;
}
.checkbox-material-pink-700 input[type=checkbox]:checked ~ .check:before {
  color: #c2185b;
}
.checkbox-material-pink-800 input[type=checkbox]:checked ~ .check:before {
  color: #ad1457;
}
.checkbox-material-pink-900 input[type=checkbox]:checked ~ .check:before {
  color: #880e4f;
}
.checkbox-material-pink-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ff80ab;
}
.checkbox-material-pink-A200 input[type=checkbox]:checked ~ .check:before {
  color: #ff4081;
}
.checkbox-material-pink-A400 input[type=checkbox]:checked ~ .check:before {
  color: #f50057;
}
.checkbox-material-pink-A700 input[type=checkbox]:checked ~ .check:before {
  color: #c51162;
}
.checkbox-material-purple input[type=checkbox]:checked ~ .check:before {
  color: #9c27b0;
}
.checkbox-material-purple-50 input[type=checkbox]:checked ~ .check:before {
  color: #f3e5f5;
}
.checkbox-material-purple-100 input[type=checkbox]:checked ~ .check:before {
  color: #e1bee7;
}
.checkbox-material-purple-200 input[type=checkbox]:checked ~ .check:before {
  color: #ce93d8;
}
.checkbox-material-purple-300 input[type=checkbox]:checked ~ .check:before {
  color: #ba68c8;
}
.checkbox-material-purple-400 input[type=checkbox]:checked ~ .check:before {
  color: #ab47bc;
}
.checkbox-material-purple-500 input[type=checkbox]:checked ~ .check:before {
  color: #9c27b0;
}
.checkbox-material-purple-600 input[type=checkbox]:checked ~ .check:before {
  color: #8e24aa;
}
.checkbox-material-purple-700 input[type=checkbox]:checked ~ .check:before {
  color: #7b1fa2;
}
.checkbox-material-purple-800 input[type=checkbox]:checked ~ .check:before {
  color: #6a1b9a;
}
.checkbox-material-purple-900 input[type=checkbox]:checked ~ .check:before {
  color: #4a148c;
}
.checkbox-material-purple-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ea80fc;
}
.checkbox-material-purple-A200 input[type=checkbox]:checked ~ .check:before {
  color: #e040fb;
}
.checkbox-material-purple-A400 input[type=checkbox]:checked ~ .check:before {
  color: #d500f9;
}
.checkbox-material-purple-A700 input[type=checkbox]:checked ~ .check:before {
  color: #aa00ff;
}
.checkbox-material-deep-purple input[type=checkbox]:checked ~ .check:before {
  color: #673ab7;
}
.checkbox-material-deep-purple-50 input[type=checkbox]:checked ~ .check:before {
  color: #ede7f6;
}
.checkbox-material-deep-purple-100 input[type=checkbox]:checked ~ .check:before {
  color: #d1c4e9;
}
.checkbox-material-deep-purple-200 input[type=checkbox]:checked ~ .check:before {
  color: #b39ddb;
}
.checkbox-material-deep-purple-300 input[type=checkbox]:checked ~ .check:before {
  color: #9575cd;
}
.checkbox-material-deep-purple-400 input[type=checkbox]:checked ~ .check:before {
  color: #7e57c2;
}
.checkbox-material-deep-purple-500 input[type=checkbox]:checked ~ .check:before {
  color: #673ab7;
}
.checkbox-material-deep-purple-600 input[type=checkbox]:checked ~ .check:before {
  color: #5e35b1;
}
.checkbox-material-deep-purple-700 input[type=checkbox]:checked ~ .check:before {
  color: #512da8;
}
.checkbox-material-deep-purple-800 input[type=checkbox]:checked ~ .check:before {
  color: #4527a0;
}
.checkbox-material-deep-purple-900 input[type=checkbox]:checked ~ .check:before {
  color: #311b92;
}
.checkbox-material-deep-purple-A100 input[type=checkbox]:checked ~ .check:before {
  color: #b388ff;
}
.checkbox-material-deep-purple-A200 input[type=checkbox]:checked ~ .check:before {
  color: #7c4dff;
}
.checkbox-material-deep-purple-A400 input[type=checkbox]:checked ~ .check:before {
  color: #651fff;
}
.checkbox-material-deep-purple-A700 input[type=checkbox]:checked ~ .check:before {
  color: #6200ea;
}
.checkbox-material-indigo input[type=checkbox]:checked ~ .check:before {
  color: #3f51b5;
}
.checkbox-material-indigo-50 input[type=checkbox]:checked ~ .check:before {
  color: #e8eaf6;
}
.checkbox-material-indigo-100 input[type=checkbox]:checked ~ .check:before {
  color: #c5cae9;
}
.checkbox-material-indigo-200 input[type=checkbox]:checked ~ .check:before {
  color: #9fa8da;
}
.checkbox-material-indigo-300 input[type=checkbox]:checked ~ .check:before {
  color: #7986cb;
}
.checkbox-material-indigo-400 input[type=checkbox]:checked ~ .check:before {
  color: #5c6bc0;
}
.checkbox-material-indigo-500 input[type=checkbox]:checked ~ .check:before {
  color: #3f51b5;
}
.checkbox-material-indigo-600 input[type=checkbox]:checked ~ .check:before {
  color: #3949ab;
}
.checkbox-material-indigo-700 input[type=checkbox]:checked ~ .check:before {
  color: #303f9f;
}
.checkbox-material-indigo-800 input[type=checkbox]:checked ~ .check:before {
  color: #283593;
}
.checkbox-material-indigo-900 input[type=checkbox]:checked ~ .check:before {
  color: #1a237e;
}
.checkbox-material-indigo-A100 input[type=checkbox]:checked ~ .check:before {
  color: #8c9eff;
}
.checkbox-material-indigo-A200 input[type=checkbox]:checked ~ .check:before {
  color: #536dfe;
}
.checkbox-material-indigo-A400 input[type=checkbox]:checked ~ .check:before {
  color: #3d5afe;
}
.checkbox-material-indigo-A700 input[type=checkbox]:checked ~ .check:before {
  color: #304ffe;
}
.checkbox-material-blue input[type=checkbox]:checked ~ .check:before {
  color: #2196f3;
}
.checkbox-material-blue-50 input[type=checkbox]:checked ~ .check:before {
  color: #e3f2fd;
}
.checkbox-material-blue-100 input[type=checkbox]:checked ~ .check:before {
  color: #bbdefb;
}
.checkbox-material-blue-200 input[type=checkbox]:checked ~ .check:before {
  color: #90caf9;
}
.checkbox-material-blue-300 input[type=checkbox]:checked ~ .check:before {
  color: #64b5f6;
}
.checkbox-material-blue-400 input[type=checkbox]:checked ~ .check:before {
  color: #42a5f5;
}
.checkbox-material-blue-500 input[type=checkbox]:checked ~ .check:before {
  color: #2196f3;
}
.checkbox-material-blue-600 input[type=checkbox]:checked ~ .check:before {
  color: #1e88e5;
}
.checkbox-material-blue-700 input[type=checkbox]:checked ~ .check:before {
  color: #1976d2;
}
.checkbox-material-blue-800 input[type=checkbox]:checked ~ .check:before {
  color: #1565c0;
}
.checkbox-material-blue-900 input[type=checkbox]:checked ~ .check:before {
  color: #0d47a1;
}
.checkbox-material-blue-A100 input[type=checkbox]:checked ~ .check:before {
  color: #82b1ff;
}
.checkbox-material-blue-A200 input[type=checkbox]:checked ~ .check:before {
  color: #448aff;
}
.checkbox-material-blue-A400 input[type=checkbox]:checked ~ .check:before {
  color: #2979ff;
}
.checkbox-material-blue-A700 input[type=checkbox]:checked ~ .check:before {
  color: #2962ff;
}
.checkbox-material-light-blue input[type=checkbox]:checked ~ .check:before {
  color: #03a9f4;
}
.checkbox-material-light-blue-50 input[type=checkbox]:checked ~ .check:before {
  color: #e1f5fe;
}
.checkbox-material-light-blue-100 input[type=checkbox]:checked ~ .check:before {
  color: #b3e5fc;
}
.checkbox-material-light-blue-200 input[type=checkbox]:checked ~ .check:before {
  color: #81d4fa;
}
.checkbox-material-light-blue-300 input[type=checkbox]:checked ~ .check:before {
  color: #4fc3f7;
}
.checkbox-material-light-blue-400 input[type=checkbox]:checked ~ .check:before {
  color: #29b6f6;
}
.checkbox-material-light-blue-500 input[type=checkbox]:checked ~ .check:before {
  color: #03a9f4;
}
.checkbox-material-light-blue-600 input[type=checkbox]:checked ~ .check:before {
  color: #039be5;
}
.checkbox-material-light-blue-700 input[type=checkbox]:checked ~ .check:before {
  color: #0288d1;
}
.checkbox-material-light-blue-800 input[type=checkbox]:checked ~ .check:before {
  color: #0277bd;
}
.checkbox-material-light-blue-900 input[type=checkbox]:checked ~ .check:before {
  color: #01579b;
}
.checkbox-material-light-blue-A100 input[type=checkbox]:checked ~ .check:before {
  color: #80d8ff;
}
.checkbox-material-light-blue-A200 input[type=checkbox]:checked ~ .check:before {
  color: #40c4ff;
}
.checkbox-material-light-blue-A400 input[type=checkbox]:checked ~ .check:before {
  color: #00b0ff;
}
.checkbox-material-light-blue-A700 input[type=checkbox]:checked ~ .check:before {
  color: #0091ea;
}
.checkbox-material-cyan input[type=checkbox]:checked ~ .check:before {
  color: #00bcd4;
}
.checkbox-material-cyan-50 input[type=checkbox]:checked ~ .check:before {
  color: #e0f7fa;
}
.checkbox-material-cyan-100 input[type=checkbox]:checked ~ .check:before {
  color: #b2ebf2;
}
.checkbox-material-cyan-200 input[type=checkbox]:checked ~ .check:before {
  color: #80deea;
}
.checkbox-material-cyan-300 input[type=checkbox]:checked ~ .check:before {
  color: #4dd0e1;
}
.checkbox-material-cyan-400 input[type=checkbox]:checked ~ .check:before {
  color: #26c6da;
}
.checkbox-material-cyan-500 input[type=checkbox]:checked ~ .check:before {
  color: #00bcd4;
}
.checkbox-material-cyan-600 input[type=checkbox]:checked ~ .check:before {
  color: #00acc1;
}
.checkbox-material-cyan-700 input[type=checkbox]:checked ~ .check:before {
  color: #0097a7;
}
.checkbox-material-cyan-800 input[type=checkbox]:checked ~ .check:before {
  color: #00838f;
}
.checkbox-material-cyan-900 input[type=checkbox]:checked ~ .check:before {
  color: #006064;
}
.checkbox-material-cyan-A100 input[type=checkbox]:checked ~ .check:before {
  color: #84ffff;
}
.checkbox-material-cyan-A200 input[type=checkbox]:checked ~ .check:before {
  color: #18ffff;
}
.checkbox-material-cyan-A400 input[type=checkbox]:checked ~ .check:before {
  color: #00e5ff;
}
.checkbox-material-cyan-A700 input[type=checkbox]:checked ~ .check:before {
  color: #00b8d4;
}
.checkbox-material-teal input[type=checkbox]:checked ~ .check:before {
  color: #009688;
}
.checkbox-material-teal-50 input[type=checkbox]:checked ~ .check:before {
  color: #e0f2f1;
}
.checkbox-material-teal-100 input[type=checkbox]:checked ~ .check:before {
  color: #b2dfdb;
}
.checkbox-material-teal-200 input[type=checkbox]:checked ~ .check:before {
  color: #80cbc4;
}
.checkbox-material-teal-300 input[type=checkbox]:checked ~ .check:before {
  color: #4db6ac;
}
.checkbox-material-teal-400 input[type=checkbox]:checked ~ .check:before {
  color: #26a69a;
}
.checkbox-material-teal-500 input[type=checkbox]:checked ~ .check:before {
  color: #009688;
}
.checkbox-material-teal-600 input[type=checkbox]:checked ~ .check:before {
  color: #00897b;
}
.checkbox-material-teal-700 input[type=checkbox]:checked ~ .check:before {
  color: #00796b;
}
.checkbox-material-teal-800 input[type=checkbox]:checked ~ .check:before {
  color: #00695c;
}
.checkbox-material-teal-900 input[type=checkbox]:checked ~ .check:before {
  color: #004d40;
}
.checkbox-material-teal-A100 input[type=checkbox]:checked ~ .check:before {
  color: #a7ffeb;
}
.checkbox-material-teal-A200 input[type=checkbox]:checked ~ .check:before {
  color: #64ffda;
}
.checkbox-material-teal-A400 input[type=checkbox]:checked ~ .check:before {
  color: #1de9b6;
}
.checkbox-material-teal-A700 input[type=checkbox]:checked ~ .check:before {
  color: #00bfa5;
}
.checkbox-material-green input[type=checkbox]:checked ~ .check:before {
  color: #4caf50;
}
.checkbox-material-green-50 input[type=checkbox]:checked ~ .check:before {
  color: #e8f5e9;
}
.checkbox-material-green-100 input[type=checkbox]:checked ~ .check:before {
  color: #c8e6c9;
}
.checkbox-material-green-200 input[type=checkbox]:checked ~ .check:before {
  color: #a5d6a7;
}
.checkbox-material-green-300 input[type=checkbox]:checked ~ .check:before {
  color: #81c784;
}
.checkbox-material-green-400 input[type=checkbox]:checked ~ .check:before {
  color: #66bb6a;
}
.checkbox-material-green-500 input[type=checkbox]:checked ~ .check:before {
  color: #4caf50;
}
.checkbox-material-green-600 input[type=checkbox]:checked ~ .check:before {
  color: #43a047;
}
.checkbox-material-green-700 input[type=checkbox]:checked ~ .check:before {
  color: #388e3c;
}
.checkbox-material-green-800 input[type=checkbox]:checked ~ .check:before {
  color: #2e7d32;
}
.checkbox-material-green-900 input[type=checkbox]:checked ~ .check:before {
  color: #1b5e20;
}
.checkbox-material-green-A100 input[type=checkbox]:checked ~ .check:before {
  color: #b9f6ca;
}
.checkbox-material-green-A200 input[type=checkbox]:checked ~ .check:before {
  color: #69f0ae;
}
.checkbox-material-green-A400 input[type=checkbox]:checked ~ .check:before {
  color: #00e676;
}
.checkbox-material-green-A700 input[type=checkbox]:checked ~ .check:before {
  color: #00c853;
}
.checkbox-material-light-green input[type=checkbox]:checked ~ .check:before {
  color: #8bc34a;
}
.checkbox-material-light-green-50 input[type=checkbox]:checked ~ .check:before {
  color: #f1f8e9;
}
.checkbox-material-light-green-100 input[type=checkbox]:checked ~ .check:before {
  color: #dcedc8;
}
.checkbox-material-light-green-200 input[type=checkbox]:checked ~ .check:before {
  color: #c5e1a5;
}
.checkbox-material-light-green-300 input[type=checkbox]:checked ~ .check:before {
  color: #aed581;
}
.checkbox-material-light-green-400 input[type=checkbox]:checked ~ .check:before {
  color: #9ccc65;
}
.checkbox-material-light-green-500 input[type=checkbox]:checked ~ .check:before {
  color: #8bc34a;
}
.checkbox-material-light-green-600 input[type=checkbox]:checked ~ .check:before {
  color: #7cb342;
}
.checkbox-material-light-green-700 input[type=checkbox]:checked ~ .check:before {
  color: #689f38;
}
.checkbox-material-light-green-800 input[type=checkbox]:checked ~ .check:before {
  color: #558b2f;
}
.checkbox-material-light-green-900 input[type=checkbox]:checked ~ .check:before {
  color: #33691e;
}
.checkbox-material-light-green-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ccff90;
}
.checkbox-material-light-green-A200 input[type=checkbox]:checked ~ .check:before {
  color: #b2ff59;
}
.checkbox-material-light-green-A400 input[type=checkbox]:checked ~ .check:before {
  color: #76ff03;
}
.checkbox-material-light-green-A700 input[type=checkbox]:checked ~ .check:before {
  color: #64dd17;
}
.checkbox-material-lime input[type=checkbox]:checked ~ .check:before {
  color: #cddc39;
}
.checkbox-material-lime-50 input[type=checkbox]:checked ~ .check:before {
  color: #f9fbe7;
}
.checkbox-material-lime-100 input[type=checkbox]:checked ~ .check:before {
  color: #f0f4c3;
}
.checkbox-material-lime-200 input[type=checkbox]:checked ~ .check:before {
  color: #e6ee9c;
}
.checkbox-material-lime-300 input[type=checkbox]:checked ~ .check:before {
  color: #dce775;
}
.checkbox-material-lime-400 input[type=checkbox]:checked ~ .check:before {
  color: #d4e157;
}
.checkbox-material-lime-500 input[type=checkbox]:checked ~ .check:before {
  color: #cddc39;
}
.checkbox-material-lime-600 input[type=checkbox]:checked ~ .check:before {
  color: #c0ca33;
}
.checkbox-material-lime-700 input[type=checkbox]:checked ~ .check:before {
  color: #afb42b;
}
.checkbox-material-lime-800 input[type=checkbox]:checked ~ .check:before {
  color: #9e9d24;
}
.checkbox-material-lime-900 input[type=checkbox]:checked ~ .check:before {
  color: #827717;
}
.checkbox-material-lime-A100 input[type=checkbox]:checked ~ .check:before {
  color: #f4ff81;
}
.checkbox-material-lime-A200 input[type=checkbox]:checked ~ .check:before {
  color: #eeff41;
}
.checkbox-material-lime-A400 input[type=checkbox]:checked ~ .check:before {
  color: #c6ff00;
}
.checkbox-material-lime-A700 input[type=checkbox]:checked ~ .check:before {
  color: #aeea00;
}
.checkbox-material-yellow input[type=checkbox]:checked ~ .check:before {
  color: #ffeb3b;
}
.checkbox-material-yellow-50 input[type=checkbox]:checked ~ .check:before {
  color: #fffde7;
}
.checkbox-material-yellow-100 input[type=checkbox]:checked ~ .check:before {
  color: #fff9c4;
}
.checkbox-material-yellow-200 input[type=checkbox]:checked ~ .check:before {
  color: #fff59d;
}
.checkbox-material-yellow-300 input[type=checkbox]:checked ~ .check:before {
  color: #fff176;
}
.checkbox-material-yellow-400 input[type=checkbox]:checked ~ .check:before {
  color: #ffee58;
}
.checkbox-material-yellow-500 input[type=checkbox]:checked ~ .check:before {
  color: #ffeb3b;
}
.checkbox-material-yellow-600 input[type=checkbox]:checked ~ .check:before {
  color: #fdd835;
}
.checkbox-material-yellow-700 input[type=checkbox]:checked ~ .check:before {
  color: #fbc02d;
}
.checkbox-material-yellow-800 input[type=checkbox]:checked ~ .check:before {
  color: #f9a825;
}
.checkbox-material-yellow-900 input[type=checkbox]:checked ~ .check:before {
  color: #f57f17;
}
.checkbox-material-yellow-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ffff8d;
}
.checkbox-material-yellow-A200 input[type=checkbox]:checked ~ .check:before {
  color: #ffff00;
}
.checkbox-material-yellow-A400 input[type=checkbox]:checked ~ .check:before {
  color: #ffea00;
}
.checkbox-material-yellow-A700 input[type=checkbox]:checked ~ .check:before {
  color: #ffd600;
}
.checkbox-material-amber input[type=checkbox]:checked ~ .check:before {
  color: #ffc107;
}
.checkbox-material-amber-50 input[type=checkbox]:checked ~ .check:before {
  color: #fff8e1;
}
.checkbox-material-amber-100 input[type=checkbox]:checked ~ .check:before {
  color: #ffecb3;
}
.checkbox-material-amber-200 input[type=checkbox]:checked ~ .check:before {
  color: #ffe082;
}
.checkbox-material-amber-300 input[type=checkbox]:checked ~ .check:before {
  color: #ffd54f;
}
.checkbox-material-amber-400 input[type=checkbox]:checked ~ .check:before {
  color: #ffca28;
}
.checkbox-material-amber-500 input[type=checkbox]:checked ~ .check:before {
  color: #ffc107;
}
.checkbox-material-amber-600 input[type=checkbox]:checked ~ .check:before {
  color: #ffb300;
}
.checkbox-material-amber-700 input[type=checkbox]:checked ~ .check:before {
  color: #ffa000;
}
.checkbox-material-amber-800 input[type=checkbox]:checked ~ .check:before {
  color: #ff8f00;
}
.checkbox-material-amber-900 input[type=checkbox]:checked ~ .check:before {
  color: #ff6f00;
}
.checkbox-material-amber-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ffe57f;
}
.checkbox-material-amber-A200 input[type=checkbox]:checked ~ .check:before {
  color: #ffd740;
}
.checkbox-material-amber-A400 input[type=checkbox]:checked ~ .check:before {
  color: #ffc400;
}
.checkbox-material-amber-A700 input[type=checkbox]:checked ~ .check:before {
  color: #ffab00;
}
.checkbox-material-orange input[type=checkbox]:checked ~ .check:before {
  color: #ff9800;
}
.checkbox-material-orange-50 input[type=checkbox]:checked ~ .check:before {
  color: #fff3e0;
}
.checkbox-material-orange-100 input[type=checkbox]:checked ~ .check:before {
  color: #ffe0b2;
}
.checkbox-material-orange-200 input[type=checkbox]:checked ~ .check:before {
  color: #ffcc80;
}
.checkbox-material-orange-300 input[type=checkbox]:checked ~ .check:before {
  color: #ffb74d;
}
.checkbox-material-orange-400 input[type=checkbox]:checked ~ .check:before {
  color: #ffa726;
}
.checkbox-material-orange-500 input[type=checkbox]:checked ~ .check:before {
  color: #ff9800;
}
.checkbox-material-orange-600 input[type=checkbox]:checked ~ .check:before {
  color: #fb8c00;
}
.checkbox-material-orange-700 input[type=checkbox]:checked ~ .check:before {
  color: #f57c00;
}
.checkbox-material-orange-800 input[type=checkbox]:checked ~ .check:before {
  color: #ef6c00;
}
.checkbox-material-orange-900 input[type=checkbox]:checked ~ .check:before {
  color: #e65100;
}
.checkbox-material-orange-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ffd180;
}
.checkbox-material-orange-A200 input[type=checkbox]:checked ~ .check:before {
  color: #ffab40;
}
.checkbox-material-orange-A400 input[type=checkbox]:checked ~ .check:before {
  color: #ff9100;
}
.checkbox-material-orange-A700 input[type=checkbox]:checked ~ .check:before {
  color: #ff6d00;
}
.checkbox-material-deep-orange input[type=checkbox]:checked ~ .check:before {
  color: #ff5722;
}
.checkbox-material-deep-orange-50 input[type=checkbox]:checked ~ .check:before {
  color: #fbe9e7;
}
.checkbox-material-deep-orange-100 input[type=checkbox]:checked ~ .check:before {
  color: #ffccbc;
}
.checkbox-material-deep-orange-200 input[type=checkbox]:checked ~ .check:before {
  color: #ffab91;
}
.checkbox-material-deep-orange-300 input[type=checkbox]:checked ~ .check:before {
  color: #ff8a65;
}
.checkbox-material-deep-orange-400 input[type=checkbox]:checked ~ .check:before {
  color: #ff7043;
}
.checkbox-material-deep-orange-500 input[type=checkbox]:checked ~ .check:before {
  color: #ff5722;
}
.checkbox-material-deep-orange-600 input[type=checkbox]:checked ~ .check:before {
  color: #f4511e;
}
.checkbox-material-deep-orange-700 input[type=checkbox]:checked ~ .check:before {
  color: #e64a19;
}
.checkbox-material-deep-orange-800 input[type=checkbox]:checked ~ .check:before {
  color: #d84315;
}
.checkbox-material-deep-orange-900 input[type=checkbox]:checked ~ .check:before {
  color: #bf360c;
}
.checkbox-material-deep-orange-A100 input[type=checkbox]:checked ~ .check:before {
  color: #ff9e80;
}
.checkbox-material-deep-orange-A200 input[type=checkbox]:checked ~ .check:before {
  color: #ff6e40;
}
.checkbox-material-deep-orange-A400 input[type=checkbox]:checked ~ .check:before {
  color: #ff3d00;
}
.checkbox-material-deep-orange-A700 input[type=checkbox]:checked ~ .check:before {
  color: #dd2c00;
}
.checkbox-material-brown input[type=checkbox]:checked ~ .check:before {
  color: #795548;
}
.checkbox-material-brown-50 input[type=checkbox]:checked ~ .check:before {
  color: #efebe9;
}
.checkbox-material-brown-100 input[type=checkbox]:checked ~ .check:before {
  color: #d7ccc8;
}
.checkbox-material-brown-200 input[type=checkbox]:checked ~ .check:before {
  color: #bcaaa4;
}
.checkbox-material-brown-300 input[type=checkbox]:checked ~ .check:before {
  color: #a1887f;
}
.checkbox-material-brown-400 input[type=checkbox]:checked ~ .check:before {
  color: #8d6e63;
}
.checkbox-material-brown-500 input[type=checkbox]:checked ~ .check:before {
  color: #795548;
}
.checkbox-material-brown-600 input[type=checkbox]:checked ~ .check:before {
  color: #6d4c41;
}
.checkbox-material-brown-700 input[type=checkbox]:checked ~ .check:before {
  color: #5d4037;
}
.checkbox-material-brown-800 input[type=checkbox]:checked ~ .check:before {
  color: #4e342e;
}
.checkbox-material-brown-900 input[type=checkbox]:checked ~ .check:before {
  color: #3e2723;
}
.checkbox-material-brown-A100 input[type=checkbox]:checked ~ .check:before {
  color: #d7ccc8;
}
.checkbox-material-brown-A200 input[type=checkbox]:checked ~ .check:before {
  color: #bcaaa4;
}
.checkbox-material-brown-A400 input[type=checkbox]:checked ~ .check:before {
  color: #8d6e63;
}
.checkbox-material-brown-A700 input[type=checkbox]:checked ~ .check:before {
  color: #5d4037;
}
.checkbox-material-grey input[type=checkbox]:checked ~ .check:before {
  color: #9e9e9e;
}
.checkbox-material-grey-50 input[type=checkbox]:checked ~ .check:before {
  color: #fafafa;
}
.checkbox-material-grey-100 input[type=checkbox]:checked ~ .check:before {
  color: #f5f5f5;
}
.checkbox-material-grey-200 input[type=checkbox]:checked ~ .check:before {
  color: #eeeeee;
}
.checkbox-material-grey-300 input[type=checkbox]:checked ~ .check:before {
  color: #e0e0e0;
}
.checkbox-material-grey-400 input[type=checkbox]:checked ~ .check:before {
  color: #bdbdbd;
}
.checkbox-material-grey-500 input[type=checkbox]:checked ~ .check:before {
  color: #9e9e9e;
}
.checkbox-material-grey-600 input[type=checkbox]:checked ~ .check:before {
  color: #757575;
}
.checkbox-material-grey-700 input[type=checkbox]:checked ~ .check:before {
  color: #616161;
}
.checkbox-material-grey-800 input[type=checkbox]:checked ~ .check:before {
  color: #424242;
}
.checkbox-material-grey-900 input[type=checkbox]:checked ~ .check:before {
  color: #212121;
}
.checkbox-material-grey-A100 input[type=checkbox]:checked ~ .check:before {
  color: #f5f5f5;
}
.checkbox-material-grey-A200 input[type=checkbox]:checked ~ .check:before {
  color: #eeeeee;
}
.checkbox-material-grey-A400 input[type=checkbox]:checked ~ .check:before {
  color: #bdbdbd;
}
.checkbox-material-grey-A700 input[type=checkbox]:checked ~ .check:before {
  color: #616161;
}
.checkbox-material-blue input[type=checkbox]:checked ~ .check:before {
  color: #607d8b;
}
.checkbox-material-blue-50 input[type=checkbox]:checked ~ .check:before {
  color: #eceff1;
}
.checkbox-material-blue-100 input[type=checkbox]:checked ~ .check:before {
  color: #cfd8dc;
}
.checkbox-material-blue-200 input[type=checkbox]:checked ~ .check:before {
  color: #b0bec5;
}
.checkbox-material-blue-300 input[type=checkbox]:checked ~ .check:before {
  color: #90a4ae;
}
.checkbox-material-blue-400 input[type=checkbox]:checked ~ .check:before {
  color: #78909c;
}
.checkbox-material-blue-500 input[type=checkbox]:checked ~ .check:before {
  color: #607d8b;
}
.checkbox-material-blue-600 input[type=checkbox]:checked ~ .check:before {
  color: #546e7a;
}
.checkbox-material-blue-700 input[type=checkbox]:checked ~ .check:before {
  color: #455a64;
}
.checkbox-material-blue-800 input[type=checkbox]:checked ~ .check:before {
  color: #37474f;
}
.checkbox-material-blue-900 input[type=checkbox]:checked ~ .check:before {
  color: #263238;
}
.checkbox-material-blue-A100 input[type=checkbox]:checked ~ .check:before {
  color: #cfd8dc;
}
.checkbox-material-blue-A200 input[type=checkbox]:checked ~ .check:before {
  color: #b0bec5;
}
.checkbox-material-blue-A400 input[type=checkbox]:checked ~ .check:before {
  color: #78909c;
}
.checkbox-material-blue-A700 input[type=checkbox]:checked ~ .check:before {
  color: #455a64;
}
.checkbox input[type=checkbox]:checked ~ .check,
.checkbox-default input[type=checkbox]:checked ~ .check {
  color: #4caf50;
}
.checkbox-primary input[type=checkbox]:checked ~ .check {
  color: #009587;
}
.checkbox-success input[type=checkbox]:checked ~ .check {
  color: #0f9d58;
}
.checkbox-info input[type=checkbox]:checked ~ .check {
  color: #03a9f4;
}
.checkbox-warning input[type=checkbox]:checked ~ .check {
  color: #ff5722;
}
.checkbox-danger input[type=checkbox]:checked ~ .check {
  color: #f44336;
}
.checkbox-material-red input[type=checkbox]:checked ~ .check {
  color: #f44336;
}
.checkbox-material-red-50 input[type=checkbox]:checked ~ .check {
  color: #ffebee;
}
.checkbox-material-red-100 input[type=checkbox]:checked ~ .check {
  color: #ffcdd2;
}
.checkbox-material-red-200 input[type=checkbox]:checked ~ .check {
  color: #ef9a9a;
}
.checkbox-material-red-300 input[type=checkbox]:checked ~ .check {
  color: #e57373;
}
.checkbox-material-red-400 input[type=checkbox]:checked ~ .check {
  color: #ef5350;
}
.checkbox-material-red-500 input[type=checkbox]:checked ~ .check {
  color: #f44336;
}
.checkbox-material-red-600 input[type=checkbox]:checked ~ .check {
  color: #e53935;
}
.checkbox-material-red-700 input[type=checkbox]:checked ~ .check {
  color: #d32f2f;
}
.checkbox-material-red-800 input[type=checkbox]:checked ~ .check {
  color: #c62828;
}
.checkbox-material-red-900 input[type=checkbox]:checked ~ .check {
  color: #b71c1c;
}
.checkbox-material-red-A100 input[type=checkbox]:checked ~ .check {
  color: #ff8a80;
}
.checkbox-material-red-A200 input[type=checkbox]:checked ~ .check {
  color: #ff5252;
}
.checkbox-material-red-A400 input[type=checkbox]:checked ~ .check {
  color: #ff1744;
}
.checkbox-material-red-A700 input[type=checkbox]:checked ~ .check {
  color: #d50000;
}
.checkbox-material-pink input[type=checkbox]:checked ~ .check {
  color: #e91e63;
}
.checkbox-material-pink-50 input[type=checkbox]:checked ~ .check {
  color: #fce4ec;
}
.checkbox-material-pink-100 input[type=checkbox]:checked ~ .check {
  color: #f8bbd0;
}
.checkbox-material-pink-200 input[type=checkbox]:checked ~ .check {
  color: #f48fb1;
}
.checkbox-material-pink-300 input[type=checkbox]:checked ~ .check {
  color: #f06292;
}
.checkbox-material-pink-400 input[type=checkbox]:checked ~ .check {
  color: #ec407a;
}
.checkbox-material-pink-500 input[type=checkbox]:checked ~ .check {
  color: #e91e63;
}
.checkbox-material-pink-600 input[type=checkbox]:checked ~ .check {
  color: #d81b60;
}
.checkbox-material-pink-700 input[type=checkbox]:checked ~ .check {
  color: #c2185b;
}
.checkbox-material-pink-800 input[type=checkbox]:checked ~ .check {
  color: #ad1457;
}
.checkbox-material-pink-900 input[type=checkbox]:checked ~ .check {
  color: #880e4f;
}
.checkbox-material-pink-A100 input[type=checkbox]:checked ~ .check {
  color: #ff80ab;
}
.checkbox-material-pink-A200 input[type=checkbox]:checked ~ .check {
  color: #ff4081;
}
.checkbox-material-pink-A400 input[type=checkbox]:checked ~ .check {
  color: #f50057;
}
.checkbox-material-pink-A700 input[type=checkbox]:checked ~ .check {
  color: #c51162;
}
.checkbox-material-purple input[type=checkbox]:checked ~ .check {
  color: #9c27b0;
}
.checkbox-material-purple-50 input[type=checkbox]:checked ~ .check {
  color: #f3e5f5;
}
.checkbox-material-purple-100 input[type=checkbox]:checked ~ .check {
  color: #e1bee7;
}
.checkbox-material-purple-200 input[type=checkbox]:checked ~ .check {
  color: #ce93d8;
}
.checkbox-material-purple-300 input[type=checkbox]:checked ~ .check {
  color: #ba68c8;
}
.checkbox-material-purple-400 input[type=checkbox]:checked ~ .check {
  color: #ab47bc;
}
.checkbox-material-purple-500 input[type=checkbox]:checked ~ .check {
  color: #9c27b0;
}
.checkbox-material-purple-600 input[type=checkbox]:checked ~ .check {
  color: #8e24aa;
}
.checkbox-material-purple-700 input[type=checkbox]:checked ~ .check {
  color: #7b1fa2;
}
.checkbox-material-purple-800 input[type=checkbox]:checked ~ .check {
  color: #6a1b9a;
}
.checkbox-material-purple-900 input[type=checkbox]:checked ~ .check {
  color: #4a148c;
}
.checkbox-material-purple-A100 input[type=checkbox]:checked ~ .check {
  color: #ea80fc;
}
.checkbox-material-purple-A200 input[type=checkbox]:checked ~ .check {
  color: #e040fb;
}
.checkbox-material-purple-A400 input[type=checkbox]:checked ~ .check {
  color: #d500f9;
}
.checkbox-material-purple-A700 input[type=checkbox]:checked ~ .check {
  color: #aa00ff;
}
.checkbox-material-deep-purple input[type=checkbox]:checked ~ .check {
  color: #673ab7;
}
.checkbox-material-deep-purple-50 input[type=checkbox]:checked ~ .check {
  color: #ede7f6;
}
.checkbox-material-deep-purple-100 input[type=checkbox]:checked ~ .check {
  color: #d1c4e9;
}
.checkbox-material-deep-purple-200 input[type=checkbox]:checked ~ .check {
  color: #b39ddb;
}
.checkbox-material-deep-purple-300 input[type=checkbox]:checked ~ .check {
  color: #9575cd;
}
.checkbox-material-deep-purple-400 input[type=checkbox]:checked ~ .check {
  color: #7e57c2;
}
.checkbox-material-deep-purple-500 input[type=checkbox]:checked ~ .check {
  color: #673ab7;
}
.checkbox-material-deep-purple-600 input[type=checkbox]:checked ~ .check {
  color: #5e35b1;
}
.checkbox-material-deep-purple-700 input[type=checkbox]:checked ~ .check {
  color: #512da8;
}
.checkbox-material-deep-purple-800 input[type=checkbox]:checked ~ .check {
  color: #4527a0;
}
.checkbox-material-deep-purple-900 input[type=checkbox]:checked ~ .check {
  color: #311b92;
}
.checkbox-material-deep-purple-A100 input[type=checkbox]:checked ~ .check {
  color: #b388ff;
}
.checkbox-material-deep-purple-A200 input[type=checkbox]:checked ~ .check {
  color: #7c4dff;
}
.checkbox-material-deep-purple-A400 input[type=checkbox]:checked ~ .check {
  color: #651fff;
}
.checkbox-material-deep-purple-A700 input[type=checkbox]:checked ~ .check {
  color: #6200ea;
}
.checkbox-material-indigo input[type=checkbox]:checked ~ .check {
  color: #3f51b5;
}
.checkbox-material-indigo-50 input[type=checkbox]:checked ~ .check {
  color: #e8eaf6;
}
.checkbox-material-indigo-100 input[type=checkbox]:checked ~ .check {
  color: #c5cae9;
}
.checkbox-material-indigo-200 input[type=checkbox]:checked ~ .check {
  color: #9fa8da;
}
.checkbox-material-indigo-300 input[type=checkbox]:checked ~ .check {
  color: #7986cb;
}
.checkbox-material-indigo-400 input[type=checkbox]:checked ~ .check {
  color: #5c6bc0;
}
.checkbox-material-indigo-500 input[type=checkbox]:checked ~ .check {
  color: #3f51b5;
}
.checkbox-material-indigo-600 input[type=checkbox]:checked ~ .check {
  color: #3949ab;
}
.checkbox-material-indigo-700 input[type=checkbox]:checked ~ .check {
  color: #303f9f;
}
.checkbox-material-indigo-800 input[type=checkbox]:checked ~ .check {
  color: #283593;
}
.checkbox-material-indigo-900 input[type=checkbox]:checked ~ .check {
  color: #1a237e;
}
.checkbox-material-indigo-A100 input[type=checkbox]:checked ~ .check {
  color: #8c9eff;
}
.checkbox-material-indigo-A200 input[type=checkbox]:checked ~ .check {
  color: #536dfe;
}
.checkbox-material-indigo-A400 input[type=checkbox]:checked ~ .check {
  color: #3d5afe;
}
.checkbox-material-indigo-A700 input[type=checkbox]:checked ~ .check {
  color: #304ffe;
}
.checkbox-material-blue input[type=checkbox]:checked ~ .check {
  color: #2196f3;
}
.checkbox-material-blue-50 input[type=checkbox]:checked ~ .check {
  color: #e3f2fd;
}
.checkbox-material-blue-100 input[type=checkbox]:checked ~ .check {
  color: #bbdefb;
}
.checkbox-material-blue-200 input[type=checkbox]:checked ~ .check {
  color: #90caf9;
}
.checkbox-material-blue-300 input[type=checkbox]:checked ~ .check {
  color: #64b5f6;
}
.checkbox-material-blue-400 input[type=checkbox]:checked ~ .check {
  color: #42a5f5;
}
.checkbox-material-blue-500 input[type=checkbox]:checked ~ .check {
  color: #2196f3;
}
.checkbox-material-blue-600 input[type=checkbox]:checked ~ .check {
  color: #1e88e5;
}
.checkbox-material-blue-700 input[type=checkbox]:checked ~ .check {
  color: #1976d2;
}
.checkbox-material-blue-800 input[type=checkbox]:checked ~ .check {
  color: #1565c0;
}
.checkbox-material-blue-900 input[type=checkbox]:checked ~ .check {
  color: #0d47a1;
}
.checkbox-material-blue-A100 input[type=checkbox]:checked ~ .check {
  color: #82b1ff;
}
.checkbox-material-blue-A200 input[type=checkbox]:checked ~ .check {
  color: #448aff;
}
.checkbox-material-blue-A400 input[type=checkbox]:checked ~ .check {
  color: #2979ff;
}
.checkbox-material-blue-A700 input[type=checkbox]:checked ~ .check {
  color: #2962ff;
}
.checkbox-material-light-blue input[type=checkbox]:checked ~ .check {
  color: #03a9f4;
}
.checkbox-material-light-blue-50 input[type=checkbox]:checked ~ .check {
  color: #e1f5fe;
}
.checkbox-material-light-blue-100 input[type=checkbox]:checked ~ .check {
  color: #b3e5fc;
}
.checkbox-material-light-blue-200 input[type=checkbox]:checked ~ .check {
  color: #81d4fa;
}
.checkbox-material-light-blue-300 input[type=checkbox]:checked ~ .check {
  color: #4fc3f7;
}
.checkbox-material-light-blue-400 input[type=checkbox]:checked ~ .check {
  color: #29b6f6;
}
.checkbox-material-light-blue-500 input[type=checkbox]:checked ~ .check {
  color: #03a9f4;
}
.checkbox-material-light-blue-600 input[type=checkbox]:checked ~ .check {
  color: #039be5;
}
.checkbox-material-light-blue-700 input[type=checkbox]:checked ~ .check {
  color: #0288d1;
}
.checkbox-material-light-blue-800 input[type=checkbox]:checked ~ .check {
  color: #0277bd;
}
.checkbox-material-light-blue-900 input[type=checkbox]:checked ~ .check {
  color: #01579b;
}
.checkbox-material-light-blue-A100 input[type=checkbox]:checked ~ .check {
  color: #80d8ff;
}
.checkbox-material-light-blue-A200 input[type=checkbox]:checked ~ .check {
  color: #40c4ff;
}
.checkbox-material-light-blue-A400 input[type=checkbox]:checked ~ .check {
  color: #00b0ff;
}
.checkbox-material-light-blue-A700 input[type=checkbox]:checked ~ .check {
  color: #0091ea;
}
.checkbox-material-cyan input[type=checkbox]:checked ~ .check {
  color: #00bcd4;
}
.checkbox-material-cyan-50 input[type=checkbox]:checked ~ .check {
  color: #e0f7fa;
}
.checkbox-material-cyan-100 input[type=checkbox]:checked ~ .check {
  color: #b2ebf2;
}
.checkbox-material-cyan-200 input[type=checkbox]:checked ~ .check {
  color: #80deea;
}
.checkbox-material-cyan-300 input[type=checkbox]:checked ~ .check {
  color: #4dd0e1;
}
.checkbox-material-cyan-400 input[type=checkbox]:checked ~ .check {
  color: #26c6da;
}
.checkbox-material-cyan-500 input[type=checkbox]:checked ~ .check {
  color: #00bcd4;
}
.checkbox-material-cyan-600 input[type=checkbox]:checked ~ .check {
  color: #00acc1;
}
.checkbox-material-cyan-700 input[type=checkbox]:checked ~ .check {
  color: #0097a7;
}
.checkbox-material-cyan-800 input[type=checkbox]:checked ~ .check {
  color: #00838f;
}
.checkbox-material-cyan-900 input[type=checkbox]:checked ~ .check {
  color: #006064;
}
.checkbox-material-cyan-A100 input[type=checkbox]:checked ~ .check {
  color: #84ffff;
}
.checkbox-material-cyan-A200 input[type=checkbox]:checked ~ .check {
  color: #18ffff;
}
.checkbox-material-cyan-A400 input[type=checkbox]:checked ~ .check {
  color: #00e5ff;
}
.checkbox-material-cyan-A700 input[type=checkbox]:checked ~ .check {
  color: #00b8d4;
}
.checkbox-material-teal input[type=checkbox]:checked ~ .check {
  color: #009688;
}
.checkbox-material-teal-50 input[type=checkbox]:checked ~ .check {
  color: #e0f2f1;
}
.checkbox-material-teal-100 input[type=checkbox]:checked ~ .check {
  color: #b2dfdb;
}
.checkbox-material-teal-200 input[type=checkbox]:checked ~ .check {
  color: #80cbc4;
}
.checkbox-material-teal-300 input[type=checkbox]:checked ~ .check {
  color: #4db6ac;
}
.checkbox-material-teal-400 input[type=checkbox]:checked ~ .check {
  color: #26a69a;
}
.checkbox-material-teal-500 input[type=checkbox]:checked ~ .check {
  color: #009688;
}
.checkbox-material-teal-600 input[type=checkbox]:checked ~ .check {
  color: #00897b;
}
.checkbox-material-teal-700 input[type=checkbox]:checked ~ .check {
  color: #00796b;
}
.checkbox-material-teal-800 input[type=checkbox]:checked ~ .check {
  color: #00695c;
}
.checkbox-material-teal-900 input[type=checkbox]:checked ~ .check {
  color: #004d40;
}
.checkbox-material-teal-A100 input[type=checkbox]:checked ~ .check {
  color: #a7ffeb;
}
.checkbox-material-teal-A200 input[type=checkbox]:checked ~ .check {
  color: #64ffda;
}
.checkbox-material-teal-A400 input[type=checkbox]:checked ~ .check {
  color: #1de9b6;
}
.checkbox-material-teal-A700 input[type=checkbox]:checked ~ .check {
  color: #00bfa5;
}
.checkbox-material-green input[type=checkbox]:checked ~ .check {
  color: #4caf50;
}
.checkbox-material-green-50 input[type=checkbox]:checked ~ .check {
  color: #e8f5e9;
}
.checkbox-material-green-100 input[type=checkbox]:checked ~ .check {
  color: #c8e6c9;
}
.checkbox-material-green-200 input[type=checkbox]:checked ~ .check {
  color: #a5d6a7;
}
.checkbox-material-green-300 input[type=checkbox]:checked ~ .check {
  color: #81c784;
}
.checkbox-material-green-400 input[type=checkbox]:checked ~ .check {
  color: #66bb6a;
}
.checkbox-material-green-500 input[type=checkbox]:checked ~ .check {
  color: #4caf50;
}
.checkbox-material-green-600 input[type=checkbox]:checked ~ .check {
  color: #43a047;
}
.checkbox-material-green-700 input[type=checkbox]:checked ~ .check {
  color: #388e3c;
}
.checkbox-material-green-800 input[type=checkbox]:checked ~ .check {
  color: #2e7d32;
}
.checkbox-material-green-900 input[type=checkbox]:checked ~ .check {
  color: #1b5e20;
}
.checkbox-material-green-A100 input[type=checkbox]:checked ~ .check {
  color: #b9f6ca;
}
.checkbox-material-green-A200 input[type=checkbox]:checked ~ .check {
  color: #69f0ae;
}
.checkbox-material-green-A400 input[type=checkbox]:checked ~ .check {
  color: #00e676;
}
.checkbox-material-green-A700 input[type=checkbox]:checked ~ .check {
  color: #00c853;
}
.checkbox-material-light-green input[type=checkbox]:checked ~ .check {
  color: #8bc34a;
}
.checkbox-material-light-green-50 input[type=checkbox]:checked ~ .check {
  color: #f1f8e9;
}
.checkbox-material-light-green-100 input[type=checkbox]:checked ~ .check {
  color: #dcedc8;
}
.checkbox-material-light-green-200 input[type=checkbox]:checked ~ .check {
  color: #c5e1a5;
}
.checkbox-material-light-green-300 input[type=checkbox]:checked ~ .check {
  color: #aed581;
}
.checkbox-material-light-green-400 input[type=checkbox]:checked ~ .check {
  color: #9ccc65;
}
.checkbox-material-light-green-500 input[type=checkbox]:checked ~ .check {
  color: #8bc34a;
}
.checkbox-material-light-green-600 input[type=checkbox]:checked ~ .check {
  color: #7cb342;
}
.checkbox-material-light-green-700 input[type=checkbox]:checked ~ .check {
  color: #689f38;
}
.checkbox-material-light-green-800 input[type=checkbox]:checked ~ .check {
  color: #558b2f;
}
.checkbox-material-light-green-900 input[type=checkbox]:checked ~ .check {
  color: #33691e;
}
.checkbox-material-light-green-A100 input[type=checkbox]:checked ~ .check {
  color: #ccff90;
}
.checkbox-material-light-green-A200 input[type=checkbox]:checked ~ .check {
  color: #b2ff59;
}
.checkbox-material-light-green-A400 input[type=checkbox]:checked ~ .check {
  color: #76ff03;
}
.checkbox-material-light-green-A700 input[type=checkbox]:checked ~ .check {
  color: #64dd17;
}
.checkbox-material-lime input[type=checkbox]:checked ~ .check {
  color: #cddc39;
}
.checkbox-material-lime-50 input[type=checkbox]:checked ~ .check {
  color: #f9fbe7;
}
.checkbox-material-lime-100 input[type=checkbox]:checked ~ .check {
  color: #f0f4c3;
}
.checkbox-material-lime-200 input[type=checkbox]:checked ~ .check {
  color: #e6ee9c;
}
.checkbox-material-lime-300 input[type=checkbox]:checked ~ .check {
  color: #dce775;
}
.checkbox-material-lime-400 input[type=checkbox]:checked ~ .check {
  color: #d4e157;
}
.checkbox-material-lime-500 input[type=checkbox]:checked ~ .check {
  color: #cddc39;
}
.checkbox-material-lime-600 input[type=checkbox]:checked ~ .check {
  color: #c0ca33;
}
.checkbox-material-lime-700 input[type=checkbox]:checked ~ .check {
  color: #afb42b;
}
.checkbox-material-lime-800 input[type=checkbox]:checked ~ .check {
  color: #9e9d24;
}
.checkbox-material-lime-900 input[type=checkbox]:checked ~ .check {
  color: #827717;
}
.checkbox-material-lime-A100 input[type=checkbox]:checked ~ .check {
  color: #f4ff81;
}
.checkbox-material-lime-A200 input[type=checkbox]:checked ~ .check {
  color: #eeff41;
}
.checkbox-material-lime-A400 input[type=checkbox]:checked ~ .check {
  color: #c6ff00;
}
.checkbox-material-lime-A700 input[type=checkbox]:checked ~ .check {
  color: #aeea00;
}
.checkbox-material-yellow input[type=checkbox]:checked ~ .check {
  color: #ffeb3b;
}
.checkbox-material-yellow-50 input[type=checkbox]:checked ~ .check {
  color: #fffde7;
}
.checkbox-material-yellow-100 input[type=checkbox]:checked ~ .check {
  color: #fff9c4;
}
.checkbox-material-yellow-200 input[type=checkbox]:checked ~ .check {
  color: #fff59d;
}
.checkbox-material-yellow-300 input[type=checkbox]:checked ~ .check {
  color: #fff176;
}
.checkbox-material-yellow-400 input[type=checkbox]:checked ~ .check {
  color: #ffee58;
}
.checkbox-material-yellow-500 input[type=checkbox]:checked ~ .check {
  color: #ffeb3b;
}
.checkbox-material-yellow-600 input[type=checkbox]:checked ~ .check {
  color: #fdd835;
}
.checkbox-material-yellow-700 input[type=checkbox]:checked ~ .check {
  color: #fbc02d;
}
.checkbox-material-yellow-800 input[type=checkbox]:checked ~ .check {
  color: #f9a825;
}
.checkbox-material-yellow-900 input[type=checkbox]:checked ~ .check {
  color: #f57f17;
}
.checkbox-material-yellow-A100 input[type=checkbox]:checked ~ .check {
  color: #ffff8d;
}
.checkbox-material-yellow-A200 input[type=checkbox]:checked ~ .check {
  color: #ffff00;
}
.checkbox-material-yellow-A400 input[type=checkbox]:checked ~ .check {
  color: #ffea00;
}
.checkbox-material-yellow-A700 input[type=checkbox]:checked ~ .check {
  color: #ffd600;
}
.checkbox-material-amber input[type=checkbox]:checked ~ .check {
  color: #ffc107;
}
.checkbox-material-amber-50 input[type=checkbox]:checked ~ .check {
  color: #fff8e1;
}
.checkbox-material-amber-100 input[type=checkbox]:checked ~ .check {
  color: #ffecb3;
}
.checkbox-material-amber-200 input[type=checkbox]:checked ~ .check {
  color: #ffe082;
}
.checkbox-material-amber-300 input[type=checkbox]:checked ~ .check {
  color: #ffd54f;
}
.checkbox-material-amber-400 input[type=checkbox]:checked ~ .check {
  color: #ffca28;
}
.checkbox-material-amber-500 input[type=checkbox]:checked ~ .check {
  color: #ffc107;
}
.checkbox-material-amber-600 input[type=checkbox]:checked ~ .check {
  color: #ffb300;
}
.checkbox-material-amber-700 input[type=checkbox]:checked ~ .check {
  color: #ffa000;
}
.checkbox-material-amber-800 input[type=checkbox]:checked ~ .check {
  color: #ff8f00;
}
.checkbox-material-amber-900 input[type=checkbox]:checked ~ .check {
  color: #ff6f00;
}
.checkbox-material-amber-A100 input[type=checkbox]:checked ~ .check {
  color: #ffe57f;
}
.checkbox-material-amber-A200 input[type=checkbox]:checked ~ .check {
  color: #ffd740;
}
.checkbox-material-amber-A400 input[type=checkbox]:checked ~ .check {
  color: #ffc400;
}
.checkbox-material-amber-A700 input[type=checkbox]:checked ~ .check {
  color: #ffab00;
}
.checkbox-material-orange input[type=checkbox]:checked ~ .check {
  color: #ff9800;
}
.checkbox-material-orange-50 input[type=checkbox]:checked ~ .check {
  color: #fff3e0;
}
.checkbox-material-orange-100 input[type=checkbox]:checked ~ .check {
  color: #ffe0b2;
}
.checkbox-material-orange-200 input[type=checkbox]:checked ~ .check {
  color: #ffcc80;
}
.checkbox-material-orange-300 input[type=checkbox]:checked ~ .check {
  color: #ffb74d;
}
.checkbox-material-orange-400 input[type=checkbox]:checked ~ .check {
  color: #ffa726;
}
.checkbox-material-orange-500 input[type=checkbox]:checked ~ .check {
  color: #ff9800;
}
.checkbox-material-orange-600 input[type=checkbox]:checked ~ .check {
  color: #fb8c00;
}
.checkbox-material-orange-700 input[type=checkbox]:checked ~ .check {
  color: #f57c00;
}
.checkbox-material-orange-800 input[type=checkbox]:checked ~ .check {
  color: #ef6c00;
}
.checkbox-material-orange-900 input[type=checkbox]:checked ~ .check {
  color: #e65100;
}
.checkbox-material-orange-A100 input[type=checkbox]:checked ~ .check {
  color: #ffd180;
}
.checkbox-material-orange-A200 input[type=checkbox]:checked ~ .check {
  color: #ffab40;
}
.checkbox-material-orange-A400 input[type=checkbox]:checked ~ .check {
  color: #ff9100;
}
.checkbox-material-orange-A700 input[type=checkbox]:checked ~ .check {
  color: #ff6d00;
}
.checkbox-material-deep-orange input[type=checkbox]:checked ~ .check {
  color: #ff5722;
}
.checkbox-material-deep-orange-50 input[type=checkbox]:checked ~ .check {
  color: #fbe9e7;
}
.checkbox-material-deep-orange-100 input[type=checkbox]:checked ~ .check {
  color: #ffccbc;
}
.checkbox-material-deep-orange-200 input[type=checkbox]:checked ~ .check {
  color: #ffab91;
}
.checkbox-material-deep-orange-300 input[type=checkbox]:checked ~ .check {
  color: #ff8a65;
}
.checkbox-material-deep-orange-400 input[type=checkbox]:checked ~ .check {
  color: #ff7043;
}
.checkbox-material-deep-orange-500 input[type=checkbox]:checked ~ .check {
  color: #ff5722;
}
.checkbox-material-deep-orange-600 input[type=checkbox]:checked ~ .check {
  color: #f4511e;
}
.checkbox-material-deep-orange-700 input[type=checkbox]:checked ~ .check {
  color: #e64a19;
}
.checkbox-material-deep-orange-800 input[type=checkbox]:checked ~ .check {
  color: #d84315;
}
.checkbox-material-deep-orange-900 input[type=checkbox]:checked ~ .check {
  color: #bf360c;
}
.checkbox-material-deep-orange-A100 input[type=checkbox]:checked ~ .check {
  color: #ff9e80;
}
.checkbox-material-deep-orange-A200 input[type=checkbox]:checked ~ .check {
  color: #ff6e40;
}
.checkbox-material-deep-orange-A400 input[type=checkbox]:checked ~ .check {
  color: #ff3d00;
}
.checkbox-material-deep-orange-A700 input[type=checkbox]:checked ~ .check {
  color: #dd2c00;
}
.checkbox-material-brown input[type=checkbox]:checked ~ .check {
  color: #795548;
}
.checkbox-material-brown-50 input[type=checkbox]:checked ~ .check {
  color: #efebe9;
}
.checkbox-material-brown-100 input[type=checkbox]:checked ~ .check {
  color: #d7ccc8;
}
.checkbox-material-brown-200 input[type=checkbox]:checked ~ .check {
  color: #bcaaa4;
}
.checkbox-material-brown-300 input[type=checkbox]:checked ~ .check {
  color: #a1887f;
}
.checkbox-material-brown-400 input[type=checkbox]:checked ~ .check {
  color: #8d6e63;
}
.checkbox-material-brown-500 input[type=checkbox]:checked ~ .check {
  color: #795548;
}
.checkbox-material-brown-600 input[type=checkbox]:checked ~ .check {
  color: #6d4c41;
}
.checkbox-material-brown-700 input[type=checkbox]:checked ~ .check {
  color: #5d4037;
}
.checkbox-material-brown-800 input[type=checkbox]:checked ~ .check {
  color: #4e342e;
}
.checkbox-material-brown-900 input[type=checkbox]:checked ~ .check {
  color: #3e2723;
}
.checkbox-material-brown-A100 input[type=checkbox]:checked ~ .check {
  color: #d7ccc8;
}
.checkbox-material-brown-A200 input[type=checkbox]:checked ~ .check {
  color: #bcaaa4;
}
.checkbox-material-brown-A400 input[type=checkbox]:checked ~ .check {
  color: #8d6e63;
}
.checkbox-material-brown-A700 input[type=checkbox]:checked ~ .check {
  color: #5d4037;
}
.checkbox-material-grey input[type=checkbox]:checked ~ .check {
  color: #9e9e9e;
}
.checkbox-material-grey-50 input[type=checkbox]:checked ~ .check {
  color: #fafafa;
}
.checkbox-material-grey-100 input[type=checkbox]:checked ~ .check {
  color: #f5f5f5;
}
.checkbox-material-grey-200 input[type=checkbox]:checked ~ .check {
  color: #eeeeee;
}
.checkbox-material-grey-300 input[type=checkbox]:checked ~ .check {
  color: #e0e0e0;
}
.checkbox-material-grey-400 input[type=checkbox]:checked ~ .check {
  color: #bdbdbd;
}
.checkbox-material-grey-500 input[type=checkbox]:checked ~ .check {
  color: #9e9e9e;
}
.checkbox-material-grey-600 input[type=checkbox]:checked ~ .check {
  color: #757575;
}
.checkbox-material-grey-700 input[type=checkbox]:checked ~ .check {
  color: #616161;
}
.checkbox-material-grey-800 input[type=checkbox]:checked ~ .check {
  color: #424242;
}
.checkbox-material-grey-900 input[type=checkbox]:checked ~ .check {
  color: #212121;
}
.checkbox-material-grey-A100 input[type=checkbox]:checked ~ .check {
  color: #f5f5f5;
}
.checkbox-material-grey-A200 input[type=checkbox]:checked ~ .check {
  color: #eeeeee;
}
.checkbox-material-grey-A400 input[type=checkbox]:checked ~ .check {
  color: #bdbdbd;
}
.checkbox-material-grey-A700 input[type=checkbox]:checked ~ .check {
  color: #616161;
}
.checkbox-material-blue input[type=checkbox]:checked ~ .check {
  color: #607d8b;
}
.checkbox-material-blue-50 input[type=checkbox]:checked ~ .check {
  color: #eceff1;
}
.checkbox-material-blue-100 input[type=checkbox]:checked ~ .check {
  color: #cfd8dc;
}
.checkbox-material-blue-200 input[type=checkbox]:checked ~ .check {
  color: #b0bec5;
}
.checkbox-material-blue-300 input[type=checkbox]:checked ~ .check {
  color: #90a4ae;
}
.checkbox-material-blue-400 input[type=checkbox]:checked ~ .check {
  color: #78909c;
}
.checkbox-material-blue-500 input[type=checkbox]:checked ~ .check {
  color: #607d8b;
}
.checkbox-material-blue-600 input[type=checkbox]:checked ~ .check {
  color: #546e7a;
}
.checkbox-material-blue-700 input[type=checkbox]:checked ~ .check {
  color: #455a64;
}
.checkbox-material-blue-800 input[type=checkbox]:checked ~ .check {
  color: #37474f;
}
.checkbox-material-blue-900 input[type=checkbox]:checked ~ .check {
  color: #263238;
}
.checkbox-material-blue-A100 input[type=checkbox]:checked ~ .check {
  color: #cfd8dc;
}
.checkbox-material-blue-A200 input[type=checkbox]:checked ~ .check {
  color: #b0bec5;
}
.checkbox-material-blue-A400 input[type=checkbox]:checked ~ .check {
  color: #78909c;
}
.checkbox-material-blue-A700 input[type=checkbox]:checked ~ .check {
  color: #455a64;
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0 0 0 0,
            0px 0px 0 0px inset;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.togglebutton {
  vertical-align: middle;
}
.togglebutton,
.togglebutton * {
  user-select: none;
}
.togglebutton label {
  font-weight: 400;
  cursor: pointer;
}
.togglebutton label input[type=checkbox]:first-child {
  opacity: 0;
  width: 0;
  height: 0;
}
.togglebutton label input[type=checkbox]:first-child:checked + .toggle {
  background-color: rgba(0, 149, 135, 0.5);
}
.togglebutton label input[type=checkbox]:first-child:checked + .toggle:after {
  background-color: #009587;
}
.togglebutton label .toggle,
.togglebutton label input[type=checkbox][disabled]:first-child + .toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
}
.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #F1F1F1;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -2px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type=checkbox][disabled]:first-child + .toggle:after,
.togglebutton label input[type=checkbox][disabled]:checked:first-child + .toggle:after {
  background-color: #BDBDBD;
}
.togglebutton label input[type=checkbox]:first-child:checked ~ .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 149, 135, 0.1);
}
.togglebutton label input[type=checkbox]:first-child ~ .toggle:active:after,
.togglebutton label input[type=checkbox][disabled]:first-child ~ .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type=checkbox]:first-child:checked + .toggle:after {
  left: 15px;
}
.form-horizontal .radio {
  margin-bottom: 10px;
}
.radio {
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.radio label {
  cursor: pointer;
  padding-left: 45px;
  position: relative;
}
.radio label span {
  display: block;
  position: absolute;
  left: 10px;
  top: 2px;
  transition-duration: 0.2s;
}
.radio label .circle {
  border: 2px solid rgba(0, 0, 0, 0.84);
  height: 15px;
  width: 15px;
  border-radius: 100%;
}
.radio label .check {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.84);
  transform: scale(0);
}
.radio label .check:after {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  left: -18px;
  top: -18px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  transform: scale(1.5);
}
.radio label input[type=radio]:not(:checked) ~ .check:after {
  animation: rippleOff 500ms;
}
.radio label input[type=radio]:checked ~ .check:after {
  animation: rippleOn 500ms;
}
.radio input[type=radio]:checked ~ .check,
.radio-default input[type=radio]:checked ~ .check {
  background-color: rgba(0, 0, 0, 0.84);
}
.radio-primary input[type=radio]:checked ~ .check {
  background-color: #009587;
}
.radio-success input[type=radio]:checked ~ .check {
  background-color: #0f9d58;
}
.radio-info input[type=radio]:checked ~ .check {
  background-color: #03a9f4;
}
.radio-warning input[type=radio]:checked ~ .check {
  background-color: #ff5722;
}
.radio-danger input[type=radio]:checked ~ .check {
  background-color: #f44336;
}
.radio-material-red input[type=radio]:checked ~ .check {
  background-color: #f44336;
}
.radio-material-red-50 input[type=radio]:checked ~ .check {
  background-color: #ffebee;
}
.radio-material-red-100 input[type=radio]:checked ~ .check {
  background-color: #ffcdd2;
}
.radio-material-red-200 input[type=radio]:checked ~ .check {
  background-color: #ef9a9a;
}
.radio-material-red-300 input[type=radio]:checked ~ .check {
  background-color: #e57373;
}
.radio-material-red-400 input[type=radio]:checked ~ .check {
  background-color: #ef5350;
}
.radio-material-red-500 input[type=radio]:checked ~ .check {
  background-color: #f44336;
}
.radio-material-red-600 input[type=radio]:checked ~ .check {
  background-color: #e53935;
}
.radio-material-red-700 input[type=radio]:checked ~ .check {
  background-color: #d32f2f;
}
.radio-material-red-800 input[type=radio]:checked ~ .check {
  background-color: #c62828;
}
.radio-material-red-900 input[type=radio]:checked ~ .check {
  background-color: #b71c1c;
}
.radio-material-red-A100 input[type=radio]:checked ~ .check {
  background-color: #ff8a80;
}
.radio-material-red-A200 input[type=radio]:checked ~ .check {
  background-color: #ff5252;
}
.radio-material-red-A400 input[type=radio]:checked ~ .check {
  background-color: #ff1744;
}
.radio-material-red-A700 input[type=radio]:checked ~ .check {
  background-color: #d50000;
}
.radio-material-pink input[type=radio]:checked ~ .check {
  background-color: #e91e63;
}
.radio-material-pink-50 input[type=radio]:checked ~ .check {
  background-color: #fce4ec;
}
.radio-material-pink-100 input[type=radio]:checked ~ .check {
  background-color: #f8bbd0;
}
.radio-material-pink-200 input[type=radio]:checked ~ .check {
  background-color: #f48fb1;
}
.radio-material-pink-300 input[type=radio]:checked ~ .check {
  background-color: #f06292;
}
.radio-material-pink-400 input[type=radio]:checked ~ .check {
  background-color: #ec407a;
}
.radio-material-pink-500 input[type=radio]:checked ~ .check {
  background-color: #e91e63;
}
.radio-material-pink-600 input[type=radio]:checked ~ .check {
  background-color: #d81b60;
}
.radio-material-pink-700 input[type=radio]:checked ~ .check {
  background-color: #c2185b;
}
.radio-material-pink-800 input[type=radio]:checked ~ .check {
  background-color: #ad1457;
}
.radio-material-pink-900 input[type=radio]:checked ~ .check {
  background-color: #880e4f;
}
.radio-material-pink-A100 input[type=radio]:checked ~ .check {
  background-color: #ff80ab;
}
.radio-material-pink-A200 input[type=radio]:checked ~ .check {
  background-color: #ff4081;
}
.radio-material-pink-A400 input[type=radio]:checked ~ .check {
  background-color: #f50057;
}
.radio-material-pink-A700 input[type=radio]:checked ~ .check {
  background-color: #c51162;
}
.radio-material-purple input[type=radio]:checked ~ .check {
  background-color: #9c27b0;
}
.radio-material-purple-50 input[type=radio]:checked ~ .check {
  background-color: #f3e5f5;
}
.radio-material-purple-100 input[type=radio]:checked ~ .check {
  background-color: #e1bee7;
}
.radio-material-purple-200 input[type=radio]:checked ~ .check {
  background-color: #ce93d8;
}
.radio-material-purple-300 input[type=radio]:checked ~ .check {
  background-color: #ba68c8;
}
.radio-material-purple-400 input[type=radio]:checked ~ .check {
  background-color: #ab47bc;
}
.radio-material-purple-500 input[type=radio]:checked ~ .check {
  background-color: #9c27b0;
}
.radio-material-purple-600 input[type=radio]:checked ~ .check {
  background-color: #8e24aa;
}
.radio-material-purple-700 input[type=radio]:checked ~ .check {
  background-color: #7b1fa2;
}
.radio-material-purple-800 input[type=radio]:checked ~ .check {
  background-color: #6a1b9a;
}
.radio-material-purple-900 input[type=radio]:checked ~ .check {
  background-color: #4a148c;
}
.radio-material-purple-A100 input[type=radio]:checked ~ .check {
  background-color: #ea80fc;
}
.radio-material-purple-A200 input[type=radio]:checked ~ .check {
  background-color: #e040fb;
}
.radio-material-purple-A400 input[type=radio]:checked ~ .check {
  background-color: #d500f9;
}
.radio-material-purple-A700 input[type=radio]:checked ~ .check {
  background-color: #aa00ff;
}
.radio-material-deep-purple input[type=radio]:checked ~ .check {
  background-color: #673ab7;
}
.radio-material-deep-purple-50 input[type=radio]:checked ~ .check {
  background-color: #ede7f6;
}
.radio-material-deep-purple-100 input[type=radio]:checked ~ .check {
  background-color: #d1c4e9;
}
.radio-material-deep-purple-200 input[type=radio]:checked ~ .check {
  background-color: #b39ddb;
}
.radio-material-deep-purple-300 input[type=radio]:checked ~ .check {
  background-color: #9575cd;
}
.radio-material-deep-purple-400 input[type=radio]:checked ~ .check {
  background-color: #7e57c2;
}
.radio-material-deep-purple-500 input[type=radio]:checked ~ .check {
  background-color: #673ab7;
}
.radio-material-deep-purple-600 input[type=radio]:checked ~ .check {
  background-color: #5e35b1;
}
.radio-material-deep-purple-700 input[type=radio]:checked ~ .check {
  background-color: #512da8;
}
.radio-material-deep-purple-800 input[type=radio]:checked ~ .check {
  background-color: #4527a0;
}
.radio-material-deep-purple-900 input[type=radio]:checked ~ .check {
  background-color: #311b92;
}
.radio-material-deep-purple-A100 input[type=radio]:checked ~ .check {
  background-color: #b388ff;
}
.radio-material-deep-purple-A200 input[type=radio]:checked ~ .check {
  background-color: #7c4dff;
}
.radio-material-deep-purple-A400 input[type=radio]:checked ~ .check {
  background-color: #651fff;
}
.radio-material-deep-purple-A700 input[type=radio]:checked ~ .check {
  background-color: #6200ea;
}
.radio-material-indigo input[type=radio]:checked ~ .check {
  background-color: #3f51b5;
}
.radio-material-indigo-50 input[type=radio]:checked ~ .check {
  background-color: #e8eaf6;
}
.radio-material-indigo-100 input[type=radio]:checked ~ .check {
  background-color: #c5cae9;
}
.radio-material-indigo-200 input[type=radio]:checked ~ .check {
  background-color: #9fa8da;
}
.radio-material-indigo-300 input[type=radio]:checked ~ .check {
  background-color: #7986cb;
}
.radio-material-indigo-400 input[type=radio]:checked ~ .check {
  background-color: #5c6bc0;
}
.radio-material-indigo-500 input[type=radio]:checked ~ .check {
  background-color: #3f51b5;
}
.radio-material-indigo-600 input[type=radio]:checked ~ .check {
  background-color: #3949ab;
}
.radio-material-indigo-700 input[type=radio]:checked ~ .check {
  background-color: #303f9f;
}
.radio-material-indigo-800 input[type=radio]:checked ~ .check {
  background-color: #283593;
}
.radio-material-indigo-900 input[type=radio]:checked ~ .check {
  background-color: #1a237e;
}
.radio-material-indigo-A100 input[type=radio]:checked ~ .check {
  background-color: #8c9eff;
}
.radio-material-indigo-A200 input[type=radio]:checked ~ .check {
  background-color: #536dfe;
}
.radio-material-indigo-A400 input[type=radio]:checked ~ .check {
  background-color: #3d5afe;
}
.radio-material-indigo-A700 input[type=radio]:checked ~ .check {
  background-color: #304ffe;
}
.radio-material-blue input[type=radio]:checked ~ .check {
  background-color: #2196f3;
}
.radio-material-blue-50 input[type=radio]:checked ~ .check {
  background-color: #e3f2fd;
}
.radio-material-blue-100 input[type=radio]:checked ~ .check {
  background-color: #bbdefb;
}
.radio-material-blue-200 input[type=radio]:checked ~ .check {
  background-color: #90caf9;
}
.radio-material-blue-300 input[type=radio]:checked ~ .check {
  background-color: #64b5f6;
}
.radio-material-blue-400 input[type=radio]:checked ~ .check {
  background-color: #42a5f5;
}
.radio-material-blue-500 input[type=radio]:checked ~ .check {
  background-color: #2196f3;
}
.radio-material-blue-600 input[type=radio]:checked ~ .check {
  background-color: #1e88e5;
}
.radio-material-blue-700 input[type=radio]:checked ~ .check {
  background-color: #1976d2;
}
.radio-material-blue-800 input[type=radio]:checked ~ .check {
  background-color: #1565c0;
}
.radio-material-blue-900 input[type=radio]:checked ~ .check {
  background-color: #0d47a1;
}
.radio-material-blue-A100 input[type=radio]:checked ~ .check {
  background-color: #82b1ff;
}
.radio-material-blue-A200 input[type=radio]:checked ~ .check {
  background-color: #448aff;
}
.radio-material-blue-A400 input[type=radio]:checked ~ .check {
  background-color: #2979ff;
}
.radio-material-blue-A700 input[type=radio]:checked ~ .check {
  background-color: #2962ff;
}
.radio-material-light-blue input[type=radio]:checked ~ .check {
  background-color: #03a9f4;
}
.radio-material-light-blue-50 input[type=radio]:checked ~ .check {
  background-color: #e1f5fe;
}
.radio-material-light-blue-100 input[type=radio]:checked ~ .check {
  background-color: #b3e5fc;
}
.radio-material-light-blue-200 input[type=radio]:checked ~ .check {
  background-color: #81d4fa;
}
.radio-material-light-blue-300 input[type=radio]:checked ~ .check {
  background-color: #4fc3f7;
}
.radio-material-light-blue-400 input[type=radio]:checked ~ .check {
  background-color: #29b6f6;
}
.radio-material-light-blue-500 input[type=radio]:checked ~ .check {
  background-color: #03a9f4;
}
.radio-material-light-blue-600 input[type=radio]:checked ~ .check {
  background-color: #039be5;
}
.radio-material-light-blue-700 input[type=radio]:checked ~ .check {
  background-color: #0288d1;
}
.radio-material-light-blue-800 input[type=radio]:checked ~ .check {
  background-color: #0277bd;
}
.radio-material-light-blue-900 input[type=radio]:checked ~ .check {
  background-color: #01579b;
}
.radio-material-light-blue-A100 input[type=radio]:checked ~ .check {
  background-color: #80d8ff;
}
.radio-material-light-blue-A200 input[type=radio]:checked ~ .check {
  background-color: #40c4ff;
}
.radio-material-light-blue-A400 input[type=radio]:checked ~ .check {
  background-color: #00b0ff;
}
.radio-material-light-blue-A700 input[type=radio]:checked ~ .check {
  background-color: #0091ea;
}
.radio-material-cyan input[type=radio]:checked ~ .check {
  background-color: #00bcd4;
}
.radio-material-cyan-50 input[type=radio]:checked ~ .check {
  background-color: #e0f7fa;
}
.radio-material-cyan-100 input[type=radio]:checked ~ .check {
  background-color: #b2ebf2;
}
.radio-material-cyan-200 input[type=radio]:checked ~ .check {
  background-color: #80deea;
}
.radio-material-cyan-300 input[type=radio]:checked ~ .check {
  background-color: #4dd0e1;
}
.radio-material-cyan-400 input[type=radio]:checked ~ .check {
  background-color: #26c6da;
}
.radio-material-cyan-500 input[type=radio]:checked ~ .check {
  background-color: #00bcd4;
}
.radio-material-cyan-600 input[type=radio]:checked ~ .check {
  background-color: #00acc1;
}
.radio-material-cyan-700 input[type=radio]:checked ~ .check {
  background-color: #0097a7;
}
.radio-material-cyan-800 input[type=radio]:checked ~ .check {
  background-color: #00838f;
}
.radio-material-cyan-900 input[type=radio]:checked ~ .check {
  background-color: #006064;
}
.radio-material-cyan-A100 input[type=radio]:checked ~ .check {
  background-color: #84ffff;
}
.radio-material-cyan-A200 input[type=radio]:checked ~ .check {
  background-color: #18ffff;
}
.radio-material-cyan-A400 input[type=radio]:checked ~ .check {
  background-color: #00e5ff;
}
.radio-material-cyan-A700 input[type=radio]:checked ~ .check {
  background-color: #00b8d4;
}
.radio-material-teal input[type=radio]:checked ~ .check {
  background-color: #009688;
}
.radio-material-teal-50 input[type=radio]:checked ~ .check {
  background-color: #e0f2f1;
}
.radio-material-teal-100 input[type=radio]:checked ~ .check {
  background-color: #b2dfdb;
}
.radio-material-teal-200 input[type=radio]:checked ~ .check {
  background-color: #80cbc4;
}
.radio-material-teal-300 input[type=radio]:checked ~ .check {
  background-color: #4db6ac;
}
.radio-material-teal-400 input[type=radio]:checked ~ .check {
  background-color: #26a69a;
}
.radio-material-teal-500 input[type=radio]:checked ~ .check {
  background-color: #009688;
}
.radio-material-teal-600 input[type=radio]:checked ~ .check {
  background-color: #00897b;
}
.radio-material-teal-700 input[type=radio]:checked ~ .check {
  background-color: #00796b;
}
.radio-material-teal-800 input[type=radio]:checked ~ .check {
  background-color: #00695c;
}
.radio-material-teal-900 input[type=radio]:checked ~ .check {
  background-color: #004d40;
}
.radio-material-teal-A100 input[type=radio]:checked ~ .check {
  background-color: #a7ffeb;
}
.radio-material-teal-A200 input[type=radio]:checked ~ .check {
  background-color: #64ffda;
}
.radio-material-teal-A400 input[type=radio]:checked ~ .check {
  background-color: #1de9b6;
}
.radio-material-teal-A700 input[type=radio]:checked ~ .check {
  background-color: #00bfa5;
}
.radio-material-green input[type=radio]:checked ~ .check {
  background-color: #4caf50;
}
.radio-material-green-50 input[type=radio]:checked ~ .check {
  background-color: #e8f5e9;
}
.radio-material-green-100 input[type=radio]:checked ~ .check {
  background-color: #c8e6c9;
}
.radio-material-green-200 input[type=radio]:checked ~ .check {
  background-color: #a5d6a7;
}
.radio-material-green-300 input[type=radio]:checked ~ .check {
  background-color: #81c784;
}
.radio-material-green-400 input[type=radio]:checked ~ .check {
  background-color: #66bb6a;
}
.radio-material-green-500 input[type=radio]:checked ~ .check {
  background-color: #4caf50;
}
.radio-material-green-600 input[type=radio]:checked ~ .check {
  background-color: #43a047;
}
.radio-material-green-700 input[type=radio]:checked ~ .check {
  background-color: #388e3c;
}
.radio-material-green-800 input[type=radio]:checked ~ .check {
  background-color: #2e7d32;
}
.radio-material-green-900 input[type=radio]:checked ~ .check {
  background-color: #1b5e20;
}
.radio-material-green-A100 input[type=radio]:checked ~ .check {
  background-color: #b9f6ca;
}
.radio-material-green-A200 input[type=radio]:checked ~ .check {
  background-color: #69f0ae;
}
.radio-material-green-A400 input[type=radio]:checked ~ .check {
  background-color: #00e676;
}
.radio-material-green-A700 input[type=radio]:checked ~ .check {
  background-color: #00c853;
}
.radio-material-light-green input[type=radio]:checked ~ .check {
  background-color: #8bc34a;
}
.radio-material-light-green-50 input[type=radio]:checked ~ .check {
  background-color: #f1f8e9;
}
.radio-material-light-green-100 input[type=radio]:checked ~ .check {
  background-color: #dcedc8;
}
.radio-material-light-green-200 input[type=radio]:checked ~ .check {
  background-color: #c5e1a5;
}
.radio-material-light-green-300 input[type=radio]:checked ~ .check {
  background-color: #aed581;
}
.radio-material-light-green-400 input[type=radio]:checked ~ .check {
  background-color: #9ccc65;
}
.radio-material-light-green-500 input[type=radio]:checked ~ .check {
  background-color: #8bc34a;
}
.radio-material-light-green-600 input[type=radio]:checked ~ .check {
  background-color: #7cb342;
}
.radio-material-light-green-700 input[type=radio]:checked ~ .check {
  background-color: #689f38;
}
.radio-material-light-green-800 input[type=radio]:checked ~ .check {
  background-color: #558b2f;
}
.radio-material-light-green-900 input[type=radio]:checked ~ .check {
  background-color: #33691e;
}
.radio-material-light-green-A100 input[type=radio]:checked ~ .check {
  background-color: #ccff90;
}
.radio-material-light-green-A200 input[type=radio]:checked ~ .check {
  background-color: #b2ff59;
}
.radio-material-light-green-A400 input[type=radio]:checked ~ .check {
  background-color: #76ff03;
}
.radio-material-light-green-A700 input[type=radio]:checked ~ .check {
  background-color: #64dd17;
}
.radio-material-lime input[type=radio]:checked ~ .check {
  background-color: #cddc39;
}
.radio-material-lime-50 input[type=radio]:checked ~ .check {
  background-color: #f9fbe7;
}
.radio-material-lime-100 input[type=radio]:checked ~ .check {
  background-color: #f0f4c3;
}
.radio-material-lime-200 input[type=radio]:checked ~ .check {
  background-color: #e6ee9c;
}
.radio-material-lime-300 input[type=radio]:checked ~ .check {
  background-color: #dce775;
}
.radio-material-lime-400 input[type=radio]:checked ~ .check {
  background-color: #d4e157;
}
.radio-material-lime-500 input[type=radio]:checked ~ .check {
  background-color: #cddc39;
}
.radio-material-lime-600 input[type=radio]:checked ~ .check {
  background-color: #c0ca33;
}
.radio-material-lime-700 input[type=radio]:checked ~ .check {
  background-color: #afb42b;
}
.radio-material-lime-800 input[type=radio]:checked ~ .check {
  background-color: #9e9d24;
}
.radio-material-lime-900 input[type=radio]:checked ~ .check {
  background-color: #827717;
}
.radio-material-lime-A100 input[type=radio]:checked ~ .check {
  background-color: #f4ff81;
}
.radio-material-lime-A200 input[type=radio]:checked ~ .check {
  background-color: #eeff41;
}
.radio-material-lime-A400 input[type=radio]:checked ~ .check {
  background-color: #c6ff00;
}
.radio-material-lime-A700 input[type=radio]:checked ~ .check {
  background-color: #aeea00;
}
.radio-material-yellow input[type=radio]:checked ~ .check {
  background-color: #ffeb3b;
}
.radio-material-yellow-50 input[type=radio]:checked ~ .check {
  background-color: #fffde7;
}
.radio-material-yellow-100 input[type=radio]:checked ~ .check {
  background-color: #fff9c4;
}
.radio-material-yellow-200 input[type=radio]:checked ~ .check {
  background-color: #fff59d;
}
.radio-material-yellow-300 input[type=radio]:checked ~ .check {
  background-color: #fff176;
}
.radio-material-yellow-400 input[type=radio]:checked ~ .check {
  background-color: #ffee58;
}
.radio-material-yellow-500 input[type=radio]:checked ~ .check {
  background-color: #ffeb3b;
}
.radio-material-yellow-600 input[type=radio]:checked ~ .check {
  background-color: #fdd835;
}
.radio-material-yellow-700 input[type=radio]:checked ~ .check {
  background-color: #fbc02d;
}
.radio-material-yellow-800 input[type=radio]:checked ~ .check {
  background-color: #f9a825;
}
.radio-material-yellow-900 input[type=radio]:checked ~ .check {
  background-color: #f57f17;
}
.radio-material-yellow-A100 input[type=radio]:checked ~ .check {
  background-color: #ffff8d;
}
.radio-material-yellow-A200 input[type=radio]:checked ~ .check {
  background-color: #ffff00;
}
.radio-material-yellow-A400 input[type=radio]:checked ~ .check {
  background-color: #ffea00;
}
.radio-material-yellow-A700 input[type=radio]:checked ~ .check {
  background-color: #ffd600;
}
.radio-material-amber input[type=radio]:checked ~ .check {
  background-color: #ffc107;
}
.radio-material-amber-50 input[type=radio]:checked ~ .check {
  background-color: #fff8e1;
}
.radio-material-amber-100 input[type=radio]:checked ~ .check {
  background-color: #ffecb3;
}
.radio-material-amber-200 input[type=radio]:checked ~ .check {
  background-color: #ffe082;
}
.radio-material-amber-300 input[type=radio]:checked ~ .check {
  background-color: #ffd54f;
}
.radio-material-amber-400 input[type=radio]:checked ~ .check {
  background-color: #ffca28;
}
.radio-material-amber-500 input[type=radio]:checked ~ .check {
  background-color: #ffc107;
}
.radio-material-amber-600 input[type=radio]:checked ~ .check {
  background-color: #ffb300;
}
.radio-material-amber-700 input[type=radio]:checked ~ .check {
  background-color: #ffa000;
}
.radio-material-amber-800 input[type=radio]:checked ~ .check {
  background-color: #ff8f00;
}
.radio-material-amber-900 input[type=radio]:checked ~ .check {
  background-color: #ff6f00;
}
.radio-material-amber-A100 input[type=radio]:checked ~ .check {
  background-color: #ffe57f;
}
.radio-material-amber-A200 input[type=radio]:checked ~ .check {
  background-color: #ffd740;
}
.radio-material-amber-A400 input[type=radio]:checked ~ .check {
  background-color: #ffc400;
}
.radio-material-amber-A700 input[type=radio]:checked ~ .check {
  background-color: #ffab00;
}
.radio-material-orange input[type=radio]:checked ~ .check {
  background-color: #ff9800;
}
.radio-material-orange-50 input[type=radio]:checked ~ .check {
  background-color: #fff3e0;
}
.radio-material-orange-100 input[type=radio]:checked ~ .check {
  background-color: #ffe0b2;
}
.radio-material-orange-200 input[type=radio]:checked ~ .check {
  background-color: #ffcc80;
}
.radio-material-orange-300 input[type=radio]:checked ~ .check {
  background-color: #ffb74d;
}
.radio-material-orange-400 input[type=radio]:checked ~ .check {
  background-color: #ffa726;
}
.radio-material-orange-500 input[type=radio]:checked ~ .check {
  background-color: #ff9800;
}
.radio-material-orange-600 input[type=radio]:checked ~ .check {
  background-color: #fb8c00;
}
.radio-material-orange-700 input[type=radio]:checked ~ .check {
  background-color: #f57c00;
}
.radio-material-orange-800 input[type=radio]:checked ~ .check {
  background-color: #ef6c00;
}
.radio-material-orange-900 input[type=radio]:checked ~ .check {
  background-color: #e65100;
}
.radio-material-orange-A100 input[type=radio]:checked ~ .check {
  background-color: #ffd180;
}
.radio-material-orange-A200 input[type=radio]:checked ~ .check {
  background-color: #ffab40;
}
.radio-material-orange-A400 input[type=radio]:checked ~ .check {
  background-color: #ff9100;
}
.radio-material-orange-A700 input[type=radio]:checked ~ .check {
  background-color: #ff6d00;
}
.radio-material-deep-orange input[type=radio]:checked ~ .check {
  background-color: #ff5722;
}
.radio-material-deep-orange-50 input[type=radio]:checked ~ .check {
  background-color: #fbe9e7;
}
.radio-material-deep-orange-100 input[type=radio]:checked ~ .check {
  background-color: #ffccbc;
}
.radio-material-deep-orange-200 input[type=radio]:checked ~ .check {
  background-color: #ffab91;
}
.radio-material-deep-orange-300 input[type=radio]:checked ~ .check {
  background-color: #ff8a65;
}
.radio-material-deep-orange-400 input[type=radio]:checked ~ .check {
  background-color: #ff7043;
}
.radio-material-deep-orange-500 input[type=radio]:checked ~ .check {
  background-color: #ff5722;
}
.radio-material-deep-orange-600 input[type=radio]:checked ~ .check {
  background-color: #f4511e;
}
.radio-material-deep-orange-700 input[type=radio]:checked ~ .check {
  background-color: #e64a19;
}
.radio-material-deep-orange-800 input[type=radio]:checked ~ .check {
  background-color: #d84315;
}
.radio-material-deep-orange-900 input[type=radio]:checked ~ .check {
  background-color: #bf360c;
}
.radio-material-deep-orange-A100 input[type=radio]:checked ~ .check {
  background-color: #ff9e80;
}
.radio-material-deep-orange-A200 input[type=radio]:checked ~ .check {
  background-color: #ff6e40;
}
.radio-material-deep-orange-A400 input[type=radio]:checked ~ .check {
  background-color: #ff3d00;
}
.radio-material-deep-orange-A700 input[type=radio]:checked ~ .check {
  background-color: #dd2c00;
}
.radio-material-brown input[type=radio]:checked ~ .check {
  background-color: #795548;
}
.radio-material-brown-50 input[type=radio]:checked ~ .check {
  background-color: #efebe9;
}
.radio-material-brown-100 input[type=radio]:checked ~ .check {
  background-color: #d7ccc8;
}
.radio-material-brown-200 input[type=radio]:checked ~ .check {
  background-color: #bcaaa4;
}
.radio-material-brown-300 input[type=radio]:checked ~ .check {
  background-color: #a1887f;
}
.radio-material-brown-400 input[type=radio]:checked ~ .check {
  background-color: #8d6e63;
}
.radio-material-brown-500 input[type=radio]:checked ~ .check {
  background-color: #795548;
}
.radio-material-brown-600 input[type=radio]:checked ~ .check {
  background-color: #6d4c41;
}
.radio-material-brown-700 input[type=radio]:checked ~ .check {
  background-color: #5d4037;
}
.radio-material-brown-800 input[type=radio]:checked ~ .check {
  background-color: #4e342e;
}
.radio-material-brown-900 input[type=radio]:checked ~ .check {
  background-color: #3e2723;
}
.radio-material-brown-A100 input[type=radio]:checked ~ .check {
  background-color: #d7ccc8;
}
.radio-material-brown-A200 input[type=radio]:checked ~ .check {
  background-color: #bcaaa4;
}
.radio-material-brown-A400 input[type=radio]:checked ~ .check {
  background-color: #8d6e63;
}
.radio-material-brown-A700 input[type=radio]:checked ~ .check {
  background-color: #5d4037;
}
.radio-material-grey input[type=radio]:checked ~ .check {
  background-color: #9e9e9e;
}
.radio-material-grey-50 input[type=radio]:checked ~ .check {
  background-color: #fafafa;
}
.radio-material-grey-100 input[type=radio]:checked ~ .check {
  background-color: #f5f5f5;
}
.radio-material-grey-200 input[type=radio]:checked ~ .check {
  background-color: #eeeeee;
}
.radio-material-grey-300 input[type=radio]:checked ~ .check {
  background-color: #e0e0e0;
}
.radio-material-grey-400 input[type=radio]:checked ~ .check {
  background-color: #bdbdbd;
}
.radio-material-grey-500 input[type=radio]:checked ~ .check {
  background-color: #9e9e9e;
}
.radio-material-grey-600 input[type=radio]:checked ~ .check {
  background-color: #757575;
}
.radio-material-grey-700 input[type=radio]:checked ~ .check {
  background-color: #616161;
}
.radio-material-grey-800 input[type=radio]:checked ~ .check {
  background-color: #424242;
}
.radio-material-grey-900 input[type=radio]:checked ~ .check {
  background-color: #212121;
}
.radio-material-grey-A100 input[type=radio]:checked ~ .check {
  background-color: #f5f5f5;
}
.radio-material-grey-A200 input[type=radio]:checked ~ .check {
  background-color: #eeeeee;
}
.radio-material-grey-A400 input[type=radio]:checked ~ .check {
  background-color: #bdbdbd;
}
.radio-material-grey-A700 input[type=radio]:checked ~ .check {
  background-color: #616161;
}
.radio-material-blue input[type=radio]:checked ~ .check {
  background-color: #607d8b;
}
.radio-material-blue-50 input[type=radio]:checked ~ .check {
  background-color: #eceff1;
}
.radio-material-blue-100 input[type=radio]:checked ~ .check {
  background-color: #cfd8dc;
}
.radio-material-blue-200 input[type=radio]:checked ~ .check {
  background-color: #b0bec5;
}
.radio-material-blue-300 input[type=radio]:checked ~ .check {
  background-color: #90a4ae;
}
.radio-material-blue-400 input[type=radio]:checked ~ .check {
  background-color: #78909c;
}
.radio-material-blue-500 input[type=radio]:checked ~ .check {
  background-color: #607d8b;
}
.radio-material-blue-600 input[type=radio]:checked ~ .check {
  background-color: #546e7a;
}
.radio-material-blue-700 input[type=radio]:checked ~ .check {
  background-color: #455a64;
}
.radio-material-blue-800 input[type=radio]:checked ~ .check {
  background-color: #37474f;
}
.radio-material-blue-900 input[type=radio]:checked ~ .check {
  background-color: #263238;
}
.radio-material-blue-A100 input[type=radio]:checked ~ .check {
  background-color: #cfd8dc;
}
.radio-material-blue-A200 input[type=radio]:checked ~ .check {
  background-color: #b0bec5;
}
.radio-material-blue-A400 input[type=radio]:checked ~ .check {
  background-color: #78909c;
}
.radio-material-blue-A700 input[type=radio]:checked ~ .check {
  background-color: #455a64;
}
.radio input[type=radio]:checked ~ .circle,
.radio-default input[type=radio]:checked ~ .circle {
  border-color: rgba(0, 0, 0, 0.84);
}
.radio-primary input[type=radio]:checked ~ .circle {
  border-color: #009587;
}
.radio-success input[type=radio]:checked ~ .circle {
  border-color: #0f9d58;
}
.radio-info input[type=radio]:checked ~ .circle {
  border-color: #03a9f4;
}
.radio-warning input[type=radio]:checked ~ .circle {
  border-color: #ff5722;
}
.radio-danger input[type=radio]:checked ~ .circle {
  border-color: #f44336;
}
.radio-material-red input[type=radio]:checked ~ .circle {
  border-color: #f44336;
}
.radio-material-red-50 input[type=radio]:checked ~ .circle {
  border-color: #ffebee;
}
.radio-material-red-100 input[type=radio]:checked ~ .circle {
  border-color: #ffcdd2;
}
.radio-material-red-200 input[type=radio]:checked ~ .circle {
  border-color: #ef9a9a;
}
.radio-material-red-300 input[type=radio]:checked ~ .circle {
  border-color: #e57373;
}
.radio-material-red-400 input[type=radio]:checked ~ .circle {
  border-color: #ef5350;
}
.radio-material-red-500 input[type=radio]:checked ~ .circle {
  border-color: #f44336;
}
.radio-material-red-600 input[type=radio]:checked ~ .circle {
  border-color: #e53935;
}
.radio-material-red-700 input[type=radio]:checked ~ .circle {
  border-color: #d32f2f;
}
.radio-material-red-800 input[type=radio]:checked ~ .circle {
  border-color: #c62828;
}
.radio-material-red-900 input[type=radio]:checked ~ .circle {
  border-color: #b71c1c;
}
.radio-material-red-A100 input[type=radio]:checked ~ .circle {
  border-color: #ff8a80;
}
.radio-material-red-A200 input[type=radio]:checked ~ .circle {
  border-color: #ff5252;
}
.radio-material-red-A400 input[type=radio]:checked ~ .circle {
  border-color: #ff1744;
}
.radio-material-red-A700 input[type=radio]:checked ~ .circle {
  border-color: #d50000;
}
.radio-material-pink input[type=radio]:checked ~ .circle {
  border-color: #e91e63;
}
.radio-material-pink-50 input[type=radio]:checked ~ .circle {
  border-color: #fce4ec;
}
.radio-material-pink-100 input[type=radio]:checked ~ .circle {
  border-color: #f8bbd0;
}
.radio-material-pink-200 input[type=radio]:checked ~ .circle {
  border-color: #f48fb1;
}
.radio-material-pink-300 input[type=radio]:checked ~ .circle {
  border-color: #f06292;
}
.radio-material-pink-400 input[type=radio]:checked ~ .circle {
  border-color: #ec407a;
}
.radio-material-pink-500 input[type=radio]:checked ~ .circle {
  border-color: #e91e63;
}
.radio-material-pink-600 input[type=radio]:checked ~ .circle {
  border-color: #d81b60;
}
.radio-material-pink-700 input[type=radio]:checked ~ .circle {
  border-color: #c2185b;
}
.radio-material-pink-800 input[type=radio]:checked ~ .circle {
  border-color: #ad1457;
}
.radio-material-pink-900 input[type=radio]:checked ~ .circle {
  border-color: #880e4f;
}
.radio-material-pink-A100 input[type=radio]:checked ~ .circle {
  border-color: #ff80ab;
}
.radio-material-pink-A200 input[type=radio]:checked ~ .circle {
  border-color: #ff4081;
}
.radio-material-pink-A400 input[type=radio]:checked ~ .circle {
  border-color: #f50057;
}
.radio-material-pink-A700 input[type=radio]:checked ~ .circle {
  border-color: #c51162;
}
.radio-material-purple input[type=radio]:checked ~ .circle {
  border-color: #9c27b0;
}
.radio-material-purple-50 input[type=radio]:checked ~ .circle {
  border-color: #f3e5f5;
}
.radio-material-purple-100 input[type=radio]:checked ~ .circle {
  border-color: #e1bee7;
}
.radio-material-purple-200 input[type=radio]:checked ~ .circle {
  border-color: #ce93d8;
}
.radio-material-purple-300 input[type=radio]:checked ~ .circle {
  border-color: #ba68c8;
}
.radio-material-purple-400 input[type=radio]:checked ~ .circle {
  border-color: #ab47bc;
}
.radio-material-purple-500 input[type=radio]:checked ~ .circle {
  border-color: #9c27b0;
}
.radio-material-purple-600 input[type=radio]:checked ~ .circle {
  border-color: #8e24aa;
}
.radio-material-purple-700 input[type=radio]:checked ~ .circle {
  border-color: #7b1fa2;
}
.radio-material-purple-800 input[type=radio]:checked ~ .circle {
  border-color: #6a1b9a;
}
.radio-material-purple-900 input[type=radio]:checked ~ .circle {
  border-color: #4a148c;
}
.radio-material-purple-A100 input[type=radio]:checked ~ .circle {
  border-color: #ea80fc;
}
.radio-material-purple-A200 input[type=radio]:checked ~ .circle {
  border-color: #e040fb;
}
.radio-material-purple-A400 input[type=radio]:checked ~ .circle {
  border-color: #d500f9;
}
.radio-material-purple-A700 input[type=radio]:checked ~ .circle {
  border-color: #aa00ff;
}
.radio-material-deep-purple input[type=radio]:checked ~ .circle {
  border-color: #673ab7;
}
.radio-material-deep-purple-50 input[type=radio]:checked ~ .circle {
  border-color: #ede7f6;
}
.radio-material-deep-purple-100 input[type=radio]:checked ~ .circle {
  border-color: #d1c4e9;
}
.radio-material-deep-purple-200 input[type=radio]:checked ~ .circle {
  border-color: #b39ddb;
}
.radio-material-deep-purple-300 input[type=radio]:checked ~ .circle {
  border-color: #9575cd;
}
.radio-material-deep-purple-400 input[type=radio]:checked ~ .circle {
  border-color: #7e57c2;
}
.radio-material-deep-purple-500 input[type=radio]:checked ~ .circle {
  border-color: #673ab7;
}
.radio-material-deep-purple-600 input[type=radio]:checked ~ .circle {
  border-color: #5e35b1;
}
.radio-material-deep-purple-700 input[type=radio]:checked ~ .circle {
  border-color: #512da8;
}
.radio-material-deep-purple-800 input[type=radio]:checked ~ .circle {
  border-color: #4527a0;
}
.radio-material-deep-purple-900 input[type=radio]:checked ~ .circle {
  border-color: #311b92;
}
.radio-material-deep-purple-A100 input[type=radio]:checked ~ .circle {
  border-color: #b388ff;
}
.radio-material-deep-purple-A200 input[type=radio]:checked ~ .circle {
  border-color: #7c4dff;
}
.radio-material-deep-purple-A400 input[type=radio]:checked ~ .circle {
  border-color: #651fff;
}
.radio-material-deep-purple-A700 input[type=radio]:checked ~ .circle {
  border-color: #6200ea;
}
.radio-material-indigo input[type=radio]:checked ~ .circle {
  border-color: #3f51b5;
}
.radio-material-indigo-50 input[type=radio]:checked ~ .circle {
  border-color: #e8eaf6;
}
.radio-material-indigo-100 input[type=radio]:checked ~ .circle {
  border-color: #c5cae9;
}
.radio-material-indigo-200 input[type=radio]:checked ~ .circle {
  border-color: #9fa8da;
}
.radio-material-indigo-300 input[type=radio]:checked ~ .circle {
  border-color: #7986cb;
}
.radio-material-indigo-400 input[type=radio]:checked ~ .circle {
  border-color: #5c6bc0;
}
.radio-material-indigo-500 input[type=radio]:checked ~ .circle {
  border-color: #3f51b5;
}
.radio-material-indigo-600 input[type=radio]:checked ~ .circle {
  border-color: #3949ab;
}
.radio-material-indigo-700 input[type=radio]:checked ~ .circle {
  border-color: #303f9f;
}
.radio-material-indigo-800 input[type=radio]:checked ~ .circle {
  border-color: #283593;
}
.radio-material-indigo-900 input[type=radio]:checked ~ .circle {
  border-color: #1a237e;
}
.radio-material-indigo-A100 input[type=radio]:checked ~ .circle {
  border-color: #8c9eff;
}
.radio-material-indigo-A200 input[type=radio]:checked ~ .circle {
  border-color: #536dfe;
}
.radio-material-indigo-A400 input[type=radio]:checked ~ .circle {
  border-color: #3d5afe;
}
.radio-material-indigo-A700 input[type=radio]:checked ~ .circle {
  border-color: #304ffe;
}
.radio-material-blue input[type=radio]:checked ~ .circle {
  border-color: #2196f3;
}
.radio-material-blue-50 input[type=radio]:checked ~ .circle {
  border-color: #e3f2fd;
}
.radio-material-blue-100 input[type=radio]:checked ~ .circle {
  border-color: #bbdefb;
}
.radio-material-blue-200 input[type=radio]:checked ~ .circle {
  border-color: #90caf9;
}
.radio-material-blue-300 input[type=radio]:checked ~ .circle {
  border-color: #64b5f6;
}
.radio-material-blue-400 input[type=radio]:checked ~ .circle {
  border-color: #42a5f5;
}
.radio-material-blue-500 input[type=radio]:checked ~ .circle {
  border-color: #2196f3;
}
.radio-material-blue-600 input[type=radio]:checked ~ .circle {
  border-color: #1e88e5;
}
.radio-material-blue-700 input[type=radio]:checked ~ .circle {
  border-color: #1976d2;
}
.radio-material-blue-800 input[type=radio]:checked ~ .circle {
  border-color: #1565c0;
}
.radio-material-blue-900 input[type=radio]:checked ~ .circle {
  border-color: #0d47a1;
}
.radio-material-blue-A100 input[type=radio]:checked ~ .circle {
  border-color: #82b1ff;
}
.radio-material-blue-A200 input[type=radio]:checked ~ .circle {
  border-color: #448aff;
}
.radio-material-blue-A400 input[type=radio]:checked ~ .circle {
  border-color: #2979ff;
}
.radio-material-blue-A700 input[type=radio]:checked ~ .circle {
  border-color: #2962ff;
}
.radio-material-light-blue input[type=radio]:checked ~ .circle {
  border-color: #03a9f4;
}
.radio-material-light-blue-50 input[type=radio]:checked ~ .circle {
  border-color: #e1f5fe;
}
.radio-material-light-blue-100 input[type=radio]:checked ~ .circle {
  border-color: #b3e5fc;
}
.radio-material-light-blue-200 input[type=radio]:checked ~ .circle {
  border-color: #81d4fa;
}
.radio-material-light-blue-300 input[type=radio]:checked ~ .circle {
  border-color: #4fc3f7;
}
.radio-material-light-blue-400 input[type=radio]:checked ~ .circle {
  border-color: #29b6f6;
}
.radio-material-light-blue-500 input[type=radio]:checked ~ .circle {
  border-color: #03a9f4;
}
.radio-material-light-blue-600 input[type=radio]:checked ~ .circle {
  border-color: #039be5;
}
.radio-material-light-blue-700 input[type=radio]:checked ~ .circle {
  border-color: #0288d1;
}
.radio-material-light-blue-800 input[type=radio]:checked ~ .circle {
  border-color: #0277bd;
}
.radio-material-light-blue-900 input[type=radio]:checked ~ .circle {
  border-color: #01579b;
}
.radio-material-light-blue-A100 input[type=radio]:checked ~ .circle {
  border-color: #80d8ff;
}
.radio-material-light-blue-A200 input[type=radio]:checked ~ .circle {
  border-color: #40c4ff;
}
.radio-material-light-blue-A400 input[type=radio]:checked ~ .circle {
  border-color: #00b0ff;
}
.radio-material-light-blue-A700 input[type=radio]:checked ~ .circle {
  border-color: #0091ea;
}
.radio-material-cyan input[type=radio]:checked ~ .circle {
  border-color: #00bcd4;
}
.radio-material-cyan-50 input[type=radio]:checked ~ .circle {
  border-color: #e0f7fa;
}
.radio-material-cyan-100 input[type=radio]:checked ~ .circle {
  border-color: #b2ebf2;
}
.radio-material-cyan-200 input[type=radio]:checked ~ .circle {
  border-color: #80deea;
}
.radio-material-cyan-300 input[type=radio]:checked ~ .circle {
  border-color: #4dd0e1;
}
.radio-material-cyan-400 input[type=radio]:checked ~ .circle {
  border-color: #26c6da;
}
.radio-material-cyan-500 input[type=radio]:checked ~ .circle {
  border-color: #00bcd4;
}
.radio-material-cyan-600 input[type=radio]:checked ~ .circle {
  border-color: #00acc1;
}
.radio-material-cyan-700 input[type=radio]:checked ~ .circle {
  border-color: #0097a7;
}
.radio-material-cyan-800 input[type=radio]:checked ~ .circle {
  border-color: #00838f;
}
.radio-material-cyan-900 input[type=radio]:checked ~ .circle {
  border-color: #006064;
}
.radio-material-cyan-A100 input[type=radio]:checked ~ .circle {
  border-color: #84ffff;
}
.radio-material-cyan-A200 input[type=radio]:checked ~ .circle {
  border-color: #18ffff;
}
.radio-material-cyan-A400 input[type=radio]:checked ~ .circle {
  border-color: #00e5ff;
}
.radio-material-cyan-A700 input[type=radio]:checked ~ .circle {
  border-color: #00b8d4;
}
.radio-material-teal input[type=radio]:checked ~ .circle {
  border-color: #009688;
}
.radio-material-teal-50 input[type=radio]:checked ~ .circle {
  border-color: #e0f2f1;
}
.radio-material-teal-100 input[type=radio]:checked ~ .circle {
  border-color: #b2dfdb;
}
.radio-material-teal-200 input[type=radio]:checked ~ .circle {
  border-color: #80cbc4;
}
.radio-material-teal-300 input[type=radio]:checked ~ .circle {
  border-color: #4db6ac;
}
.radio-material-teal-400 input[type=radio]:checked ~ .circle {
  border-color: #26a69a;
}
.radio-material-teal-500 input[type=radio]:checked ~ .circle {
  border-color: #009688;
}
.radio-material-teal-600 input[type=radio]:checked ~ .circle {
  border-color: #00897b;
}
.radio-material-teal-700 input[type=radio]:checked ~ .circle {
  border-color: #00796b;
}
.radio-material-teal-800 input[type=radio]:checked ~ .circle {
  border-color: #00695c;
}
.radio-material-teal-900 input[type=radio]:checked ~ .circle {
  border-color: #004d40;
}
.radio-material-teal-A100 input[type=radio]:checked ~ .circle {
  border-color: #a7ffeb;
}
.radio-material-teal-A200 input[type=radio]:checked ~ .circle {
  border-color: #64ffda;
}
.radio-material-teal-A400 input[type=radio]:checked ~ .circle {
  border-color: #1de9b6;
}
.radio-material-teal-A700 input[type=radio]:checked ~ .circle {
  border-color: #00bfa5;
}
.radio-material-green input[type=radio]:checked ~ .circle {
  border-color: #4caf50;
}
.radio-material-green-50 input[type=radio]:checked ~ .circle {
  border-color: #e8f5e9;
}
.radio-material-green-100 input[type=radio]:checked ~ .circle {
  border-color: #c8e6c9;
}
.radio-material-green-200 input[type=radio]:checked ~ .circle {
  border-color: #a5d6a7;
}
.radio-material-green-300 input[type=radio]:checked ~ .circle {
  border-color: #81c784;
}
.radio-material-green-400 input[type=radio]:checked ~ .circle {
  border-color: #66bb6a;
}
.radio-material-green-500 input[type=radio]:checked ~ .circle {
  border-color: #4caf50;
}
.radio-material-green-600 input[type=radio]:checked ~ .circle {
  border-color: #43a047;
}
.radio-material-green-700 input[type=radio]:checked ~ .circle {
  border-color: #388e3c;
}
.radio-material-green-800 input[type=radio]:checked ~ .circle {
  border-color: #2e7d32;
}
.radio-material-green-900 input[type=radio]:checked ~ .circle {
  border-color: #1b5e20;
}
.radio-material-green-A100 input[type=radio]:checked ~ .circle {
  border-color: #b9f6ca;
}
.radio-material-green-A200 input[type=radio]:checked ~ .circle {
  border-color: #69f0ae;
}
.radio-material-green-A400 input[type=radio]:checked ~ .circle {
  border-color: #00e676;
}
.radio-material-green-A700 input[type=radio]:checked ~ .circle {
  border-color: #00c853;
}
.radio-material-light-green input[type=radio]:checked ~ .circle {
  border-color: #8bc34a;
}
.radio-material-light-green-50 input[type=radio]:checked ~ .circle {
  border-color: #f1f8e9;
}
.radio-material-light-green-100 input[type=radio]:checked ~ .circle {
  border-color: #dcedc8;
}
.radio-material-light-green-200 input[type=radio]:checked ~ .circle {
  border-color: #c5e1a5;
}
.radio-material-light-green-300 input[type=radio]:checked ~ .circle {
  border-color: #aed581;
}
.radio-material-light-green-400 input[type=radio]:checked ~ .circle {
  border-color: #9ccc65;
}
.radio-material-light-green-500 input[type=radio]:checked ~ .circle {
  border-color: #8bc34a;
}
.radio-material-light-green-600 input[type=radio]:checked ~ .circle {
  border-color: #7cb342;
}
.radio-material-light-green-700 input[type=radio]:checked ~ .circle {
  border-color: #689f38;
}
.radio-material-light-green-800 input[type=radio]:checked ~ .circle {
  border-color: #558b2f;
}
.radio-material-light-green-900 input[type=radio]:checked ~ .circle {
  border-color: #33691e;
}
.radio-material-light-green-A100 input[type=radio]:checked ~ .circle {
  border-color: #ccff90;
}
.radio-material-light-green-A200 input[type=radio]:checked ~ .circle {
  border-color: #b2ff59;
}
.radio-material-light-green-A400 input[type=radio]:checked ~ .circle {
  border-color: #76ff03;
}
.radio-material-light-green-A700 input[type=radio]:checked ~ .circle {
  border-color: #64dd17;
}
.radio-material-lime input[type=radio]:checked ~ .circle {
  border-color: #cddc39;
}
.radio-material-lime-50 input[type=radio]:checked ~ .circle {
  border-color: #f9fbe7;
}
.radio-material-lime-100 input[type=radio]:checked ~ .circle {
  border-color: #f0f4c3;
}
.radio-material-lime-200 input[type=radio]:checked ~ .circle {
  border-color: #e6ee9c;
}
.radio-material-lime-300 input[type=radio]:checked ~ .circle {
  border-color: #dce775;
}
.radio-material-lime-400 input[type=radio]:checked ~ .circle {
  border-color: #d4e157;
}
.radio-material-lime-500 input[type=radio]:checked ~ .circle {
  border-color: #cddc39;
}
.radio-material-lime-600 input[type=radio]:checked ~ .circle {
  border-color: #c0ca33;
}
.radio-material-lime-700 input[type=radio]:checked ~ .circle {
  border-color: #afb42b;
}
.radio-material-lime-800 input[type=radio]:checked ~ .circle {
  border-color: #9e9d24;
}
.radio-material-lime-900 input[type=radio]:checked ~ .circle {
  border-color: #827717;
}
.radio-material-lime-A100 input[type=radio]:checked ~ .circle {
  border-color: #f4ff81;
}
.radio-material-lime-A200 input[type=radio]:checked ~ .circle {
  border-color: #eeff41;
}
.radio-material-lime-A400 input[type=radio]:checked ~ .circle {
  border-color: #c6ff00;
}
.radio-material-lime-A700 input[type=radio]:checked ~ .circle {
  border-color: #aeea00;
}
.radio-material-yellow input[type=radio]:checked ~ .circle {
  border-color: #ffeb3b;
}
.radio-material-yellow-50 input[type=radio]:checked ~ .circle {
  border-color: #fffde7;
}
.radio-material-yellow-100 input[type=radio]:checked ~ .circle {
  border-color: #fff9c4;
}
.radio-material-yellow-200 input[type=radio]:checked ~ .circle {
  border-color: #fff59d;
}
.radio-material-yellow-300 input[type=radio]:checked ~ .circle {
  border-color: #fff176;
}
.radio-material-yellow-400 input[type=radio]:checked ~ .circle {
  border-color: #ffee58;
}
.radio-material-yellow-500 input[type=radio]:checked ~ .circle {
  border-color: #ffeb3b;
}
.radio-material-yellow-600 input[type=radio]:checked ~ .circle {
  border-color: #fdd835;
}
.radio-material-yellow-700 input[type=radio]:checked ~ .circle {
  border-color: #fbc02d;
}
.radio-material-yellow-800 input[type=radio]:checked ~ .circle {
  border-color: #f9a825;
}
.radio-material-yellow-900 input[type=radio]:checked ~ .circle {
  border-color: #f57f17;
}
.radio-material-yellow-A100 input[type=radio]:checked ~ .circle {
  border-color: #ffff8d;
}
.radio-material-yellow-A200 input[type=radio]:checked ~ .circle {
  border-color: #ffff00;
}
.radio-material-yellow-A400 input[type=radio]:checked ~ .circle {
  border-color: #ffea00;
}
.radio-material-yellow-A700 input[type=radio]:checked ~ .circle {
  border-color: #ffd600;
}
.radio-material-amber input[type=radio]:checked ~ .circle {
  border-color: #ffc107;
}
.radio-material-amber-50 input[type=radio]:checked ~ .circle {
  border-color: #fff8e1;
}
.radio-material-amber-100 input[type=radio]:checked ~ .circle {
  border-color: #ffecb3;
}
.radio-material-amber-200 input[type=radio]:checked ~ .circle {
  border-color: #ffe082;
}
.radio-material-amber-300 input[type=radio]:checked ~ .circle {
  border-color: #ffd54f;
}
.radio-material-amber-400 input[type=radio]:checked ~ .circle {
  border-color: #ffca28;
}
.radio-material-amber-500 input[type=radio]:checked ~ .circle {
  border-color: #ffc107;
}
.radio-material-amber-600 input[type=radio]:checked ~ .circle {
  border-color: #ffb300;
}
.radio-material-amber-700 input[type=radio]:checked ~ .circle {
  border-color: #ffa000;
}
.radio-material-amber-800 input[type=radio]:checked ~ .circle {
  border-color: #ff8f00;
}
.radio-material-amber-900 input[type=radio]:checked ~ .circle {
  border-color: #ff6f00;
}
.radio-material-amber-A100 input[type=radio]:checked ~ .circle {
  border-color: #ffe57f;
}
.radio-material-amber-A200 input[type=radio]:checked ~ .circle {
  border-color: #ffd740;
}
.radio-material-amber-A400 input[type=radio]:checked ~ .circle {
  border-color: #ffc400;
}
.radio-material-amber-A700 input[type=radio]:checked ~ .circle {
  border-color: #ffab00;
}
.radio-material-orange input[type=radio]:checked ~ .circle {
  border-color: #ff9800;
}
.radio-material-orange-50 input[type=radio]:checked ~ .circle {
  border-color: #fff3e0;
}
.radio-material-orange-100 input[type=radio]:checked ~ .circle {
  border-color: #ffe0b2;
}
.radio-material-orange-200 input[type=radio]:checked ~ .circle {
  border-color: #ffcc80;
}
.radio-material-orange-300 input[type=radio]:checked ~ .circle {
  border-color: #ffb74d;
}
.radio-material-orange-400 input[type=radio]:checked ~ .circle {
  border-color: #ffa726;
}
.radio-material-orange-500 input[type=radio]:checked ~ .circle {
  border-color: #ff9800;
}
.radio-material-orange-600 input[type=radio]:checked ~ .circle {
  border-color: #fb8c00;
}
.radio-material-orange-700 input[type=radio]:checked ~ .circle {
  border-color: #f57c00;
}
.radio-material-orange-800 input[type=radio]:checked ~ .circle {
  border-color: #ef6c00;
}
.radio-material-orange-900 input[type=radio]:checked ~ .circle {
  border-color: #e65100;
}
.radio-material-orange-A100 input[type=radio]:checked ~ .circle {
  border-color: #ffd180;
}
.radio-material-orange-A200 input[type=radio]:checked ~ .circle {
  border-color: #ffab40;
}
.radio-material-orange-A400 input[type=radio]:checked ~ .circle {
  border-color: #ff9100;
}
.radio-material-orange-A700 input[type=radio]:checked ~ .circle {
  border-color: #ff6d00;
}
.radio-material-deep-orange input[type=radio]:checked ~ .circle {
  border-color: #ff5722;
}
.radio-material-deep-orange-50 input[type=radio]:checked ~ .circle {
  border-color: #fbe9e7;
}
.radio-material-deep-orange-100 input[type=radio]:checked ~ .circle {
  border-color: #ffccbc;
}
.radio-material-deep-orange-200 input[type=radio]:checked ~ .circle {
  border-color: #ffab91;
}
.radio-material-deep-orange-300 input[type=radio]:checked ~ .circle {
  border-color: #ff8a65;
}
.radio-material-deep-orange-400 input[type=radio]:checked ~ .circle {
  border-color: #ff7043;
}
.radio-material-deep-orange-500 input[type=radio]:checked ~ .circle {
  border-color: #ff5722;
}
.radio-material-deep-orange-600 input[type=radio]:checked ~ .circle {
  border-color: #f4511e;
}
.radio-material-deep-orange-700 input[type=radio]:checked ~ .circle {
  border-color: #e64a19;
}
.radio-material-deep-orange-800 input[type=radio]:checked ~ .circle {
  border-color: #d84315;
}
.radio-material-deep-orange-900 input[type=radio]:checked ~ .circle {
  border-color: #bf360c;
}
.radio-material-deep-orange-A100 input[type=radio]:checked ~ .circle {
  border-color: #ff9e80;
}
.radio-material-deep-orange-A200 input[type=radio]:checked ~ .circle {
  border-color: #ff6e40;
}
.radio-material-deep-orange-A400 input[type=radio]:checked ~ .circle {
  border-color: #ff3d00;
}
.radio-material-deep-orange-A700 input[type=radio]:checked ~ .circle {
  border-color: #dd2c00;
}
.radio-material-brown input[type=radio]:checked ~ .circle {
  border-color: #795548;
}
.radio-material-brown-50 input[type=radio]:checked ~ .circle {
  border-color: #efebe9;
}
.radio-material-brown-100 input[type=radio]:checked ~ .circle {
  border-color: #d7ccc8;
}
.radio-material-brown-200 input[type=radio]:checked ~ .circle {
  border-color: #bcaaa4;
}
.radio-material-brown-300 input[type=radio]:checked ~ .circle {
  border-color: #a1887f;
}
.radio-material-brown-400 input[type=radio]:checked ~ .circle {
  border-color: #8d6e63;
}
.radio-material-brown-500 input[type=radio]:checked ~ .circle {
  border-color: #795548;
}
.radio-material-brown-600 input[type=radio]:checked ~ .circle {
  border-color: #6d4c41;
}
.radio-material-brown-700 input[type=radio]:checked ~ .circle {
  border-color: #5d4037;
}
.radio-material-brown-800 input[type=radio]:checked ~ .circle {
  border-color: #4e342e;
}
.radio-material-brown-900 input[type=radio]:checked ~ .circle {
  border-color: #3e2723;
}
.radio-material-brown-A100 input[type=radio]:checked ~ .circle {
  border-color: #d7ccc8;
}
.radio-material-brown-A200 input[type=radio]:checked ~ .circle {
  border-color: #bcaaa4;
}
.radio-material-brown-A400 input[type=radio]:checked ~ .circle {
  border-color: #8d6e63;
}
.radio-material-brown-A700 input[type=radio]:checked ~ .circle {
  border-color: #5d4037;
}
.radio-material-grey input[type=radio]:checked ~ .circle {
  border-color: #9e9e9e;
}
.radio-material-grey-50 input[type=radio]:checked ~ .circle {
  border-color: #fafafa;
}
.radio-material-grey-100 input[type=radio]:checked ~ .circle {
  border-color: #f5f5f5;
}
.radio-material-grey-200 input[type=radio]:checked ~ .circle {
  border-color: #eeeeee;
}
.radio-material-grey-300 input[type=radio]:checked ~ .circle {
  border-color: #e0e0e0;
}
.radio-material-grey-400 input[type=radio]:checked ~ .circle {
  border-color: #bdbdbd;
}
.radio-material-grey-500 input[type=radio]:checked ~ .circle {
  border-color: #9e9e9e;
}
.radio-material-grey-600 input[type=radio]:checked ~ .circle {
  border-color: #757575;
}
.radio-material-grey-700 input[type=radio]:checked ~ .circle {
  border-color: #616161;
}
.radio-material-grey-800 input[type=radio]:checked ~ .circle {
  border-color: #424242;
}
.radio-material-grey-900 input[type=radio]:checked ~ .circle {
  border-color: #212121;
}
.radio-material-grey-A100 input[type=radio]:checked ~ .circle {
  border-color: #f5f5f5;
}
.radio-material-grey-A200 input[type=radio]:checked ~ .circle {
  border-color: #eeeeee;
}
.radio-material-grey-A400 input[type=radio]:checked ~ .circle {
  border-color: #bdbdbd;
}
.radio-material-grey-A700 input[type=radio]:checked ~ .circle {
  border-color: #616161;
}
.radio-material-blue input[type=radio]:checked ~ .circle {
  border-color: #607d8b;
}
.radio-material-blue-50 input[type=radio]:checked ~ .circle {
  border-color: #eceff1;
}
.radio-material-blue-100 input[type=radio]:checked ~ .circle {
  border-color: #cfd8dc;
}
.radio-material-blue-200 input[type=radio]:checked ~ .circle {
  border-color: #b0bec5;
}
.radio-material-blue-300 input[type=radio]:checked ~ .circle {
  border-color: #90a4ae;
}
.radio-material-blue-400 input[type=radio]:checked ~ .circle {
  border-color: #78909c;
}
.radio-material-blue-500 input[type=radio]:checked ~ .circle {
  border-color: #607d8b;
}
.radio-material-blue-600 input[type=radio]:checked ~ .circle {
  border-color: #546e7a;
}
.radio-material-blue-700 input[type=radio]:checked ~ .circle {
  border-color: #455a64;
}
.radio-material-blue-800 input[type=radio]:checked ~ .circle {
  border-color: #37474f;
}
.radio-material-blue-900 input[type=radio]:checked ~ .circle {
  border-color: #263238;
}
.radio-material-blue-A100 input[type=radio]:checked ~ .circle {
  border-color: #cfd8dc;
}
.radio-material-blue-A200 input[type=radio]:checked ~ .circle {
  border-color: #b0bec5;
}
.radio-material-blue-A400 input[type=radio]:checked ~ .circle {
  border-color: #78909c;
}
.radio-material-blue-A700 input[type=radio]:checked ~ .circle {
  border-color: #455a64;
}
.radio input[type=radio][disabled] ~ .check,
.radio input[type=radio][disabled] ~ .circle {
  opacity: 0.5;
}
.radio input[type=radio] {
  display: none;
}
.radio input[type=radio]:checked ~ .check {
  transform: scale(0.55);
}
.radio input[type=radio][disabled] ~ .circle {
  border-color: rgba(0, 0, 0, 0.84);
}
.radio input[type=radio][disabled] ~ .check {
  background-color: rgba(0, 0, 0, 0.84);
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
fieldset[disabled] .form-control,
.form-control-wrapper .form-control,
.form-control,
fieldset[disabled] .form-control:focus,
.form-control-wrapper .form-control:focus,
.form-control:focus,
fieldset[disabled] .form-control.focus,
.form-control-wrapper .form-control.focus,
.form-control.focus {
  padding: 0;
  float: none;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid #757575;
}
fieldset[disabled] .form-control:not(textarea):not(select),
.form-control-wrapper .form-control:not(textarea):not(select),
.form-control:not(textarea):not(select),
fieldset[disabled] .form-control:focus:not(textarea):not(select),
.form-control-wrapper .form-control:focus:not(textarea):not(select),
.form-control:focus:not(textarea):not(select),
fieldset[disabled] .form-control.focus:not(textarea):not(select),
.form-control-wrapper .form-control.focus:not(textarea):not(select),
.form-control.focus:not(textarea):not(select) {
  height: 28px;
}
fieldset[disabled] .form-control:disabled,
.form-control-wrapper .form-control:disabled,
.form-control:disabled,
fieldset[disabled] .form-control:focus:disabled,
.form-control-wrapper .form-control:focus:disabled,
.form-control:focus:disabled,
fieldset[disabled] .form-control.focus:disabled,
.form-control-wrapper .form-control.focus:disabled,
.form-control.focus:disabled {
  border-style: dashed;
}
select.form-control {
  height: 23px;
}
select[multiple].form-control,
select[multiple].form-control:focus,
select[multiple].form-control.focus {
  height: 85px;
}
.form-control-wrapper {
  position: relative;
}
.form-control-wrapper .form-control:focus,
.form-control-wrapper .form-control.focus {
  outline: none;
}
.form-control-wrapper .floating-label {
  color: #7E7E7E;
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 5px;
  transition: 0.2s ease all;
  opacity: 0;
}
.form-control-wrapper .form-control:not(.empty) ~ .floating-label {
  top: -10px;
  font-size: 10px;
  opacity: 1;
}
.form-control-wrapper .form-control:focus:invalid ~ .floating-label,
.form-control-wrapper .form-control.focus:invalid ~ .floating-label {
  color: #f44336;
}
.form-control-wrapper .form-control:focus ~ .material-input:after,
.form-control-wrapper .form-control.focus ~ .material-input:after {
  background-color: #009587;
}
.form-control-wrapper .form-control:focus:invalid ~ .material-input:before,
.form-control-wrapper .form-control.focus:invalid ~ .material-input:before,
.form-control-wrapper .form-control:focus:invalid ~ .material-input:after,
.form-control-wrapper .form-control.focus:invalid ~ .material-input:after {
  background-color: #f44336;
}
.form-control-wrapper .form-control.empty ~ .floating-label {
  opacity: 1;
}
.form-control-wrapper .material-input:before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  height: 2px;
  background-color: #009587;
  bottom: -1px;
  transform: scaleX(0);
  transition: transform 0s;
}
.form-control-wrapper .form-control:focus ~ .material-input:before,
.form-control-wrapper .form-control.focus ~ .material-input:before {
  transform: scaleX(1);
  transition: transform 0.2s ease-out;
}
.form-control-wrapper .material-input:after {
  content: "";
  position: absolute;
  height: 18px;
  width: 100px;
  margin-top: -1px;
  top: 7px;
  left: 0;
  pointer-events: none;
  opacity: 0.9;
  transform-origin: left;
}
.form-control-wrapper .input-lg ~ .material-input:after {
  height: 26px;
}
.form-control-wrapper textarea {
  resize: none;
}
.form-control-wrapper textarea ~ .form-control-highlight {
  margin-top: -11px;
}
.form-control-wrapper .form-control:focus ~ .material-input:after,
.form-control-wrapper .form-control.focus ~ .material-input:after {
  animation: input-highlight 0.3s ease;
  animation-fill-mode: forwards;
  opacity: 0;
}
.form-control-wrapper .hint {
  position: absolute;
  font-size: 80%;
  display: none;
}
.form-control-wrapper .form-control:focus ~ .hint,
.form-control-wrapper .form-control.focus ~ .hint {
  display: block;
}
.form-control-wrapper select ~ .material-input:after {
  display: none;
}
.form-group {
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.form-group.has-warning .material-input:before,
.form-group.has-warning input.form-control:focus ~ .material-input:after,
.form-group.has-warning input.form-control.focus ~ .material-input:after {
  background: #ff5722;
}
.form-group.has-warning .control-label,
.form-group.has-warning input.form-control:not(.empty) ~ .floating-label {
  color: #ff5722;
}
.form-group.has-error .material-input:before,
.form-group.has-error input.form-control:focus ~ .material-input:after,
.form-group.has-error input.form-control.focus ~ .material-input:after {
  background: #f44336;
}
.form-group.has-error .control-label,
.form-group.has-error input.form-control:not(.empty) ~ .floating-label {
  color: #f44336;
}
.form-group.has-success .material-input:before,
.form-group.has-success input.form-control:focus ~ .material-input:after,
.form-group.has-success input.form-control.focus ~ .material-input:after {
  background: #0f9d58;
}
.form-group.has-success .control-label,
.form-group.has-success input.form-control:not(.empty) ~ .floating-label {
  color: #0f9d58;
}
.form-group.has-info .material-input:before,
.form-group.has-info input.form-control:focus ~ .material-input:after,
.form-group.has-info input.form-control.focus ~ .material-input:after {
  background: #03a9f4;
}
.form-group.has-info .control-label,
.form-group.has-info input.form-control:not(.empty) ~ .floating-label {
  color: #03a9f4;
}
.form-group .material-input:before,
.form-group-default .material-input:before {
  background-color: #009587;
}
.form-group-primary .material-input:before {
  background-color: #009587;
}
.form-group-success .material-input:before {
  background-color: #0f9d58;
}
.form-group-info .material-input:before {
  background-color: #03a9f4;
}
.form-group-warning .material-input:before {
  background-color: #ff5722;
}
.form-group-danger .material-input:before {
  background-color: #f44336;
}
.form-group-material-red .material-input:before {
  background-color: #f44336;
}
.form-group-material-red-50 .material-input:before {
  background-color: #ffebee;
}
.form-group-material-red-100 .material-input:before {
  background-color: #ffcdd2;
}
.form-group-material-red-200 .material-input:before {
  background-color: #ef9a9a;
}
.form-group-material-red-300 .material-input:before {
  background-color: #e57373;
}
.form-group-material-red-400 .material-input:before {
  background-color: #ef5350;
}
.form-group-material-red-500 .material-input:before {
  background-color: #f44336;
}
.form-group-material-red-600 .material-input:before {
  background-color: #e53935;
}
.form-group-material-red-700 .material-input:before {
  background-color: #d32f2f;
}
.form-group-material-red-800 .material-input:before {
  background-color: #c62828;
}
.form-group-material-red-900 .material-input:before {
  background-color: #b71c1c;
}
.form-group-material-red-A100 .material-input:before {
  background-color: #ff8a80;
}
.form-group-material-red-A200 .material-input:before {
  background-color: #ff5252;
}
.form-group-material-red-A400 .material-input:before {
  background-color: #ff1744;
}
.form-group-material-red-A700 .material-input:before {
  background-color: #d50000;
}
.form-group-material-pink .material-input:before {
  background-color: #e91e63;
}
.form-group-material-pink-50 .material-input:before {
  background-color: #fce4ec;
}
.form-group-material-pink-100 .material-input:before {
  background-color: #f8bbd0;
}
.form-group-material-pink-200 .material-input:before {
  background-color: #f48fb1;
}
.form-group-material-pink-300 .material-input:before {
  background-color: #f06292;
}
.form-group-material-pink-400 .material-input:before {
  background-color: #ec407a;
}
.form-group-material-pink-500 .material-input:before {
  background-color: #e91e63;
}
.form-group-material-pink-600 .material-input:before {
  background-color: #d81b60;
}
.form-group-material-pink-700 .material-input:before {
  background-color: #c2185b;
}
.form-group-material-pink-800 .material-input:before {
  background-color: #ad1457;
}
.form-group-material-pink-900 .material-input:before {
  background-color: #880e4f;
}
.form-group-material-pink-A100 .material-input:before {
  background-color: #ff80ab;
}
.form-group-material-pink-A200 .material-input:before {
  background-color: #ff4081;
}
.form-group-material-pink-A400 .material-input:before {
  background-color: #f50057;
}
.form-group-material-pink-A700 .material-input:before {
  background-color: #c51162;
}
.form-group-material-purple .material-input:before {
  background-color: #9c27b0;
}
.form-group-material-purple-50 .material-input:before {
  background-color: #f3e5f5;
}
.form-group-material-purple-100 .material-input:before {
  background-color: #e1bee7;
}
.form-group-material-purple-200 .material-input:before {
  background-color: #ce93d8;
}
.form-group-material-purple-300 .material-input:before {
  background-color: #ba68c8;
}
.form-group-material-purple-400 .material-input:before {
  background-color: #ab47bc;
}
.form-group-material-purple-500 .material-input:before {
  background-color: #9c27b0;
}
.form-group-material-purple-600 .material-input:before {
  background-color: #8e24aa;
}
.form-group-material-purple-700 .material-input:before {
  background-color: #7b1fa2;
}
.form-group-material-purple-800 .material-input:before {
  background-color: #6a1b9a;
}
.form-group-material-purple-900 .material-input:before {
  background-color: #4a148c;
}
.form-group-material-purple-A100 .material-input:before {
  background-color: #ea80fc;
}
.form-group-material-purple-A200 .material-input:before {
  background-color: #e040fb;
}
.form-group-material-purple-A400 .material-input:before {
  background-color: #d500f9;
}
.form-group-material-purple-A700 .material-input:before {
  background-color: #aa00ff;
}
.form-group-material-deep-purple .material-input:before {
  background-color: #673ab7;
}
.form-group-material-deep-purple-50 .material-input:before {
  background-color: #ede7f6;
}
.form-group-material-deep-purple-100 .material-input:before {
  background-color: #d1c4e9;
}
.form-group-material-deep-purple-200 .material-input:before {
  background-color: #b39ddb;
}
.form-group-material-deep-purple-300 .material-input:before {
  background-color: #9575cd;
}
.form-group-material-deep-purple-400 .material-input:before {
  background-color: #7e57c2;
}
.form-group-material-deep-purple-500 .material-input:before {
  background-color: #673ab7;
}
.form-group-material-deep-purple-600 .material-input:before {
  background-color: #5e35b1;
}
.form-group-material-deep-purple-700 .material-input:before {
  background-color: #512da8;
}
.form-group-material-deep-purple-800 .material-input:before {
  background-color: #4527a0;
}
.form-group-material-deep-purple-900 .material-input:before {
  background-color: #311b92;
}
.form-group-material-deep-purple-A100 .material-input:before {
  background-color: #b388ff;
}
.form-group-material-deep-purple-A200 .material-input:before {
  background-color: #7c4dff;
}
.form-group-material-deep-purple-A400 .material-input:before {
  background-color: #651fff;
}
.form-group-material-deep-purple-A700 .material-input:before {
  background-color: #6200ea;
}
.form-group-material-indigo .material-input:before {
  background-color: #3f51b5;
}
.form-group-material-indigo-50 .material-input:before {
  background-color: #e8eaf6;
}
.form-group-material-indigo-100 .material-input:before {
  background-color: #c5cae9;
}
.form-group-material-indigo-200 .material-input:before {
  background-color: #9fa8da;
}
.form-group-material-indigo-300 .material-input:before {
  background-color: #7986cb;
}
.form-group-material-indigo-400 .material-input:before {
  background-color: #5c6bc0;
}
.form-group-material-indigo-500 .material-input:before {
  background-color: #3f51b5;
}
.form-group-material-indigo-600 .material-input:before {
  background-color: #3949ab;
}
.form-group-material-indigo-700 .material-input:before {
  background-color: #303f9f;
}
.form-group-material-indigo-800 .material-input:before {
  background-color: #283593;
}
.form-group-material-indigo-900 .material-input:before {
  background-color: #1a237e;
}
.form-group-material-indigo-A100 .material-input:before {
  background-color: #8c9eff;
}
.form-group-material-indigo-A200 .material-input:before {
  background-color: #536dfe;
}
.form-group-material-indigo-A400 .material-input:before {
  background-color: #3d5afe;
}
.form-group-material-indigo-A700 .material-input:before {
  background-color: #304ffe;
}
.form-group-material-blue .material-input:before {
  background-color: #2196f3;
}
.form-group-material-blue-50 .material-input:before {
  background-color: #e3f2fd;
}
.form-group-material-blue-100 .material-input:before {
  background-color: #bbdefb;
}
.form-group-material-blue-200 .material-input:before {
  background-color: #90caf9;
}
.form-group-material-blue-300 .material-input:before {
  background-color: #64b5f6;
}
.form-group-material-blue-400 .material-input:before {
  background-color: #42a5f5;
}
.form-group-material-blue-500 .material-input:before {
  background-color: #2196f3;
}
.form-group-material-blue-600 .material-input:before {
  background-color: #1e88e5;
}
.form-group-material-blue-700 .material-input:before {
  background-color: #1976d2;
}
.form-group-material-blue-800 .material-input:before {
  background-color: #1565c0;
}
.form-group-material-blue-900 .material-input:before {
  background-color: #0d47a1;
}
.form-group-material-blue-A100 .material-input:before {
  background-color: #82b1ff;
}
.form-group-material-blue-A200 .material-input:before {
  background-color: #448aff;
}
.form-group-material-blue-A400 .material-input:before {
  background-color: #2979ff;
}
.form-group-material-blue-A700 .material-input:before {
  background-color: #2962ff;
}
.form-group-material-light-blue .material-input:before {
  background-color: #03a9f4;
}
.form-group-material-light-blue-50 .material-input:before {
  background-color: #e1f5fe;
}
.form-group-material-light-blue-100 .material-input:before {
  background-color: #b3e5fc;
}
.form-group-material-light-blue-200 .material-input:before {
  background-color: #81d4fa;
}
.form-group-material-light-blue-300 .material-input:before {
  background-color: #4fc3f7;
}
.form-group-material-light-blue-400 .material-input:before {
  background-color: #29b6f6;
}
.form-group-material-light-blue-500 .material-input:before {
  background-color: #03a9f4;
}
.form-group-material-light-blue-600 .material-input:before {
  background-color: #039be5;
}
.form-group-material-light-blue-700 .material-input:before {
  background-color: #0288d1;
}
.form-group-material-light-blue-800 .material-input:before {
  background-color: #0277bd;
}
.form-group-material-light-blue-900 .material-input:before {
  background-color: #01579b;
}
.form-group-material-light-blue-A100 .material-input:before {
  background-color: #80d8ff;
}
.form-group-material-light-blue-A200 .material-input:before {
  background-color: #40c4ff;
}
.form-group-material-light-blue-A400 .material-input:before {
  background-color: #00b0ff;
}
.form-group-material-light-blue-A700 .material-input:before {
  background-color: #0091ea;
}
.form-group-material-cyan .material-input:before {
  background-color: #00bcd4;
}
.form-group-material-cyan-50 .material-input:before {
  background-color: #e0f7fa;
}
.form-group-material-cyan-100 .material-input:before {
  background-color: #b2ebf2;
}
.form-group-material-cyan-200 .material-input:before {
  background-color: #80deea;
}
.form-group-material-cyan-300 .material-input:before {
  background-color: #4dd0e1;
}
.form-group-material-cyan-400 .material-input:before {
  background-color: #26c6da;
}
.form-group-material-cyan-500 .material-input:before {
  background-color: #00bcd4;
}
.form-group-material-cyan-600 .material-input:before {
  background-color: #00acc1;
}
.form-group-material-cyan-700 .material-input:before {
  background-color: #0097a7;
}
.form-group-material-cyan-800 .material-input:before {
  background-color: #00838f;
}
.form-group-material-cyan-900 .material-input:before {
  background-color: #006064;
}
.form-group-material-cyan-A100 .material-input:before {
  background-color: #84ffff;
}
.form-group-material-cyan-A200 .material-input:before {
  background-color: #18ffff;
}
.form-group-material-cyan-A400 .material-input:before {
  background-color: #00e5ff;
}
.form-group-material-cyan-A700 .material-input:before {
  background-color: #00b8d4;
}
.form-group-material-teal .material-input:before {
  background-color: #009688;
}
.form-group-material-teal-50 .material-input:before {
  background-color: #e0f2f1;
}
.form-group-material-teal-100 .material-input:before {
  background-color: #b2dfdb;
}
.form-group-material-teal-200 .material-input:before {
  background-color: #80cbc4;
}
.form-group-material-teal-300 .material-input:before {
  background-color: #4db6ac;
}
.form-group-material-teal-400 .material-input:before {
  background-color: #26a69a;
}
.form-group-material-teal-500 .material-input:before {
  background-color: #009688;
}
.form-group-material-teal-600 .material-input:before {
  background-color: #00897b;
}
.form-group-material-teal-700 .material-input:before {
  background-color: #00796b;
}
.form-group-material-teal-800 .material-input:before {
  background-color: #00695c;
}
.form-group-material-teal-900 .material-input:before {
  background-color: #004d40;
}
.form-group-material-teal-A100 .material-input:before {
  background-color: #a7ffeb;
}
.form-group-material-teal-A200 .material-input:before {
  background-color: #64ffda;
}
.form-group-material-teal-A400 .material-input:before {
  background-color: #1de9b6;
}
.form-group-material-teal-A700 .material-input:before {
  background-color: #00bfa5;
}
.form-group-material-green .material-input:before {
  background-color: #4caf50;
}
.form-group-material-green-50 .material-input:before {
  background-color: #e8f5e9;
}
.form-group-material-green-100 .material-input:before {
  background-color: #c8e6c9;
}
.form-group-material-green-200 .material-input:before {
  background-color: #a5d6a7;
}
.form-group-material-green-300 .material-input:before {
  background-color: #81c784;
}
.form-group-material-green-400 .material-input:before {
  background-color: #66bb6a;
}
.form-group-material-green-500 .material-input:before {
  background-color: #4caf50;
}
.form-group-material-green-600 .material-input:before {
  background-color: #43a047;
}
.form-group-material-green-700 .material-input:before {
  background-color: #388e3c;
}
.form-group-material-green-800 .material-input:before {
  background-color: #2e7d32;
}
.form-group-material-green-900 .material-input:before {
  background-color: #1b5e20;
}
.form-group-material-green-A100 .material-input:before {
  background-color: #b9f6ca;
}
.form-group-material-green-A200 .material-input:before {
  background-color: #69f0ae;
}
.form-group-material-green-A400 .material-input:before {
  background-color: #00e676;
}
.form-group-material-green-A700 .material-input:before {
  background-color: #00c853;
}
.form-group-material-light-green .material-input:before {
  background-color: #8bc34a;
}
.form-group-material-light-green-50 .material-input:before {
  background-color: #f1f8e9;
}
.form-group-material-light-green-100 .material-input:before {
  background-color: #dcedc8;
}
.form-group-material-light-green-200 .material-input:before {
  background-color: #c5e1a5;
}
.form-group-material-light-green-300 .material-input:before {
  background-color: #aed581;
}
.form-group-material-light-green-400 .material-input:before {
  background-color: #9ccc65;
}
.form-group-material-light-green-500 .material-input:before {
  background-color: #8bc34a;
}
.form-group-material-light-green-600 .material-input:before {
  background-color: #7cb342;
}
.form-group-material-light-green-700 .material-input:before {
  background-color: #689f38;
}
.form-group-material-light-green-800 .material-input:before {
  background-color: #558b2f;
}
.form-group-material-light-green-900 .material-input:before {
  background-color: #33691e;
}
.form-group-material-light-green-A100 .material-input:before {
  background-color: #ccff90;
}
.form-group-material-light-green-A200 .material-input:before {
  background-color: #b2ff59;
}
.form-group-material-light-green-A400 .material-input:before {
  background-color: #76ff03;
}
.form-group-material-light-green-A700 .material-input:before {
  background-color: #64dd17;
}
.form-group-material-lime .material-input:before {
  background-color: #cddc39;
}
.form-group-material-lime-50 .material-input:before {
  background-color: #f9fbe7;
}
.form-group-material-lime-100 .material-input:before {
  background-color: #f0f4c3;
}
.form-group-material-lime-200 .material-input:before {
  background-color: #e6ee9c;
}
.form-group-material-lime-300 .material-input:before {
  background-color: #dce775;
}
.form-group-material-lime-400 .material-input:before {
  background-color: #d4e157;
}
.form-group-material-lime-500 .material-input:before {
  background-color: #cddc39;
}
.form-group-material-lime-600 .material-input:before {
  background-color: #c0ca33;
}
.form-group-material-lime-700 .material-input:before {
  background-color: #afb42b;
}
.form-group-material-lime-800 .material-input:before {
  background-color: #9e9d24;
}
.form-group-material-lime-900 .material-input:before {
  background-color: #827717;
}
.form-group-material-lime-A100 .material-input:before {
  background-color: #f4ff81;
}
.form-group-material-lime-A200 .material-input:before {
  background-color: #eeff41;
}
.form-group-material-lime-A400 .material-input:before {
  background-color: #c6ff00;
}
.form-group-material-lime-A700 .material-input:before {
  background-color: #aeea00;
}
.form-group-material-yellow .material-input:before {
  background-color: #ffeb3b;
}
.form-group-material-yellow-50 .material-input:before {
  background-color: #fffde7;
}
.form-group-material-yellow-100 .material-input:before {
  background-color: #fff9c4;
}
.form-group-material-yellow-200 .material-input:before {
  background-color: #fff59d;
}
.form-group-material-yellow-300 .material-input:before {
  background-color: #fff176;
}
.form-group-material-yellow-400 .material-input:before {
  background-color: #ffee58;
}
.form-group-material-yellow-500 .material-input:before {
  background-color: #ffeb3b;
}
.form-group-material-yellow-600 .material-input:before {
  background-color: #fdd835;
}
.form-group-material-yellow-700 .material-input:before {
  background-color: #fbc02d;
}
.form-group-material-yellow-800 .material-input:before {
  background-color: #f9a825;
}
.form-group-material-yellow-900 .material-input:before {
  background-color: #f57f17;
}
.form-group-material-yellow-A100 .material-input:before {
  background-color: #ffff8d;
}
.form-group-material-yellow-A200 .material-input:before {
  background-color: #ffff00;
}
.form-group-material-yellow-A400 .material-input:before {
  background-color: #ffea00;
}
.form-group-material-yellow-A700 .material-input:before {
  background-color: #ffd600;
}
.form-group-material-amber .material-input:before {
  background-color: #ffc107;
}
.form-group-material-amber-50 .material-input:before {
  background-color: #fff8e1;
}
.form-group-material-amber-100 .material-input:before {
  background-color: #ffecb3;
}
.form-group-material-amber-200 .material-input:before {
  background-color: #ffe082;
}
.form-group-material-amber-300 .material-input:before {
  background-color: #ffd54f;
}
.form-group-material-amber-400 .material-input:before {
  background-color: #ffca28;
}
.form-group-material-amber-500 .material-input:before {
  background-color: #ffc107;
}
.form-group-material-amber-600 .material-input:before {
  background-color: #ffb300;
}
.form-group-material-amber-700 .material-input:before {
  background-color: #ffa000;
}
.form-group-material-amber-800 .material-input:before {
  background-color: #ff8f00;
}
.form-group-material-amber-900 .material-input:before {
  background-color: #ff6f00;
}
.form-group-material-amber-A100 .material-input:before {
  background-color: #ffe57f;
}
.form-group-material-amber-A200 .material-input:before {
  background-color: #ffd740;
}
.form-group-material-amber-A400 .material-input:before {
  background-color: #ffc400;
}
.form-group-material-amber-A700 .material-input:before {
  background-color: #ffab00;
}
.form-group-material-orange .material-input:before {
  background-color: #ff9800;
}
.form-group-material-orange-50 .material-input:before {
  background-color: #fff3e0;
}
.form-group-material-orange-100 .material-input:before {
  background-color: #ffe0b2;
}
.form-group-material-orange-200 .material-input:before {
  background-color: #ffcc80;
}
.form-group-material-orange-300 .material-input:before {
  background-color: #ffb74d;
}
.form-group-material-orange-400 .material-input:before {
  background-color: #ffa726;
}
.form-group-material-orange-500 .material-input:before {
  background-color: #ff9800;
}
.form-group-material-orange-600 .material-input:before {
  background-color: #fb8c00;
}
.form-group-material-orange-700 .material-input:before {
  background-color: #f57c00;
}
.form-group-material-orange-800 .material-input:before {
  background-color: #ef6c00;
}
.form-group-material-orange-900 .material-input:before {
  background-color: #e65100;
}
.form-group-material-orange-A100 .material-input:before {
  background-color: #ffd180;
}
.form-group-material-orange-A200 .material-input:before {
  background-color: #ffab40;
}
.form-group-material-orange-A400 .material-input:before {
  background-color: #ff9100;
}
.form-group-material-orange-A700 .material-input:before {
  background-color: #ff6d00;
}
.form-group-material-deep-orange .material-input:before {
  background-color: #ff5722;
}
.form-group-material-deep-orange-50 .material-input:before {
  background-color: #fbe9e7;
}
.form-group-material-deep-orange-100 .material-input:before {
  background-color: #ffccbc;
}
.form-group-material-deep-orange-200 .material-input:before {
  background-color: #ffab91;
}
.form-group-material-deep-orange-300 .material-input:before {
  background-color: #ff8a65;
}
.form-group-material-deep-orange-400 .material-input:before {
  background-color: #ff7043;
}
.form-group-material-deep-orange-500 .material-input:before {
  background-color: #ff5722;
}
.form-group-material-deep-orange-600 .material-input:before {
  background-color: #f4511e;
}
.form-group-material-deep-orange-700 .material-input:before {
  background-color: #e64a19;
}
.form-group-material-deep-orange-800 .material-input:before {
  background-color: #d84315;
}
.form-group-material-deep-orange-900 .material-input:before {
  background-color: #bf360c;
}
.form-group-material-deep-orange-A100 .material-input:before {
  background-color: #ff9e80;
}
.form-group-material-deep-orange-A200 .material-input:before {
  background-color: #ff6e40;
}
.form-group-material-deep-orange-A400 .material-input:before {
  background-color: #ff3d00;
}
.form-group-material-deep-orange-A700 .material-input:before {
  background-color: #dd2c00;
}
.form-group-material-brown .material-input:before {
  background-color: #795548;
}
.form-group-material-brown-50 .material-input:before {
  background-color: #efebe9;
}
.form-group-material-brown-100 .material-input:before {
  background-color: #d7ccc8;
}
.form-group-material-brown-200 .material-input:before {
  background-color: #bcaaa4;
}
.form-group-material-brown-300 .material-input:before {
  background-color: #a1887f;
}
.form-group-material-brown-400 .material-input:before {
  background-color: #8d6e63;
}
.form-group-material-brown-500 .material-input:before {
  background-color: #795548;
}
.form-group-material-brown-600 .material-input:before {
  background-color: #6d4c41;
}
.form-group-material-brown-700 .material-input:before {
  background-color: #5d4037;
}
.form-group-material-brown-800 .material-input:before {
  background-color: #4e342e;
}
.form-group-material-brown-900 .material-input:before {
  background-color: #3e2723;
}
.form-group-material-brown-A100 .material-input:before {
  background-color: #d7ccc8;
}
.form-group-material-brown-A200 .material-input:before {
  background-color: #bcaaa4;
}
.form-group-material-brown-A400 .material-input:before {
  background-color: #8d6e63;
}
.form-group-material-brown-A700 .material-input:before {
  background-color: #5d4037;
}
.form-group-material-grey .material-input:before {
  background-color: #9e9e9e;
}
.form-group-material-grey-50 .material-input:before {
  background-color: #fafafa;
}
.form-group-material-grey-100 .material-input:before {
  background-color: #f5f5f5;
}
.form-group-material-grey-200 .material-input:before {
  background-color: #eeeeee;
}
.form-group-material-grey-300 .material-input:before {
  background-color: #e0e0e0;
}
.form-group-material-grey-400 .material-input:before {
  background-color: #bdbdbd;
}
.form-group-material-grey-500 .material-input:before {
  background-color: #9e9e9e;
}
.form-group-material-grey-600 .material-input:before {
  background-color: #757575;
}
.form-group-material-grey-700 .material-input:before {
  background-color: #616161;
}
.form-group-material-grey-800 .material-input:before {
  background-color: #424242;
}
.form-group-material-grey-900 .material-input:before {
  background-color: #212121;
}
.form-group-material-grey-A100 .material-input:before {
  background-color: #f5f5f5;
}
.form-group-material-grey-A200 .material-input:before {
  background-color: #eeeeee;
}
.form-group-material-grey-A400 .material-input:before {
  background-color: #bdbdbd;
}
.form-group-material-grey-A700 .material-input:before {
  background-color: #616161;
}
.form-group-material-blue .material-input:before {
  background-color: #607d8b;
}
.form-group-material-blue-50 .material-input:before {
  background-color: #eceff1;
}
.form-group-material-blue-100 .material-input:before {
  background-color: #cfd8dc;
}
.form-group-material-blue-200 .material-input:before {
  background-color: #b0bec5;
}
.form-group-material-blue-300 .material-input:before {
  background-color: #90a4ae;
}
.form-group-material-blue-400 .material-input:before {
  background-color: #78909c;
}
.form-group-material-blue-500 .material-input:before {
  background-color: #607d8b;
}
.form-group-material-blue-600 .material-input:before {
  background-color: #546e7a;
}
.form-group-material-blue-700 .material-input:before {
  background-color: #455a64;
}
.form-group-material-blue-800 .material-input:before {
  background-color: #37474f;
}
.form-group-material-blue-900 .material-input:before {
  background-color: #263238;
}
.form-group-material-blue-A100 .material-input:before {
  background-color: #cfd8dc;
}
.form-group-material-blue-A200 .material-input:before {
  background-color: #b0bec5;
}
.form-group-material-blue-A400 .material-input:before {
  background-color: #78909c;
}
.form-group-material-blue-A700 .material-input:before {
  background-color: #455a64;
}
.form-group input.form-control:focus ~ .material-input:after,
.form-group-default input.form-control:focus ~ .material-input:after {
  background-color: #009587;
}
.form-group-primary input.form-control:focus ~ .material-input:after {
  background-color: #009587;
}
.form-group-success input.form-control:focus ~ .material-input:after {
  background-color: #0f9d58;
}
.form-group-info input.form-control:focus ~ .material-input:after {
  background-color: #03a9f4;
}
.form-group-warning input.form-control:focus ~ .material-input:after {
  background-color: #ff5722;
}
.form-group-danger input.form-control:focus ~ .material-input:after {
  background-color: #f44336;
}
.form-group-material-red input.form-control:focus ~ .material-input:after {
  background-color: #f44336;
}
.form-group-material-red-50 input.form-control:focus ~ .material-input:after {
  background-color: #ffebee;
}
.form-group-material-red-100 input.form-control:focus ~ .material-input:after {
  background-color: #ffcdd2;
}
.form-group-material-red-200 input.form-control:focus ~ .material-input:after {
  background-color: #ef9a9a;
}
.form-group-material-red-300 input.form-control:focus ~ .material-input:after {
  background-color: #e57373;
}
.form-group-material-red-400 input.form-control:focus ~ .material-input:after {
  background-color: #ef5350;
}
.form-group-material-red-500 input.form-control:focus ~ .material-input:after {
  background-color: #f44336;
}
.form-group-material-red-600 input.form-control:focus ~ .material-input:after {
  background-color: #e53935;
}
.form-group-material-red-700 input.form-control:focus ~ .material-input:after {
  background-color: #d32f2f;
}
.form-group-material-red-800 input.form-control:focus ~ .material-input:after {
  background-color: #c62828;
}
.form-group-material-red-900 input.form-control:focus ~ .material-input:after {
  background-color: #b71c1c;
}
.form-group-material-red-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ff8a80;
}
.form-group-material-red-A200 input.form-control:focus ~ .material-input:after {
  background-color: #ff5252;
}
.form-group-material-red-A400 input.form-control:focus ~ .material-input:after {
  background-color: #ff1744;
}
.form-group-material-red-A700 input.form-control:focus ~ .material-input:after {
  background-color: #d50000;
}
.form-group-material-pink input.form-control:focus ~ .material-input:after {
  background-color: #e91e63;
}
.form-group-material-pink-50 input.form-control:focus ~ .material-input:after {
  background-color: #fce4ec;
}
.form-group-material-pink-100 input.form-control:focus ~ .material-input:after {
  background-color: #f8bbd0;
}
.form-group-material-pink-200 input.form-control:focus ~ .material-input:after {
  background-color: #f48fb1;
}
.form-group-material-pink-300 input.form-control:focus ~ .material-input:after {
  background-color: #f06292;
}
.form-group-material-pink-400 input.form-control:focus ~ .material-input:after {
  background-color: #ec407a;
}
.form-group-material-pink-500 input.form-control:focus ~ .material-input:after {
  background-color: #e91e63;
}
.form-group-material-pink-600 input.form-control:focus ~ .material-input:after {
  background-color: #d81b60;
}
.form-group-material-pink-700 input.form-control:focus ~ .material-input:after {
  background-color: #c2185b;
}
.form-group-material-pink-800 input.form-control:focus ~ .material-input:after {
  background-color: #ad1457;
}
.form-group-material-pink-900 input.form-control:focus ~ .material-input:after {
  background-color: #880e4f;
}
.form-group-material-pink-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ff80ab;
}
.form-group-material-pink-A200 input.form-control:focus ~ .material-input:after {
  background-color: #ff4081;
}
.form-group-material-pink-A400 input.form-control:focus ~ .material-input:after {
  background-color: #f50057;
}
.form-group-material-pink-A700 input.form-control:focus ~ .material-input:after {
  background-color: #c51162;
}
.form-group-material-purple input.form-control:focus ~ .material-input:after {
  background-color: #9c27b0;
}
.form-group-material-purple-50 input.form-control:focus ~ .material-input:after {
  background-color: #f3e5f5;
}
.form-group-material-purple-100 input.form-control:focus ~ .material-input:after {
  background-color: #e1bee7;
}
.form-group-material-purple-200 input.form-control:focus ~ .material-input:after {
  background-color: #ce93d8;
}
.form-group-material-purple-300 input.form-control:focus ~ .material-input:after {
  background-color: #ba68c8;
}
.form-group-material-purple-400 input.form-control:focus ~ .material-input:after {
  background-color: #ab47bc;
}
.form-group-material-purple-500 input.form-control:focus ~ .material-input:after {
  background-color: #9c27b0;
}
.form-group-material-purple-600 input.form-control:focus ~ .material-input:after {
  background-color: #8e24aa;
}
.form-group-material-purple-700 input.form-control:focus ~ .material-input:after {
  background-color: #7b1fa2;
}
.form-group-material-purple-800 input.form-control:focus ~ .material-input:after {
  background-color: #6a1b9a;
}
.form-group-material-purple-900 input.form-control:focus ~ .material-input:after {
  background-color: #4a148c;
}
.form-group-material-purple-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ea80fc;
}
.form-group-material-purple-A200 input.form-control:focus ~ .material-input:after {
  background-color: #e040fb;
}
.form-group-material-purple-A400 input.form-control:focus ~ .material-input:after {
  background-color: #d500f9;
}
.form-group-material-purple-A700 input.form-control:focus ~ .material-input:after {
  background-color: #aa00ff;
}
.form-group-material-deep-purple input.form-control:focus ~ .material-input:after {
  background-color: #673ab7;
}
.form-group-material-deep-purple-50 input.form-control:focus ~ .material-input:after {
  background-color: #ede7f6;
}
.form-group-material-deep-purple-100 input.form-control:focus ~ .material-input:after {
  background-color: #d1c4e9;
}
.form-group-material-deep-purple-200 input.form-control:focus ~ .material-input:after {
  background-color: #b39ddb;
}
.form-group-material-deep-purple-300 input.form-control:focus ~ .material-input:after {
  background-color: #9575cd;
}
.form-group-material-deep-purple-400 input.form-control:focus ~ .material-input:after {
  background-color: #7e57c2;
}
.form-group-material-deep-purple-500 input.form-control:focus ~ .material-input:after {
  background-color: #673ab7;
}
.form-group-material-deep-purple-600 input.form-control:focus ~ .material-input:after {
  background-color: #5e35b1;
}
.form-group-material-deep-purple-700 input.form-control:focus ~ .material-input:after {
  background-color: #512da8;
}
.form-group-material-deep-purple-800 input.form-control:focus ~ .material-input:after {
  background-color: #4527a0;
}
.form-group-material-deep-purple-900 input.form-control:focus ~ .material-input:after {
  background-color: #311b92;
}
.form-group-material-deep-purple-A100 input.form-control:focus ~ .material-input:after {
  background-color: #b388ff;
}
.form-group-material-deep-purple-A200 input.form-control:focus ~ .material-input:after {
  background-color: #7c4dff;
}
.form-group-material-deep-purple-A400 input.form-control:focus ~ .material-input:after {
  background-color: #651fff;
}
.form-group-material-deep-purple-A700 input.form-control:focus ~ .material-input:after {
  background-color: #6200ea;
}
.form-group-material-indigo input.form-control:focus ~ .material-input:after {
  background-color: #3f51b5;
}
.form-group-material-indigo-50 input.form-control:focus ~ .material-input:after {
  background-color: #e8eaf6;
}
.form-group-material-indigo-100 input.form-control:focus ~ .material-input:after {
  background-color: #c5cae9;
}
.form-group-material-indigo-200 input.form-control:focus ~ .material-input:after {
  background-color: #9fa8da;
}
.form-group-material-indigo-300 input.form-control:focus ~ .material-input:after {
  background-color: #7986cb;
}
.form-group-material-indigo-400 input.form-control:focus ~ .material-input:after {
  background-color: #5c6bc0;
}
.form-group-material-indigo-500 input.form-control:focus ~ .material-input:after {
  background-color: #3f51b5;
}
.form-group-material-indigo-600 input.form-control:focus ~ .material-input:after {
  background-color: #3949ab;
}
.form-group-material-indigo-700 input.form-control:focus ~ .material-input:after {
  background-color: #303f9f;
}
.form-group-material-indigo-800 input.form-control:focus ~ .material-input:after {
  background-color: #283593;
}
.form-group-material-indigo-900 input.form-control:focus ~ .material-input:after {
  background-color: #1a237e;
}
.form-group-material-indigo-A100 input.form-control:focus ~ .material-input:after {
  background-color: #8c9eff;
}
.form-group-material-indigo-A200 input.form-control:focus ~ .material-input:after {
  background-color: #536dfe;
}
.form-group-material-indigo-A400 input.form-control:focus ~ .material-input:after {
  background-color: #3d5afe;
}
.form-group-material-indigo-A700 input.form-control:focus ~ .material-input:after {
  background-color: #304ffe;
}
.form-group-material-blue input.form-control:focus ~ .material-input:after {
  background-color: #2196f3;
}
.form-group-material-blue-50 input.form-control:focus ~ .material-input:after {
  background-color: #e3f2fd;
}
.form-group-material-blue-100 input.form-control:focus ~ .material-input:after {
  background-color: #bbdefb;
}
.form-group-material-blue-200 input.form-control:focus ~ .material-input:after {
  background-color: #90caf9;
}
.form-group-material-blue-300 input.form-control:focus ~ .material-input:after {
  background-color: #64b5f6;
}
.form-group-material-blue-400 input.form-control:focus ~ .material-input:after {
  background-color: #42a5f5;
}
.form-group-material-blue-500 input.form-control:focus ~ .material-input:after {
  background-color: #2196f3;
}
.form-group-material-blue-600 input.form-control:focus ~ .material-input:after {
  background-color: #1e88e5;
}
.form-group-material-blue-700 input.form-control:focus ~ .material-input:after {
  background-color: #1976d2;
}
.form-group-material-blue-800 input.form-control:focus ~ .material-input:after {
  background-color: #1565c0;
}
.form-group-material-blue-900 input.form-control:focus ~ .material-input:after {
  background-color: #0d47a1;
}
.form-group-material-blue-A100 input.form-control:focus ~ .material-input:after {
  background-color: #82b1ff;
}
.form-group-material-blue-A200 input.form-control:focus ~ .material-input:after {
  background-color: #448aff;
}
.form-group-material-blue-A400 input.form-control:focus ~ .material-input:after {
  background-color: #2979ff;
}
.form-group-material-blue-A700 input.form-control:focus ~ .material-input:after {
  background-color: #2962ff;
}
.form-group-material-light-blue input.form-control:focus ~ .material-input:after {
  background-color: #03a9f4;
}
.form-group-material-light-blue-50 input.form-control:focus ~ .material-input:after {
  background-color: #e1f5fe;
}
.form-group-material-light-blue-100 input.form-control:focus ~ .material-input:after {
  background-color: #b3e5fc;
}
.form-group-material-light-blue-200 input.form-control:focus ~ .material-input:after {
  background-color: #81d4fa;
}
.form-group-material-light-blue-300 input.form-control:focus ~ .material-input:after {
  background-color: #4fc3f7;
}
.form-group-material-light-blue-400 input.form-control:focus ~ .material-input:after {
  background-color: #29b6f6;
}
.form-group-material-light-blue-500 input.form-control:focus ~ .material-input:after {
  background-color: #03a9f4;
}
.form-group-material-light-blue-600 input.form-control:focus ~ .material-input:after {
  background-color: #039be5;
}
.form-group-material-light-blue-700 input.form-control:focus ~ .material-input:after {
  background-color: #0288d1;
}
.form-group-material-light-blue-800 input.form-control:focus ~ .material-input:after {
  background-color: #0277bd;
}
.form-group-material-light-blue-900 input.form-control:focus ~ .material-input:after {
  background-color: #01579b;
}
.form-group-material-light-blue-A100 input.form-control:focus ~ .material-input:after {
  background-color: #80d8ff;
}
.form-group-material-light-blue-A200 input.form-control:focus ~ .material-input:after {
  background-color: #40c4ff;
}
.form-group-material-light-blue-A400 input.form-control:focus ~ .material-input:after {
  background-color: #00b0ff;
}
.form-group-material-light-blue-A700 input.form-control:focus ~ .material-input:after {
  background-color: #0091ea;
}
.form-group-material-cyan input.form-control:focus ~ .material-input:after {
  background-color: #00bcd4;
}
.form-group-material-cyan-50 input.form-control:focus ~ .material-input:after {
  background-color: #e0f7fa;
}
.form-group-material-cyan-100 input.form-control:focus ~ .material-input:after {
  background-color: #b2ebf2;
}
.form-group-material-cyan-200 input.form-control:focus ~ .material-input:after {
  background-color: #80deea;
}
.form-group-material-cyan-300 input.form-control:focus ~ .material-input:after {
  background-color: #4dd0e1;
}
.form-group-material-cyan-400 input.form-control:focus ~ .material-input:after {
  background-color: #26c6da;
}
.form-group-material-cyan-500 input.form-control:focus ~ .material-input:after {
  background-color: #00bcd4;
}
.form-group-material-cyan-600 input.form-control:focus ~ .material-input:after {
  background-color: #00acc1;
}
.form-group-material-cyan-700 input.form-control:focus ~ .material-input:after {
  background-color: #0097a7;
}
.form-group-material-cyan-800 input.form-control:focus ~ .material-input:after {
  background-color: #00838f;
}
.form-group-material-cyan-900 input.form-control:focus ~ .material-input:after {
  background-color: #006064;
}
.form-group-material-cyan-A100 input.form-control:focus ~ .material-input:after {
  background-color: #84ffff;
}
.form-group-material-cyan-A200 input.form-control:focus ~ .material-input:after {
  background-color: #18ffff;
}
.form-group-material-cyan-A400 input.form-control:focus ~ .material-input:after {
  background-color: #00e5ff;
}
.form-group-material-cyan-A700 input.form-control:focus ~ .material-input:after {
  background-color: #00b8d4;
}
.form-group-material-teal input.form-control:focus ~ .material-input:after {
  background-color: #009688;
}
.form-group-material-teal-50 input.form-control:focus ~ .material-input:after {
  background-color: #e0f2f1;
}
.form-group-material-teal-100 input.form-control:focus ~ .material-input:after {
  background-color: #b2dfdb;
}
.form-group-material-teal-200 input.form-control:focus ~ .material-input:after {
  background-color: #80cbc4;
}
.form-group-material-teal-300 input.form-control:focus ~ .material-input:after {
  background-color: #4db6ac;
}
.form-group-material-teal-400 input.form-control:focus ~ .material-input:after {
  background-color: #26a69a;
}
.form-group-material-teal-500 input.form-control:focus ~ .material-input:after {
  background-color: #009688;
}
.form-group-material-teal-600 input.form-control:focus ~ .material-input:after {
  background-color: #00897b;
}
.form-group-material-teal-700 input.form-control:focus ~ .material-input:after {
  background-color: #00796b;
}
.form-group-material-teal-800 input.form-control:focus ~ .material-input:after {
  background-color: #00695c;
}
.form-group-material-teal-900 input.form-control:focus ~ .material-input:after {
  background-color: #004d40;
}
.form-group-material-teal-A100 input.form-control:focus ~ .material-input:after {
  background-color: #a7ffeb;
}
.form-group-material-teal-A200 input.form-control:focus ~ .material-input:after {
  background-color: #64ffda;
}
.form-group-material-teal-A400 input.form-control:focus ~ .material-input:after {
  background-color: #1de9b6;
}
.form-group-material-teal-A700 input.form-control:focus ~ .material-input:after {
  background-color: #00bfa5;
}
.form-group-material-green input.form-control:focus ~ .material-input:after {
  background-color: #4caf50;
}
.form-group-material-green-50 input.form-control:focus ~ .material-input:after {
  background-color: #e8f5e9;
}
.form-group-material-green-100 input.form-control:focus ~ .material-input:after {
  background-color: #c8e6c9;
}
.form-group-material-green-200 input.form-control:focus ~ .material-input:after {
  background-color: #a5d6a7;
}
.form-group-material-green-300 input.form-control:focus ~ .material-input:after {
  background-color: #81c784;
}
.form-group-material-green-400 input.form-control:focus ~ .material-input:after {
  background-color: #66bb6a;
}
.form-group-material-green-500 input.form-control:focus ~ .material-input:after {
  background-color: #4caf50;
}
.form-group-material-green-600 input.form-control:focus ~ .material-input:after {
  background-color: #43a047;
}
.form-group-material-green-700 input.form-control:focus ~ .material-input:after {
  background-color: #388e3c;
}
.form-group-material-green-800 input.form-control:focus ~ .material-input:after {
  background-color: #2e7d32;
}
.form-group-material-green-900 input.form-control:focus ~ .material-input:after {
  background-color: #1b5e20;
}
.form-group-material-green-A100 input.form-control:focus ~ .material-input:after {
  background-color: #b9f6ca;
}
.form-group-material-green-A200 input.form-control:focus ~ .material-input:after {
  background-color: #69f0ae;
}
.form-group-material-green-A400 input.form-control:focus ~ .material-input:after {
  background-color: #00e676;
}
.form-group-material-green-A700 input.form-control:focus ~ .material-input:after {
  background-color: #00c853;
}
.form-group-material-light-green input.form-control:focus ~ .material-input:after {
  background-color: #8bc34a;
}
.form-group-material-light-green-50 input.form-control:focus ~ .material-input:after {
  background-color: #f1f8e9;
}
.form-group-material-light-green-100 input.form-control:focus ~ .material-input:after {
  background-color: #dcedc8;
}
.form-group-material-light-green-200 input.form-control:focus ~ .material-input:after {
  background-color: #c5e1a5;
}
.form-group-material-light-green-300 input.form-control:focus ~ .material-input:after {
  background-color: #aed581;
}
.form-group-material-light-green-400 input.form-control:focus ~ .material-input:after {
  background-color: #9ccc65;
}
.form-group-material-light-green-500 input.form-control:focus ~ .material-input:after {
  background-color: #8bc34a;
}
.form-group-material-light-green-600 input.form-control:focus ~ .material-input:after {
  background-color: #7cb342;
}
.form-group-material-light-green-700 input.form-control:focus ~ .material-input:after {
  background-color: #689f38;
}
.form-group-material-light-green-800 input.form-control:focus ~ .material-input:after {
  background-color: #558b2f;
}
.form-group-material-light-green-900 input.form-control:focus ~ .material-input:after {
  background-color: #33691e;
}
.form-group-material-light-green-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ccff90;
}
.form-group-material-light-green-A200 input.form-control:focus ~ .material-input:after {
  background-color: #b2ff59;
}
.form-group-material-light-green-A400 input.form-control:focus ~ .material-input:after {
  background-color: #76ff03;
}
.form-group-material-light-green-A700 input.form-control:focus ~ .material-input:after {
  background-color: #64dd17;
}
.form-group-material-lime input.form-control:focus ~ .material-input:after {
  background-color: #cddc39;
}
.form-group-material-lime-50 input.form-control:focus ~ .material-input:after {
  background-color: #f9fbe7;
}
.form-group-material-lime-100 input.form-control:focus ~ .material-input:after {
  background-color: #f0f4c3;
}
.form-group-material-lime-200 input.form-control:focus ~ .material-input:after {
  background-color: #e6ee9c;
}
.form-group-material-lime-300 input.form-control:focus ~ .material-input:after {
  background-color: #dce775;
}
.form-group-material-lime-400 input.form-control:focus ~ .material-input:after {
  background-color: #d4e157;
}
.form-group-material-lime-500 input.form-control:focus ~ .material-input:after {
  background-color: #cddc39;
}
.form-group-material-lime-600 input.form-control:focus ~ .material-input:after {
  background-color: #c0ca33;
}
.form-group-material-lime-700 input.form-control:focus ~ .material-input:after {
  background-color: #afb42b;
}
.form-group-material-lime-800 input.form-control:focus ~ .material-input:after {
  background-color: #9e9d24;
}
.form-group-material-lime-900 input.form-control:focus ~ .material-input:after {
  background-color: #827717;
}
.form-group-material-lime-A100 input.form-control:focus ~ .material-input:after {
  background-color: #f4ff81;
}
.form-group-material-lime-A200 input.form-control:focus ~ .material-input:after {
  background-color: #eeff41;
}
.form-group-material-lime-A400 input.form-control:focus ~ .material-input:after {
  background-color: #c6ff00;
}
.form-group-material-lime-A700 input.form-control:focus ~ .material-input:after {
  background-color: #aeea00;
}
.form-group-material-yellow input.form-control:focus ~ .material-input:after {
  background-color: #ffeb3b;
}
.form-group-material-yellow-50 input.form-control:focus ~ .material-input:after {
  background-color: #fffde7;
}
.form-group-material-yellow-100 input.form-control:focus ~ .material-input:after {
  background-color: #fff9c4;
}
.form-group-material-yellow-200 input.form-control:focus ~ .material-input:after {
  background-color: #fff59d;
}
.form-group-material-yellow-300 input.form-control:focus ~ .material-input:after {
  background-color: #fff176;
}
.form-group-material-yellow-400 input.form-control:focus ~ .material-input:after {
  background-color: #ffee58;
}
.form-group-material-yellow-500 input.form-control:focus ~ .material-input:after {
  background-color: #ffeb3b;
}
.form-group-material-yellow-600 input.form-control:focus ~ .material-input:after {
  background-color: #fdd835;
}
.form-group-material-yellow-700 input.form-control:focus ~ .material-input:after {
  background-color: #fbc02d;
}
.form-group-material-yellow-800 input.form-control:focus ~ .material-input:after {
  background-color: #f9a825;
}
.form-group-material-yellow-900 input.form-control:focus ~ .material-input:after {
  background-color: #f57f17;
}
.form-group-material-yellow-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ffff8d;
}
.form-group-material-yellow-A200 input.form-control:focus ~ .material-input:after {
  background-color: #ffff00;
}
.form-group-material-yellow-A400 input.form-control:focus ~ .material-input:after {
  background-color: #ffea00;
}
.form-group-material-yellow-A700 input.form-control:focus ~ .material-input:after {
  background-color: #ffd600;
}
.form-group-material-amber input.form-control:focus ~ .material-input:after {
  background-color: #ffc107;
}
.form-group-material-amber-50 input.form-control:focus ~ .material-input:after {
  background-color: #fff8e1;
}
.form-group-material-amber-100 input.form-control:focus ~ .material-input:after {
  background-color: #ffecb3;
}
.form-group-material-amber-200 input.form-control:focus ~ .material-input:after {
  background-color: #ffe082;
}
.form-group-material-amber-300 input.form-control:focus ~ .material-input:after {
  background-color: #ffd54f;
}
.form-group-material-amber-400 input.form-control:focus ~ .material-input:after {
  background-color: #ffca28;
}
.form-group-material-amber-500 input.form-control:focus ~ .material-input:after {
  background-color: #ffc107;
}
.form-group-material-amber-600 input.form-control:focus ~ .material-input:after {
  background-color: #ffb300;
}
.form-group-material-amber-700 input.form-control:focus ~ .material-input:after {
  background-color: #ffa000;
}
.form-group-material-amber-800 input.form-control:focus ~ .material-input:after {
  background-color: #ff8f00;
}
.form-group-material-amber-900 input.form-control:focus ~ .material-input:after {
  background-color: #ff6f00;
}
.form-group-material-amber-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ffe57f;
}
.form-group-material-amber-A200 input.form-control:focus ~ .material-input:after {
  background-color: #ffd740;
}
.form-group-material-amber-A400 input.form-control:focus ~ .material-input:after {
  background-color: #ffc400;
}
.form-group-material-amber-A700 input.form-control:focus ~ .material-input:after {
  background-color: #ffab00;
}
.form-group-material-orange input.form-control:focus ~ .material-input:after {
  background-color: #ff9800;
}
.form-group-material-orange-50 input.form-control:focus ~ .material-input:after {
  background-color: #fff3e0;
}
.form-group-material-orange-100 input.form-control:focus ~ .material-input:after {
  background-color: #ffe0b2;
}
.form-group-material-orange-200 input.form-control:focus ~ .material-input:after {
  background-color: #ffcc80;
}
.form-group-material-orange-300 input.form-control:focus ~ .material-input:after {
  background-color: #ffb74d;
}
.form-group-material-orange-400 input.form-control:focus ~ .material-input:after {
  background-color: #ffa726;
}
.form-group-material-orange-500 input.form-control:focus ~ .material-input:after {
  background-color: #ff9800;
}
.form-group-material-orange-600 input.form-control:focus ~ .material-input:after {
  background-color: #fb8c00;
}
.form-group-material-orange-700 input.form-control:focus ~ .material-input:after {
  background-color: #f57c00;
}
.form-group-material-orange-800 input.form-control:focus ~ .material-input:after {
  background-color: #ef6c00;
}
.form-group-material-orange-900 input.form-control:focus ~ .material-input:after {
  background-color: #e65100;
}
.form-group-material-orange-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ffd180;
}
.form-group-material-orange-A200 input.form-control:focus ~ .material-input:after {
  background-color: #ffab40;
}
.form-group-material-orange-A400 input.form-control:focus ~ .material-input:after {
  background-color: #ff9100;
}
.form-group-material-orange-A700 input.form-control:focus ~ .material-input:after {
  background-color: #ff6d00;
}
.form-group-material-deep-orange input.form-control:focus ~ .material-input:after {
  background-color: #ff5722;
}
.form-group-material-deep-orange-50 input.form-control:focus ~ .material-input:after {
  background-color: #fbe9e7;
}
.form-group-material-deep-orange-100 input.form-control:focus ~ .material-input:after {
  background-color: #ffccbc;
}
.form-group-material-deep-orange-200 input.form-control:focus ~ .material-input:after {
  background-color: #ffab91;
}
.form-group-material-deep-orange-300 input.form-control:focus ~ .material-input:after {
  background-color: #ff8a65;
}
.form-group-material-deep-orange-400 input.form-control:focus ~ .material-input:after {
  background-color: #ff7043;
}
.form-group-material-deep-orange-500 input.form-control:focus ~ .material-input:after {
  background-color: #ff5722;
}
.form-group-material-deep-orange-600 input.form-control:focus ~ .material-input:after {
  background-color: #f4511e;
}
.form-group-material-deep-orange-700 input.form-control:focus ~ .material-input:after {
  background-color: #e64a19;
}
.form-group-material-deep-orange-800 input.form-control:focus ~ .material-input:after {
  background-color: #d84315;
}
.form-group-material-deep-orange-900 input.form-control:focus ~ .material-input:after {
  background-color: #bf360c;
}
.form-group-material-deep-orange-A100 input.form-control:focus ~ .material-input:after {
  background-color: #ff9e80;
}
.form-group-material-deep-orange-A200 input.form-control:focus ~ .material-input:after {
  background-color: #ff6e40;
}
.form-group-material-deep-orange-A400 input.form-control:focus ~ .material-input:after {
  background-color: #ff3d00;
}
.form-group-material-deep-orange-A700 input.form-control:focus ~ .material-input:after {
  background-color: #dd2c00;
}
.form-group-material-brown input.form-control:focus ~ .material-input:after {
  background-color: #795548;
}
.form-group-material-brown-50 input.form-control:focus ~ .material-input:after {
  background-color: #efebe9;
}
.form-group-material-brown-100 input.form-control:focus ~ .material-input:after {
  background-color: #d7ccc8;
}
.form-group-material-brown-200 input.form-control:focus ~ .material-input:after {
  background-color: #bcaaa4;
}
.form-group-material-brown-300 input.form-control:focus ~ .material-input:after {
  background-color: #a1887f;
}
.form-group-material-brown-400 input.form-control:focus ~ .material-input:after {
  background-color: #8d6e63;
}
.form-group-material-brown-500 input.form-control:focus ~ .material-input:after {
  background-color: #795548;
}
.form-group-material-brown-600 input.form-control:focus ~ .material-input:after {
  background-color: #6d4c41;
}
.form-group-material-brown-700 input.form-control:focus ~ .material-input:after {
  background-color: #5d4037;
}
.form-group-material-brown-800 input.form-control:focus ~ .material-input:after {
  background-color: #4e342e;
}
.form-group-material-brown-900 input.form-control:focus ~ .material-input:after {
  background-color: #3e2723;
}
.form-group-material-brown-A100 input.form-control:focus ~ .material-input:after {
  background-color: #d7ccc8;
}
.form-group-material-brown-A200 input.form-control:focus ~ .material-input:after {
  background-color: #bcaaa4;
}
.form-group-material-brown-A400 input.form-control:focus ~ .material-input:after {
  background-color: #8d6e63;
}
.form-group-material-brown-A700 input.form-control:focus ~ .material-input:after {
  background-color: #5d4037;
}
.form-group-material-grey input.form-control:focus ~ .material-input:after {
  background-color: #9e9e9e;
}
.form-group-material-grey-50 input.form-control:focus ~ .material-input:after {
  background-color: #fafafa;
}
.form-group-material-grey-100 input.form-control:focus ~ .material-input:after {
  background-color: #f5f5f5;
}
.form-group-material-grey-200 input.form-control:focus ~ .material-input:after {
  background-color: #eeeeee;
}
.form-group-material-grey-300 input.form-control:focus ~ .material-input:after {
  background-color: #e0e0e0;
}
.form-group-material-grey-400 input.form-control:focus ~ .material-input:after {
  background-color: #bdbdbd;
}
.form-group-material-grey-500 input.form-control:focus ~ .material-input:after {
  background-color: #9e9e9e;
}
.form-group-material-grey-600 input.form-control:focus ~ .material-input:after {
  background-color: #757575;
}
.form-group-material-grey-700 input.form-control:focus ~ .material-input:after {
  background-color: #616161;
}
.form-group-material-grey-800 input.form-control:focus ~ .material-input:after {
  background-color: #424242;
}
.form-group-material-grey-900 input.form-control:focus ~ .material-input:after {
  background-color: #212121;
}
.form-group-material-grey-A100 input.form-control:focus ~ .material-input:after {
  background-color: #f5f5f5;
}
.form-group-material-grey-A200 input.form-control:focus ~ .material-input:after {
  background-color: #eeeeee;
}
.form-group-material-grey-A400 input.form-control:focus ~ .material-input:after {
  background-color: #bdbdbd;
}
.form-group-material-grey-A700 input.form-control:focus ~ .material-input:after {
  background-color: #616161;
}
.form-group-material-blue input.form-control:focus ~ .material-input:after {
  background-color: #607d8b;
}
.form-group-material-blue-50 input.form-control:focus ~ .material-input:after {
  background-color: #eceff1;
}
.form-group-material-blue-100 input.form-control:focus ~ .material-input:after {
  background-color: #cfd8dc;
}
.form-group-material-blue-200 input.form-control:focus ~ .material-input:after {
  background-color: #b0bec5;
}
.form-group-material-blue-300 input.form-control:focus ~ .material-input:after {
  background-color: #90a4ae;
}
.form-group-material-blue-400 input.form-control:focus ~ .material-input:after {
  background-color: #78909c;
}
.form-group-material-blue-500 input.form-control:focus ~ .material-input:after {
  background-color: #607d8b;
}
.form-group-material-blue-600 input.form-control:focus ~ .material-input:after {
  background-color: #546e7a;
}
.form-group-material-blue-700 input.form-control:focus ~ .material-input:after {
  background-color: #455a64;
}
.form-group-material-blue-800 input.form-control:focus ~ .material-input:after {
  background-color: #37474f;
}
.form-group-material-blue-900 input.form-control:focus ~ .material-input:after {
  background-color: #263238;
}
.form-group-material-blue-A100 input.form-control:focus ~ .material-input:after {
  background-color: #cfd8dc;
}
.form-group-material-blue-A200 input.form-control:focus ~ .material-input:after {
  background-color: #b0bec5;
}
.form-group-material-blue-A400 input.form-control:focus ~ .material-input:after {
  background-color: #78909c;
}
.form-group-material-blue-A700 input.form-control:focus ~ .material-input:after {
  background-color: #455a64;
}
.form-group input.form-control.focus ~ .material-input:after,
.form-group-default input.form-control.focus ~ .material-input:after {
  background-color: #009587;
}
.form-group-primary input.form-control.focus ~ .material-input:after {
  background-color: #009587;
}
.form-group-success input.form-control.focus ~ .material-input:after {
  background-color: #0f9d58;
}
.form-group-info input.form-control.focus ~ .material-input:after {
  background-color: #03a9f4;
}
.form-group-warning input.form-control.focus ~ .material-input:after {
  background-color: #ff5722;
}
.form-group-danger input.form-control.focus ~ .material-input:after {
  background-color: #f44336;
}
.form-group-material-red input.form-control.focus ~ .material-input:after {
  background-color: #f44336;
}
.form-group-material-red-50 input.form-control.focus ~ .material-input:after {
  background-color: #ffebee;
}
.form-group-material-red-100 input.form-control.focus ~ .material-input:after {
  background-color: #ffcdd2;
}
.form-group-material-red-200 input.form-control.focus ~ .material-input:after {
  background-color: #ef9a9a;
}
.form-group-material-red-300 input.form-control.focus ~ .material-input:after {
  background-color: #e57373;
}
.form-group-material-red-400 input.form-control.focus ~ .material-input:after {
  background-color: #ef5350;
}
.form-group-material-red-500 input.form-control.focus ~ .material-input:after {
  background-color: #f44336;
}
.form-group-material-red-600 input.form-control.focus ~ .material-input:after {
  background-color: #e53935;
}
.form-group-material-red-700 input.form-control.focus ~ .material-input:after {
  background-color: #d32f2f;
}
.form-group-material-red-800 input.form-control.focus ~ .material-input:after {
  background-color: #c62828;
}
.form-group-material-red-900 input.form-control.focus ~ .material-input:after {
  background-color: #b71c1c;
}
.form-group-material-red-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ff8a80;
}
.form-group-material-red-A200 input.form-control.focus ~ .material-input:after {
  background-color: #ff5252;
}
.form-group-material-red-A400 input.form-control.focus ~ .material-input:after {
  background-color: #ff1744;
}
.form-group-material-red-A700 input.form-control.focus ~ .material-input:after {
  background-color: #d50000;
}
.form-group-material-pink input.form-control.focus ~ .material-input:after {
  background-color: #e91e63;
}
.form-group-material-pink-50 input.form-control.focus ~ .material-input:after {
  background-color: #fce4ec;
}
.form-group-material-pink-100 input.form-control.focus ~ .material-input:after {
  background-color: #f8bbd0;
}
.form-group-material-pink-200 input.form-control.focus ~ .material-input:after {
  background-color: #f48fb1;
}
.form-group-material-pink-300 input.form-control.focus ~ .material-input:after {
  background-color: #f06292;
}
.form-group-material-pink-400 input.form-control.focus ~ .material-input:after {
  background-color: #ec407a;
}
.form-group-material-pink-500 input.form-control.focus ~ .material-input:after {
  background-color: #e91e63;
}
.form-group-material-pink-600 input.form-control.focus ~ .material-input:after {
  background-color: #d81b60;
}
.form-group-material-pink-700 input.form-control.focus ~ .material-input:after {
  background-color: #c2185b;
}
.form-group-material-pink-800 input.form-control.focus ~ .material-input:after {
  background-color: #ad1457;
}
.form-group-material-pink-900 input.form-control.focus ~ .material-input:after {
  background-color: #880e4f;
}
.form-group-material-pink-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ff80ab;
}
.form-group-material-pink-A200 input.form-control.focus ~ .material-input:after {
  background-color: #ff4081;
}
.form-group-material-pink-A400 input.form-control.focus ~ .material-input:after {
  background-color: #f50057;
}
.form-group-material-pink-A700 input.form-control.focus ~ .material-input:after {
  background-color: #c51162;
}
.form-group-material-purple input.form-control.focus ~ .material-input:after {
  background-color: #9c27b0;
}
.form-group-material-purple-50 input.form-control.focus ~ .material-input:after {
  background-color: #f3e5f5;
}
.form-group-material-purple-100 input.form-control.focus ~ .material-input:after {
  background-color: #e1bee7;
}
.form-group-material-purple-200 input.form-control.focus ~ .material-input:after {
  background-color: #ce93d8;
}
.form-group-material-purple-300 input.form-control.focus ~ .material-input:after {
  background-color: #ba68c8;
}
.form-group-material-purple-400 input.form-control.focus ~ .material-input:after {
  background-color: #ab47bc;
}
.form-group-material-purple-500 input.form-control.focus ~ .material-input:after {
  background-color: #9c27b0;
}
.form-group-material-purple-600 input.form-control.focus ~ .material-input:after {
  background-color: #8e24aa;
}
.form-group-material-purple-700 input.form-control.focus ~ .material-input:after {
  background-color: #7b1fa2;
}
.form-group-material-purple-800 input.form-control.focus ~ .material-input:after {
  background-color: #6a1b9a;
}
.form-group-material-purple-900 input.form-control.focus ~ .material-input:after {
  background-color: #4a148c;
}
.form-group-material-purple-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ea80fc;
}
.form-group-material-purple-A200 input.form-control.focus ~ .material-input:after {
  background-color: #e040fb;
}
.form-group-material-purple-A400 input.form-control.focus ~ .material-input:after {
  background-color: #d500f9;
}
.form-group-material-purple-A700 input.form-control.focus ~ .material-input:after {
  background-color: #aa00ff;
}
.form-group-material-deep-purple input.form-control.focus ~ .material-input:after {
  background-color: #673ab7;
}
.form-group-material-deep-purple-50 input.form-control.focus ~ .material-input:after {
  background-color: #ede7f6;
}
.form-group-material-deep-purple-100 input.form-control.focus ~ .material-input:after {
  background-color: #d1c4e9;
}
.form-group-material-deep-purple-200 input.form-control.focus ~ .material-input:after {
  background-color: #b39ddb;
}
.form-group-material-deep-purple-300 input.form-control.focus ~ .material-input:after {
  background-color: #9575cd;
}
.form-group-material-deep-purple-400 input.form-control.focus ~ .material-input:after {
  background-color: #7e57c2;
}
.form-group-material-deep-purple-500 input.form-control.focus ~ .material-input:after {
  background-color: #673ab7;
}
.form-group-material-deep-purple-600 input.form-control.focus ~ .material-input:after {
  background-color: #5e35b1;
}
.form-group-material-deep-purple-700 input.form-control.focus ~ .material-input:after {
  background-color: #512da8;
}
.form-group-material-deep-purple-800 input.form-control.focus ~ .material-input:after {
  background-color: #4527a0;
}
.form-group-material-deep-purple-900 input.form-control.focus ~ .material-input:after {
  background-color: #311b92;
}
.form-group-material-deep-purple-A100 input.form-control.focus ~ .material-input:after {
  background-color: #b388ff;
}
.form-group-material-deep-purple-A200 input.form-control.focus ~ .material-input:after {
  background-color: #7c4dff;
}
.form-group-material-deep-purple-A400 input.form-control.focus ~ .material-input:after {
  background-color: #651fff;
}
.form-group-material-deep-purple-A700 input.form-control.focus ~ .material-input:after {
  background-color: #6200ea;
}
.form-group-material-indigo input.form-control.focus ~ .material-input:after {
  background-color: #3f51b5;
}
.form-group-material-indigo-50 input.form-control.focus ~ .material-input:after {
  background-color: #e8eaf6;
}
.form-group-material-indigo-100 input.form-control.focus ~ .material-input:after {
  background-color: #c5cae9;
}
.form-group-material-indigo-200 input.form-control.focus ~ .material-input:after {
  background-color: #9fa8da;
}
.form-group-material-indigo-300 input.form-control.focus ~ .material-input:after {
  background-color: #7986cb;
}
.form-group-material-indigo-400 input.form-control.focus ~ .material-input:after {
  background-color: #5c6bc0;
}
.form-group-material-indigo-500 input.form-control.focus ~ .material-input:after {
  background-color: #3f51b5;
}
.form-group-material-indigo-600 input.form-control.focus ~ .material-input:after {
  background-color: #3949ab;
}
.form-group-material-indigo-700 input.form-control.focus ~ .material-input:after {
  background-color: #303f9f;
}
.form-group-material-indigo-800 input.form-control.focus ~ .material-input:after {
  background-color: #283593;
}
.form-group-material-indigo-900 input.form-control.focus ~ .material-input:after {
  background-color: #1a237e;
}
.form-group-material-indigo-A100 input.form-control.focus ~ .material-input:after {
  background-color: #8c9eff;
}
.form-group-material-indigo-A200 input.form-control.focus ~ .material-input:after {
  background-color: #536dfe;
}
.form-group-material-indigo-A400 input.form-control.focus ~ .material-input:after {
  background-color: #3d5afe;
}
.form-group-material-indigo-A700 input.form-control.focus ~ .material-input:after {
  background-color: #304ffe;
}
.form-group-material-blue input.form-control.focus ~ .material-input:after {
  background-color: #2196f3;
}
.form-group-material-blue-50 input.form-control.focus ~ .material-input:after {
  background-color: #e3f2fd;
}
.form-group-material-blue-100 input.form-control.focus ~ .material-input:after {
  background-color: #bbdefb;
}
.form-group-material-blue-200 input.form-control.focus ~ .material-input:after {
  background-color: #90caf9;
}
.form-group-material-blue-300 input.form-control.focus ~ .material-input:after {
  background-color: #64b5f6;
}
.form-group-material-blue-400 input.form-control.focus ~ .material-input:after {
  background-color: #42a5f5;
}
.form-group-material-blue-500 input.form-control.focus ~ .material-input:after {
  background-color: #2196f3;
}
.form-group-material-blue-600 input.form-control.focus ~ .material-input:after {
  background-color: #1e88e5;
}
.form-group-material-blue-700 input.form-control.focus ~ .material-input:after {
  background-color: #1976d2;
}
.form-group-material-blue-800 input.form-control.focus ~ .material-input:after {
  background-color: #1565c0;
}
.form-group-material-blue-900 input.form-control.focus ~ .material-input:after {
  background-color: #0d47a1;
}
.form-group-material-blue-A100 input.form-control.focus ~ .material-input:after {
  background-color: #82b1ff;
}
.form-group-material-blue-A200 input.form-control.focus ~ .material-input:after {
  background-color: #448aff;
}
.form-group-material-blue-A400 input.form-control.focus ~ .material-input:after {
  background-color: #2979ff;
}
.form-group-material-blue-A700 input.form-control.focus ~ .material-input:after {
  background-color: #2962ff;
}
.form-group-material-light-blue input.form-control.focus ~ .material-input:after {
  background-color: #03a9f4;
}
.form-group-material-light-blue-50 input.form-control.focus ~ .material-input:after {
  background-color: #e1f5fe;
}
.form-group-material-light-blue-100 input.form-control.focus ~ .material-input:after {
  background-color: #b3e5fc;
}
.form-group-material-light-blue-200 input.form-control.focus ~ .material-input:after {
  background-color: #81d4fa;
}
.form-group-material-light-blue-300 input.form-control.focus ~ .material-input:after {
  background-color: #4fc3f7;
}
.form-group-material-light-blue-400 input.form-control.focus ~ .material-input:after {
  background-color: #29b6f6;
}
.form-group-material-light-blue-500 input.form-control.focus ~ .material-input:after {
  background-color: #03a9f4;
}
.form-group-material-light-blue-600 input.form-control.focus ~ .material-input:after {
  background-color: #039be5;
}
.form-group-material-light-blue-700 input.form-control.focus ~ .material-input:after {
  background-color: #0288d1;
}
.form-group-material-light-blue-800 input.form-control.focus ~ .material-input:after {
  background-color: #0277bd;
}
.form-group-material-light-blue-900 input.form-control.focus ~ .material-input:after {
  background-color: #01579b;
}
.form-group-material-light-blue-A100 input.form-control.focus ~ .material-input:after {
  background-color: #80d8ff;
}
.form-group-material-light-blue-A200 input.form-control.focus ~ .material-input:after {
  background-color: #40c4ff;
}
.form-group-material-light-blue-A400 input.form-control.focus ~ .material-input:after {
  background-color: #00b0ff;
}
.form-group-material-light-blue-A700 input.form-control.focus ~ .material-input:after {
  background-color: #0091ea;
}
.form-group-material-cyan input.form-control.focus ~ .material-input:after {
  background-color: #00bcd4;
}
.form-group-material-cyan-50 input.form-control.focus ~ .material-input:after {
  background-color: #e0f7fa;
}
.form-group-material-cyan-100 input.form-control.focus ~ .material-input:after {
  background-color: #b2ebf2;
}
.form-group-material-cyan-200 input.form-control.focus ~ .material-input:after {
  background-color: #80deea;
}
.form-group-material-cyan-300 input.form-control.focus ~ .material-input:after {
  background-color: #4dd0e1;
}
.form-group-material-cyan-400 input.form-control.focus ~ .material-input:after {
  background-color: #26c6da;
}
.form-group-material-cyan-500 input.form-control.focus ~ .material-input:after {
  background-color: #00bcd4;
}
.form-group-material-cyan-600 input.form-control.focus ~ .material-input:after {
  background-color: #00acc1;
}
.form-group-material-cyan-700 input.form-control.focus ~ .material-input:after {
  background-color: #0097a7;
}
.form-group-material-cyan-800 input.form-control.focus ~ .material-input:after {
  background-color: #00838f;
}
.form-group-material-cyan-900 input.form-control.focus ~ .material-input:after {
  background-color: #006064;
}
.form-group-material-cyan-A100 input.form-control.focus ~ .material-input:after {
  background-color: #84ffff;
}
.form-group-material-cyan-A200 input.form-control.focus ~ .material-input:after {
  background-color: #18ffff;
}
.form-group-material-cyan-A400 input.form-control.focus ~ .material-input:after {
  background-color: #00e5ff;
}
.form-group-material-cyan-A700 input.form-control.focus ~ .material-input:after {
  background-color: #00b8d4;
}
.form-group-material-teal input.form-control.focus ~ .material-input:after {
  background-color: #009688;
}
.form-group-material-teal-50 input.form-control.focus ~ .material-input:after {
  background-color: #e0f2f1;
}
.form-group-material-teal-100 input.form-control.focus ~ .material-input:after {
  background-color: #b2dfdb;
}
.form-group-material-teal-200 input.form-control.focus ~ .material-input:after {
  background-color: #80cbc4;
}
.form-group-material-teal-300 input.form-control.focus ~ .material-input:after {
  background-color: #4db6ac;
}
.form-group-material-teal-400 input.form-control.focus ~ .material-input:after {
  background-color: #26a69a;
}
.form-group-material-teal-500 input.form-control.focus ~ .material-input:after {
  background-color: #009688;
}
.form-group-material-teal-600 input.form-control.focus ~ .material-input:after {
  background-color: #00897b;
}
.form-group-material-teal-700 input.form-control.focus ~ .material-input:after {
  background-color: #00796b;
}
.form-group-material-teal-800 input.form-control.focus ~ .material-input:after {
  background-color: #00695c;
}
.form-group-material-teal-900 input.form-control.focus ~ .material-input:after {
  background-color: #004d40;
}
.form-group-material-teal-A100 input.form-control.focus ~ .material-input:after {
  background-color: #a7ffeb;
}
.form-group-material-teal-A200 input.form-control.focus ~ .material-input:after {
  background-color: #64ffda;
}
.form-group-material-teal-A400 input.form-control.focus ~ .material-input:after {
  background-color: #1de9b6;
}
.form-group-material-teal-A700 input.form-control.focus ~ .material-input:after {
  background-color: #00bfa5;
}
.form-group-material-green input.form-control.focus ~ .material-input:after {
  background-color: #4caf50;
}
.form-group-material-green-50 input.form-control.focus ~ .material-input:after {
  background-color: #e8f5e9;
}
.form-group-material-green-100 input.form-control.focus ~ .material-input:after {
  background-color: #c8e6c9;
}
.form-group-material-green-200 input.form-control.focus ~ .material-input:after {
  background-color: #a5d6a7;
}
.form-group-material-green-300 input.form-control.focus ~ .material-input:after {
  background-color: #81c784;
}
.form-group-material-green-400 input.form-control.focus ~ .material-input:after {
  background-color: #66bb6a;
}
.form-group-material-green-500 input.form-control.focus ~ .material-input:after {
  background-color: #4caf50;
}
.form-group-material-green-600 input.form-control.focus ~ .material-input:after {
  background-color: #43a047;
}
.form-group-material-green-700 input.form-control.focus ~ .material-input:after {
  background-color: #388e3c;
}
.form-group-material-green-800 input.form-control.focus ~ .material-input:after {
  background-color: #2e7d32;
}
.form-group-material-green-900 input.form-control.focus ~ .material-input:after {
  background-color: #1b5e20;
}
.form-group-material-green-A100 input.form-control.focus ~ .material-input:after {
  background-color: #b9f6ca;
}
.form-group-material-green-A200 input.form-control.focus ~ .material-input:after {
  background-color: #69f0ae;
}
.form-group-material-green-A400 input.form-control.focus ~ .material-input:after {
  background-color: #00e676;
}
.form-group-material-green-A700 input.form-control.focus ~ .material-input:after {
  background-color: #00c853;
}
.form-group-material-light-green input.form-control.focus ~ .material-input:after {
  background-color: #8bc34a;
}
.form-group-material-light-green-50 input.form-control.focus ~ .material-input:after {
  background-color: #f1f8e9;
}
.form-group-material-light-green-100 input.form-control.focus ~ .material-input:after {
  background-color: #dcedc8;
}
.form-group-material-light-green-200 input.form-control.focus ~ .material-input:after {
  background-color: #c5e1a5;
}
.form-group-material-light-green-300 input.form-control.focus ~ .material-input:after {
  background-color: #aed581;
}
.form-group-material-light-green-400 input.form-control.focus ~ .material-input:after {
  background-color: #9ccc65;
}
.form-group-material-light-green-500 input.form-control.focus ~ .material-input:after {
  background-color: #8bc34a;
}
.form-group-material-light-green-600 input.form-control.focus ~ .material-input:after {
  background-color: #7cb342;
}
.form-group-material-light-green-700 input.form-control.focus ~ .material-input:after {
  background-color: #689f38;
}
.form-group-material-light-green-800 input.form-control.focus ~ .material-input:after {
  background-color: #558b2f;
}
.form-group-material-light-green-900 input.form-control.focus ~ .material-input:after {
  background-color: #33691e;
}
.form-group-material-light-green-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ccff90;
}
.form-group-material-light-green-A200 input.form-control.focus ~ .material-input:after {
  background-color: #b2ff59;
}
.form-group-material-light-green-A400 input.form-control.focus ~ .material-input:after {
  background-color: #76ff03;
}
.form-group-material-light-green-A700 input.form-control.focus ~ .material-input:after {
  background-color: #64dd17;
}
.form-group-material-lime input.form-control.focus ~ .material-input:after {
  background-color: #cddc39;
}
.form-group-material-lime-50 input.form-control.focus ~ .material-input:after {
  background-color: #f9fbe7;
}
.form-group-material-lime-100 input.form-control.focus ~ .material-input:after {
  background-color: #f0f4c3;
}
.form-group-material-lime-200 input.form-control.focus ~ .material-input:after {
  background-color: #e6ee9c;
}
.form-group-material-lime-300 input.form-control.focus ~ .material-input:after {
  background-color: #dce775;
}
.form-group-material-lime-400 input.form-control.focus ~ .material-input:after {
  background-color: #d4e157;
}
.form-group-material-lime-500 input.form-control.focus ~ .material-input:after {
  background-color: #cddc39;
}
.form-group-material-lime-600 input.form-control.focus ~ .material-input:after {
  background-color: #c0ca33;
}
.form-group-material-lime-700 input.form-control.focus ~ .material-input:after {
  background-color: #afb42b;
}
.form-group-material-lime-800 input.form-control.focus ~ .material-input:after {
  background-color: #9e9d24;
}
.form-group-material-lime-900 input.form-control.focus ~ .material-input:after {
  background-color: #827717;
}
.form-group-material-lime-A100 input.form-control.focus ~ .material-input:after {
  background-color: #f4ff81;
}
.form-group-material-lime-A200 input.form-control.focus ~ .material-input:after {
  background-color: #eeff41;
}
.form-group-material-lime-A400 input.form-control.focus ~ .material-input:after {
  background-color: #c6ff00;
}
.form-group-material-lime-A700 input.form-control.focus ~ .material-input:after {
  background-color: #aeea00;
}
.form-group-material-yellow input.form-control.focus ~ .material-input:after {
  background-color: #ffeb3b;
}
.form-group-material-yellow-50 input.form-control.focus ~ .material-input:after {
  background-color: #fffde7;
}
.form-group-material-yellow-100 input.form-control.focus ~ .material-input:after {
  background-color: #fff9c4;
}
.form-group-material-yellow-200 input.form-control.focus ~ .material-input:after {
  background-color: #fff59d;
}
.form-group-material-yellow-300 input.form-control.focus ~ .material-input:after {
  background-color: #fff176;
}
.form-group-material-yellow-400 input.form-control.focus ~ .material-input:after {
  background-color: #ffee58;
}
.form-group-material-yellow-500 input.form-control.focus ~ .material-input:after {
  background-color: #ffeb3b;
}
.form-group-material-yellow-600 input.form-control.focus ~ .material-input:after {
  background-color: #fdd835;
}
.form-group-material-yellow-700 input.form-control.focus ~ .material-input:after {
  background-color: #fbc02d;
}
.form-group-material-yellow-800 input.form-control.focus ~ .material-input:after {
  background-color: #f9a825;
}
.form-group-material-yellow-900 input.form-control.focus ~ .material-input:after {
  background-color: #f57f17;
}
.form-group-material-yellow-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ffff8d;
}
.form-group-material-yellow-A200 input.form-control.focus ~ .material-input:after {
  background-color: #ffff00;
}
.form-group-material-yellow-A400 input.form-control.focus ~ .material-input:after {
  background-color: #ffea00;
}
.form-group-material-yellow-A700 input.form-control.focus ~ .material-input:after {
  background-color: #ffd600;
}
.form-group-material-amber input.form-control.focus ~ .material-input:after {
  background-color: #ffc107;
}
.form-group-material-amber-50 input.form-control.focus ~ .material-input:after {
  background-color: #fff8e1;
}
.form-group-material-amber-100 input.form-control.focus ~ .material-input:after {
  background-color: #ffecb3;
}
.form-group-material-amber-200 input.form-control.focus ~ .material-input:after {
  background-color: #ffe082;
}
.form-group-material-amber-300 input.form-control.focus ~ .material-input:after {
  background-color: #ffd54f;
}
.form-group-material-amber-400 input.form-control.focus ~ .material-input:after {
  background-color: #ffca28;
}
.form-group-material-amber-500 input.form-control.focus ~ .material-input:after {
  background-color: #ffc107;
}
.form-group-material-amber-600 input.form-control.focus ~ .material-input:after {
  background-color: #ffb300;
}
.form-group-material-amber-700 input.form-control.focus ~ .material-input:after {
  background-color: #ffa000;
}
.form-group-material-amber-800 input.form-control.focus ~ .material-input:after {
  background-color: #ff8f00;
}
.form-group-material-amber-900 input.form-control.focus ~ .material-input:after {
  background-color: #ff6f00;
}
.form-group-material-amber-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ffe57f;
}
.form-group-material-amber-A200 input.form-control.focus ~ .material-input:after {
  background-color: #ffd740;
}
.form-group-material-amber-A400 input.form-control.focus ~ .material-input:after {
  background-color: #ffc400;
}
.form-group-material-amber-A700 input.form-control.focus ~ .material-input:after {
  background-color: #ffab00;
}
.form-group-material-orange input.form-control.focus ~ .material-input:after {
  background-color: #ff9800;
}
.form-group-material-orange-50 input.form-control.focus ~ .material-input:after {
  background-color: #fff3e0;
}
.form-group-material-orange-100 input.form-control.focus ~ .material-input:after {
  background-color: #ffe0b2;
}
.form-group-material-orange-200 input.form-control.focus ~ .material-input:after {
  background-color: #ffcc80;
}
.form-group-material-orange-300 input.form-control.focus ~ .material-input:after {
  background-color: #ffb74d;
}
.form-group-material-orange-400 input.form-control.focus ~ .material-input:after {
  background-color: #ffa726;
}
.form-group-material-orange-500 input.form-control.focus ~ .material-input:after {
  background-color: #ff9800;
}
.form-group-material-orange-600 input.form-control.focus ~ .material-input:after {
  background-color: #fb8c00;
}
.form-group-material-orange-700 input.form-control.focus ~ .material-input:after {
  background-color: #f57c00;
}
.form-group-material-orange-800 input.form-control.focus ~ .material-input:after {
  background-color: #ef6c00;
}
.form-group-material-orange-900 input.form-control.focus ~ .material-input:after {
  background-color: #e65100;
}
.form-group-material-orange-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ffd180;
}
.form-group-material-orange-A200 input.form-control.focus ~ .material-input:after {
  background-color: #ffab40;
}
.form-group-material-orange-A400 input.form-control.focus ~ .material-input:after {
  background-color: #ff9100;
}
.form-group-material-orange-A700 input.form-control.focus ~ .material-input:after {
  background-color: #ff6d00;
}
.form-group-material-deep-orange input.form-control.focus ~ .material-input:after {
  background-color: #ff5722;
}
.form-group-material-deep-orange-50 input.form-control.focus ~ .material-input:after {
  background-color: #fbe9e7;
}
.form-group-material-deep-orange-100 input.form-control.focus ~ .material-input:after {
  background-color: #ffccbc;
}
.form-group-material-deep-orange-200 input.form-control.focus ~ .material-input:after {
  background-color: #ffab91;
}
.form-group-material-deep-orange-300 input.form-control.focus ~ .material-input:after {
  background-color: #ff8a65;
}
.form-group-material-deep-orange-400 input.form-control.focus ~ .material-input:after {
  background-color: #ff7043;
}
.form-group-material-deep-orange-500 input.form-control.focus ~ .material-input:after {
  background-color: #ff5722;
}
.form-group-material-deep-orange-600 input.form-control.focus ~ .material-input:after {
  background-color: #f4511e;
}
.form-group-material-deep-orange-700 input.form-control.focus ~ .material-input:after {
  background-color: #e64a19;
}
.form-group-material-deep-orange-800 input.form-control.focus ~ .material-input:after {
  background-color: #d84315;
}
.form-group-material-deep-orange-900 input.form-control.focus ~ .material-input:after {
  background-color: #bf360c;
}
.form-group-material-deep-orange-A100 input.form-control.focus ~ .material-input:after {
  background-color: #ff9e80;
}
.form-group-material-deep-orange-A200 input.form-control.focus ~ .material-input:after {
  background-color: #ff6e40;
}
.form-group-material-deep-orange-A400 input.form-control.focus ~ .material-input:after {
  background-color: #ff3d00;
}
.form-group-material-deep-orange-A700 input.form-control.focus ~ .material-input:after {
  background-color: #dd2c00;
}
.form-group-material-brown input.form-control.focus ~ .material-input:after {
  background-color: #795548;
}
.form-group-material-brown-50 input.form-control.focus ~ .material-input:after {
  background-color: #efebe9;
}
.form-group-material-brown-100 input.form-control.focus ~ .material-input:after {
  background-color: #d7ccc8;
}
.form-group-material-brown-200 input.form-control.focus ~ .material-input:after {
  background-color: #bcaaa4;
}
.form-group-material-brown-300 input.form-control.focus ~ .material-input:after {
  background-color: #a1887f;
}
.form-group-material-brown-400 input.form-control.focus ~ .material-input:after {
  background-color: #8d6e63;
}
.form-group-material-brown-500 input.form-control.focus ~ .material-input:after {
  background-color: #795548;
}
.form-group-material-brown-600 input.form-control.focus ~ .material-input:after {
  background-color: #6d4c41;
}
.form-group-material-brown-700 input.form-control.focus ~ .material-input:after {
  background-color: #5d4037;
}
.form-group-material-brown-800 input.form-control.focus ~ .material-input:after {
  background-color: #4e342e;
}
.form-group-material-brown-900 input.form-control.focus ~ .material-input:after {
  background-color: #3e2723;
}
.form-group-material-brown-A100 input.form-control.focus ~ .material-input:after {
  background-color: #d7ccc8;
}
.form-group-material-brown-A200 input.form-control.focus ~ .material-input:after {
  background-color: #bcaaa4;
}
.form-group-material-brown-A400 input.form-control.focus ~ .material-input:after {
  background-color: #8d6e63;
}
.form-group-material-brown-A700 input.form-control.focus ~ .material-input:after {
  background-color: #5d4037;
}
.form-group-material-grey input.form-control.focus ~ .material-input:after {
  background-color: #9e9e9e;
}
.form-group-material-grey-50 input.form-control.focus ~ .material-input:after {
  background-color: #fafafa;
}
.form-group-material-grey-100 input.form-control.focus ~ .material-input:after {
  background-color: #f5f5f5;
}
.form-group-material-grey-200 input.form-control.focus ~ .material-input:after {
  background-color: #eeeeee;
}
.form-group-material-grey-300 input.form-control.focus ~ .material-input:after {
  background-color: #e0e0e0;
}
.form-group-material-grey-400 input.form-control.focus ~ .material-input:after {
  background-color: #bdbdbd;
}
.form-group-material-grey-500 input.form-control.focus ~ .material-input:after {
  background-color: #9e9e9e;
}
.form-group-material-grey-600 input.form-control.focus ~ .material-input:after {
  background-color: #757575;
}
.form-group-material-grey-700 input.form-control.focus ~ .material-input:after {
  background-color: #616161;
}
.form-group-material-grey-800 input.form-control.focus ~ .material-input:after {
  background-color: #424242;
}
.form-group-material-grey-900 input.form-control.focus ~ .material-input:after {
  background-color: #212121;
}
.form-group-material-grey-A100 input.form-control.focus ~ .material-input:after {
  background-color: #f5f5f5;
}
.form-group-material-grey-A200 input.form-control.focus ~ .material-input:after {
  background-color: #eeeeee;
}
.form-group-material-grey-A400 input.form-control.focus ~ .material-input:after {
  background-color: #bdbdbd;
}
.form-group-material-grey-A700 input.form-control.focus ~ .material-input:after {
  background-color: #616161;
}
.form-group-material-blue input.form-control.focus ~ .material-input:after {
  background-color: #607d8b;
}
.form-group-material-blue-50 input.form-control.focus ~ .material-input:after {
  background-color: #eceff1;
}
.form-group-material-blue-100 input.form-control.focus ~ .material-input:after {
  background-color: #cfd8dc;
}
.form-group-material-blue-200 input.form-control.focus ~ .material-input:after {
  background-color: #b0bec5;
}
.form-group-material-blue-300 input.form-control.focus ~ .material-input:after {
  background-color: #90a4ae;
}
.form-group-material-blue-400 input.form-control.focus ~ .material-input:after {
  background-color: #78909c;
}
.form-group-material-blue-500 input.form-control.focus ~ .material-input:after {
  background-color: #607d8b;
}
.form-group-material-blue-600 input.form-control.focus ~ .material-input:after {
  background-color: #546e7a;
}
.form-group-material-blue-700 input.form-control.focus ~ .material-input:after {
  background-color: #455a64;
}
.form-group-material-blue-800 input.form-control.focus ~ .material-input:after {
  background-color: #37474f;
}
.form-group-material-blue-900 input.form-control.focus ~ .material-input:after {
  background-color: #263238;
}
.form-group-material-blue-A100 input.form-control.focus ~ .material-input:after {
  background-color: #cfd8dc;
}
.form-group-material-blue-A200 input.form-control.focus ~ .material-input:after {
  background-color: #b0bec5;
}
.form-group-material-blue-A400 input.form-control.focus ~ .material-input:after {
  background-color: #78909c;
}
.form-group-material-blue-A700 input.form-control.focus ~ .material-input:after {
  background-color: #455a64;
}
.form-group .control-label,
.form-group-default .control-label {
  color: rgba(0, 0, 0, 0.84);
}
.form-group-primary .control-label {
  color: #009587;
}
.form-group-success .control-label {
  color: #0f9d58;
}
.form-group-info .control-label {
  color: #03a9f4;
}
.form-group-warning .control-label {
  color: #ff5722;
}
.form-group-danger .control-label {
  color: #f44336;
}
.form-group-material-red .control-label {
  color: #f44336;
}
.form-group-material-red-50 .control-label {
  color: #ffebee;
}
.form-group-material-red-100 .control-label {
  color: #ffcdd2;
}
.form-group-material-red-200 .control-label {
  color: #ef9a9a;
}
.form-group-material-red-300 .control-label {
  color: #e57373;
}
.form-group-material-red-400 .control-label {
  color: #ef5350;
}
.form-group-material-red-500 .control-label {
  color: #f44336;
}
.form-group-material-red-600 .control-label {
  color: #e53935;
}
.form-group-material-red-700 .control-label {
  color: #d32f2f;
}
.form-group-material-red-800 .control-label {
  color: #c62828;
}
.form-group-material-red-900 .control-label {
  color: #b71c1c;
}
.form-group-material-red-A100 .control-label {
  color: #ff8a80;
}
.form-group-material-red-A200 .control-label {
  color: #ff5252;
}
.form-group-material-red-A400 .control-label {
  color: #ff1744;
}
.form-group-material-red-A700 .control-label {
  color: #d50000;
}
.form-group-material-pink .control-label {
  color: #e91e63;
}
.form-group-material-pink-50 .control-label {
  color: #fce4ec;
}
.form-group-material-pink-100 .control-label {
  color: #f8bbd0;
}
.form-group-material-pink-200 .control-label {
  color: #f48fb1;
}
.form-group-material-pink-300 .control-label {
  color: #f06292;
}
.form-group-material-pink-400 .control-label {
  color: #ec407a;
}
.form-group-material-pink-500 .control-label {
  color: #e91e63;
}
.form-group-material-pink-600 .control-label {
  color: #d81b60;
}
.form-group-material-pink-700 .control-label {
  color: #c2185b;
}
.form-group-material-pink-800 .control-label {
  color: #ad1457;
}
.form-group-material-pink-900 .control-label {
  color: #880e4f;
}
.form-group-material-pink-A100 .control-label {
  color: #ff80ab;
}
.form-group-material-pink-A200 .control-label {
  color: #ff4081;
}
.form-group-material-pink-A400 .control-label {
  color: #f50057;
}
.form-group-material-pink-A700 .control-label {
  color: #c51162;
}
.form-group-material-purple .control-label {
  color: #9c27b0;
}
.form-group-material-purple-50 .control-label {
  color: #f3e5f5;
}
.form-group-material-purple-100 .control-label {
  color: #e1bee7;
}
.form-group-material-purple-200 .control-label {
  color: #ce93d8;
}
.form-group-material-purple-300 .control-label {
  color: #ba68c8;
}
.form-group-material-purple-400 .control-label {
  color: #ab47bc;
}
.form-group-material-purple-500 .control-label {
  color: #9c27b0;
}
.form-group-material-purple-600 .control-label {
  color: #8e24aa;
}
.form-group-material-purple-700 .control-label {
  color: #7b1fa2;
}
.form-group-material-purple-800 .control-label {
  color: #6a1b9a;
}
.form-group-material-purple-900 .control-label {
  color: #4a148c;
}
.form-group-material-purple-A100 .control-label {
  color: #ea80fc;
}
.form-group-material-purple-A200 .control-label {
  color: #e040fb;
}
.form-group-material-purple-A400 .control-label {
  color: #d500f9;
}
.form-group-material-purple-A700 .control-label {
  color: #aa00ff;
}
.form-group-material-deep-purple .control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-50 .control-label {
  color: #ede7f6;
}
.form-group-material-deep-purple-100 .control-label {
  color: #d1c4e9;
}
.form-group-material-deep-purple-200 .control-label {
  color: #b39ddb;
}
.form-group-material-deep-purple-300 .control-label {
  color: #9575cd;
}
.form-group-material-deep-purple-400 .control-label {
  color: #7e57c2;
}
.form-group-material-deep-purple-500 .control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-600 .control-label {
  color: #5e35b1;
}
.form-group-material-deep-purple-700 .control-label {
  color: #512da8;
}
.form-group-material-deep-purple-800 .control-label {
  color: #4527a0;
}
.form-group-material-deep-purple-900 .control-label {
  color: #311b92;
}
.form-group-material-deep-purple-A100 .control-label {
  color: #b388ff;
}
.form-group-material-deep-purple-A200 .control-label {
  color: #7c4dff;
}
.form-group-material-deep-purple-A400 .control-label {
  color: #651fff;
}
.form-group-material-deep-purple-A700 .control-label {
  color: #6200ea;
}
.form-group-material-indigo .control-label {
  color: #3f51b5;
}
.form-group-material-indigo-50 .control-label {
  color: #e8eaf6;
}
.form-group-material-indigo-100 .control-label {
  color: #c5cae9;
}
.form-group-material-indigo-200 .control-label {
  color: #9fa8da;
}
.form-group-material-indigo-300 .control-label {
  color: #7986cb;
}
.form-group-material-indigo-400 .control-label {
  color: #5c6bc0;
}
.form-group-material-indigo-500 .control-label {
  color: #3f51b5;
}
.form-group-material-indigo-600 .control-label {
  color: #3949ab;
}
.form-group-material-indigo-700 .control-label {
  color: #303f9f;
}
.form-group-material-indigo-800 .control-label {
  color: #283593;
}
.form-group-material-indigo-900 .control-label {
  color: #1a237e;
}
.form-group-material-indigo-A100 .control-label {
  color: #8c9eff;
}
.form-group-material-indigo-A200 .control-label {
  color: #536dfe;
}
.form-group-material-indigo-A400 .control-label {
  color: #3d5afe;
}
.form-group-material-indigo-A700 .control-label {
  color: #304ffe;
}
.form-group-material-blue .control-label {
  color: #2196f3;
}
.form-group-material-blue-50 .control-label {
  color: #e3f2fd;
}
.form-group-material-blue-100 .control-label {
  color: #bbdefb;
}
.form-group-material-blue-200 .control-label {
  color: #90caf9;
}
.form-group-material-blue-300 .control-label {
  color: #64b5f6;
}
.form-group-material-blue-400 .control-label {
  color: #42a5f5;
}
.form-group-material-blue-500 .control-label {
  color: #2196f3;
}
.form-group-material-blue-600 .control-label {
  color: #1e88e5;
}
.form-group-material-blue-700 .control-label {
  color: #1976d2;
}
.form-group-material-blue-800 .control-label {
  color: #1565c0;
}
.form-group-material-blue-900 .control-label {
  color: #0d47a1;
}
.form-group-material-blue-A100 .control-label {
  color: #82b1ff;
}
.form-group-material-blue-A200 .control-label {
  color: #448aff;
}
.form-group-material-blue-A400 .control-label {
  color: #2979ff;
}
.form-group-material-blue-A700 .control-label {
  color: #2962ff;
}
.form-group-material-light-blue .control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-50 .control-label {
  color: #e1f5fe;
}
.form-group-material-light-blue-100 .control-label {
  color: #b3e5fc;
}
.form-group-material-light-blue-200 .control-label {
  color: #81d4fa;
}
.form-group-material-light-blue-300 .control-label {
  color: #4fc3f7;
}
.form-group-material-light-blue-400 .control-label {
  color: #29b6f6;
}
.form-group-material-light-blue-500 .control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-600 .control-label {
  color: #039be5;
}
.form-group-material-light-blue-700 .control-label {
  color: #0288d1;
}
.form-group-material-light-blue-800 .control-label {
  color: #0277bd;
}
.form-group-material-light-blue-900 .control-label {
  color: #01579b;
}
.form-group-material-light-blue-A100 .control-label {
  color: #80d8ff;
}
.form-group-material-light-blue-A200 .control-label {
  color: #40c4ff;
}
.form-group-material-light-blue-A400 .control-label {
  color: #00b0ff;
}
.form-group-material-light-blue-A700 .control-label {
  color: #0091ea;
}
.form-group-material-cyan .control-label {
  color: #00bcd4;
}
.form-group-material-cyan-50 .control-label {
  color: #e0f7fa;
}
.form-group-material-cyan-100 .control-label {
  color: #b2ebf2;
}
.form-group-material-cyan-200 .control-label {
  color: #80deea;
}
.form-group-material-cyan-300 .control-label {
  color: #4dd0e1;
}
.form-group-material-cyan-400 .control-label {
  color: #26c6da;
}
.form-group-material-cyan-500 .control-label {
  color: #00bcd4;
}
.form-group-material-cyan-600 .control-label {
  color: #00acc1;
}
.form-group-material-cyan-700 .control-label {
  color: #0097a7;
}
.form-group-material-cyan-800 .control-label {
  color: #00838f;
}
.form-group-material-cyan-900 .control-label {
  color: #006064;
}
.form-group-material-cyan-A100 .control-label {
  color: #84ffff;
}
.form-group-material-cyan-A200 .control-label {
  color: #18ffff;
}
.form-group-material-cyan-A400 .control-label {
  color: #00e5ff;
}
.form-group-material-cyan-A700 .control-label {
  color: #00b8d4;
}
.form-group-material-teal .control-label {
  color: #009688;
}
.form-group-material-teal-50 .control-label {
  color: #e0f2f1;
}
.form-group-material-teal-100 .control-label {
  color: #b2dfdb;
}
.form-group-material-teal-200 .control-label {
  color: #80cbc4;
}
.form-group-material-teal-300 .control-label {
  color: #4db6ac;
}
.form-group-material-teal-400 .control-label {
  color: #26a69a;
}
.form-group-material-teal-500 .control-label {
  color: #009688;
}
.form-group-material-teal-600 .control-label {
  color: #00897b;
}
.form-group-material-teal-700 .control-label {
  color: #00796b;
}
.form-group-material-teal-800 .control-label {
  color: #00695c;
}
.form-group-material-teal-900 .control-label {
  color: #004d40;
}
.form-group-material-teal-A100 .control-label {
  color: #a7ffeb;
}
.form-group-material-teal-A200 .control-label {
  color: #64ffda;
}
.form-group-material-teal-A400 .control-label {
  color: #1de9b6;
}
.form-group-material-teal-A700 .control-label {
  color: #00bfa5;
}
.form-group-material-green .control-label {
  color: #4caf50;
}
.form-group-material-green-50 .control-label {
  color: #e8f5e9;
}
.form-group-material-green-100 .control-label {
  color: #c8e6c9;
}
.form-group-material-green-200 .control-label {
  color: #a5d6a7;
}
.form-group-material-green-300 .control-label {
  color: #81c784;
}
.form-group-material-green-400 .control-label {
  color: #66bb6a;
}
.form-group-material-green-500 .control-label {
  color: #4caf50;
}
.form-group-material-green-600 .control-label {
  color: #43a047;
}
.form-group-material-green-700 .control-label {
  color: #388e3c;
}
.form-group-material-green-800 .control-label {
  color: #2e7d32;
}
.form-group-material-green-900 .control-label {
  color: #1b5e20;
}
.form-group-material-green-A100 .control-label {
  color: #b9f6ca;
}
.form-group-material-green-A200 .control-label {
  color: #69f0ae;
}
.form-group-material-green-A400 .control-label {
  color: #00e676;
}
.form-group-material-green-A700 .control-label {
  color: #00c853;
}
.form-group-material-light-green .control-label {
  color: #8bc34a;
}
.form-group-material-light-green-50 .control-label {
  color: #f1f8e9;
}
.form-group-material-light-green-100 .control-label {
  color: #dcedc8;
}
.form-group-material-light-green-200 .control-label {
  color: #c5e1a5;
}
.form-group-material-light-green-300 .control-label {
  color: #aed581;
}
.form-group-material-light-green-400 .control-label {
  color: #9ccc65;
}
.form-group-material-light-green-500 .control-label {
  color: #8bc34a;
}
.form-group-material-light-green-600 .control-label {
  color: #7cb342;
}
.form-group-material-light-green-700 .control-label {
  color: #689f38;
}
.form-group-material-light-green-800 .control-label {
  color: #558b2f;
}
.form-group-material-light-green-900 .control-label {
  color: #33691e;
}
.form-group-material-light-green-A100 .control-label {
  color: #ccff90;
}
.form-group-material-light-green-A200 .control-label {
  color: #b2ff59;
}
.form-group-material-light-green-A400 .control-label {
  color: #76ff03;
}
.form-group-material-light-green-A700 .control-label {
  color: #64dd17;
}
.form-group-material-lime .control-label {
  color: #cddc39;
}
.form-group-material-lime-50 .control-label {
  color: #f9fbe7;
}
.form-group-material-lime-100 .control-label {
  color: #f0f4c3;
}
.form-group-material-lime-200 .control-label {
  color: #e6ee9c;
}
.form-group-material-lime-300 .control-label {
  color: #dce775;
}
.form-group-material-lime-400 .control-label {
  color: #d4e157;
}
.form-group-material-lime-500 .control-label {
  color: #cddc39;
}
.form-group-material-lime-600 .control-label {
  color: #c0ca33;
}
.form-group-material-lime-700 .control-label {
  color: #afb42b;
}
.form-group-material-lime-800 .control-label {
  color: #9e9d24;
}
.form-group-material-lime-900 .control-label {
  color: #827717;
}
.form-group-material-lime-A100 .control-label {
  color: #f4ff81;
}
.form-group-material-lime-A200 .control-label {
  color: #eeff41;
}
.form-group-material-lime-A400 .control-label {
  color: #c6ff00;
}
.form-group-material-lime-A700 .control-label {
  color: #aeea00;
}
.form-group-material-yellow .control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-50 .control-label {
  color: #fffde7;
}
.form-group-material-yellow-100 .control-label {
  color: #fff9c4;
}
.form-group-material-yellow-200 .control-label {
  color: #fff59d;
}
.form-group-material-yellow-300 .control-label {
  color: #fff176;
}
.form-group-material-yellow-400 .control-label {
  color: #ffee58;
}
.form-group-material-yellow-500 .control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-600 .control-label {
  color: #fdd835;
}
.form-group-material-yellow-700 .control-label {
  color: #fbc02d;
}
.form-group-material-yellow-800 .control-label {
  color: #f9a825;
}
.form-group-material-yellow-900 .control-label {
  color: #f57f17;
}
.form-group-material-yellow-A100 .control-label {
  color: #ffff8d;
}
.form-group-material-yellow-A200 .control-label {
  color: #ffff00;
}
.form-group-material-yellow-A400 .control-label {
  color: #ffea00;
}
.form-group-material-yellow-A700 .control-label {
  color: #ffd600;
}
.form-group-material-amber .control-label {
  color: #ffc107;
}
.form-group-material-amber-50 .control-label {
  color: #fff8e1;
}
.form-group-material-amber-100 .control-label {
  color: #ffecb3;
}
.form-group-material-amber-200 .control-label {
  color: #ffe082;
}
.form-group-material-amber-300 .control-label {
  color: #ffd54f;
}
.form-group-material-amber-400 .control-label {
  color: #ffca28;
}
.form-group-material-amber-500 .control-label {
  color: #ffc107;
}
.form-group-material-amber-600 .control-label {
  color: #ffb300;
}
.form-group-material-amber-700 .control-label {
  color: #ffa000;
}
.form-group-material-amber-800 .control-label {
  color: #ff8f00;
}
.form-group-material-amber-900 .control-label {
  color: #ff6f00;
}
.form-group-material-amber-A100 .control-label {
  color: #ffe57f;
}
.form-group-material-amber-A200 .control-label {
  color: #ffd740;
}
.form-group-material-amber-A400 .control-label {
  color: #ffc400;
}
.form-group-material-amber-A700 .control-label {
  color: #ffab00;
}
.form-group-material-orange .control-label {
  color: #ff9800;
}
.form-group-material-orange-50 .control-label {
  color: #fff3e0;
}
.form-group-material-orange-100 .control-label {
  color: #ffe0b2;
}
.form-group-material-orange-200 .control-label {
  color: #ffcc80;
}
.form-group-material-orange-300 .control-label {
  color: #ffb74d;
}
.form-group-material-orange-400 .control-label {
  color: #ffa726;
}
.form-group-material-orange-500 .control-label {
  color: #ff9800;
}
.form-group-material-orange-600 .control-label {
  color: #fb8c00;
}
.form-group-material-orange-700 .control-label {
  color: #f57c00;
}
.form-group-material-orange-800 .control-label {
  color: #ef6c00;
}
.form-group-material-orange-900 .control-label {
  color: #e65100;
}
.form-group-material-orange-A100 .control-label {
  color: #ffd180;
}
.form-group-material-orange-A200 .control-label {
  color: #ffab40;
}
.form-group-material-orange-A400 .control-label {
  color: #ff9100;
}
.form-group-material-orange-A700 .control-label {
  color: #ff6d00;
}
.form-group-material-deep-orange .control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-50 .control-label {
  color: #fbe9e7;
}
.form-group-material-deep-orange-100 .control-label {
  color: #ffccbc;
}
.form-group-material-deep-orange-200 .control-label {
  color: #ffab91;
}
.form-group-material-deep-orange-300 .control-label {
  color: #ff8a65;
}
.form-group-material-deep-orange-400 .control-label {
  color: #ff7043;
}
.form-group-material-deep-orange-500 .control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-600 .control-label {
  color: #f4511e;
}
.form-group-material-deep-orange-700 .control-label {
  color: #e64a19;
}
.form-group-material-deep-orange-800 .control-label {
  color: #d84315;
}
.form-group-material-deep-orange-900 .control-label {
  color: #bf360c;
}
.form-group-material-deep-orange-A100 .control-label {
  color: #ff9e80;
}
.form-group-material-deep-orange-A200 .control-label {
  color: #ff6e40;
}
.form-group-material-deep-orange-A400 .control-label {
  color: #ff3d00;
}
.form-group-material-deep-orange-A700 .control-label {
  color: #dd2c00;
}
.form-group-material-brown .control-label {
  color: #795548;
}
.form-group-material-brown-50 .control-label {
  color: #efebe9;
}
.form-group-material-brown-100 .control-label {
  color: #d7ccc8;
}
.form-group-material-brown-200 .control-label {
  color: #bcaaa4;
}
.form-group-material-brown-300 .control-label {
  color: #a1887f;
}
.form-group-material-brown-400 .control-label {
  color: #8d6e63;
}
.form-group-material-brown-500 .control-label {
  color: #795548;
}
.form-group-material-brown-600 .control-label {
  color: #6d4c41;
}
.form-group-material-brown-700 .control-label {
  color: #5d4037;
}
.form-group-material-brown-800 .control-label {
  color: #4e342e;
}
.form-group-material-brown-900 .control-label {
  color: #3e2723;
}
.form-group-material-brown-A100 .control-label {
  color: #d7ccc8;
}
.form-group-material-brown-A200 .control-label {
  color: #bcaaa4;
}
.form-group-material-brown-A400 .control-label {
  color: #8d6e63;
}
.form-group-material-brown-A700 .control-label {
  color: #5d4037;
}
.form-group-material-grey .control-label {
  color: #9e9e9e;
}
.form-group-material-grey-50 .control-label {
  color: #fafafa;
}
.form-group-material-grey-100 .control-label {
  color: #f5f5f5;
}
.form-group-material-grey-200 .control-label {
  color: #eeeeee;
}
.form-group-material-grey-300 .control-label {
  color: #e0e0e0;
}
.form-group-material-grey-400 .control-label {
  color: #bdbdbd;
}
.form-group-material-grey-500 .control-label {
  color: #9e9e9e;
}
.form-group-material-grey-600 .control-label {
  color: #757575;
}
.form-group-material-grey-700 .control-label {
  color: #616161;
}
.form-group-material-grey-800 .control-label {
  color: #424242;
}
.form-group-material-grey-900 .control-label {
  color: #212121;
}
.form-group-material-grey-A100 .control-label {
  color: #f5f5f5;
}
.form-group-material-grey-A200 .control-label {
  color: #eeeeee;
}
.form-group-material-grey-A400 .control-label {
  color: #bdbdbd;
}
.form-group-material-grey-A700 .control-label {
  color: #616161;
}
.form-group-material-blue .control-label {
  color: #607d8b;
}
.form-group-material-blue-50 .control-label {
  color: #eceff1;
}
.form-group-material-blue-100 .control-label {
  color: #cfd8dc;
}
.form-group-material-blue-200 .control-label {
  color: #b0bec5;
}
.form-group-material-blue-300 .control-label {
  color: #90a4ae;
}
.form-group-material-blue-400 .control-label {
  color: #78909c;
}
.form-group-material-blue-500 .control-label {
  color: #607d8b;
}
.form-group-material-blue-600 .control-label {
  color: #546e7a;
}
.form-group-material-blue-700 .control-label {
  color: #455a64;
}
.form-group-material-blue-800 .control-label {
  color: #37474f;
}
.form-group-material-blue-900 .control-label {
  color: #263238;
}
.form-group-material-blue-A100 .control-label {
  color: #cfd8dc;
}
.form-group-material-blue-A200 .control-label {
  color: #b0bec5;
}
.form-group-material-blue-A400 .control-label {
  color: #78909c;
}
.form-group-material-blue-A700 .control-label {
  color: #455a64;
}
.form-group input.form-control:not(.empty) ~ .floating-label,
.form-group-default input.form-control:not(.empty) ~ .floating-label {
  color: #009587;
}
.form-group-primary input.form-control:not(.empty) ~ .floating-label {
  color: #009587;
}
.form-group-success input.form-control:not(.empty) ~ .floating-label {
  color: #0f9d58;
}
.form-group-info input.form-control:not(.empty) ~ .floating-label {
  color: #03a9f4;
}
.form-group-warning input.form-control:not(.empty) ~ .floating-label {
  color: #ff5722;
}
.form-group-danger input.form-control:not(.empty) ~ .floating-label {
  color: #f44336;
}
.form-group-material-red input.form-control:not(.empty) ~ .floating-label {
  color: #f44336;
}
.form-group-material-red-50 input.form-control:not(.empty) ~ .floating-label {
  color: #ffebee;
}
.form-group-material-red-100 input.form-control:not(.empty) ~ .floating-label {
  color: #ffcdd2;
}
.form-group-material-red-200 input.form-control:not(.empty) ~ .floating-label {
  color: #ef9a9a;
}
.form-group-material-red-300 input.form-control:not(.empty) ~ .floating-label {
  color: #e57373;
}
.form-group-material-red-400 input.form-control:not(.empty) ~ .floating-label {
  color: #ef5350;
}
.form-group-material-red-500 input.form-control:not(.empty) ~ .floating-label {
  color: #f44336;
}
.form-group-material-red-600 input.form-control:not(.empty) ~ .floating-label {
  color: #e53935;
}
.form-group-material-red-700 input.form-control:not(.empty) ~ .floating-label {
  color: #d32f2f;
}
.form-group-material-red-800 input.form-control:not(.empty) ~ .floating-label {
  color: #c62828;
}
.form-group-material-red-900 input.form-control:not(.empty) ~ .floating-label {
  color: #b71c1c;
}
.form-group-material-red-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ff8a80;
}
.form-group-material-red-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #ff5252;
}
.form-group-material-red-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #ff1744;
}
.form-group-material-red-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #d50000;
}
.form-group-material-pink input.form-control:not(.empty) ~ .floating-label {
  color: #e91e63;
}
.form-group-material-pink-50 input.form-control:not(.empty) ~ .floating-label {
  color: #fce4ec;
}
.form-group-material-pink-100 input.form-control:not(.empty) ~ .floating-label {
  color: #f8bbd0;
}
.form-group-material-pink-200 input.form-control:not(.empty) ~ .floating-label {
  color: #f48fb1;
}
.form-group-material-pink-300 input.form-control:not(.empty) ~ .floating-label {
  color: #f06292;
}
.form-group-material-pink-400 input.form-control:not(.empty) ~ .floating-label {
  color: #ec407a;
}
.form-group-material-pink-500 input.form-control:not(.empty) ~ .floating-label {
  color: #e91e63;
}
.form-group-material-pink-600 input.form-control:not(.empty) ~ .floating-label {
  color: #d81b60;
}
.form-group-material-pink-700 input.form-control:not(.empty) ~ .floating-label {
  color: #c2185b;
}
.form-group-material-pink-800 input.form-control:not(.empty) ~ .floating-label {
  color: #ad1457;
}
.form-group-material-pink-900 input.form-control:not(.empty) ~ .floating-label {
  color: #880e4f;
}
.form-group-material-pink-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ff80ab;
}
.form-group-material-pink-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #ff4081;
}
.form-group-material-pink-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #f50057;
}
.form-group-material-pink-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #c51162;
}
.form-group-material-purple input.form-control:not(.empty) ~ .floating-label {
  color: #9c27b0;
}
.form-group-material-purple-50 input.form-control:not(.empty) ~ .floating-label {
  color: #f3e5f5;
}
.form-group-material-purple-100 input.form-control:not(.empty) ~ .floating-label {
  color: #e1bee7;
}
.form-group-material-purple-200 input.form-control:not(.empty) ~ .floating-label {
  color: #ce93d8;
}
.form-group-material-purple-300 input.form-control:not(.empty) ~ .floating-label {
  color: #ba68c8;
}
.form-group-material-purple-400 input.form-control:not(.empty) ~ .floating-label {
  color: #ab47bc;
}
.form-group-material-purple-500 input.form-control:not(.empty) ~ .floating-label {
  color: #9c27b0;
}
.form-group-material-purple-600 input.form-control:not(.empty) ~ .floating-label {
  color: #8e24aa;
}
.form-group-material-purple-700 input.form-control:not(.empty) ~ .floating-label {
  color: #7b1fa2;
}
.form-group-material-purple-800 input.form-control:not(.empty) ~ .floating-label {
  color: #6a1b9a;
}
.form-group-material-purple-900 input.form-control:not(.empty) ~ .floating-label {
  color: #4a148c;
}
.form-group-material-purple-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ea80fc;
}
.form-group-material-purple-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #e040fb;
}
.form-group-material-purple-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #d500f9;
}
.form-group-material-purple-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #aa00ff;
}
.form-group-material-deep-purple input.form-control:not(.empty) ~ .floating-label {
  color: #673ab7;
}
.form-group-material-deep-purple-50 input.form-control:not(.empty) ~ .floating-label {
  color: #ede7f6;
}
.form-group-material-deep-purple-100 input.form-control:not(.empty) ~ .floating-label {
  color: #d1c4e9;
}
.form-group-material-deep-purple-200 input.form-control:not(.empty) ~ .floating-label {
  color: #b39ddb;
}
.form-group-material-deep-purple-300 input.form-control:not(.empty) ~ .floating-label {
  color: #9575cd;
}
.form-group-material-deep-purple-400 input.form-control:not(.empty) ~ .floating-label {
  color: #7e57c2;
}
.form-group-material-deep-purple-500 input.form-control:not(.empty) ~ .floating-label {
  color: #673ab7;
}
.form-group-material-deep-purple-600 input.form-control:not(.empty) ~ .floating-label {
  color: #5e35b1;
}
.form-group-material-deep-purple-700 input.form-control:not(.empty) ~ .floating-label {
  color: #512da8;
}
.form-group-material-deep-purple-800 input.form-control:not(.empty) ~ .floating-label {
  color: #4527a0;
}
.form-group-material-deep-purple-900 input.form-control:not(.empty) ~ .floating-label {
  color: #311b92;
}
.form-group-material-deep-purple-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #b388ff;
}
.form-group-material-deep-purple-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #7c4dff;
}
.form-group-material-deep-purple-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #651fff;
}
.form-group-material-deep-purple-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #6200ea;
}
.form-group-material-indigo input.form-control:not(.empty) ~ .floating-label {
  color: #3f51b5;
}
.form-group-material-indigo-50 input.form-control:not(.empty) ~ .floating-label {
  color: #e8eaf6;
}
.form-group-material-indigo-100 input.form-control:not(.empty) ~ .floating-label {
  color: #c5cae9;
}
.form-group-material-indigo-200 input.form-control:not(.empty) ~ .floating-label {
  color: #9fa8da;
}
.form-group-material-indigo-300 input.form-control:not(.empty) ~ .floating-label {
  color: #7986cb;
}
.form-group-material-indigo-400 input.form-control:not(.empty) ~ .floating-label {
  color: #5c6bc0;
}
.form-group-material-indigo-500 input.form-control:not(.empty) ~ .floating-label {
  color: #3f51b5;
}
.form-group-material-indigo-600 input.form-control:not(.empty) ~ .floating-label {
  color: #3949ab;
}
.form-group-material-indigo-700 input.form-control:not(.empty) ~ .floating-label {
  color: #303f9f;
}
.form-group-material-indigo-800 input.form-control:not(.empty) ~ .floating-label {
  color: #283593;
}
.form-group-material-indigo-900 input.form-control:not(.empty) ~ .floating-label {
  color: #1a237e;
}
.form-group-material-indigo-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #8c9eff;
}
.form-group-material-indigo-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #536dfe;
}
.form-group-material-indigo-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #3d5afe;
}
.form-group-material-indigo-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #304ffe;
}
.form-group-material-blue input.form-control:not(.empty) ~ .floating-label {
  color: #2196f3;
}
.form-group-material-blue-50 input.form-control:not(.empty) ~ .floating-label {
  color: #e3f2fd;
}
.form-group-material-blue-100 input.form-control:not(.empty) ~ .floating-label {
  color: #bbdefb;
}
.form-group-material-blue-200 input.form-control:not(.empty) ~ .floating-label {
  color: #90caf9;
}
.form-group-material-blue-300 input.form-control:not(.empty) ~ .floating-label {
  color: #64b5f6;
}
.form-group-material-blue-400 input.form-control:not(.empty) ~ .floating-label {
  color: #42a5f5;
}
.form-group-material-blue-500 input.form-control:not(.empty) ~ .floating-label {
  color: #2196f3;
}
.form-group-material-blue-600 input.form-control:not(.empty) ~ .floating-label {
  color: #1e88e5;
}
.form-group-material-blue-700 input.form-control:not(.empty) ~ .floating-label {
  color: #1976d2;
}
.form-group-material-blue-800 input.form-control:not(.empty) ~ .floating-label {
  color: #1565c0;
}
.form-group-material-blue-900 input.form-control:not(.empty) ~ .floating-label {
  color: #0d47a1;
}
.form-group-material-blue-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #82b1ff;
}
.form-group-material-blue-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #448aff;
}
.form-group-material-blue-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #2979ff;
}
.form-group-material-blue-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #2962ff;
}
.form-group-material-light-blue input.form-control:not(.empty) ~ .floating-label {
  color: #03a9f4;
}
.form-group-material-light-blue-50 input.form-control:not(.empty) ~ .floating-label {
  color: #e1f5fe;
}
.form-group-material-light-blue-100 input.form-control:not(.empty) ~ .floating-label {
  color: #b3e5fc;
}
.form-group-material-light-blue-200 input.form-control:not(.empty) ~ .floating-label {
  color: #81d4fa;
}
.form-group-material-light-blue-300 input.form-control:not(.empty) ~ .floating-label {
  color: #4fc3f7;
}
.form-group-material-light-blue-400 input.form-control:not(.empty) ~ .floating-label {
  color: #29b6f6;
}
.form-group-material-light-blue-500 input.form-control:not(.empty) ~ .floating-label {
  color: #03a9f4;
}
.form-group-material-light-blue-600 input.form-control:not(.empty) ~ .floating-label {
  color: #039be5;
}
.form-group-material-light-blue-700 input.form-control:not(.empty) ~ .floating-label {
  color: #0288d1;
}
.form-group-material-light-blue-800 input.form-control:not(.empty) ~ .floating-label {
  color: #0277bd;
}
.form-group-material-light-blue-900 input.form-control:not(.empty) ~ .floating-label {
  color: #01579b;
}
.form-group-material-light-blue-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #80d8ff;
}
.form-group-material-light-blue-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #40c4ff;
}
.form-group-material-light-blue-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #00b0ff;
}
.form-group-material-light-blue-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #0091ea;
}
.form-group-material-cyan input.form-control:not(.empty) ~ .floating-label {
  color: #00bcd4;
}
.form-group-material-cyan-50 input.form-control:not(.empty) ~ .floating-label {
  color: #e0f7fa;
}
.form-group-material-cyan-100 input.form-control:not(.empty) ~ .floating-label {
  color: #b2ebf2;
}
.form-group-material-cyan-200 input.form-control:not(.empty) ~ .floating-label {
  color: #80deea;
}
.form-group-material-cyan-300 input.form-control:not(.empty) ~ .floating-label {
  color: #4dd0e1;
}
.form-group-material-cyan-400 input.form-control:not(.empty) ~ .floating-label {
  color: #26c6da;
}
.form-group-material-cyan-500 input.form-control:not(.empty) ~ .floating-label {
  color: #00bcd4;
}
.form-group-material-cyan-600 input.form-control:not(.empty) ~ .floating-label {
  color: #00acc1;
}
.form-group-material-cyan-700 input.form-control:not(.empty) ~ .floating-label {
  color: #0097a7;
}
.form-group-material-cyan-800 input.form-control:not(.empty) ~ .floating-label {
  color: #00838f;
}
.form-group-material-cyan-900 input.form-control:not(.empty) ~ .floating-label {
  color: #006064;
}
.form-group-material-cyan-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #84ffff;
}
.form-group-material-cyan-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #18ffff;
}
.form-group-material-cyan-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #00e5ff;
}
.form-group-material-cyan-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #00b8d4;
}
.form-group-material-teal input.form-control:not(.empty) ~ .floating-label {
  color: #009688;
}
.form-group-material-teal-50 input.form-control:not(.empty) ~ .floating-label {
  color: #e0f2f1;
}
.form-group-material-teal-100 input.form-control:not(.empty) ~ .floating-label {
  color: #b2dfdb;
}
.form-group-material-teal-200 input.form-control:not(.empty) ~ .floating-label {
  color: #80cbc4;
}
.form-group-material-teal-300 input.form-control:not(.empty) ~ .floating-label {
  color: #4db6ac;
}
.form-group-material-teal-400 input.form-control:not(.empty) ~ .floating-label {
  color: #26a69a;
}
.form-group-material-teal-500 input.form-control:not(.empty) ~ .floating-label {
  color: #009688;
}
.form-group-material-teal-600 input.form-control:not(.empty) ~ .floating-label {
  color: #00897b;
}
.form-group-material-teal-700 input.form-control:not(.empty) ~ .floating-label {
  color: #00796b;
}
.form-group-material-teal-800 input.form-control:not(.empty) ~ .floating-label {
  color: #00695c;
}
.form-group-material-teal-900 input.form-control:not(.empty) ~ .floating-label {
  color: #004d40;
}
.form-group-material-teal-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #a7ffeb;
}
.form-group-material-teal-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #64ffda;
}
.form-group-material-teal-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #1de9b6;
}
.form-group-material-teal-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #00bfa5;
}
.form-group-material-green input.form-control:not(.empty) ~ .floating-label {
  color: #4caf50;
}
.form-group-material-green-50 input.form-control:not(.empty) ~ .floating-label {
  color: #e8f5e9;
}
.form-group-material-green-100 input.form-control:not(.empty) ~ .floating-label {
  color: #c8e6c9;
}
.form-group-material-green-200 input.form-control:not(.empty) ~ .floating-label {
  color: #a5d6a7;
}
.form-group-material-green-300 input.form-control:not(.empty) ~ .floating-label {
  color: #81c784;
}
.form-group-material-green-400 input.form-control:not(.empty) ~ .floating-label {
  color: #66bb6a;
}
.form-group-material-green-500 input.form-control:not(.empty) ~ .floating-label {
  color: #4caf50;
}
.form-group-material-green-600 input.form-control:not(.empty) ~ .floating-label {
  color: #43a047;
}
.form-group-material-green-700 input.form-control:not(.empty) ~ .floating-label {
  color: #388e3c;
}
.form-group-material-green-800 input.form-control:not(.empty) ~ .floating-label {
  color: #2e7d32;
}
.form-group-material-green-900 input.form-control:not(.empty) ~ .floating-label {
  color: #1b5e20;
}
.form-group-material-green-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #b9f6ca;
}
.form-group-material-green-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #69f0ae;
}
.form-group-material-green-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #00e676;
}
.form-group-material-green-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #00c853;
}
.form-group-material-light-green input.form-control:not(.empty) ~ .floating-label {
  color: #8bc34a;
}
.form-group-material-light-green-50 input.form-control:not(.empty) ~ .floating-label {
  color: #f1f8e9;
}
.form-group-material-light-green-100 input.form-control:not(.empty) ~ .floating-label {
  color: #dcedc8;
}
.form-group-material-light-green-200 input.form-control:not(.empty) ~ .floating-label {
  color: #c5e1a5;
}
.form-group-material-light-green-300 input.form-control:not(.empty) ~ .floating-label {
  color: #aed581;
}
.form-group-material-light-green-400 input.form-control:not(.empty) ~ .floating-label {
  color: #9ccc65;
}
.form-group-material-light-green-500 input.form-control:not(.empty) ~ .floating-label {
  color: #8bc34a;
}
.form-group-material-light-green-600 input.form-control:not(.empty) ~ .floating-label {
  color: #7cb342;
}
.form-group-material-light-green-700 input.form-control:not(.empty) ~ .floating-label {
  color: #689f38;
}
.form-group-material-light-green-800 input.form-control:not(.empty) ~ .floating-label {
  color: #558b2f;
}
.form-group-material-light-green-900 input.form-control:not(.empty) ~ .floating-label {
  color: #33691e;
}
.form-group-material-light-green-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ccff90;
}
.form-group-material-light-green-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #b2ff59;
}
.form-group-material-light-green-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #76ff03;
}
.form-group-material-light-green-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #64dd17;
}
.form-group-material-lime input.form-control:not(.empty) ~ .floating-label {
  color: #cddc39;
}
.form-group-material-lime-50 input.form-control:not(.empty) ~ .floating-label {
  color: #f9fbe7;
}
.form-group-material-lime-100 input.form-control:not(.empty) ~ .floating-label {
  color: #f0f4c3;
}
.form-group-material-lime-200 input.form-control:not(.empty) ~ .floating-label {
  color: #e6ee9c;
}
.form-group-material-lime-300 input.form-control:not(.empty) ~ .floating-label {
  color: #dce775;
}
.form-group-material-lime-400 input.form-control:not(.empty) ~ .floating-label {
  color: #d4e157;
}
.form-group-material-lime-500 input.form-control:not(.empty) ~ .floating-label {
  color: #cddc39;
}
.form-group-material-lime-600 input.form-control:not(.empty) ~ .floating-label {
  color: #c0ca33;
}
.form-group-material-lime-700 input.form-control:not(.empty) ~ .floating-label {
  color: #afb42b;
}
.form-group-material-lime-800 input.form-control:not(.empty) ~ .floating-label {
  color: #9e9d24;
}
.form-group-material-lime-900 input.form-control:not(.empty) ~ .floating-label {
  color: #827717;
}
.form-group-material-lime-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #f4ff81;
}
.form-group-material-lime-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #eeff41;
}
.form-group-material-lime-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #c6ff00;
}
.form-group-material-lime-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #aeea00;
}
.form-group-material-yellow input.form-control:not(.empty) ~ .floating-label {
  color: #ffeb3b;
}
.form-group-material-yellow-50 input.form-control:not(.empty) ~ .floating-label {
  color: #fffde7;
}
.form-group-material-yellow-100 input.form-control:not(.empty) ~ .floating-label {
  color: #fff9c4;
}
.form-group-material-yellow-200 input.form-control:not(.empty) ~ .floating-label {
  color: #fff59d;
}
.form-group-material-yellow-300 input.form-control:not(.empty) ~ .floating-label {
  color: #fff176;
}
.form-group-material-yellow-400 input.form-control:not(.empty) ~ .floating-label {
  color: #ffee58;
}
.form-group-material-yellow-500 input.form-control:not(.empty) ~ .floating-label {
  color: #ffeb3b;
}
.form-group-material-yellow-600 input.form-control:not(.empty) ~ .floating-label {
  color: #fdd835;
}
.form-group-material-yellow-700 input.form-control:not(.empty) ~ .floating-label {
  color: #fbc02d;
}
.form-group-material-yellow-800 input.form-control:not(.empty) ~ .floating-label {
  color: #f9a825;
}
.form-group-material-yellow-900 input.form-control:not(.empty) ~ .floating-label {
  color: #f57f17;
}
.form-group-material-yellow-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ffff8d;
}
.form-group-material-yellow-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #ffff00;
}
.form-group-material-yellow-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #ffea00;
}
.form-group-material-yellow-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #ffd600;
}
.form-group-material-amber input.form-control:not(.empty) ~ .floating-label {
  color: #ffc107;
}
.form-group-material-amber-50 input.form-control:not(.empty) ~ .floating-label {
  color: #fff8e1;
}
.form-group-material-amber-100 input.form-control:not(.empty) ~ .floating-label {
  color: #ffecb3;
}
.form-group-material-amber-200 input.form-control:not(.empty) ~ .floating-label {
  color: #ffe082;
}
.form-group-material-amber-300 input.form-control:not(.empty) ~ .floating-label {
  color: #ffd54f;
}
.form-group-material-amber-400 input.form-control:not(.empty) ~ .floating-label {
  color: #ffca28;
}
.form-group-material-amber-500 input.form-control:not(.empty) ~ .floating-label {
  color: #ffc107;
}
.form-group-material-amber-600 input.form-control:not(.empty) ~ .floating-label {
  color: #ffb300;
}
.form-group-material-amber-700 input.form-control:not(.empty) ~ .floating-label {
  color: #ffa000;
}
.form-group-material-amber-800 input.form-control:not(.empty) ~ .floating-label {
  color: #ff8f00;
}
.form-group-material-amber-900 input.form-control:not(.empty) ~ .floating-label {
  color: #ff6f00;
}
.form-group-material-amber-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ffe57f;
}
.form-group-material-amber-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #ffd740;
}
.form-group-material-amber-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #ffc400;
}
.form-group-material-amber-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #ffab00;
}
.form-group-material-orange input.form-control:not(.empty) ~ .floating-label {
  color: #ff9800;
}
.form-group-material-orange-50 input.form-control:not(.empty) ~ .floating-label {
  color: #fff3e0;
}
.form-group-material-orange-100 input.form-control:not(.empty) ~ .floating-label {
  color: #ffe0b2;
}
.form-group-material-orange-200 input.form-control:not(.empty) ~ .floating-label {
  color: #ffcc80;
}
.form-group-material-orange-300 input.form-control:not(.empty) ~ .floating-label {
  color: #ffb74d;
}
.form-group-material-orange-400 input.form-control:not(.empty) ~ .floating-label {
  color: #ffa726;
}
.form-group-material-orange-500 input.form-control:not(.empty) ~ .floating-label {
  color: #ff9800;
}
.form-group-material-orange-600 input.form-control:not(.empty) ~ .floating-label {
  color: #fb8c00;
}
.form-group-material-orange-700 input.form-control:not(.empty) ~ .floating-label {
  color: #f57c00;
}
.form-group-material-orange-800 input.form-control:not(.empty) ~ .floating-label {
  color: #ef6c00;
}
.form-group-material-orange-900 input.form-control:not(.empty) ~ .floating-label {
  color: #e65100;
}
.form-group-material-orange-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ffd180;
}
.form-group-material-orange-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #ffab40;
}
.form-group-material-orange-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #ff9100;
}
.form-group-material-orange-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #ff6d00;
}
.form-group-material-deep-orange input.form-control:not(.empty) ~ .floating-label {
  color: #ff5722;
}
.form-group-material-deep-orange-50 input.form-control:not(.empty) ~ .floating-label {
  color: #fbe9e7;
}
.form-group-material-deep-orange-100 input.form-control:not(.empty) ~ .floating-label {
  color: #ffccbc;
}
.form-group-material-deep-orange-200 input.form-control:not(.empty) ~ .floating-label {
  color: #ffab91;
}
.form-group-material-deep-orange-300 input.form-control:not(.empty) ~ .floating-label {
  color: #ff8a65;
}
.form-group-material-deep-orange-400 input.form-control:not(.empty) ~ .floating-label {
  color: #ff7043;
}
.form-group-material-deep-orange-500 input.form-control:not(.empty) ~ .floating-label {
  color: #ff5722;
}
.form-group-material-deep-orange-600 input.form-control:not(.empty) ~ .floating-label {
  color: #f4511e;
}
.form-group-material-deep-orange-700 input.form-control:not(.empty) ~ .floating-label {
  color: #e64a19;
}
.form-group-material-deep-orange-800 input.form-control:not(.empty) ~ .floating-label {
  color: #d84315;
}
.form-group-material-deep-orange-900 input.form-control:not(.empty) ~ .floating-label {
  color: #bf360c;
}
.form-group-material-deep-orange-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #ff9e80;
}
.form-group-material-deep-orange-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #ff6e40;
}
.form-group-material-deep-orange-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #ff3d00;
}
.form-group-material-deep-orange-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #dd2c00;
}
.form-group-material-brown input.form-control:not(.empty) ~ .floating-label {
  color: #795548;
}
.form-group-material-brown-50 input.form-control:not(.empty) ~ .floating-label {
  color: #efebe9;
}
.form-group-material-brown-100 input.form-control:not(.empty) ~ .floating-label {
  color: #d7ccc8;
}
.form-group-material-brown-200 input.form-control:not(.empty) ~ .floating-label {
  color: #bcaaa4;
}
.form-group-material-brown-300 input.form-control:not(.empty) ~ .floating-label {
  color: #a1887f;
}
.form-group-material-brown-400 input.form-control:not(.empty) ~ .floating-label {
  color: #8d6e63;
}
.form-group-material-brown-500 input.form-control:not(.empty) ~ .floating-label {
  color: #795548;
}
.form-group-material-brown-600 input.form-control:not(.empty) ~ .floating-label {
  color: #6d4c41;
}
.form-group-material-brown-700 input.form-control:not(.empty) ~ .floating-label {
  color: #5d4037;
}
.form-group-material-brown-800 input.form-control:not(.empty) ~ .floating-label {
  color: #4e342e;
}
.form-group-material-brown-900 input.form-control:not(.empty) ~ .floating-label {
  color: #3e2723;
}
.form-group-material-brown-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #d7ccc8;
}
.form-group-material-brown-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #bcaaa4;
}
.form-group-material-brown-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #8d6e63;
}
.form-group-material-brown-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #5d4037;
}
.form-group-material-grey input.form-control:not(.empty) ~ .floating-label {
  color: #9e9e9e;
}
.form-group-material-grey-50 input.form-control:not(.empty) ~ .floating-label {
  color: #fafafa;
}
.form-group-material-grey-100 input.form-control:not(.empty) ~ .floating-label {
  color: #f5f5f5;
}
.form-group-material-grey-200 input.form-control:not(.empty) ~ .floating-label {
  color: #eeeeee;
}
.form-group-material-grey-300 input.form-control:not(.empty) ~ .floating-label {
  color: #e0e0e0;
}
.form-group-material-grey-400 input.form-control:not(.empty) ~ .floating-label {
  color: #bdbdbd;
}
.form-group-material-grey-500 input.form-control:not(.empty) ~ .floating-label {
  color: #9e9e9e;
}
.form-group-material-grey-600 input.form-control:not(.empty) ~ .floating-label {
  color: #757575;
}
.form-group-material-grey-700 input.form-control:not(.empty) ~ .floating-label {
  color: #616161;
}
.form-group-material-grey-800 input.form-control:not(.empty) ~ .floating-label {
  color: #424242;
}
.form-group-material-grey-900 input.form-control:not(.empty) ~ .floating-label {
  color: #212121;
}
.form-group-material-grey-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #f5f5f5;
}
.form-group-material-grey-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #eeeeee;
}
.form-group-material-grey-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #bdbdbd;
}
.form-group-material-grey-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #616161;
}
.form-group-material-blue input.form-control:not(.empty) ~ .floating-label {
  color: #607d8b;
}
.form-group-material-blue-50 input.form-control:not(.empty) ~ .floating-label {
  color: #eceff1;
}
.form-group-material-blue-100 input.form-control:not(.empty) ~ .floating-label {
  color: #cfd8dc;
}
.form-group-material-blue-200 input.form-control:not(.empty) ~ .floating-label {
  color: #b0bec5;
}
.form-group-material-blue-300 input.form-control:not(.empty) ~ .floating-label {
  color: #90a4ae;
}
.form-group-material-blue-400 input.form-control:not(.empty) ~ .floating-label {
  color: #78909c;
}
.form-group-material-blue-500 input.form-control:not(.empty) ~ .floating-label {
  color: #607d8b;
}
.form-group-material-blue-600 input.form-control:not(.empty) ~ .floating-label {
  color: #546e7a;
}
.form-group-material-blue-700 input.form-control:not(.empty) ~ .floating-label {
  color: #455a64;
}
.form-group-material-blue-800 input.form-control:not(.empty) ~ .floating-label {
  color: #37474f;
}
.form-group-material-blue-900 input.form-control:not(.empty) ~ .floating-label {
  color: #263238;
}
.form-group-material-blue-A100 input.form-control:not(.empty) ~ .floating-label {
  color: #cfd8dc;
}
.form-group-material-blue-A200 input.form-control:not(.empty) ~ .floating-label {
  color: #b0bec5;
}
.form-group-material-blue-A400 input.form-control:not(.empty) ~ .floating-label {
  color: #78909c;
}
.form-group-material-blue-A700 input.form-control:not(.empty) ~ .floating-label {
  color: #455a64;
}
.input-group .form-control-wrapper {
  margin-right: 5px;
  margin-left: 5px;
}
.input-group .form-control-wrapper .form-control {
  float: none;
}
.input-group .input-group-addon {
  border: 0;
  background: transparent;
}
.input-group .input-group-btn .btn {
  border-radius: 4px;
  margin: 0;
}
select.form-control {
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid #757575;
  border-radius: 0;
}
select.form-control:focus,
select.form-control.focus {
  box-shadow: none;
  border-color: #757575;
}
@keyframes input-highlight {
  0% {
    left: 20%;
    transform: scaleX(20%);
  }
  99% {
    transform: scaleX(0);
    left: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.form-control-wrapper input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
legend {
  border-bottom: 0;
}
.list-group {
  border-radius: 0;
}
.list-group .list-group-item {
  background-color: transparent;
  overflow: hidden;
  border: 0;
  border-radius: 0;
  padding: 0 16px;
}
.list-group .list-group-item .row-picture,
.list-group .list-group-item .row-action-primary {
  float: left;
  display: inline-block;
  padding-right: 16px;
}
.list-group .list-group-item .row-picture img,
.list-group .list-group-item .row-action-primary img,
.list-group .list-group-item .row-picture i,
.list-group .list-group-item .row-action-primary i,
.list-group .list-group-item .row-picture label,
.list-group .list-group-item .row-action-primary label {
  display: block;
  width: 56px;
  height: 56px;
}
.list-group .list-group-item .row-picture img,
.list-group .list-group-item .row-action-primary img {
  background: rgba(0, 0, 0, 0.1);
  padding: 1px;
}
.list-group .list-group-item .row-picture img.circle,
.list-group .list-group-item .row-action-primary img.circle {
  border-radius: 100%;
}
.list-group .list-group-item .row-picture i,
.list-group .list-group-item .row-action-primary i {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  text-align: center;
  line-height: 56px;
  font-size: 20px;
  color: white;
}
.list-group .list-group-item .row-picture label,
.list-group .list-group-item .row-action-primary label {
  margin-left: 7px;
  margin-right: -7px;
  margin-top: 5px;
  margin-bottom: -5px;
}
.list-group .list-group-item .row-content {
  display: inline-block;
  width: calc(100% - 92px);
  min-height: 66px;
}
.list-group .list-group-item .row-content .action-secondary {
  position: absolute;
  right: 16px;
  top: 16px;
}
.list-group .list-group-item .row-content .action-secondary i {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.list-group .list-group-item .row-content .action-secondary ~ * {
  max-width: calc(100% - 30px);
}
.list-group .list-group-item .row-content .least-content {
  position: absolute;
  right: 16px;
  top: 0px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.list-group .list-group-item .list-group-item-heading {
  color: rgba(0, 0, 0, 0.77);
  font-size: 20px;
  line-height: 29px;
}
.list-group .list-group-item.active:hover,
.list-group .list-group-item.active:focus {
  background: rgba(0, 0, 0, 0.15);
  outline: 10px solid rgba(0, 0, 0, 0.15);
}
.list-group .list-group-item.active .list-group-item-heading,
.list-group .list-group-item.active .list-group-item-text {
  color: rgba(0, 0, 0, 0.84);
}
.list-group .list-group-separator {
  clear: both;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
}
.list-group .list-group-separator:before {
  content: "";
  width: calc(100% - 90px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  float: right;
}
.navbar {
  background-color: #009587;
  border: 0;
  border-radius: 0;
}
.navbar .navbar-brand {
  position: relative;
  height: 60px;
  line-height: 30px;
  color: inherit;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: inherit;
  background-color: transparent;
}
.navbar .navbar-text {
  color: inherit;
  margin-top: 20px;
  margin-bottom: 20px;
}
.navbar .navbar-nav > li > a {
  color: inherit;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  color: inherit;
  background-color: transparent;
}
.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar .navbar-nav > .disabled > a,
.navbar .navbar-nav > .disabled > a:hover,
.navbar .navbar-nav > .disabled > a:focus {
  color: inherit;
  background-color: transparent;
  opacity: 0.9;
}
.navbar .navbar-toggle {
  border: 0;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: transparent;
}
.navbar .navbar-toggle .icon-bar {
  background-color: inherit;
  border: 1px solid;
}
.navbar .navbar-default .navbar-toggle,
.navbar .navbar-inverse .navbar-toggle {
  border-color: transparent;
}
.navbar .navbar-collapse,
.navbar .navbar-form {
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar .navbar-nav > .open > a,
.navbar .navbar-nav > .open > a:hover,
.navbar .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: inherit;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .dropdown-header {
    border: 0;
    color: inherit;
  }
  .navbar .navbar-nav .open .dropdown-menu .divider {
    border-bottom: 1px solid;
    opacity: 0.08;
  }
  .navbar .navbar-nav .open .dropdown-menu > li > a {
    color: inherit;
  }
  .navbar .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > li > a:focus {
    color: inherit;
    background-color: transparent;
  }
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: inherit;
    background-color: transparent;
  }
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: inherit;
    background-color: transparent;
  }
}
.navbar .navbar-link {
  color: inherit;
}
.navbar .navbar-link:hover {
  color: inherit;
}
.navbar .btn-link {
  color: inherit;
}
.navbar .btn-link:hover,
.navbar .btn-link:focus {
  color: inherit;
}
.navbar .btn-link[disabled]:hover,
fieldset[disabled] .navbar .btn-link:hover,
.navbar .btn-link[disabled]:focus,
fieldset[disabled] .navbar .btn-link:focus {
  color: inherit;
}
.navbar .navbar-form {
  margin-top: 16px;
}
.navbar .navbar-form .form-control-wrapper .form-control,
.navbar .navbar-form .form-control {
  border-color: inherit;
  color: inherit;
}
.navbar .navbar-form .form-control-wrapper .material-input:before,
.navbar .navbar-form .form-control-wrapper input:focus ~ .material-input:after {
  background-color: inherit;
}
.navbar,
.navbar-default {
  background-color: #009587;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-primary {
  background-color: #009587;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-success {
  background-color: #0f9d58;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-info {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-warning {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-danger {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-50 {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-100 {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-200 {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-300 {
  background-color: #e57373;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-400 {
  background-color: #ef5350;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-500 {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-600 {
  background-color: #e53935;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-700 {
  background-color: #d32f2f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-800 {
  background-color: #c62828;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-900 {
  background-color: #b71c1c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A100 {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-A200 {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A400 {
  background-color: #ff1744;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A700 {
  background-color: #d50000;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-50 {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-100 {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-200 {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-300 {
  background-color: #f06292;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-400 {
  background-color: #ec407a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-500 {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-600 {
  background-color: #d81b60;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-700 {
  background-color: #c2185b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-800 {
  background-color: #ad1457;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-900 {
  background-color: #880e4f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A100 {
  background-color: #ff80ab;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A200 {
  background-color: #ff4081;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A400 {
  background-color: #f50057;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A700 {
  background-color: #c51162;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-50 {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-100 {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-200 {
  background-color: #ce93d8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-300 {
  background-color: #ba68c8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-400 {
  background-color: #ab47bc;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-500 {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-600 {
  background-color: #8e24aa;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-700 {
  background-color: #7b1fa2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-800 {
  background-color: #6a1b9a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-900 {
  background-color: #4a148c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A100 {
  background-color: #ea80fc;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A200 {
  background-color: #e040fb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A400 {
  background-color: #d500f9;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A700 {
  background-color: #aa00ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-50 {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-100 {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-200 {
  background-color: #b39ddb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-300 {
  background-color: #9575cd;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-400 {
  background-color: #7e57c2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-500 {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-600 {
  background-color: #5e35b1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-700 {
  background-color: #512da8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-800 {
  background-color: #4527a0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-900 {
  background-color: #311b92;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A100 {
  background-color: #b388ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A200 {
  background-color: #7c4dff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A400 {
  background-color: #651fff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A700 {
  background-color: #6200ea;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-50 {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-100 {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-200 {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-300 {
  background-color: #7986cb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-400 {
  background-color: #5c6bc0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-500 {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-600 {
  background-color: #3949ab;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-700 {
  background-color: #303f9f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-800 {
  background-color: #283593;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-900 {
  background-color: #1a237e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A100 {
  background-color: #8c9eff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A200 {
  background-color: #536dfe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A400 {
  background-color: #3d5afe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A700 {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-50 {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100 {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200 {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300 {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-400 {
  background-color: #42a5f5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500 {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600 {
  background-color: #1e88e5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700 {
  background-color: #1976d2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800 {
  background-color: #1565c0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900 {
  background-color: #0d47a1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A100 {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A200 {
  background-color: #448aff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A400 {
  background-color: #2979ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700 {
  background-color: #2962ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-50 {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-100 {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-200 {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-300 {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-400 {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-500 {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-600 {
  background-color: #039be5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-700 {
  background-color: #0288d1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-800 {
  background-color: #0277bd;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-900 {
  background-color: #01579b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A100 {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A200 {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A400 {
  background-color: #00b0ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A700 {
  background-color: #0091ea;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-50 {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-100 {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-200 {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-300 {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-400 {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-500 {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-600 {
  background-color: #00acc1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-700 {
  background-color: #0097a7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-800 {
  background-color: #00838f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-900 {
  background-color: #006064;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-A100 {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A200 {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A400 {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A700 {
  background-color: #00b8d4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-50 {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-100 {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-200 {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-300 {
  background-color: #4db6ac;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-400 {
  background-color: #26a69a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-500 {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-600 {
  background-color: #00897b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-700 {
  background-color: #00796b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-800 {
  background-color: #00695c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-900 {
  background-color: #004d40;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-A100 {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A200 {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A400 {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A700 {
  background-color: #00bfa5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-50 {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-100 {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-200 {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-300 {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-400 {
  background-color: #66bb6a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-500 {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-600 {
  background-color: #43a047;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-700 {
  background-color: #388e3c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-800 {
  background-color: #2e7d32;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-900 {
  background-color: #1b5e20;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-A100 {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A200 {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A400 {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A700 {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-50 {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-100 {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-200 {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-300 {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-400 {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-500 {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-600 {
  background-color: #7cb342;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-700 {
  background-color: #689f38;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-800 {
  background-color: #558b2f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-900 {
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-A100 {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A200 {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A400 {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A700 {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-50 {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-100 {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-200 {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-300 {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-400 {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-500 {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-600 {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-700 {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-800 {
  background-color: #9e9d24;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-900 {
  background-color: #827717;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-A100 {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A200 {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A400 {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A700 {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-50 {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-100 {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-200 {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-300 {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-400 {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-500 {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-600 {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-700 {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-800 {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-900 {
  background-color: #f57f17;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-yellow-A100 {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A200 {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A400 {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A700 {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-50 {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-100 {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-200 {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-300 {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-400 {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-500 {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-600 {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-700 {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-800 {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-900 {
  background-color: #ff6f00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-amber-A100 {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A200 {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A400 {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A700 {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-50 {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-100 {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-200 {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-300 {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-400 {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-500 {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-600 {
  background-color: #fb8c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-700 {
  background-color: #f57c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-800 {
  background-color: #ef6c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-900 {
  background-color: #e65100;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-A100 {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A200 {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A400 {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A700 {
  background-color: #ff6d00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-50 {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-100 {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-200 {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-300 {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-400 {
  background-color: #ff7043;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-500 {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-600 {
  background-color: #f4511e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-700 {
  background-color: #e64a19;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-800 {
  background-color: #d84315;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-900 {
  background-color: #bf360c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A100 {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-A200 {
  background-color: #ff6e40;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A400 {
  background-color: #ff3d00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A700 {
  background-color: #dd2c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-50 {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-300 {
  background-color: #a1887f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-400 {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-500 {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-600 {
  background-color: #6d4c41;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-700 {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-800 {
  background-color: #4e342e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-900 {
  background-color: #3e2723;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A400 {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A700 {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-50 {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-100 {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-300 {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-500 {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-600 {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-700 {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-800 {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-900 {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-A100 {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A700 {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-50 {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300 {
  background-color: #90a4ae;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-400 {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500 {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600 {
  background-color: #546e7a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700 {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800 {
  background-color: #37474f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900 {
  background-color: #263238;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A400 {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700 {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.navbar .form-control::placeholder,
.navbar-default .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-primary .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-success .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-info .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-warning .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-danger .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-A200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A100 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A100 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A100 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A100 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-500 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-500 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-500 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-600 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-500 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-600 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-800 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-yellow-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-500 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-600 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-800 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-amber-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A700 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-500 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-A200 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-300 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A400 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-50 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A100 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A200 .form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A400 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-inverse {
  background-color: #3f51b5;
}
.navbar-material-white {
  background-color: #FFF;
}
.navbar-material-white .navbar-brand,
.navbar-material-white .navbar-brand:hover,
.navbar-material-white .navbar-brand:focus {
  color: inherit;
}
.navbar-material-white .navbar-nav > li > a {
  color: inherit;
}
.navbar-material-white .navbar-nav > li > a:hover,
.navbar-material-white .navbar-nav > li > a:focus {
  color: inherit;
  background-color: transparent;
}
.navbar-material-white .navbar-nav > .active > a,
.navbar-material-white .navbar-nav > .active > a:hover,
.navbar-material-white .navbar-nav > .active > a:focus {
  color: inherit;
  background-color: transparent;
}
.navbar-material-white .navbar-nav > .disabled > a,
.navbar-material-white .navbar-nav > .disabled > a:hover,
.navbar-material-white .navbar-nav > .disabled > a:focus {
  color: inherit;
  background-color: transparent;
}
.navbar-material-white .navbar-nav > .open > a,
.navbar-material-white .navbar-nav > .open > a:hover,
.navbar-material-white .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: inherit;
}
@media (max-width: 1199px) {
  .navbar .navbar-brand {
    height: 50px;
    padding: 10px 15px;
  }
  .navbar .navbar-form {
    margin-top: 10px;
  }
  .navbar .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.dropdown-menu .divider {
  background-color: rgba(229, 229, 229, 0.12);
}
.dropdown-menu li {
  overflow: hidden;
  position: relative;
}
.dropdown-menu li a:hover {
  background-color: transparent;
  color: #009587;
}
.dropdown-menu li a:hover,
.dropdown-menu-default li a:hover {
  color: #009587;
}
.dropdown-menu-primary li a:hover {
  color: #009587;
}
.dropdown-menu-success li a:hover {
  color: #0f9d58;
}
.dropdown-menu-info li a:hover {
  color: #03a9f4;
}
.dropdown-menu-warning li a:hover {
  color: #ff5722;
}
.dropdown-menu-danger li a:hover {
  color: #f44336;
}
.dropdown-menu-material-red li a:hover {
  color: #f44336;
}
.dropdown-menu-material-red-50 li a:hover {
  color: #ffebee;
}
.dropdown-menu-material-red-100 li a:hover {
  color: #ffcdd2;
}
.dropdown-menu-material-red-200 li a:hover {
  color: #ef9a9a;
}
.dropdown-menu-material-red-300 li a:hover {
  color: #e57373;
}
.dropdown-menu-material-red-400 li a:hover {
  color: #ef5350;
}
.dropdown-menu-material-red-500 li a:hover {
  color: #f44336;
}
.dropdown-menu-material-red-600 li a:hover {
  color: #e53935;
}
.dropdown-menu-material-red-700 li a:hover {
  color: #d32f2f;
}
.dropdown-menu-material-red-800 li a:hover {
  color: #c62828;
}
.dropdown-menu-material-red-900 li a:hover {
  color: #b71c1c;
}
.dropdown-menu-material-red-A100 li a:hover {
  color: #ff8a80;
}
.dropdown-menu-material-red-A200 li a:hover {
  color: #ff5252;
}
.dropdown-menu-material-red-A400 li a:hover {
  color: #ff1744;
}
.dropdown-menu-material-red-A700 li a:hover {
  color: #d50000;
}
.dropdown-menu-material-pink li a:hover {
  color: #e91e63;
}
.dropdown-menu-material-pink-50 li a:hover {
  color: #fce4ec;
}
.dropdown-menu-material-pink-100 li a:hover {
  color: #f8bbd0;
}
.dropdown-menu-material-pink-200 li a:hover {
  color: #f48fb1;
}
.dropdown-menu-material-pink-300 li a:hover {
  color: #f06292;
}
.dropdown-menu-material-pink-400 li a:hover {
  color: #ec407a;
}
.dropdown-menu-material-pink-500 li a:hover {
  color: #e91e63;
}
.dropdown-menu-material-pink-600 li a:hover {
  color: #d81b60;
}
.dropdown-menu-material-pink-700 li a:hover {
  color: #c2185b;
}
.dropdown-menu-material-pink-800 li a:hover {
  color: #ad1457;
}
.dropdown-menu-material-pink-900 li a:hover {
  color: #880e4f;
}
.dropdown-menu-material-pink-A100 li a:hover {
  color: #ff80ab;
}
.dropdown-menu-material-pink-A200 li a:hover {
  color: #ff4081;
}
.dropdown-menu-material-pink-A400 li a:hover {
  color: #f50057;
}
.dropdown-menu-material-pink-A700 li a:hover {
  color: #c51162;
}
.dropdown-menu-material-purple li a:hover {
  color: #9c27b0;
}
.dropdown-menu-material-purple-50 li a:hover {
  color: #f3e5f5;
}
.dropdown-menu-material-purple-100 li a:hover {
  color: #e1bee7;
}
.dropdown-menu-material-purple-200 li a:hover {
  color: #ce93d8;
}
.dropdown-menu-material-purple-300 li a:hover {
  color: #ba68c8;
}
.dropdown-menu-material-purple-400 li a:hover {
  color: #ab47bc;
}
.dropdown-menu-material-purple-500 li a:hover {
  color: #9c27b0;
}
.dropdown-menu-material-purple-600 li a:hover {
  color: #8e24aa;
}
.dropdown-menu-material-purple-700 li a:hover {
  color: #7b1fa2;
}
.dropdown-menu-material-purple-800 li a:hover {
  color: #6a1b9a;
}
.dropdown-menu-material-purple-900 li a:hover {
  color: #4a148c;
}
.dropdown-menu-material-purple-A100 li a:hover {
  color: #ea80fc;
}
.dropdown-menu-material-purple-A200 li a:hover {
  color: #e040fb;
}
.dropdown-menu-material-purple-A400 li a:hover {
  color: #d500f9;
}
.dropdown-menu-material-purple-A700 li a:hover {
  color: #aa00ff;
}
.dropdown-menu-material-deep-purple li a:hover {
  color: #673ab7;
}
.dropdown-menu-material-deep-purple-50 li a:hover {
  color: #ede7f6;
}
.dropdown-menu-material-deep-purple-100 li a:hover {
  color: #d1c4e9;
}
.dropdown-menu-material-deep-purple-200 li a:hover {
  color: #b39ddb;
}
.dropdown-menu-material-deep-purple-300 li a:hover {
  color: #9575cd;
}
.dropdown-menu-material-deep-purple-400 li a:hover {
  color: #7e57c2;
}
.dropdown-menu-material-deep-purple-500 li a:hover {
  color: #673ab7;
}
.dropdown-menu-material-deep-purple-600 li a:hover {
  color: #5e35b1;
}
.dropdown-menu-material-deep-purple-700 li a:hover {
  color: #512da8;
}
.dropdown-menu-material-deep-purple-800 li a:hover {
  color: #4527a0;
}
.dropdown-menu-material-deep-purple-900 li a:hover {
  color: #311b92;
}
.dropdown-menu-material-deep-purple-A100 li a:hover {
  color: #b388ff;
}
.dropdown-menu-material-deep-purple-A200 li a:hover {
  color: #7c4dff;
}
.dropdown-menu-material-deep-purple-A400 li a:hover {
  color: #651fff;
}
.dropdown-menu-material-deep-purple-A700 li a:hover {
  color: #6200ea;
}
.dropdown-menu-material-indigo li a:hover {
  color: #3f51b5;
}
.dropdown-menu-material-indigo-50 li a:hover {
  color: #e8eaf6;
}
.dropdown-menu-material-indigo-100 li a:hover {
  color: #c5cae9;
}
.dropdown-menu-material-indigo-200 li a:hover {
  color: #9fa8da;
}
.dropdown-menu-material-indigo-300 li a:hover {
  color: #7986cb;
}
.dropdown-menu-material-indigo-400 li a:hover {
  color: #5c6bc0;
}
.dropdown-menu-material-indigo-500 li a:hover {
  color: #3f51b5;
}
.dropdown-menu-material-indigo-600 li a:hover {
  color: #3949ab;
}
.dropdown-menu-material-indigo-700 li a:hover {
  color: #303f9f;
}
.dropdown-menu-material-indigo-800 li a:hover {
  color: #283593;
}
.dropdown-menu-material-indigo-900 li a:hover {
  color: #1a237e;
}
.dropdown-menu-material-indigo-A100 li a:hover {
  color: #8c9eff;
}
.dropdown-menu-material-indigo-A200 li a:hover {
  color: #536dfe;
}
.dropdown-menu-material-indigo-A400 li a:hover {
  color: #3d5afe;
}
.dropdown-menu-material-indigo-A700 li a:hover {
  color: #304ffe;
}
.dropdown-menu-material-blue li a:hover {
  color: #2196f3;
}
.dropdown-menu-material-blue-50 li a:hover {
  color: #e3f2fd;
}
.dropdown-menu-material-blue-100 li a:hover {
  color: #bbdefb;
}
.dropdown-menu-material-blue-200 li a:hover {
  color: #90caf9;
}
.dropdown-menu-material-blue-300 li a:hover {
  color: #64b5f6;
}
.dropdown-menu-material-blue-400 li a:hover {
  color: #42a5f5;
}
.dropdown-menu-material-blue-500 li a:hover {
  color: #2196f3;
}
.dropdown-menu-material-blue-600 li a:hover {
  color: #1e88e5;
}
.dropdown-menu-material-blue-700 li a:hover {
  color: #1976d2;
}
.dropdown-menu-material-blue-800 li a:hover {
  color: #1565c0;
}
.dropdown-menu-material-blue-900 li a:hover {
  color: #0d47a1;
}
.dropdown-menu-material-blue-A100 li a:hover {
  color: #82b1ff;
}
.dropdown-menu-material-blue-A200 li a:hover {
  color: #448aff;
}
.dropdown-menu-material-blue-A400 li a:hover {
  color: #2979ff;
}
.dropdown-menu-material-blue-A700 li a:hover {
  color: #2962ff;
}
.dropdown-menu-material-light-blue li a:hover {
  color: #03a9f4;
}
.dropdown-menu-material-light-blue-50 li a:hover {
  color: #e1f5fe;
}
.dropdown-menu-material-light-blue-100 li a:hover {
  color: #b3e5fc;
}
.dropdown-menu-material-light-blue-200 li a:hover {
  color: #81d4fa;
}
.dropdown-menu-material-light-blue-300 li a:hover {
  color: #4fc3f7;
}
.dropdown-menu-material-light-blue-400 li a:hover {
  color: #29b6f6;
}
.dropdown-menu-material-light-blue-500 li a:hover {
  color: #03a9f4;
}
.dropdown-menu-material-light-blue-600 li a:hover {
  color: #039be5;
}
.dropdown-menu-material-light-blue-700 li a:hover {
  color: #0288d1;
}
.dropdown-menu-material-light-blue-800 li a:hover {
  color: #0277bd;
}
.dropdown-menu-material-light-blue-900 li a:hover {
  color: #01579b;
}
.dropdown-menu-material-light-blue-A100 li a:hover {
  color: #80d8ff;
}
.dropdown-menu-material-light-blue-A200 li a:hover {
  color: #40c4ff;
}
.dropdown-menu-material-light-blue-A400 li a:hover {
  color: #00b0ff;
}
.dropdown-menu-material-light-blue-A700 li a:hover {
  color: #0091ea;
}
.dropdown-menu-material-cyan li a:hover {
  color: #00bcd4;
}
.dropdown-menu-material-cyan-50 li a:hover {
  color: #e0f7fa;
}
.dropdown-menu-material-cyan-100 li a:hover {
  color: #b2ebf2;
}
.dropdown-menu-material-cyan-200 li a:hover {
  color: #80deea;
}
.dropdown-menu-material-cyan-300 li a:hover {
  color: #4dd0e1;
}
.dropdown-menu-material-cyan-400 li a:hover {
  color: #26c6da;
}
.dropdown-menu-material-cyan-500 li a:hover {
  color: #00bcd4;
}
.dropdown-menu-material-cyan-600 li a:hover {
  color: #00acc1;
}
.dropdown-menu-material-cyan-700 li a:hover {
  color: #0097a7;
}
.dropdown-menu-material-cyan-800 li a:hover {
  color: #00838f;
}
.dropdown-menu-material-cyan-900 li a:hover {
  color: #006064;
}
.dropdown-menu-material-cyan-A100 li a:hover {
  color: #84ffff;
}
.dropdown-menu-material-cyan-A200 li a:hover {
  color: #18ffff;
}
.dropdown-menu-material-cyan-A400 li a:hover {
  color: #00e5ff;
}
.dropdown-menu-material-cyan-A700 li a:hover {
  color: #00b8d4;
}
.dropdown-menu-material-teal li a:hover {
  color: #009688;
}
.dropdown-menu-material-teal-50 li a:hover {
  color: #e0f2f1;
}
.dropdown-menu-material-teal-100 li a:hover {
  color: #b2dfdb;
}
.dropdown-menu-material-teal-200 li a:hover {
  color: #80cbc4;
}
.dropdown-menu-material-teal-300 li a:hover {
  color: #4db6ac;
}
.dropdown-menu-material-teal-400 li a:hover {
  color: #26a69a;
}
.dropdown-menu-material-teal-500 li a:hover {
  color: #009688;
}
.dropdown-menu-material-teal-600 li a:hover {
  color: #00897b;
}
.dropdown-menu-material-teal-700 li a:hover {
  color: #00796b;
}
.dropdown-menu-material-teal-800 li a:hover {
  color: #00695c;
}
.dropdown-menu-material-teal-900 li a:hover {
  color: #004d40;
}
.dropdown-menu-material-teal-A100 li a:hover {
  color: #a7ffeb;
}
.dropdown-menu-material-teal-A200 li a:hover {
  color: #64ffda;
}
.dropdown-menu-material-teal-A400 li a:hover {
  color: #1de9b6;
}
.dropdown-menu-material-teal-A700 li a:hover {
  color: #00bfa5;
}
.dropdown-menu-material-green li a:hover {
  color: #4caf50;
}
.dropdown-menu-material-green-50 li a:hover {
  color: #e8f5e9;
}
.dropdown-menu-material-green-100 li a:hover {
  color: #c8e6c9;
}
.dropdown-menu-material-green-200 li a:hover {
  color: #a5d6a7;
}
.dropdown-menu-material-green-300 li a:hover {
  color: #81c784;
}
.dropdown-menu-material-green-400 li a:hover {
  color: #66bb6a;
}
.dropdown-menu-material-green-500 li a:hover {
  color: #4caf50;
}
.dropdown-menu-material-green-600 li a:hover {
  color: #43a047;
}
.dropdown-menu-material-green-700 li a:hover {
  color: #388e3c;
}
.dropdown-menu-material-green-800 li a:hover {
  color: #2e7d32;
}
.dropdown-menu-material-green-900 li a:hover {
  color: #1b5e20;
}
.dropdown-menu-material-green-A100 li a:hover {
  color: #b9f6ca;
}
.dropdown-menu-material-green-A200 li a:hover {
  color: #69f0ae;
}
.dropdown-menu-material-green-A400 li a:hover {
  color: #00e676;
}
.dropdown-menu-material-green-A700 li a:hover {
  color: #00c853;
}
.dropdown-menu-material-light-green li a:hover {
  color: #8bc34a;
}
.dropdown-menu-material-light-green-50 li a:hover {
  color: #f1f8e9;
}
.dropdown-menu-material-light-green-100 li a:hover {
  color: #dcedc8;
}
.dropdown-menu-material-light-green-200 li a:hover {
  color: #c5e1a5;
}
.dropdown-menu-material-light-green-300 li a:hover {
  color: #aed581;
}
.dropdown-menu-material-light-green-400 li a:hover {
  color: #9ccc65;
}
.dropdown-menu-material-light-green-500 li a:hover {
  color: #8bc34a;
}
.dropdown-menu-material-light-green-600 li a:hover {
  color: #7cb342;
}
.dropdown-menu-material-light-green-700 li a:hover {
  color: #689f38;
}
.dropdown-menu-material-light-green-800 li a:hover {
  color: #558b2f;
}
.dropdown-menu-material-light-green-900 li a:hover {
  color: #33691e;
}
.dropdown-menu-material-light-green-A100 li a:hover {
  color: #ccff90;
}
.dropdown-menu-material-light-green-A200 li a:hover {
  color: #b2ff59;
}
.dropdown-menu-material-light-green-A400 li a:hover {
  color: #76ff03;
}
.dropdown-menu-material-light-green-A700 li a:hover {
  color: #64dd17;
}
.dropdown-menu-material-lime li a:hover {
  color: #cddc39;
}
.dropdown-menu-material-lime-50 li a:hover {
  color: #f9fbe7;
}
.dropdown-menu-material-lime-100 li a:hover {
  color: #f0f4c3;
}
.dropdown-menu-material-lime-200 li a:hover {
  color: #e6ee9c;
}
.dropdown-menu-material-lime-300 li a:hover {
  color: #dce775;
}
.dropdown-menu-material-lime-400 li a:hover {
  color: #d4e157;
}
.dropdown-menu-material-lime-500 li a:hover {
  color: #cddc39;
}
.dropdown-menu-material-lime-600 li a:hover {
  color: #c0ca33;
}
.dropdown-menu-material-lime-700 li a:hover {
  color: #afb42b;
}
.dropdown-menu-material-lime-800 li a:hover {
  color: #9e9d24;
}
.dropdown-menu-material-lime-900 li a:hover {
  color: #827717;
}
.dropdown-menu-material-lime-A100 li a:hover {
  color: #f4ff81;
}
.dropdown-menu-material-lime-A200 li a:hover {
  color: #eeff41;
}
.dropdown-menu-material-lime-A400 li a:hover {
  color: #c6ff00;
}
.dropdown-menu-material-lime-A700 li a:hover {
  color: #aeea00;
}
.dropdown-menu-material-yellow li a:hover {
  color: #ffeb3b;
}
.dropdown-menu-material-yellow-50 li a:hover {
  color: #fffde7;
}
.dropdown-menu-material-yellow-100 li a:hover {
  color: #fff9c4;
}
.dropdown-menu-material-yellow-200 li a:hover {
  color: #fff59d;
}
.dropdown-menu-material-yellow-300 li a:hover {
  color: #fff176;
}
.dropdown-menu-material-yellow-400 li a:hover {
  color: #ffee58;
}
.dropdown-menu-material-yellow-500 li a:hover {
  color: #ffeb3b;
}
.dropdown-menu-material-yellow-600 li a:hover {
  color: #fdd835;
}
.dropdown-menu-material-yellow-700 li a:hover {
  color: #fbc02d;
}
.dropdown-menu-material-yellow-800 li a:hover {
  color: #f9a825;
}
.dropdown-menu-material-yellow-900 li a:hover {
  color: #f57f17;
}
.dropdown-menu-material-yellow-A100 li a:hover {
  color: #ffff8d;
}
.dropdown-menu-material-yellow-A200 li a:hover {
  color: #ffff00;
}
.dropdown-menu-material-yellow-A400 li a:hover {
  color: #ffea00;
}
.dropdown-menu-material-yellow-A700 li a:hover {
  color: #ffd600;
}
.dropdown-menu-material-amber li a:hover {
  color: #ffc107;
}
.dropdown-menu-material-amber-50 li a:hover {
  color: #fff8e1;
}
.dropdown-menu-material-amber-100 li a:hover {
  color: #ffecb3;
}
.dropdown-menu-material-amber-200 li a:hover {
  color: #ffe082;
}
.dropdown-menu-material-amber-300 li a:hover {
  color: #ffd54f;
}
.dropdown-menu-material-amber-400 li a:hover {
  color: #ffca28;
}
.dropdown-menu-material-amber-500 li a:hover {
  color: #ffc107;
}
.dropdown-menu-material-amber-600 li a:hover {
  color: #ffb300;
}
.dropdown-menu-material-amber-700 li a:hover {
  color: #ffa000;
}
.dropdown-menu-material-amber-800 li a:hover {
  color: #ff8f00;
}
.dropdown-menu-material-amber-900 li a:hover {
  color: #ff6f00;
}
.dropdown-menu-material-amber-A100 li a:hover {
  color: #ffe57f;
}
.dropdown-menu-material-amber-A200 li a:hover {
  color: #ffd740;
}
.dropdown-menu-material-amber-A400 li a:hover {
  color: #ffc400;
}
.dropdown-menu-material-amber-A700 li a:hover {
  color: #ffab00;
}
.dropdown-menu-material-orange li a:hover {
  color: #ff9800;
}
.dropdown-menu-material-orange-50 li a:hover {
  color: #fff3e0;
}
.dropdown-menu-material-orange-100 li a:hover {
  color: #ffe0b2;
}
.dropdown-menu-material-orange-200 li a:hover {
  color: #ffcc80;
}
.dropdown-menu-material-orange-300 li a:hover {
  color: #ffb74d;
}
.dropdown-menu-material-orange-400 li a:hover {
  color: #ffa726;
}
.dropdown-menu-material-orange-500 li a:hover {
  color: #ff9800;
}
.dropdown-menu-material-orange-600 li a:hover {
  color: #fb8c00;
}
.dropdown-menu-material-orange-700 li a:hover {
  color: #f57c00;
}
.dropdown-menu-material-orange-800 li a:hover {
  color: #ef6c00;
}
.dropdown-menu-material-orange-900 li a:hover {
  color: #e65100;
}
.dropdown-menu-material-orange-A100 li a:hover {
  color: #ffd180;
}
.dropdown-menu-material-orange-A200 li a:hover {
  color: #ffab40;
}
.dropdown-menu-material-orange-A400 li a:hover {
  color: #ff9100;
}
.dropdown-menu-material-orange-A700 li a:hover {
  color: #ff6d00;
}
.dropdown-menu-material-deep-orange li a:hover {
  color: #ff5722;
}
.dropdown-menu-material-deep-orange-50 li a:hover {
  color: #fbe9e7;
}
.dropdown-menu-material-deep-orange-100 li a:hover {
  color: #ffccbc;
}
.dropdown-menu-material-deep-orange-200 li a:hover {
  color: #ffab91;
}
.dropdown-menu-material-deep-orange-300 li a:hover {
  color: #ff8a65;
}
.dropdown-menu-material-deep-orange-400 li a:hover {
  color: #ff7043;
}
.dropdown-menu-material-deep-orange-500 li a:hover {
  color: #ff5722;
}
.dropdown-menu-material-deep-orange-600 li a:hover {
  color: #f4511e;
}
.dropdown-menu-material-deep-orange-700 li a:hover {
  color: #e64a19;
}
.dropdown-menu-material-deep-orange-800 li a:hover {
  color: #d84315;
}
.dropdown-menu-material-deep-orange-900 li a:hover {
  color: #bf360c;
}
.dropdown-menu-material-deep-orange-A100 li a:hover {
  color: #ff9e80;
}
.dropdown-menu-material-deep-orange-A200 li a:hover {
  color: #ff6e40;
}
.dropdown-menu-material-deep-orange-A400 li a:hover {
  color: #ff3d00;
}
.dropdown-menu-material-deep-orange-A700 li a:hover {
  color: #dd2c00;
}
.dropdown-menu-material-brown li a:hover {
  color: #795548;
}
.dropdown-menu-material-brown-50 li a:hover {
  color: #efebe9;
}
.dropdown-menu-material-brown-100 li a:hover {
  color: #d7ccc8;
}
.dropdown-menu-material-brown-200 li a:hover {
  color: #bcaaa4;
}
.dropdown-menu-material-brown-300 li a:hover {
  color: #a1887f;
}
.dropdown-menu-material-brown-400 li a:hover {
  color: #8d6e63;
}
.dropdown-menu-material-brown-500 li a:hover {
  color: #795548;
}
.dropdown-menu-material-brown-600 li a:hover {
  color: #6d4c41;
}
.dropdown-menu-material-brown-700 li a:hover {
  color: #5d4037;
}
.dropdown-menu-material-brown-800 li a:hover {
  color: #4e342e;
}
.dropdown-menu-material-brown-900 li a:hover {
  color: #3e2723;
}
.dropdown-menu-material-brown-A100 li a:hover {
  color: #d7ccc8;
}
.dropdown-menu-material-brown-A200 li a:hover {
  color: #bcaaa4;
}
.dropdown-menu-material-brown-A400 li a:hover {
  color: #8d6e63;
}
.dropdown-menu-material-brown-A700 li a:hover {
  color: #5d4037;
}
.dropdown-menu-material-grey li a:hover {
  color: #9e9e9e;
}
.dropdown-menu-material-grey-50 li a:hover {
  color: #fafafa;
}
.dropdown-menu-material-grey-100 li a:hover {
  color: #f5f5f5;
}
.dropdown-menu-material-grey-200 li a:hover {
  color: #eeeeee;
}
.dropdown-menu-material-grey-300 li a:hover {
  color: #e0e0e0;
}
.dropdown-menu-material-grey-400 li a:hover {
  color: #bdbdbd;
}
.dropdown-menu-material-grey-500 li a:hover {
  color: #9e9e9e;
}
.dropdown-menu-material-grey-600 li a:hover {
  color: #757575;
}
.dropdown-menu-material-grey-700 li a:hover {
  color: #616161;
}
.dropdown-menu-material-grey-800 li a:hover {
  color: #424242;
}
.dropdown-menu-material-grey-900 li a:hover {
  color: #212121;
}
.dropdown-menu-material-grey-A100 li a:hover {
  color: #f5f5f5;
}
.dropdown-menu-material-grey-A200 li a:hover {
  color: #eeeeee;
}
.dropdown-menu-material-grey-A400 li a:hover {
  color: #bdbdbd;
}
.dropdown-menu-material-grey-A700 li a:hover {
  color: #616161;
}
.dropdown-menu-material-blue li a:hover {
  color: #607d8b;
}
.dropdown-menu-material-blue-50 li a:hover {
  color: #eceff1;
}
.dropdown-menu-material-blue-100 li a:hover {
  color: #cfd8dc;
}
.dropdown-menu-material-blue-200 li a:hover {
  color: #b0bec5;
}
.dropdown-menu-material-blue-300 li a:hover {
  color: #90a4ae;
}
.dropdown-menu-material-blue-400 li a:hover {
  color: #78909c;
}
.dropdown-menu-material-blue-500 li a:hover {
  color: #607d8b;
}
.dropdown-menu-material-blue-600 li a:hover {
  color: #546e7a;
}
.dropdown-menu-material-blue-700 li a:hover {
  color: #455a64;
}
.dropdown-menu-material-blue-800 li a:hover {
  color: #37474f;
}
.dropdown-menu-material-blue-900 li a:hover {
  color: #263238;
}
.dropdown-menu-material-blue-A100 li a:hover {
  color: #cfd8dc;
}
.dropdown-menu-material-blue-A200 li a:hover {
  color: #b0bec5;
}
.dropdown-menu-material-blue-A400 li a:hover {
  color: #78909c;
}
.dropdown-menu-material-blue-A700 li a:hover {
  color: #455a64;
}
.alert {
  border: 0px;
  border-radius: 0;
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.alert a,
.alert .alert-link {
  color: #FFFFFF;
}
.alert,
.alert-default {
  background-color: #ffffff;
}
.alert-primary {
  background-color: #009587;
}
.alert-success {
  background-color: #0f9d58;
}
.alert-info {
  background-color: #03a9f4;
}
.alert-warning {
  background-color: #ff5722;
}
.alert-danger {
  background-color: #f44336;
}
.alert-material-red {
  background-color: #f44336;
}
.alert-material-red-50 {
  background-color: #ffebee;
}
.alert-material-red-100 {
  background-color: #ffcdd2;
}
.alert-material-red-200 {
  background-color: #ef9a9a;
}
.alert-material-red-300 {
  background-color: #e57373;
}
.alert-material-red-400 {
  background-color: #ef5350;
}
.alert-material-red-500 {
  background-color: #f44336;
}
.alert-material-red-600 {
  background-color: #e53935;
}
.alert-material-red-700 {
  background-color: #d32f2f;
}
.alert-material-red-800 {
  background-color: #c62828;
}
.alert-material-red-900 {
  background-color: #b71c1c;
}
.alert-material-red-A100 {
  background-color: #ff8a80;
}
.alert-material-red-A200 {
  background-color: #ff5252;
}
.alert-material-red-A400 {
  background-color: #ff1744;
}
.alert-material-red-A700 {
  background-color: #d50000;
}
.alert-material-pink {
  background-color: #e91e63;
}
.alert-material-pink-50 {
  background-color: #fce4ec;
}
.alert-material-pink-100 {
  background-color: #f8bbd0;
}
.alert-material-pink-200 {
  background-color: #f48fb1;
}
.alert-material-pink-300 {
  background-color: #f06292;
}
.alert-material-pink-400 {
  background-color: #ec407a;
}
.alert-material-pink-500 {
  background-color: #e91e63;
}
.alert-material-pink-600 {
  background-color: #d81b60;
}
.alert-material-pink-700 {
  background-color: #c2185b;
}
.alert-material-pink-800 {
  background-color: #ad1457;
}
.alert-material-pink-900 {
  background-color: #880e4f;
}
.alert-material-pink-A100 {
  background-color: #ff80ab;
}
.alert-material-pink-A200 {
  background-color: #ff4081;
}
.alert-material-pink-A400 {
  background-color: #f50057;
}
.alert-material-pink-A700 {
  background-color: #c51162;
}
.alert-material-purple {
  background-color: #9c27b0;
}
.alert-material-purple-50 {
  background-color: #f3e5f5;
}
.alert-material-purple-100 {
  background-color: #e1bee7;
}
.alert-material-purple-200 {
  background-color: #ce93d8;
}
.alert-material-purple-300 {
  background-color: #ba68c8;
}
.alert-material-purple-400 {
  background-color: #ab47bc;
}
.alert-material-purple-500 {
  background-color: #9c27b0;
}
.alert-material-purple-600 {
  background-color: #8e24aa;
}
.alert-material-purple-700 {
  background-color: #7b1fa2;
}
.alert-material-purple-800 {
  background-color: #6a1b9a;
}
.alert-material-purple-900 {
  background-color: #4a148c;
}
.alert-material-purple-A100 {
  background-color: #ea80fc;
}
.alert-material-purple-A200 {
  background-color: #e040fb;
}
.alert-material-purple-A400 {
  background-color: #d500f9;
}
.alert-material-purple-A700 {
  background-color: #aa00ff;
}
.alert-material-deep-purple {
  background-color: #673ab7;
}
.alert-material-deep-purple-50 {
  background-color: #ede7f6;
}
.alert-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.alert-material-deep-purple-200 {
  background-color: #b39ddb;
}
.alert-material-deep-purple-300 {
  background-color: #9575cd;
}
.alert-material-deep-purple-400 {
  background-color: #7e57c2;
}
.alert-material-deep-purple-500 {
  background-color: #673ab7;
}
.alert-material-deep-purple-600 {
  background-color: #5e35b1;
}
.alert-material-deep-purple-700 {
  background-color: #512da8;
}
.alert-material-deep-purple-800 {
  background-color: #4527a0;
}
.alert-material-deep-purple-900 {
  background-color: #311b92;
}
.alert-material-deep-purple-A100 {
  background-color: #b388ff;
}
.alert-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.alert-material-deep-purple-A400 {
  background-color: #651fff;
}
.alert-material-deep-purple-A700 {
  background-color: #6200ea;
}
.alert-material-indigo {
  background-color: #3f51b5;
}
.alert-material-indigo-50 {
  background-color: #e8eaf6;
}
.alert-material-indigo-100 {
  background-color: #c5cae9;
}
.alert-material-indigo-200 {
  background-color: #9fa8da;
}
.alert-material-indigo-300 {
  background-color: #7986cb;
}
.alert-material-indigo-400 {
  background-color: #5c6bc0;
}
.alert-material-indigo-500 {
  background-color: #3f51b5;
}
.alert-material-indigo-600 {
  background-color: #3949ab;
}
.alert-material-indigo-700 {
  background-color: #303f9f;
}
.alert-material-indigo-800 {
  background-color: #283593;
}
.alert-material-indigo-900 {
  background-color: #1a237e;
}
.alert-material-indigo-A100 {
  background-color: #8c9eff;
}
.alert-material-indigo-A200 {
  background-color: #536dfe;
}
.alert-material-indigo-A400 {
  background-color: #3d5afe;
}
.alert-material-indigo-A700 {
  background-color: #304ffe;
}
.alert-material-blue {
  background-color: #2196f3;
}
.alert-material-blue-50 {
  background-color: #e3f2fd;
}
.alert-material-blue-100 {
  background-color: #bbdefb;
}
.alert-material-blue-200 {
  background-color: #90caf9;
}
.alert-material-blue-300 {
  background-color: #64b5f6;
}
.alert-material-blue-400 {
  background-color: #42a5f5;
}
.alert-material-blue-500 {
  background-color: #2196f3;
}
.alert-material-blue-600 {
  background-color: #1e88e5;
}
.alert-material-blue-700 {
  background-color: #1976d2;
}
.alert-material-blue-800 {
  background-color: #1565c0;
}
.alert-material-blue-900 {
  background-color: #0d47a1;
}
.alert-material-blue-A100 {
  background-color: #82b1ff;
}
.alert-material-blue-A200 {
  background-color: #448aff;
}
.alert-material-blue-A400 {
  background-color: #2979ff;
}
.alert-material-blue-A700 {
  background-color: #2962ff;
}
.alert-material-light-blue {
  background-color: #03a9f4;
}
.alert-material-light-blue-50 {
  background-color: #e1f5fe;
}
.alert-material-light-blue-100 {
  background-color: #b3e5fc;
}
.alert-material-light-blue-200 {
  background-color: #81d4fa;
}
.alert-material-light-blue-300 {
  background-color: #4fc3f7;
}
.alert-material-light-blue-400 {
  background-color: #29b6f6;
}
.alert-material-light-blue-500 {
  background-color: #03a9f4;
}
.alert-material-light-blue-600 {
  background-color: #039be5;
}
.alert-material-light-blue-700 {
  background-color: #0288d1;
}
.alert-material-light-blue-800 {
  background-color: #0277bd;
}
.alert-material-light-blue-900 {
  background-color: #01579b;
}
.alert-material-light-blue-A100 {
  background-color: #80d8ff;
}
.alert-material-light-blue-A200 {
  background-color: #40c4ff;
}
.alert-material-light-blue-A400 {
  background-color: #00b0ff;
}
.alert-material-light-blue-A700 {
  background-color: #0091ea;
}
.alert-material-cyan {
  background-color: #00bcd4;
}
.alert-material-cyan-50 {
  background-color: #e0f7fa;
}
.alert-material-cyan-100 {
  background-color: #b2ebf2;
}
.alert-material-cyan-200 {
  background-color: #80deea;
}
.alert-material-cyan-300 {
  background-color: #4dd0e1;
}
.alert-material-cyan-400 {
  background-color: #26c6da;
}
.alert-material-cyan-500 {
  background-color: #00bcd4;
}
.alert-material-cyan-600 {
  background-color: #00acc1;
}
.alert-material-cyan-700 {
  background-color: #0097a7;
}
.alert-material-cyan-800 {
  background-color: #00838f;
}
.alert-material-cyan-900 {
  background-color: #006064;
}
.alert-material-cyan-A100 {
  background-color: #84ffff;
}
.alert-material-cyan-A200 {
  background-color: #18ffff;
}
.alert-material-cyan-A400 {
  background-color: #00e5ff;
}
.alert-material-cyan-A700 {
  background-color: #00b8d4;
}
.alert-material-teal {
  background-color: #009688;
}
.alert-material-teal-50 {
  background-color: #e0f2f1;
}
.alert-material-teal-100 {
  background-color: #b2dfdb;
}
.alert-material-teal-200 {
  background-color: #80cbc4;
}
.alert-material-teal-300 {
  background-color: #4db6ac;
}
.alert-material-teal-400 {
  background-color: #26a69a;
}
.alert-material-teal-500 {
  background-color: #009688;
}
.alert-material-teal-600 {
  background-color: #00897b;
}
.alert-material-teal-700 {
  background-color: #00796b;
}
.alert-material-teal-800 {
  background-color: #00695c;
}
.alert-material-teal-900 {
  background-color: #004d40;
}
.alert-material-teal-A100 {
  background-color: #a7ffeb;
}
.alert-material-teal-A200 {
  background-color: #64ffda;
}
.alert-material-teal-A400 {
  background-color: #1de9b6;
}
.alert-material-teal-A700 {
  background-color: #00bfa5;
}
.alert-material-green {
  background-color: #4caf50;
}
.alert-material-green-50 {
  background-color: #e8f5e9;
}
.alert-material-green-100 {
  background-color: #c8e6c9;
}
.alert-material-green-200 {
  background-color: #a5d6a7;
}
.alert-material-green-300 {
  background-color: #81c784;
}
.alert-material-green-400 {
  background-color: #66bb6a;
}
.alert-material-green-500 {
  background-color: #4caf50;
}
.alert-material-green-600 {
  background-color: #43a047;
}
.alert-material-green-700 {
  background-color: #388e3c;
}
.alert-material-green-800 {
  background-color: #2e7d32;
}
.alert-material-green-900 {
  background-color: #1b5e20;
}
.alert-material-green-A100 {
  background-color: #b9f6ca;
}
.alert-material-green-A200 {
  background-color: #69f0ae;
}
.alert-material-green-A400 {
  background-color: #00e676;
}
.alert-material-green-A700 {
  background-color: #00c853;
}
.alert-material-light-green {
  background-color: #8bc34a;
}
.alert-material-light-green-50 {
  background-color: #f1f8e9;
}
.alert-material-light-green-100 {
  background-color: #dcedc8;
}
.alert-material-light-green-200 {
  background-color: #c5e1a5;
}
.alert-material-light-green-300 {
  background-color: #aed581;
}
.alert-material-light-green-400 {
  background-color: #9ccc65;
}
.alert-material-light-green-500 {
  background-color: #8bc34a;
}
.alert-material-light-green-600 {
  background-color: #7cb342;
}
.alert-material-light-green-700 {
  background-color: #689f38;
}
.alert-material-light-green-800 {
  background-color: #558b2f;
}
.alert-material-light-green-900 {
  background-color: #33691e;
}
.alert-material-light-green-A100 {
  background-color: #ccff90;
}
.alert-material-light-green-A200 {
  background-color: #b2ff59;
}
.alert-material-light-green-A400 {
  background-color: #76ff03;
}
.alert-material-light-green-A700 {
  background-color: #64dd17;
}
.alert-material-lime {
  background-color: #cddc39;
}
.alert-material-lime-50 {
  background-color: #f9fbe7;
}
.alert-material-lime-100 {
  background-color: #f0f4c3;
}
.alert-material-lime-200 {
  background-color: #e6ee9c;
}
.alert-material-lime-300 {
  background-color: #dce775;
}
.alert-material-lime-400 {
  background-color: #d4e157;
}
.alert-material-lime-500 {
  background-color: #cddc39;
}
.alert-material-lime-600 {
  background-color: #c0ca33;
}
.alert-material-lime-700 {
  background-color: #afb42b;
}
.alert-material-lime-800 {
  background-color: #9e9d24;
}
.alert-material-lime-900 {
  background-color: #827717;
}
.alert-material-lime-A100 {
  background-color: #f4ff81;
}
.alert-material-lime-A200 {
  background-color: #eeff41;
}
.alert-material-lime-A400 {
  background-color: #c6ff00;
}
.alert-material-lime-A700 {
  background-color: #aeea00;
}
.alert-material-yellow {
  background-color: #ffeb3b;
}
.alert-material-yellow-50 {
  background-color: #fffde7;
}
.alert-material-yellow-100 {
  background-color: #fff9c4;
}
.alert-material-yellow-200 {
  background-color: #fff59d;
}
.alert-material-yellow-300 {
  background-color: #fff176;
}
.alert-material-yellow-400 {
  background-color: #ffee58;
}
.alert-material-yellow-500 {
  background-color: #ffeb3b;
}
.alert-material-yellow-600 {
  background-color: #fdd835;
}
.alert-material-yellow-700 {
  background-color: #fbc02d;
}
.alert-material-yellow-800 {
  background-color: #f9a825;
}
.alert-material-yellow-900 {
  background-color: #f57f17;
}
.alert-material-yellow-A100 {
  background-color: #ffff8d;
}
.alert-material-yellow-A200 {
  background-color: #ffff00;
}
.alert-material-yellow-A400 {
  background-color: #ffea00;
}
.alert-material-yellow-A700 {
  background-color: #ffd600;
}
.alert-material-amber {
  background-color: #ffc107;
}
.alert-material-amber-50 {
  background-color: #fff8e1;
}
.alert-material-amber-100 {
  background-color: #ffecb3;
}
.alert-material-amber-200 {
  background-color: #ffe082;
}
.alert-material-amber-300 {
  background-color: #ffd54f;
}
.alert-material-amber-400 {
  background-color: #ffca28;
}
.alert-material-amber-500 {
  background-color: #ffc107;
}
.alert-material-amber-600 {
  background-color: #ffb300;
}
.alert-material-amber-700 {
  background-color: #ffa000;
}
.alert-material-amber-800 {
  background-color: #ff8f00;
}
.alert-material-amber-900 {
  background-color: #ff6f00;
}
.alert-material-amber-A100 {
  background-color: #ffe57f;
}
.alert-material-amber-A200 {
  background-color: #ffd740;
}
.alert-material-amber-A400 {
  background-color: #ffc400;
}
.alert-material-amber-A700 {
  background-color: #ffab00;
}
.alert-material-orange {
  background-color: #ff9800;
}
.alert-material-orange-50 {
  background-color: #fff3e0;
}
.alert-material-orange-100 {
  background-color: #ffe0b2;
}
.alert-material-orange-200 {
  background-color: #ffcc80;
}
.alert-material-orange-300 {
  background-color: #ffb74d;
}
.alert-material-orange-400 {
  background-color: #ffa726;
}
.alert-material-orange-500 {
  background-color: #ff9800;
}
.alert-material-orange-600 {
  background-color: #fb8c00;
}
.alert-material-orange-700 {
  background-color: #f57c00;
}
.alert-material-orange-800 {
  background-color: #ef6c00;
}
.alert-material-orange-900 {
  background-color: #e65100;
}
.alert-material-orange-A100 {
  background-color: #ffd180;
}
.alert-material-orange-A200 {
  background-color: #ffab40;
}
.alert-material-orange-A400 {
  background-color: #ff9100;
}
.alert-material-orange-A700 {
  background-color: #ff6d00;
}
.alert-material-deep-orange {
  background-color: #ff5722;
}
.alert-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.alert-material-deep-orange-100 {
  background-color: #ffccbc;
}
.alert-material-deep-orange-200 {
  background-color: #ffab91;
}
.alert-material-deep-orange-300 {
  background-color: #ff8a65;
}
.alert-material-deep-orange-400 {
  background-color: #ff7043;
}
.alert-material-deep-orange-500 {
  background-color: #ff5722;
}
.alert-material-deep-orange-600 {
  background-color: #f4511e;
}
.alert-material-deep-orange-700 {
  background-color: #e64a19;
}
.alert-material-deep-orange-800 {
  background-color: #d84315;
}
.alert-material-deep-orange-900 {
  background-color: #bf360c;
}
.alert-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.alert-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.alert-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.alert-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.alert-material-brown {
  background-color: #795548;
}
.alert-material-brown-50 {
  background-color: #efebe9;
}
.alert-material-brown-100 {
  background-color: #d7ccc8;
}
.alert-material-brown-200 {
  background-color: #bcaaa4;
}
.alert-material-brown-300 {
  background-color: #a1887f;
}
.alert-material-brown-400 {
  background-color: #8d6e63;
}
.alert-material-brown-500 {
  background-color: #795548;
}
.alert-material-brown-600 {
  background-color: #6d4c41;
}
.alert-material-brown-700 {
  background-color: #5d4037;
}
.alert-material-brown-800 {
  background-color: #4e342e;
}
.alert-material-brown-900 {
  background-color: #3e2723;
}
.alert-material-brown-A100 {
  background-color: #d7ccc8;
}
.alert-material-brown-A200 {
  background-color: #bcaaa4;
}
.alert-material-brown-A400 {
  background-color: #8d6e63;
}
.alert-material-brown-A700 {
  background-color: #5d4037;
}
.alert-material-grey {
  background-color: #9e9e9e;
}
.alert-material-grey-50 {
  background-color: #fafafa;
}
.alert-material-grey-100 {
  background-color: #f5f5f5;
}
.alert-material-grey-200 {
  background-color: #eeeeee;
}
.alert-material-grey-300 {
  background-color: #e0e0e0;
}
.alert-material-grey-400 {
  background-color: #bdbdbd;
}
.alert-material-grey-500 {
  background-color: #9e9e9e;
}
.alert-material-grey-600 {
  background-color: #757575;
}
.alert-material-grey-700 {
  background-color: #616161;
}
.alert-material-grey-800 {
  background-color: #424242;
}
.alert-material-grey-900 {
  background-color: #212121;
}
.alert-material-grey-A100 {
  background-color: #f5f5f5;
}
.alert-material-grey-A200 {
  background-color: #eeeeee;
}
.alert-material-grey-A400 {
  background-color: #bdbdbd;
}
.alert-material-grey-A700 {
  background-color: #616161;
}
.alert-material-blue {
  background-color: #607d8b;
}
.alert-material-blue-50 {
  background-color: #eceff1;
}
.alert-material-blue-100 {
  background-color: #cfd8dc;
}
.alert-material-blue-200 {
  background-color: #b0bec5;
}
.alert-material-blue-300 {
  background-color: #90a4ae;
}
.alert-material-blue-400 {
  background-color: #78909c;
}
.alert-material-blue-500 {
  background-color: #607d8b;
}
.alert-material-blue-600 {
  background-color: #546e7a;
}
.alert-material-blue-700 {
  background-color: #455a64;
}
.alert-material-blue-800 {
  background-color: #37474f;
}
.alert-material-blue-900 {
  background-color: #263238;
}
.alert-material-blue-A100 {
  background-color: #cfd8dc;
}
.alert-material-blue-A200 {
  background-color: #b0bec5;
}
.alert-material-blue-A400 {
  background-color: #78909c;
}
.alert-material-blue-A700 {
  background-color: #455a64;
}
.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  color: #FFFFFF;
}
.alert-default a,
.alert-default .alert-link {
  color: #000000;
}
.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #c8c8c8;
}
.progress .progress-bar {
  box-shadow: none;
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.progress .progress-bar,
.progress .progress-bar-default {
  background-color: #009587;
}
.progress .progress-bar-primary {
  background-color: #009587;
}
.progress .progress-bar-success {
  background-color: #0f9d58;
}
.progress .progress-bar-info {
  background-color: #03a9f4;
}
.progress .progress-bar-warning {
  background-color: #ff5722;
}
.progress .progress-bar-danger {
  background-color: #f44336;
}
.progress .progress-bar-material-red {
  background-color: #f44336;
}
.progress .progress-bar-material-red-50 {
  background-color: #ffebee;
}
.progress .progress-bar-material-red-100 {
  background-color: #ffcdd2;
}
.progress .progress-bar-material-red-200 {
  background-color: #ef9a9a;
}
.progress .progress-bar-material-red-300 {
  background-color: #e57373;
}
.progress .progress-bar-material-red-400 {
  background-color: #ef5350;
}
.progress .progress-bar-material-red-500 {
  background-color: #f44336;
}
.progress .progress-bar-material-red-600 {
  background-color: #e53935;
}
.progress .progress-bar-material-red-700 {
  background-color: #d32f2f;
}
.progress .progress-bar-material-red-800 {
  background-color: #c62828;
}
.progress .progress-bar-material-red-900 {
  background-color: #b71c1c;
}
.progress .progress-bar-material-red-A100 {
  background-color: #ff8a80;
}
.progress .progress-bar-material-red-A200 {
  background-color: #ff5252;
}
.progress .progress-bar-material-red-A400 {
  background-color: #ff1744;
}
.progress .progress-bar-material-red-A700 {
  background-color: #d50000;
}
.progress .progress-bar-material-pink {
  background-color: #e91e63;
}
.progress .progress-bar-material-pink-50 {
  background-color: #fce4ec;
}
.progress .progress-bar-material-pink-100 {
  background-color: #f8bbd0;
}
.progress .progress-bar-material-pink-200 {
  background-color: #f48fb1;
}
.progress .progress-bar-material-pink-300 {
  background-color: #f06292;
}
.progress .progress-bar-material-pink-400 {
  background-color: #ec407a;
}
.progress .progress-bar-material-pink-500 {
  background-color: #e91e63;
}
.progress .progress-bar-material-pink-600 {
  background-color: #d81b60;
}
.progress .progress-bar-material-pink-700 {
  background-color: #c2185b;
}
.progress .progress-bar-material-pink-800 {
  background-color: #ad1457;
}
.progress .progress-bar-material-pink-900 {
  background-color: #880e4f;
}
.progress .progress-bar-material-pink-A100 {
  background-color: #ff80ab;
}
.progress .progress-bar-material-pink-A200 {
  background-color: #ff4081;
}
.progress .progress-bar-material-pink-A400 {
  background-color: #f50057;
}
.progress .progress-bar-material-pink-A700 {
  background-color: #c51162;
}
.progress .progress-bar-material-purple {
  background-color: #9c27b0;
}
.progress .progress-bar-material-purple-50 {
  background-color: #f3e5f5;
}
.progress .progress-bar-material-purple-100 {
  background-color: #e1bee7;
}
.progress .progress-bar-material-purple-200 {
  background-color: #ce93d8;
}
.progress .progress-bar-material-purple-300 {
  background-color: #ba68c8;
}
.progress .progress-bar-material-purple-400 {
  background-color: #ab47bc;
}
.progress .progress-bar-material-purple-500 {
  background-color: #9c27b0;
}
.progress .progress-bar-material-purple-600 {
  background-color: #8e24aa;
}
.progress .progress-bar-material-purple-700 {
  background-color: #7b1fa2;
}
.progress .progress-bar-material-purple-800 {
  background-color: #6a1b9a;
}
.progress .progress-bar-material-purple-900 {
  background-color: #4a148c;
}
.progress .progress-bar-material-purple-A100 {
  background-color: #ea80fc;
}
.progress .progress-bar-material-purple-A200 {
  background-color: #e040fb;
}
.progress .progress-bar-material-purple-A400 {
  background-color: #d500f9;
}
.progress .progress-bar-material-purple-A700 {
  background-color: #aa00ff;
}
.progress .progress-bar-material-deep-purple {
  background-color: #673ab7;
}
.progress .progress-bar-material-deep-purple-50 {
  background-color: #ede7f6;
}
.progress .progress-bar-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.progress .progress-bar-material-deep-purple-200 {
  background-color: #b39ddb;
}
.progress .progress-bar-material-deep-purple-300 {
  background-color: #9575cd;
}
.progress .progress-bar-material-deep-purple-400 {
  background-color: #7e57c2;
}
.progress .progress-bar-material-deep-purple-500 {
  background-color: #673ab7;
}
.progress .progress-bar-material-deep-purple-600 {
  background-color: #5e35b1;
}
.progress .progress-bar-material-deep-purple-700 {
  background-color: #512da8;
}
.progress .progress-bar-material-deep-purple-800 {
  background-color: #4527a0;
}
.progress .progress-bar-material-deep-purple-900 {
  background-color: #311b92;
}
.progress .progress-bar-material-deep-purple-A100 {
  background-color: #b388ff;
}
.progress .progress-bar-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.progress .progress-bar-material-deep-purple-A400 {
  background-color: #651fff;
}
.progress .progress-bar-material-deep-purple-A700 {
  background-color: #6200ea;
}
.progress .progress-bar-material-indigo {
  background-color: #3f51b5;
}
.progress .progress-bar-material-indigo-50 {
  background-color: #e8eaf6;
}
.progress .progress-bar-material-indigo-100 {
  background-color: #c5cae9;
}
.progress .progress-bar-material-indigo-200 {
  background-color: #9fa8da;
}
.progress .progress-bar-material-indigo-300 {
  background-color: #7986cb;
}
.progress .progress-bar-material-indigo-400 {
  background-color: #5c6bc0;
}
.progress .progress-bar-material-indigo-500 {
  background-color: #3f51b5;
}
.progress .progress-bar-material-indigo-600 {
  background-color: #3949ab;
}
.progress .progress-bar-material-indigo-700 {
  background-color: #303f9f;
}
.progress .progress-bar-material-indigo-800 {
  background-color: #283593;
}
.progress .progress-bar-material-indigo-900 {
  background-color: #1a237e;
}
.progress .progress-bar-material-indigo-A100 {
  background-color: #8c9eff;
}
.progress .progress-bar-material-indigo-A200 {
  background-color: #536dfe;
}
.progress .progress-bar-material-indigo-A400 {
  background-color: #3d5afe;
}
.progress .progress-bar-material-indigo-A700 {
  background-color: #304ffe;
}
.progress .progress-bar-material-blue {
  background-color: #2196f3;
}
.progress .progress-bar-material-blue-50 {
  background-color: #e3f2fd;
}
.progress .progress-bar-material-blue-100 {
  background-color: #bbdefb;
}
.progress .progress-bar-material-blue-200 {
  background-color: #90caf9;
}
.progress .progress-bar-material-blue-300 {
  background-color: #64b5f6;
}
.progress .progress-bar-material-blue-400 {
  background-color: #42a5f5;
}
.progress .progress-bar-material-blue-500 {
  background-color: #2196f3;
}
.progress .progress-bar-material-blue-600 {
  background-color: #1e88e5;
}
.progress .progress-bar-material-blue-700 {
  background-color: #1976d2;
}
.progress .progress-bar-material-blue-800 {
  background-color: #1565c0;
}
.progress .progress-bar-material-blue-900 {
  background-color: #0d47a1;
}
.progress .progress-bar-material-blue-A100 {
  background-color: #82b1ff;
}
.progress .progress-bar-material-blue-A200 {
  background-color: #448aff;
}
.progress .progress-bar-material-blue-A400 {
  background-color: #2979ff;
}
.progress .progress-bar-material-blue-A700 {
  background-color: #2962ff;
}
.progress .progress-bar-material-light-blue {
  background-color: #03a9f4;
}
.progress .progress-bar-material-light-blue-50 {
  background-color: #e1f5fe;
}
.progress .progress-bar-material-light-blue-100 {
  background-color: #b3e5fc;
}
.progress .progress-bar-material-light-blue-200 {
  background-color: #81d4fa;
}
.progress .progress-bar-material-light-blue-300 {
  background-color: #4fc3f7;
}
.progress .progress-bar-material-light-blue-400 {
  background-color: #29b6f6;
}
.progress .progress-bar-material-light-blue-500 {
  background-color: #03a9f4;
}
.progress .progress-bar-material-light-blue-600 {
  background-color: #039be5;
}
.progress .progress-bar-material-light-blue-700 {
  background-color: #0288d1;
}
.progress .progress-bar-material-light-blue-800 {
  background-color: #0277bd;
}
.progress .progress-bar-material-light-blue-900 {
  background-color: #01579b;
}
.progress .progress-bar-material-light-blue-A100 {
  background-color: #80d8ff;
}
.progress .progress-bar-material-light-blue-A200 {
  background-color: #40c4ff;
}
.progress .progress-bar-material-light-blue-A400 {
  background-color: #00b0ff;
}
.progress .progress-bar-material-light-blue-A700 {
  background-color: #0091ea;
}
.progress .progress-bar-material-cyan {
  background-color: #00bcd4;
}
.progress .progress-bar-material-cyan-50 {
  background-color: #e0f7fa;
}
.progress .progress-bar-material-cyan-100 {
  background-color: #b2ebf2;
}
.progress .progress-bar-material-cyan-200 {
  background-color: #80deea;
}
.progress .progress-bar-material-cyan-300 {
  background-color: #4dd0e1;
}
.progress .progress-bar-material-cyan-400 {
  background-color: #26c6da;
}
.progress .progress-bar-material-cyan-500 {
  background-color: #00bcd4;
}
.progress .progress-bar-material-cyan-600 {
  background-color: #00acc1;
}
.progress .progress-bar-material-cyan-700 {
  background-color: #0097a7;
}
.progress .progress-bar-material-cyan-800 {
  background-color: #00838f;
}
.progress .progress-bar-material-cyan-900 {
  background-color: #006064;
}
.progress .progress-bar-material-cyan-A100 {
  background-color: #84ffff;
}
.progress .progress-bar-material-cyan-A200 {
  background-color: #18ffff;
}
.progress .progress-bar-material-cyan-A400 {
  background-color: #00e5ff;
}
.progress .progress-bar-material-cyan-A700 {
  background-color: #00b8d4;
}
.progress .progress-bar-material-teal {
  background-color: #009688;
}
.progress .progress-bar-material-teal-50 {
  background-color: #e0f2f1;
}
.progress .progress-bar-material-teal-100 {
  background-color: #b2dfdb;
}
.progress .progress-bar-material-teal-200 {
  background-color: #80cbc4;
}
.progress .progress-bar-material-teal-300 {
  background-color: #4db6ac;
}
.progress .progress-bar-material-teal-400 {
  background-color: #26a69a;
}
.progress .progress-bar-material-teal-500 {
  background-color: #009688;
}
.progress .progress-bar-material-teal-600 {
  background-color: #00897b;
}
.progress .progress-bar-material-teal-700 {
  background-color: #00796b;
}
.progress .progress-bar-material-teal-800 {
  background-color: #00695c;
}
.progress .progress-bar-material-teal-900 {
  background-color: #004d40;
}
.progress .progress-bar-material-teal-A100 {
  background-color: #a7ffeb;
}
.progress .progress-bar-material-teal-A200 {
  background-color: #64ffda;
}
.progress .progress-bar-material-teal-A400 {
  background-color: #1de9b6;
}
.progress .progress-bar-material-teal-A700 {
  background-color: #00bfa5;
}
.progress .progress-bar-material-green {
  background-color: #4caf50;
}
.progress .progress-bar-material-green-50 {
  background-color: #e8f5e9;
}
.progress .progress-bar-material-green-100 {
  background-color: #c8e6c9;
}
.progress .progress-bar-material-green-200 {
  background-color: #a5d6a7;
}
.progress .progress-bar-material-green-300 {
  background-color: #81c784;
}
.progress .progress-bar-material-green-400 {
  background-color: #66bb6a;
}
.progress .progress-bar-material-green-500 {
  background-color: #4caf50;
}
.progress .progress-bar-material-green-600 {
  background-color: #43a047;
}
.progress .progress-bar-material-green-700 {
  background-color: #388e3c;
}
.progress .progress-bar-material-green-800 {
  background-color: #2e7d32;
}
.progress .progress-bar-material-green-900 {
  background-color: #1b5e20;
}
.progress .progress-bar-material-green-A100 {
  background-color: #b9f6ca;
}
.progress .progress-bar-material-green-A200 {
  background-color: #69f0ae;
}
.progress .progress-bar-material-green-A400 {
  background-color: #00e676;
}
.progress .progress-bar-material-green-A700 {
  background-color: #00c853;
}
.progress .progress-bar-material-light-green {
  background-color: #8bc34a;
}
.progress .progress-bar-material-light-green-50 {
  background-color: #f1f8e9;
}
.progress .progress-bar-material-light-green-100 {
  background-color: #dcedc8;
}
.progress .progress-bar-material-light-green-200 {
  background-color: #c5e1a5;
}
.progress .progress-bar-material-light-green-300 {
  background-color: #aed581;
}
.progress .progress-bar-material-light-green-400 {
  background-color: #9ccc65;
}
.progress .progress-bar-material-light-green-500 {
  background-color: #8bc34a;
}
.progress .progress-bar-material-light-green-600 {
  background-color: #7cb342;
}
.progress .progress-bar-material-light-green-700 {
  background-color: #689f38;
}
.progress .progress-bar-material-light-green-800 {
  background-color: #558b2f;
}
.progress .progress-bar-material-light-green-900 {
  background-color: #33691e;
}
.progress .progress-bar-material-light-green-A100 {
  background-color: #ccff90;
}
.progress .progress-bar-material-light-green-A200 {
  background-color: #b2ff59;
}
.progress .progress-bar-material-light-green-A400 {
  background-color: #76ff03;
}
.progress .progress-bar-material-light-green-A700 {
  background-color: #64dd17;
}
.progress .progress-bar-material-lime {
  background-color: #cddc39;
}
.progress .progress-bar-material-lime-50 {
  background-color: #f9fbe7;
}
.progress .progress-bar-material-lime-100 {
  background-color: #f0f4c3;
}
.progress .progress-bar-material-lime-200 {
  background-color: #e6ee9c;
}
.progress .progress-bar-material-lime-300 {
  background-color: #dce775;
}
.progress .progress-bar-material-lime-400 {
  background-color: #d4e157;
}
.progress .progress-bar-material-lime-500 {
  background-color: #cddc39;
}
.progress .progress-bar-material-lime-600 {
  background-color: #c0ca33;
}
.progress .progress-bar-material-lime-700 {
  background-color: #afb42b;
}
.progress .progress-bar-material-lime-800 {
  background-color: #9e9d24;
}
.progress .progress-bar-material-lime-900 {
  background-color: #827717;
}
.progress .progress-bar-material-lime-A100 {
  background-color: #f4ff81;
}
.progress .progress-bar-material-lime-A200 {
  background-color: #eeff41;
}
.progress .progress-bar-material-lime-A400 {
  background-color: #c6ff00;
}
.progress .progress-bar-material-lime-A700 {
  background-color: #aeea00;
}
.progress .progress-bar-material-yellow {
  background-color: #ffeb3b;
}
.progress .progress-bar-material-yellow-50 {
  background-color: #fffde7;
}
.progress .progress-bar-material-yellow-100 {
  background-color: #fff9c4;
}
.progress .progress-bar-material-yellow-200 {
  background-color: #fff59d;
}
.progress .progress-bar-material-yellow-300 {
  background-color: #fff176;
}
.progress .progress-bar-material-yellow-400 {
  background-color: #ffee58;
}
.progress .progress-bar-material-yellow-500 {
  background-color: #ffeb3b;
}
.progress .progress-bar-material-yellow-600 {
  background-color: #fdd835;
}
.progress .progress-bar-material-yellow-700 {
  background-color: #fbc02d;
}
.progress .progress-bar-material-yellow-800 {
  background-color: #f9a825;
}
.progress .progress-bar-material-yellow-900 {
  background-color: #f57f17;
}
.progress .progress-bar-material-yellow-A100 {
  background-color: #ffff8d;
}
.progress .progress-bar-material-yellow-A200 {
  background-color: #ffff00;
}
.progress .progress-bar-material-yellow-A400 {
  background-color: #ffea00;
}
.progress .progress-bar-material-yellow-A700 {
  background-color: #ffd600;
}
.progress .progress-bar-material-amber {
  background-color: #ffc107;
}
.progress .progress-bar-material-amber-50 {
  background-color: #fff8e1;
}
.progress .progress-bar-material-amber-100 {
  background-color: #ffecb3;
}
.progress .progress-bar-material-amber-200 {
  background-color: #ffe082;
}
.progress .progress-bar-material-amber-300 {
  background-color: #ffd54f;
}
.progress .progress-bar-material-amber-400 {
  background-color: #ffca28;
}
.progress .progress-bar-material-amber-500 {
  background-color: #ffc107;
}
.progress .progress-bar-material-amber-600 {
  background-color: #ffb300;
}
.progress .progress-bar-material-amber-700 {
  background-color: #ffa000;
}
.progress .progress-bar-material-amber-800 {
  background-color: #ff8f00;
}
.progress .progress-bar-material-amber-900 {
  background-color: #ff6f00;
}
.progress .progress-bar-material-amber-A100 {
  background-color: #ffe57f;
}
.progress .progress-bar-material-amber-A200 {
  background-color: #ffd740;
}
.progress .progress-bar-material-amber-A400 {
  background-color: #ffc400;
}
.progress .progress-bar-material-amber-A700 {
  background-color: #ffab00;
}
.progress .progress-bar-material-orange {
  background-color: #ff9800;
}
.progress .progress-bar-material-orange-50 {
  background-color: #fff3e0;
}
.progress .progress-bar-material-orange-100 {
  background-color: #ffe0b2;
}
.progress .progress-bar-material-orange-200 {
  background-color: #ffcc80;
}
.progress .progress-bar-material-orange-300 {
  background-color: #ffb74d;
}
.progress .progress-bar-material-orange-400 {
  background-color: #ffa726;
}
.progress .progress-bar-material-orange-500 {
  background-color: #ff9800;
}
.progress .progress-bar-material-orange-600 {
  background-color: #fb8c00;
}
.progress .progress-bar-material-orange-700 {
  background-color: #f57c00;
}
.progress .progress-bar-material-orange-800 {
  background-color: #ef6c00;
}
.progress .progress-bar-material-orange-900 {
  background-color: #e65100;
}
.progress .progress-bar-material-orange-A100 {
  background-color: #ffd180;
}
.progress .progress-bar-material-orange-A200 {
  background-color: #ffab40;
}
.progress .progress-bar-material-orange-A400 {
  background-color: #ff9100;
}
.progress .progress-bar-material-orange-A700 {
  background-color: #ff6d00;
}
.progress .progress-bar-material-deep-orange {
  background-color: #ff5722;
}
.progress .progress-bar-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.progress .progress-bar-material-deep-orange-100 {
  background-color: #ffccbc;
}
.progress .progress-bar-material-deep-orange-200 {
  background-color: #ffab91;
}
.progress .progress-bar-material-deep-orange-300 {
  background-color: #ff8a65;
}
.progress .progress-bar-material-deep-orange-400 {
  background-color: #ff7043;
}
.progress .progress-bar-material-deep-orange-500 {
  background-color: #ff5722;
}
.progress .progress-bar-material-deep-orange-600 {
  background-color: #f4511e;
}
.progress .progress-bar-material-deep-orange-700 {
  background-color: #e64a19;
}
.progress .progress-bar-material-deep-orange-800 {
  background-color: #d84315;
}
.progress .progress-bar-material-deep-orange-900 {
  background-color: #bf360c;
}
.progress .progress-bar-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.progress .progress-bar-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.progress .progress-bar-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.progress .progress-bar-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.progress .progress-bar-material-brown {
  background-color: #795548;
}
.progress .progress-bar-material-brown-50 {
  background-color: #efebe9;
}
.progress .progress-bar-material-brown-100 {
  background-color: #d7ccc8;
}
.progress .progress-bar-material-brown-200 {
  background-color: #bcaaa4;
}
.progress .progress-bar-material-brown-300 {
  background-color: #a1887f;
}
.progress .progress-bar-material-brown-400 {
  background-color: #8d6e63;
}
.progress .progress-bar-material-brown-500 {
  background-color: #795548;
}
.progress .progress-bar-material-brown-600 {
  background-color: #6d4c41;
}
.progress .progress-bar-material-brown-700 {
  background-color: #5d4037;
}
.progress .progress-bar-material-brown-800 {
  background-color: #4e342e;
}
.progress .progress-bar-material-brown-900 {
  background-color: #3e2723;
}
.progress .progress-bar-material-brown-A100 {
  background-color: #d7ccc8;
}
.progress .progress-bar-material-brown-A200 {
  background-color: #bcaaa4;
}
.progress .progress-bar-material-brown-A400 {
  background-color: #8d6e63;
}
.progress .progress-bar-material-brown-A700 {
  background-color: #5d4037;
}
.progress .progress-bar-material-grey {
  background-color: #9e9e9e;
}
.progress .progress-bar-material-grey-50 {
  background-color: #fafafa;
}
.progress .progress-bar-material-grey-100 {
  background-color: #f5f5f5;
}
.progress .progress-bar-material-grey-200 {
  background-color: #eeeeee;
}
.progress .progress-bar-material-grey-300 {
  background-color: #e0e0e0;
}
.progress .progress-bar-material-grey-400 {
  background-color: #bdbdbd;
}
.progress .progress-bar-material-grey-500 {
  background-color: #9e9e9e;
}
.progress .progress-bar-material-grey-600 {
  background-color: #757575;
}
.progress .progress-bar-material-grey-700 {
  background-color: #616161;
}
.progress .progress-bar-material-grey-800 {
  background-color: #424242;
}
.progress .progress-bar-material-grey-900 {
  background-color: #212121;
}
.progress .progress-bar-material-grey-A100 {
  background-color: #f5f5f5;
}
.progress .progress-bar-material-grey-A200 {
  background-color: #eeeeee;
}
.progress .progress-bar-material-grey-A400 {
  background-color: #bdbdbd;
}
.progress .progress-bar-material-grey-A700 {
  background-color: #616161;
}
.progress .progress-bar-material-blue {
  background-color: #607d8b;
}
.progress .progress-bar-material-blue-50 {
  background-color: #eceff1;
}
.progress .progress-bar-material-blue-100 {
  background-color: #cfd8dc;
}
.progress .progress-bar-material-blue-200 {
  background-color: #b0bec5;
}
.progress .progress-bar-material-blue-300 {
  background-color: #90a4ae;
}
.progress .progress-bar-material-blue-400 {
  background-color: #78909c;
}
.progress .progress-bar-material-blue-500 {
  background-color: #607d8b;
}
.progress .progress-bar-material-blue-600 {
  background-color: #546e7a;
}
.progress .progress-bar-material-blue-700 {
  background-color: #455a64;
}
.progress .progress-bar-material-blue-800 {
  background-color: #37474f;
}
.progress .progress-bar-material-blue-900 {
  background-color: #263238;
}
.progress .progress-bar-material-blue-A100 {
  background-color: #cfd8dc;
}
.progress .progress-bar-material-blue-A200 {
  background-color: #b0bec5;
}
.progress .progress-bar-material-blue-A400 {
  background-color: #78909c;
}
.progress .progress-bar-material-blue-A700 {
  background-color: #455a64;
}
.text-warning {
  color: #ff5722;
}
.text-primary {
  color: #009587;
}
.text-danger {
  color: #f44336;
}
.text-success {
  color: #0f9d58;
}
.text-info {
  color: #03a9f4;
}
.nav-tabs {
  background: #009587;
}
.nav-tabs > li > a {
  color: #FFFFFF;
  border: 0;
  margin: 0;
}
.nav-tabs > li > a:hover {
  background-color: transparent;
  border: 0;
}
.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: transparent !important;
  border: 0 !important;
  color: #FFFFFF !important;
  font-weight: 500;
}
.nav-tabs > li.disabled > a,
.nav-tabs > li.disabled > a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.popover,
.tooltip-inner {
  background: #323232;
  color: #FFF;
  border-radius: 2px;
}
.tooltip,
.tooltip.in {
  opacity: 1;
}
.popover.left .arrow:after,
.tooltip.left .arrow:after,
.popover.left .tooltip-arrow,
.tooltip.left .tooltip-arrow {
  border-left-color: #323232;
}
.popover.right .arrow:after,
.tooltip.right .arrow:after,
.popover.right .tooltip-arrow,
.tooltip.right .tooltip-arrow {
  border-right-color: #323232;
}
.popover.top .arrow:after,
.tooltip.top .arrow:after,
.popover.top .tooltip-arrow,
.tooltip.top .tooltip-arrow {
  border-top-color: #323232;
}
.popover.bottom .arrow:after,
.tooltip.bottom .arrow:after,
.popover.bottom .tooltip-arrow,
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #323232;
}
.mdi,
icon {
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  line-height: inherit;
  vertical-align: bottom;
}
.mdi,
icon,
.mdi-default,
icon-default {
  color: rgba(0, 0, 0, 0.84);
}
.mdi-primary,
icon-primary {
  color: #009587;
}
.mdi-success,
icon-success {
  color: #0f9d58;
}
.mdi-info,
icon-info {
  color: #03a9f4;
}
.mdi-warning,
icon-warning {
  color: #ff5722;
}
.mdi-danger,
icon-danger {
  color: #f44336;
}
.mdi-material-red,
icon-material-red {
  color: #f44336;
}
.mdi-material-red-50,
icon-material-red-50 {
  color: #ffebee;
}
.mdi-material-red-100,
icon-material-red-100 {
  color: #ffcdd2;
}
.mdi-material-red-200,
icon-material-red-200 {
  color: #ef9a9a;
}
.mdi-material-red-300,
icon-material-red-300 {
  color: #e57373;
}
.mdi-material-red-400,
icon-material-red-400 {
  color: #ef5350;
}
.mdi-material-red-500,
icon-material-red-500 {
  color: #f44336;
}
.mdi-material-red-600,
icon-material-red-600 {
  color: #e53935;
}
.mdi-material-red-700,
icon-material-red-700 {
  color: #d32f2f;
}
.mdi-material-red-800,
icon-material-red-800 {
  color: #c62828;
}
.mdi-material-red-900,
icon-material-red-900 {
  color: #b71c1c;
}
.mdi-material-red-A100,
icon-material-red-A100 {
  color: #ff8a80;
}
.mdi-material-red-A200,
icon-material-red-A200 {
  color: #ff5252;
}
.mdi-material-red-A400,
icon-material-red-A400 {
  color: #ff1744;
}
.mdi-material-red-A700,
icon-material-red-A700 {
  color: #d50000;
}
.mdi-material-pink,
icon-material-pink {
  color: #e91e63;
}
.mdi-material-pink-50,
icon-material-pink-50 {
  color: #fce4ec;
}
.mdi-material-pink-100,
icon-material-pink-100 {
  color: #f8bbd0;
}
.mdi-material-pink-200,
icon-material-pink-200 {
  color: #f48fb1;
}
.mdi-material-pink-300,
icon-material-pink-300 {
  color: #f06292;
}
.mdi-material-pink-400,
icon-material-pink-400 {
  color: #ec407a;
}
.mdi-material-pink-500,
icon-material-pink-500 {
  color: #e91e63;
}
.mdi-material-pink-600,
icon-material-pink-600 {
  color: #d81b60;
}
.mdi-material-pink-700,
icon-material-pink-700 {
  color: #c2185b;
}
.mdi-material-pink-800,
icon-material-pink-800 {
  color: #ad1457;
}
.mdi-material-pink-900,
icon-material-pink-900 {
  color: #880e4f;
}
.mdi-material-pink-A100,
icon-material-pink-A100 {
  color: #ff80ab;
}
.mdi-material-pink-A200,
icon-material-pink-A200 {
  color: #ff4081;
}
.mdi-material-pink-A400,
icon-material-pink-A400 {
  color: #f50057;
}
.mdi-material-pink-A700,
icon-material-pink-A700 {
  color: #c51162;
}
.mdi-material-purple,
icon-material-purple {
  color: #9c27b0;
}
.mdi-material-purple-50,
icon-material-purple-50 {
  color: #f3e5f5;
}
.mdi-material-purple-100,
icon-material-purple-100 {
  color: #e1bee7;
}
.mdi-material-purple-200,
icon-material-purple-200 {
  color: #ce93d8;
}
.mdi-material-purple-300,
icon-material-purple-300 {
  color: #ba68c8;
}
.mdi-material-purple-400,
icon-material-purple-400 {
  color: #ab47bc;
}
.mdi-material-purple-500,
icon-material-purple-500 {
  color: #9c27b0;
}
.mdi-material-purple-600,
icon-material-purple-600 {
  color: #8e24aa;
}
.mdi-material-purple-700,
icon-material-purple-700 {
  color: #7b1fa2;
}
.mdi-material-purple-800,
icon-material-purple-800 {
  color: #6a1b9a;
}
.mdi-material-purple-900,
icon-material-purple-900 {
  color: #4a148c;
}
.mdi-material-purple-A100,
icon-material-purple-A100 {
  color: #ea80fc;
}
.mdi-material-purple-A200,
icon-material-purple-A200 {
  color: #e040fb;
}
.mdi-material-purple-A400,
icon-material-purple-A400 {
  color: #d500f9;
}
.mdi-material-purple-A700,
icon-material-purple-A700 {
  color: #aa00ff;
}
.mdi-material-deep-purple,
icon-material-deep-purple {
  color: #673ab7;
}
.mdi-material-deep-purple-50,
icon-material-deep-purple-50 {
  color: #ede7f6;
}
.mdi-material-deep-purple-100,
icon-material-deep-purple-100 {
  color: #d1c4e9;
}
.mdi-material-deep-purple-200,
icon-material-deep-purple-200 {
  color: #b39ddb;
}
.mdi-material-deep-purple-300,
icon-material-deep-purple-300 {
  color: #9575cd;
}
.mdi-material-deep-purple-400,
icon-material-deep-purple-400 {
  color: #7e57c2;
}
.mdi-material-deep-purple-500,
icon-material-deep-purple-500 {
  color: #673ab7;
}
.mdi-material-deep-purple-600,
icon-material-deep-purple-600 {
  color: #5e35b1;
}
.mdi-material-deep-purple-700,
icon-material-deep-purple-700 {
  color: #512da8;
}
.mdi-material-deep-purple-800,
icon-material-deep-purple-800 {
  color: #4527a0;
}
.mdi-material-deep-purple-900,
icon-material-deep-purple-900 {
  color: #311b92;
}
.mdi-material-deep-purple-A100,
icon-material-deep-purple-A100 {
  color: #b388ff;
}
.mdi-material-deep-purple-A200,
icon-material-deep-purple-A200 {
  color: #7c4dff;
}
.mdi-material-deep-purple-A400,
icon-material-deep-purple-A400 {
  color: #651fff;
}
.mdi-material-deep-purple-A700,
icon-material-deep-purple-A700 {
  color: #6200ea;
}
.mdi-material-indigo,
icon-material-indigo {
  color: #3f51b5;
}
.mdi-material-indigo-50,
icon-material-indigo-50 {
  color: #e8eaf6;
}
.mdi-material-indigo-100,
icon-material-indigo-100 {
  color: #c5cae9;
}
.mdi-material-indigo-200,
icon-material-indigo-200 {
  color: #9fa8da;
}
.mdi-material-indigo-300,
icon-material-indigo-300 {
  color: #7986cb;
}
.mdi-material-indigo-400,
icon-material-indigo-400 {
  color: #5c6bc0;
}
.mdi-material-indigo-500,
icon-material-indigo-500 {
  color: #3f51b5;
}
.mdi-material-indigo-600,
icon-material-indigo-600 {
  color: #3949ab;
}
.mdi-material-indigo-700,
icon-material-indigo-700 {
  color: #303f9f;
}
.mdi-material-indigo-800,
icon-material-indigo-800 {
  color: #283593;
}
.mdi-material-indigo-900,
icon-material-indigo-900 {
  color: #1a237e;
}
.mdi-material-indigo-A100,
icon-material-indigo-A100 {
  color: #8c9eff;
}
.mdi-material-indigo-A200,
icon-material-indigo-A200 {
  color: #536dfe;
}
.mdi-material-indigo-A400,
icon-material-indigo-A400 {
  color: #3d5afe;
}
.mdi-material-indigo-A700,
icon-material-indigo-A700 {
  color: #304ffe;
}
.mdi-material-blue,
icon-material-blue {
  color: #2196f3;
}
.mdi-material-blue-50,
icon-material-blue-50 {
  color: #e3f2fd;
}
.mdi-material-blue-100,
icon-material-blue-100 {
  color: #bbdefb;
}
.mdi-material-blue-200,
icon-material-blue-200 {
  color: #90caf9;
}
.mdi-material-blue-300,
icon-material-blue-300 {
  color: #64b5f6;
}
.mdi-material-blue-400,
icon-material-blue-400 {
  color: #42a5f5;
}
.mdi-material-blue-500,
icon-material-blue-500 {
  color: #2196f3;
}
.mdi-material-blue-600,
icon-material-blue-600 {
  color: #1e88e5;
}
.mdi-material-blue-700,
icon-material-blue-700 {
  color: #1976d2;
}
.mdi-material-blue-800,
icon-material-blue-800 {
  color: #1565c0;
}
.mdi-material-blue-900,
icon-material-blue-900 {
  color: #0d47a1;
}
.mdi-material-blue-A100,
icon-material-blue-A100 {
  color: #82b1ff;
}
.mdi-material-blue-A200,
icon-material-blue-A200 {
  color: #448aff;
}
.mdi-material-blue-A400,
icon-material-blue-A400 {
  color: #2979ff;
}
.mdi-material-blue-A700,
icon-material-blue-A700 {
  color: #2962ff;
}
.mdi-material-light-blue,
icon-material-light-blue {
  color: #03a9f4;
}
.mdi-material-light-blue-50,
icon-material-light-blue-50 {
  color: #e1f5fe;
}
.mdi-material-light-blue-100,
icon-material-light-blue-100 {
  color: #b3e5fc;
}
.mdi-material-light-blue-200,
icon-material-light-blue-200 {
  color: #81d4fa;
}
.mdi-material-light-blue-300,
icon-material-light-blue-300 {
  color: #4fc3f7;
}
.mdi-material-light-blue-400,
icon-material-light-blue-400 {
  color: #29b6f6;
}
.mdi-material-light-blue-500,
icon-material-light-blue-500 {
  color: #03a9f4;
}
.mdi-material-light-blue-600,
icon-material-light-blue-600 {
  color: #039be5;
}
.mdi-material-light-blue-700,
icon-material-light-blue-700 {
  color: #0288d1;
}
.mdi-material-light-blue-800,
icon-material-light-blue-800 {
  color: #0277bd;
}
.mdi-material-light-blue-900,
icon-material-light-blue-900 {
  color: #01579b;
}
.mdi-material-light-blue-A100,
icon-material-light-blue-A100 {
  color: #80d8ff;
}
.mdi-material-light-blue-A200,
icon-material-light-blue-A200 {
  color: #40c4ff;
}
.mdi-material-light-blue-A400,
icon-material-light-blue-A400 {
  color: #00b0ff;
}
.mdi-material-light-blue-A700,
icon-material-light-blue-A700 {
  color: #0091ea;
}
.mdi-material-cyan,
icon-material-cyan {
  color: #00bcd4;
}
.mdi-material-cyan-50,
icon-material-cyan-50 {
  color: #e0f7fa;
}
.mdi-material-cyan-100,
icon-material-cyan-100 {
  color: #b2ebf2;
}
.mdi-material-cyan-200,
icon-material-cyan-200 {
  color: #80deea;
}
.mdi-material-cyan-300,
icon-material-cyan-300 {
  color: #4dd0e1;
}
.mdi-material-cyan-400,
icon-material-cyan-400 {
  color: #26c6da;
}
.mdi-material-cyan-500,
icon-material-cyan-500 {
  color: #00bcd4;
}
.mdi-material-cyan-600,
icon-material-cyan-600 {
  color: #00acc1;
}
.mdi-material-cyan-700,
icon-material-cyan-700 {
  color: #0097a7;
}
.mdi-material-cyan-800,
icon-material-cyan-800 {
  color: #00838f;
}
.mdi-material-cyan-900,
icon-material-cyan-900 {
  color: #006064;
}
.mdi-material-cyan-A100,
icon-material-cyan-A100 {
  color: #84ffff;
}
.mdi-material-cyan-A200,
icon-material-cyan-A200 {
  color: #18ffff;
}
.mdi-material-cyan-A400,
icon-material-cyan-A400 {
  color: #00e5ff;
}
.mdi-material-cyan-A700,
icon-material-cyan-A700 {
  color: #00b8d4;
}
.mdi-material-teal,
icon-material-teal {
  color: #009688;
}
.mdi-material-teal-50,
icon-material-teal-50 {
  color: #e0f2f1;
}
.mdi-material-teal-100,
icon-material-teal-100 {
  color: #b2dfdb;
}
.mdi-material-teal-200,
icon-material-teal-200 {
  color: #80cbc4;
}
.mdi-material-teal-300,
icon-material-teal-300 {
  color: #4db6ac;
}
.mdi-material-teal-400,
icon-material-teal-400 {
  color: #26a69a;
}
.mdi-material-teal-500,
icon-material-teal-500 {
  color: #009688;
}
.mdi-material-teal-600,
icon-material-teal-600 {
  color: #00897b;
}
.mdi-material-teal-700,
icon-material-teal-700 {
  color: #00796b;
}
.mdi-material-teal-800,
icon-material-teal-800 {
  color: #00695c;
}
.mdi-material-teal-900,
icon-material-teal-900 {
  color: #004d40;
}
.mdi-material-teal-A100,
icon-material-teal-A100 {
  color: #a7ffeb;
}
.mdi-material-teal-A200,
icon-material-teal-A200 {
  color: #64ffda;
}
.mdi-material-teal-A400,
icon-material-teal-A400 {
  color: #1de9b6;
}
.mdi-material-teal-A700,
icon-material-teal-A700 {
  color: #00bfa5;
}
.mdi-material-green,
icon-material-green {
  color: #4caf50;
}
.mdi-material-green-50,
icon-material-green-50 {
  color: #e8f5e9;
}
.mdi-material-green-100,
icon-material-green-100 {
  color: #c8e6c9;
}
.mdi-material-green-200,
icon-material-green-200 {
  color: #a5d6a7;
}
.mdi-material-green-300,
icon-material-green-300 {
  color: #81c784;
}
.mdi-material-green-400,
icon-material-green-400 {
  color: #66bb6a;
}
.mdi-material-green-500,
icon-material-green-500 {
  color: #4caf50;
}
.mdi-material-green-600,
icon-material-green-600 {
  color: #43a047;
}
.mdi-material-green-700,
icon-material-green-700 {
  color: #388e3c;
}
.mdi-material-green-800,
icon-material-green-800 {
  color: #2e7d32;
}
.mdi-material-green-900,
icon-material-green-900 {
  color: #1b5e20;
}
.mdi-material-green-A100,
icon-material-green-A100 {
  color: #b9f6ca;
}
.mdi-material-green-A200,
icon-material-green-A200 {
  color: #69f0ae;
}
.mdi-material-green-A400,
icon-material-green-A400 {
  color: #00e676;
}
.mdi-material-green-A700,
icon-material-green-A700 {
  color: #00c853;
}
.mdi-material-light-green,
icon-material-light-green {
  color: #8bc34a;
}
.mdi-material-light-green-50,
icon-material-light-green-50 {
  color: #f1f8e9;
}
.mdi-material-light-green-100,
icon-material-light-green-100 {
  color: #dcedc8;
}
.mdi-material-light-green-200,
icon-material-light-green-200 {
  color: #c5e1a5;
}
.mdi-material-light-green-300,
icon-material-light-green-300 {
  color: #aed581;
}
.mdi-material-light-green-400,
icon-material-light-green-400 {
  color: #9ccc65;
}
.mdi-material-light-green-500,
icon-material-light-green-500 {
  color: #8bc34a;
}
.mdi-material-light-green-600,
icon-material-light-green-600 {
  color: #7cb342;
}
.mdi-material-light-green-700,
icon-material-light-green-700 {
  color: #689f38;
}
.mdi-material-light-green-800,
icon-material-light-green-800 {
  color: #558b2f;
}
.mdi-material-light-green-900,
icon-material-light-green-900 {
  color: #33691e;
}
.mdi-material-light-green-A100,
icon-material-light-green-A100 {
  color: #ccff90;
}
.mdi-material-light-green-A200,
icon-material-light-green-A200 {
  color: #b2ff59;
}
.mdi-material-light-green-A400,
icon-material-light-green-A400 {
  color: #76ff03;
}
.mdi-material-light-green-A700,
icon-material-light-green-A700 {
  color: #64dd17;
}
.mdi-material-lime,
icon-material-lime {
  color: #cddc39;
}
.mdi-material-lime-50,
icon-material-lime-50 {
  color: #f9fbe7;
}
.mdi-material-lime-100,
icon-material-lime-100 {
  color: #f0f4c3;
}
.mdi-material-lime-200,
icon-material-lime-200 {
  color: #e6ee9c;
}
.mdi-material-lime-300,
icon-material-lime-300 {
  color: #dce775;
}
.mdi-material-lime-400,
icon-material-lime-400 {
  color: #d4e157;
}
.mdi-material-lime-500,
icon-material-lime-500 {
  color: #cddc39;
}
.mdi-material-lime-600,
icon-material-lime-600 {
  color: #c0ca33;
}
.mdi-material-lime-700,
icon-material-lime-700 {
  color: #afb42b;
}
.mdi-material-lime-800,
icon-material-lime-800 {
  color: #9e9d24;
}
.mdi-material-lime-900,
icon-material-lime-900 {
  color: #827717;
}
.mdi-material-lime-A100,
icon-material-lime-A100 {
  color: #f4ff81;
}
.mdi-material-lime-A200,
icon-material-lime-A200 {
  color: #eeff41;
}
.mdi-material-lime-A400,
icon-material-lime-A400 {
  color: #c6ff00;
}
.mdi-material-lime-A700,
icon-material-lime-A700 {
  color: #aeea00;
}
.mdi-material-yellow,
icon-material-yellow {
  color: #ffeb3b;
}
.mdi-material-yellow-50,
icon-material-yellow-50 {
  color: #fffde7;
}
.mdi-material-yellow-100,
icon-material-yellow-100 {
  color: #fff9c4;
}
.mdi-material-yellow-200,
icon-material-yellow-200 {
  color: #fff59d;
}
.mdi-material-yellow-300,
icon-material-yellow-300 {
  color: #fff176;
}
.mdi-material-yellow-400,
icon-material-yellow-400 {
  color: #ffee58;
}
.mdi-material-yellow-500,
icon-material-yellow-500 {
  color: #ffeb3b;
}
.mdi-material-yellow-600,
icon-material-yellow-600 {
  color: #fdd835;
}
.mdi-material-yellow-700,
icon-material-yellow-700 {
  color: #fbc02d;
}
.mdi-material-yellow-800,
icon-material-yellow-800 {
  color: #f9a825;
}
.mdi-material-yellow-900,
icon-material-yellow-900 {
  color: #f57f17;
}
.mdi-material-yellow-A100,
icon-material-yellow-A100 {
  color: #ffff8d;
}
.mdi-material-yellow-A200,
icon-material-yellow-A200 {
  color: #ffff00;
}
.mdi-material-yellow-A400,
icon-material-yellow-A400 {
  color: #ffea00;
}
.mdi-material-yellow-A700,
icon-material-yellow-A700 {
  color: #ffd600;
}
.mdi-material-amber,
icon-material-amber {
  color: #ffc107;
}
.mdi-material-amber-50,
icon-material-amber-50 {
  color: #fff8e1;
}
.mdi-material-amber-100,
icon-material-amber-100 {
  color: #ffecb3;
}
.mdi-material-amber-200,
icon-material-amber-200 {
  color: #ffe082;
}
.mdi-material-amber-300,
icon-material-amber-300 {
  color: #ffd54f;
}
.mdi-material-amber-400,
icon-material-amber-400 {
  color: #ffca28;
}
.mdi-material-amber-500,
icon-material-amber-500 {
  color: #ffc107;
}
.mdi-material-amber-600,
icon-material-amber-600 {
  color: #ffb300;
}
.mdi-material-amber-700,
icon-material-amber-700 {
  color: #ffa000;
}
.mdi-material-amber-800,
icon-material-amber-800 {
  color: #ff8f00;
}
.mdi-material-amber-900,
icon-material-amber-900 {
  color: #ff6f00;
}
.mdi-material-amber-A100,
icon-material-amber-A100 {
  color: #ffe57f;
}
.mdi-material-amber-A200,
icon-material-amber-A200 {
  color: #ffd740;
}
.mdi-material-amber-A400,
icon-material-amber-A400 {
  color: #ffc400;
}
.mdi-material-amber-A700,
icon-material-amber-A700 {
  color: #ffab00;
}
.mdi-material-orange,
icon-material-orange {
  color: #ff9800;
}
.mdi-material-orange-50,
icon-material-orange-50 {
  color: #fff3e0;
}
.mdi-material-orange-100,
icon-material-orange-100 {
  color: #ffe0b2;
}
.mdi-material-orange-200,
icon-material-orange-200 {
  color: #ffcc80;
}
.mdi-material-orange-300,
icon-material-orange-300 {
  color: #ffb74d;
}
.mdi-material-orange-400,
icon-material-orange-400 {
  color: #ffa726;
}
.mdi-material-orange-500,
icon-material-orange-500 {
  color: #ff9800;
}
.mdi-material-orange-600,
icon-material-orange-600 {
  color: #fb8c00;
}
.mdi-material-orange-700,
icon-material-orange-700 {
  color: #f57c00;
}
.mdi-material-orange-800,
icon-material-orange-800 {
  color: #ef6c00;
}
.mdi-material-orange-900,
icon-material-orange-900 {
  color: #e65100;
}
.mdi-material-orange-A100,
icon-material-orange-A100 {
  color: #ffd180;
}
.mdi-material-orange-A200,
icon-material-orange-A200 {
  color: #ffab40;
}
.mdi-material-orange-A400,
icon-material-orange-A400 {
  color: #ff9100;
}
.mdi-material-orange-A700,
icon-material-orange-A700 {
  color: #ff6d00;
}
.mdi-material-deep-orange,
icon-material-deep-orange {
  color: #ff5722;
}
.mdi-material-deep-orange-50,
icon-material-deep-orange-50 {
  color: #fbe9e7;
}
.mdi-material-deep-orange-100,
icon-material-deep-orange-100 {
  color: #ffccbc;
}
.mdi-material-deep-orange-200,
icon-material-deep-orange-200 {
  color: #ffab91;
}
.mdi-material-deep-orange-300,
icon-material-deep-orange-300 {
  color: #ff8a65;
}
.mdi-material-deep-orange-400,
icon-material-deep-orange-400 {
  color: #ff7043;
}
.mdi-material-deep-orange-500,
icon-material-deep-orange-500 {
  color: #ff5722;
}
.mdi-material-deep-orange-600,
icon-material-deep-orange-600 {
  color: #f4511e;
}
.mdi-material-deep-orange-700,
icon-material-deep-orange-700 {
  color: #e64a19;
}
.mdi-material-deep-orange-800,
icon-material-deep-orange-800 {
  color: #d84315;
}
.mdi-material-deep-orange-900,
icon-material-deep-orange-900 {
  color: #bf360c;
}
.mdi-material-deep-orange-A100,
icon-material-deep-orange-A100 {
  color: #ff9e80;
}
.mdi-material-deep-orange-A200,
icon-material-deep-orange-A200 {
  color: #ff6e40;
}
.mdi-material-deep-orange-A400,
icon-material-deep-orange-A400 {
  color: #ff3d00;
}
.mdi-material-deep-orange-A700,
icon-material-deep-orange-A700 {
  color: #dd2c00;
}
.mdi-material-brown,
icon-material-brown {
  color: #795548;
}
.mdi-material-brown-50,
icon-material-brown-50 {
  color: #efebe9;
}
.mdi-material-brown-100,
icon-material-brown-100 {
  color: #d7ccc8;
}
.mdi-material-brown-200,
icon-material-brown-200 {
  color: #bcaaa4;
}
.mdi-material-brown-300,
icon-material-brown-300 {
  color: #a1887f;
}
.mdi-material-brown-400,
icon-material-brown-400 {
  color: #8d6e63;
}
.mdi-material-brown-500,
icon-material-brown-500 {
  color: #795548;
}
.mdi-material-brown-600,
icon-material-brown-600 {
  color: #6d4c41;
}
.mdi-material-brown-700,
icon-material-brown-700 {
  color: #5d4037;
}
.mdi-material-brown-800,
icon-material-brown-800 {
  color: #4e342e;
}
.mdi-material-brown-900,
icon-material-brown-900 {
  color: #3e2723;
}
.mdi-material-brown-A100,
icon-material-brown-A100 {
  color: #d7ccc8;
}
.mdi-material-brown-A200,
icon-material-brown-A200 {
  color: #bcaaa4;
}
.mdi-material-brown-A400,
icon-material-brown-A400 {
  color: #8d6e63;
}
.mdi-material-brown-A700,
icon-material-brown-A700 {
  color: #5d4037;
}
.mdi-material-grey,
icon-material-grey {
  color: #9e9e9e;
}
.mdi-material-grey-50,
icon-material-grey-50 {
  color: #fafafa;
}
.mdi-material-grey-100,
icon-material-grey-100 {
  color: #f5f5f5;
}
.mdi-material-grey-200,
icon-material-grey-200 {
  color: #eeeeee;
}
.mdi-material-grey-300,
icon-material-grey-300 {
  color: #e0e0e0;
}
.mdi-material-grey-400,
icon-material-grey-400 {
  color: #bdbdbd;
}
.mdi-material-grey-500,
icon-material-grey-500 {
  color: #9e9e9e;
}
.mdi-material-grey-600,
icon-material-grey-600 {
  color: #757575;
}
.mdi-material-grey-700,
icon-material-grey-700 {
  color: #616161;
}
.mdi-material-grey-800,
icon-material-grey-800 {
  color: #424242;
}
.mdi-material-grey-900,
icon-material-grey-900 {
  color: #212121;
}
.mdi-material-grey-A100,
icon-material-grey-A100 {
  color: #f5f5f5;
}
.mdi-material-grey-A200,
icon-material-grey-A200 {
  color: #eeeeee;
}
.mdi-material-grey-A400,
icon-material-grey-A400 {
  color: #bdbdbd;
}
.mdi-material-grey-A700,
icon-material-grey-A700 {
  color: #616161;
}
.mdi-material-blue,
icon-material-blue {
  color: #607d8b;
}
.mdi-material-blue-50,
icon-material-blue-50 {
  color: #eceff1;
}
.mdi-material-blue-100,
icon-material-blue-100 {
  color: #cfd8dc;
}
.mdi-material-blue-200,
icon-material-blue-200 {
  color: #b0bec5;
}
.mdi-material-blue-300,
icon-material-blue-300 {
  color: #90a4ae;
}
.mdi-material-blue-400,
icon-material-blue-400 {
  color: #78909c;
}
.mdi-material-blue-500,
icon-material-blue-500 {
  color: #607d8b;
}
.mdi-material-blue-600,
icon-material-blue-600 {
  color: #546e7a;
}
.mdi-material-blue-700,
icon-material-blue-700 {
  color: #455a64;
}
.mdi-material-blue-800,
icon-material-blue-800 {
  color: #37474f;
}
.mdi-material-blue-900,
icon-material-blue-900 {
  color: #263238;
}
.mdi-material-blue-A100,
icon-material-blue-A100 {
  color: #cfd8dc;
}
.mdi-material-blue-A200,
icon-material-blue-A200 {
  color: #b0bec5;
}
.mdi-material-blue-A400,
icon-material-blue-A400 {
  color: #78909c;
}
.mdi-material-blue-A700,
icon-material-blue-A700 {
  color: #455a64;
}
.card {
  /***** Make height equal to width (http://stackoverflow.com/a/6615994) ****/
  display: inline-block;
  position: relative;
  width: 100%;
  /**************************************************************************/
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.84);
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
}
.card .card-height-indicator {
  margin-top: 100%;
}
.card .card-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.card .card-image {
  height: 60%;
  position: relative;
  overflow: hidden;
}
.card .card-image img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  pointer-events: none;
}
.card .card-image .card-image-headline {
  position: absolute;
  bottom: 16px;
  left: 18px;
  color: #ffffff;
  font-size: 2em;
}
.card .card-body {
  height: 30%;
  padding: 18px;
}
.card .card-footer {
  height: 10%;
  padding: 18px;
}
.card .card-footer button {
  margin: 0 !important;
  position: relative;
  bottom: 25px;
  width: auto;
}
.card .card-footer button:first-child {
  left: -15px;
}
.modal-content {
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.22), 0 19px 60px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: none;
}
.modal-content .modal-header {
  border-bottom: none;
  padding: 24px;
  padding-bottom: 0;
}
.modal-content .modal-body {
  padding: 24px;
}
.modal-content .modal-footer {
  border-top: none;
  padding: 24px;
}
.modal-content .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-content .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal-content .modal-footer button + button {
  margin-bottom: 16px;
}
.modal-content .modal-body + .modal-footer {
  padding-top: 0;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}
.label {
  border-radius: 1px;
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.label,
.label-default {
  background-color: #9e9e9e;
}
.label-primary {
  background-color: #009587;
}
.label-success {
  background-color: #0f9d58;
}
.label-info {
  background-color: #03a9f4;
}
.label-warning {
  background-color: #ff5722;
}
.label-danger {
  background-color: #f44336;
}
.label-material-red {
  background-color: #f44336;
}
.label-material-red-50 {
  background-color: #ffebee;
}
.label-material-red-100 {
  background-color: #ffcdd2;
}
.label-material-red-200 {
  background-color: #ef9a9a;
}
.label-material-red-300 {
  background-color: #e57373;
}
.label-material-red-400 {
  background-color: #ef5350;
}
.label-material-red-500 {
  background-color: #f44336;
}
.label-material-red-600 {
  background-color: #e53935;
}
.label-material-red-700 {
  background-color: #d32f2f;
}
.label-material-red-800 {
  background-color: #c62828;
}
.label-material-red-900 {
  background-color: #b71c1c;
}
.label-material-red-A100 {
  background-color: #ff8a80;
}
.label-material-red-A200 {
  background-color: #ff5252;
}
.label-material-red-A400 {
  background-color: #ff1744;
}
.label-material-red-A700 {
  background-color: #d50000;
}
.label-material-pink {
  background-color: #e91e63;
}
.label-material-pink-50 {
  background-color: #fce4ec;
}
.label-material-pink-100 {
  background-color: #f8bbd0;
}
.label-material-pink-200 {
  background-color: #f48fb1;
}
.label-material-pink-300 {
  background-color: #f06292;
}
.label-material-pink-400 {
  background-color: #ec407a;
}
.label-material-pink-500 {
  background-color: #e91e63;
}
.label-material-pink-600 {
  background-color: #d81b60;
}
.label-material-pink-700 {
  background-color: #c2185b;
}
.label-material-pink-800 {
  background-color: #ad1457;
}
.label-material-pink-900 {
  background-color: #880e4f;
}
.label-material-pink-A100 {
  background-color: #ff80ab;
}
.label-material-pink-A200 {
  background-color: #ff4081;
}
.label-material-pink-A400 {
  background-color: #f50057;
}
.label-material-pink-A700 {
  background-color: #c51162;
}
.label-material-purple {
  background-color: #9c27b0;
}
.label-material-purple-50 {
  background-color: #f3e5f5;
}
.label-material-purple-100 {
  background-color: #e1bee7;
}
.label-material-purple-200 {
  background-color: #ce93d8;
}
.label-material-purple-300 {
  background-color: #ba68c8;
}
.label-material-purple-400 {
  background-color: #ab47bc;
}
.label-material-purple-500 {
  background-color: #9c27b0;
}
.label-material-purple-600 {
  background-color: #8e24aa;
}
.label-material-purple-700 {
  background-color: #7b1fa2;
}
.label-material-purple-800 {
  background-color: #6a1b9a;
}
.label-material-purple-900 {
  background-color: #4a148c;
}
.label-material-purple-A100 {
  background-color: #ea80fc;
}
.label-material-purple-A200 {
  background-color: #e040fb;
}
.label-material-purple-A400 {
  background-color: #d500f9;
}
.label-material-purple-A700 {
  background-color: #aa00ff;
}
.label-material-deep-purple {
  background-color: #673ab7;
}
.label-material-deep-purple-50 {
  background-color: #ede7f6;
}
.label-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.label-material-deep-purple-200 {
  background-color: #b39ddb;
}
.label-material-deep-purple-300 {
  background-color: #9575cd;
}
.label-material-deep-purple-400 {
  background-color: #7e57c2;
}
.label-material-deep-purple-500 {
  background-color: #673ab7;
}
.label-material-deep-purple-600 {
  background-color: #5e35b1;
}
.label-material-deep-purple-700 {
  background-color: #512da8;
}
.label-material-deep-purple-800 {
  background-color: #4527a0;
}
.label-material-deep-purple-900 {
  background-color: #311b92;
}
.label-material-deep-purple-A100 {
  background-color: #b388ff;
}
.label-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.label-material-deep-purple-A400 {
  background-color: #651fff;
}
.label-material-deep-purple-A700 {
  background-color: #6200ea;
}
.label-material-indigo {
  background-color: #3f51b5;
}
.label-material-indigo-50 {
  background-color: #e8eaf6;
}
.label-material-indigo-100 {
  background-color: #c5cae9;
}
.label-material-indigo-200 {
  background-color: #9fa8da;
}
.label-material-indigo-300 {
  background-color: #7986cb;
}
.label-material-indigo-400 {
  background-color: #5c6bc0;
}
.label-material-indigo-500 {
  background-color: #3f51b5;
}
.label-material-indigo-600 {
  background-color: #3949ab;
}
.label-material-indigo-700 {
  background-color: #303f9f;
}
.label-material-indigo-800 {
  background-color: #283593;
}
.label-material-indigo-900 {
  background-color: #1a237e;
}
.label-material-indigo-A100 {
  background-color: #8c9eff;
}
.label-material-indigo-A200 {
  background-color: #536dfe;
}
.label-material-indigo-A400 {
  background-color: #3d5afe;
}
.label-material-indigo-A700 {
  background-color: #304ffe;
}
.label-material-blue {
  background-color: #2196f3;
}
.label-material-blue-50 {
  background-color: #e3f2fd;
}
.label-material-blue-100 {
  background-color: #bbdefb;
}
.label-material-blue-200 {
  background-color: #90caf9;
}
.label-material-blue-300 {
  background-color: #64b5f6;
}
.label-material-blue-400 {
  background-color: #42a5f5;
}
.label-material-blue-500 {
  background-color: #2196f3;
}
.label-material-blue-600 {
  background-color: #1e88e5;
}
.label-material-blue-700 {
  background-color: #1976d2;
}
.label-material-blue-800 {
  background-color: #1565c0;
}
.label-material-blue-900 {
  background-color: #0d47a1;
}
.label-material-blue-A100 {
  background-color: #82b1ff;
}
.label-material-blue-A200 {
  background-color: #448aff;
}
.label-material-blue-A400 {
  background-color: #2979ff;
}
.label-material-blue-A700 {
  background-color: #2962ff;
}
.label-material-light-blue {
  background-color: #03a9f4;
}
.label-material-light-blue-50 {
  background-color: #e1f5fe;
}
.label-material-light-blue-100 {
  background-color: #b3e5fc;
}
.label-material-light-blue-200 {
  background-color: #81d4fa;
}
.label-material-light-blue-300 {
  background-color: #4fc3f7;
}
.label-material-light-blue-400 {
  background-color: #29b6f6;
}
.label-material-light-blue-500 {
  background-color: #03a9f4;
}
.label-material-light-blue-600 {
  background-color: #039be5;
}
.label-material-light-blue-700 {
  background-color: #0288d1;
}
.label-material-light-blue-800 {
  background-color: #0277bd;
}
.label-material-light-blue-900 {
  background-color: #01579b;
}
.label-material-light-blue-A100 {
  background-color: #80d8ff;
}
.label-material-light-blue-A200 {
  background-color: #40c4ff;
}
.label-material-light-blue-A400 {
  background-color: #00b0ff;
}
.label-material-light-blue-A700 {
  background-color: #0091ea;
}
.label-material-cyan {
  background-color: #00bcd4;
}
.label-material-cyan-50 {
  background-color: #e0f7fa;
}
.label-material-cyan-100 {
  background-color: #b2ebf2;
}
.label-material-cyan-200 {
  background-color: #80deea;
}
.label-material-cyan-300 {
  background-color: #4dd0e1;
}
.label-material-cyan-400 {
  background-color: #26c6da;
}
.label-material-cyan-500 {
  background-color: #00bcd4;
}
.label-material-cyan-600 {
  background-color: #00acc1;
}
.label-material-cyan-700 {
  background-color: #0097a7;
}
.label-material-cyan-800 {
  background-color: #00838f;
}
.label-material-cyan-900 {
  background-color: #006064;
}
.label-material-cyan-A100 {
  background-color: #84ffff;
}
.label-material-cyan-A200 {
  background-color: #18ffff;
}
.label-material-cyan-A400 {
  background-color: #00e5ff;
}
.label-material-cyan-A700 {
  background-color: #00b8d4;
}
.label-material-teal {
  background-color: #009688;
}
.label-material-teal-50 {
  background-color: #e0f2f1;
}
.label-material-teal-100 {
  background-color: #b2dfdb;
}
.label-material-teal-200 {
  background-color: #80cbc4;
}
.label-material-teal-300 {
  background-color: #4db6ac;
}
.label-material-teal-400 {
  background-color: #26a69a;
}
.label-material-teal-500 {
  background-color: #009688;
}
.label-material-teal-600 {
  background-color: #00897b;
}
.label-material-teal-700 {
  background-color: #00796b;
}
.label-material-teal-800 {
  background-color: #00695c;
}
.label-material-teal-900 {
  background-color: #004d40;
}
.label-material-teal-A100 {
  background-color: #a7ffeb;
}
.label-material-teal-A200 {
  background-color: #64ffda;
}
.label-material-teal-A400 {
  background-color: #1de9b6;
}
.label-material-teal-A700 {
  background-color: #00bfa5;
}
.label-material-green {
  background-color: #4caf50;
}
.label-material-green-50 {
  background-color: #e8f5e9;
}
.label-material-green-100 {
  background-color: #c8e6c9;
}
.label-material-green-200 {
  background-color: #a5d6a7;
}
.label-material-green-300 {
  background-color: #81c784;
}
.label-material-green-400 {
  background-color: #66bb6a;
}
.label-material-green-500 {
  background-color: #4caf50;
}
.label-material-green-600 {
  background-color: #43a047;
}
.label-material-green-700 {
  background-color: #388e3c;
}
.label-material-green-800 {
  background-color: #2e7d32;
}
.label-material-green-900 {
  background-color: #1b5e20;
}
.label-material-green-A100 {
  background-color: #b9f6ca;
}
.label-material-green-A200 {
  background-color: #69f0ae;
}
.label-material-green-A400 {
  background-color: #00e676;
}
.label-material-green-A700 {
  background-color: #00c853;
}
.label-material-light-green {
  background-color: #8bc34a;
}
.label-material-light-green-50 {
  background-color: #f1f8e9;
}
.label-material-light-green-100 {
  background-color: #dcedc8;
}
.label-material-light-green-200 {
  background-color: #c5e1a5;
}
.label-material-light-green-300 {
  background-color: #aed581;
}
.label-material-light-green-400 {
  background-color: #9ccc65;
}
.label-material-light-green-500 {
  background-color: #8bc34a;
}
.label-material-light-green-600 {
  background-color: #7cb342;
}
.label-material-light-green-700 {
  background-color: #689f38;
}
.label-material-light-green-800 {
  background-color: #558b2f;
}
.label-material-light-green-900 {
  background-color: #33691e;
}
.label-material-light-green-A100 {
  background-color: #ccff90;
}
.label-material-light-green-A200 {
  background-color: #b2ff59;
}
.label-material-light-green-A400 {
  background-color: #76ff03;
}
.label-material-light-green-A700 {
  background-color: #64dd17;
}
.label-material-lime {
  background-color: #cddc39;
}
.label-material-lime-50 {
  background-color: #f9fbe7;
}
.label-material-lime-100 {
  background-color: #f0f4c3;
}
.label-material-lime-200 {
  background-color: #e6ee9c;
}
.label-material-lime-300 {
  background-color: #dce775;
}
.label-material-lime-400 {
  background-color: #d4e157;
}
.label-material-lime-500 {
  background-color: #cddc39;
}
.label-material-lime-600 {
  background-color: #c0ca33;
}
.label-material-lime-700 {
  background-color: #afb42b;
}
.label-material-lime-800 {
  background-color: #9e9d24;
}
.label-material-lime-900 {
  background-color: #827717;
}
.label-material-lime-A100 {
  background-color: #f4ff81;
}
.label-material-lime-A200 {
  background-color: #eeff41;
}
.label-material-lime-A400 {
  background-color: #c6ff00;
}
.label-material-lime-A700 {
  background-color: #aeea00;
}
.label-material-yellow {
  background-color: #ffeb3b;
}
.label-material-yellow-50 {
  background-color: #fffde7;
}
.label-material-yellow-100 {
  background-color: #fff9c4;
}
.label-material-yellow-200 {
  background-color: #fff59d;
}
.label-material-yellow-300 {
  background-color: #fff176;
}
.label-material-yellow-400 {
  background-color: #ffee58;
}
.label-material-yellow-500 {
  background-color: #ffeb3b;
}
.label-material-yellow-600 {
  background-color: #fdd835;
}
.label-material-yellow-700 {
  background-color: #fbc02d;
}
.label-material-yellow-800 {
  background-color: #f9a825;
}
.label-material-yellow-900 {
  background-color: #f57f17;
}
.label-material-yellow-A100 {
  background-color: #ffff8d;
}
.label-material-yellow-A200 {
  background-color: #ffff00;
}
.label-material-yellow-A400 {
  background-color: #ffea00;
}
.label-material-yellow-A700 {
  background-color: #ffd600;
}
.label-material-amber {
  background-color: #ffc107;
}
.label-material-amber-50 {
  background-color: #fff8e1;
}
.label-material-amber-100 {
  background-color: #ffecb3;
}
.label-material-amber-200 {
  background-color: #ffe082;
}
.label-material-amber-300 {
  background-color: #ffd54f;
}
.label-material-amber-400 {
  background-color: #ffca28;
}
.label-material-amber-500 {
  background-color: #ffc107;
}
.label-material-amber-600 {
  background-color: #ffb300;
}
.label-material-amber-700 {
  background-color: #ffa000;
}
.label-material-amber-800 {
  background-color: #ff8f00;
}
.label-material-amber-900 {
  background-color: #ff6f00;
}
.label-material-amber-A100 {
  background-color: #ffe57f;
}
.label-material-amber-A200 {
  background-color: #ffd740;
}
.label-material-amber-A400 {
  background-color: #ffc400;
}
.label-material-amber-A700 {
  background-color: #ffab00;
}
.label-material-orange {
  background-color: #ff9800;
}
.label-material-orange-50 {
  background-color: #fff3e0;
}
.label-material-orange-100 {
  background-color: #ffe0b2;
}
.label-material-orange-200 {
  background-color: #ffcc80;
}
.label-material-orange-300 {
  background-color: #ffb74d;
}
.label-material-orange-400 {
  background-color: #ffa726;
}
.label-material-orange-500 {
  background-color: #ff9800;
}
.label-material-orange-600 {
  background-color: #fb8c00;
}
.label-material-orange-700 {
  background-color: #f57c00;
}
.label-material-orange-800 {
  background-color: #ef6c00;
}
.label-material-orange-900 {
  background-color: #e65100;
}
.label-material-orange-A100 {
  background-color: #ffd180;
}
.label-material-orange-A200 {
  background-color: #ffab40;
}
.label-material-orange-A400 {
  background-color: #ff9100;
}
.label-material-orange-A700 {
  background-color: #ff6d00;
}
.label-material-deep-orange {
  background-color: #ff5722;
}
.label-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.label-material-deep-orange-100 {
  background-color: #ffccbc;
}
.label-material-deep-orange-200 {
  background-color: #ffab91;
}
.label-material-deep-orange-300 {
  background-color: #ff8a65;
}
.label-material-deep-orange-400 {
  background-color: #ff7043;
}
.label-material-deep-orange-500 {
  background-color: #ff5722;
}
.label-material-deep-orange-600 {
  background-color: #f4511e;
}
.label-material-deep-orange-700 {
  background-color: #e64a19;
}
.label-material-deep-orange-800 {
  background-color: #d84315;
}
.label-material-deep-orange-900 {
  background-color: #bf360c;
}
.label-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.label-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.label-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.label-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.label-material-brown {
  background-color: #795548;
}
.label-material-brown-50 {
  background-color: #efebe9;
}
.label-material-brown-100 {
  background-color: #d7ccc8;
}
.label-material-brown-200 {
  background-color: #bcaaa4;
}
.label-material-brown-300 {
  background-color: #a1887f;
}
.label-material-brown-400 {
  background-color: #8d6e63;
}
.label-material-brown-500 {
  background-color: #795548;
}
.label-material-brown-600 {
  background-color: #6d4c41;
}
.label-material-brown-700 {
  background-color: #5d4037;
}
.label-material-brown-800 {
  background-color: #4e342e;
}
.label-material-brown-900 {
  background-color: #3e2723;
}
.label-material-brown-A100 {
  background-color: #d7ccc8;
}
.label-material-brown-A200 {
  background-color: #bcaaa4;
}
.label-material-brown-A400 {
  background-color: #8d6e63;
}
.label-material-brown-A700 {
  background-color: #5d4037;
}
.label-material-grey {
  background-color: #9e9e9e;
}
.label-material-grey-50 {
  background-color: #fafafa;
}
.label-material-grey-100 {
  background-color: #f5f5f5;
}
.label-material-grey-200 {
  background-color: #eeeeee;
}
.label-material-grey-300 {
  background-color: #e0e0e0;
}
.label-material-grey-400 {
  background-color: #bdbdbd;
}
.label-material-grey-500 {
  background-color: #9e9e9e;
}
.label-material-grey-600 {
  background-color: #757575;
}
.label-material-grey-700 {
  background-color: #616161;
}
.label-material-grey-800 {
  background-color: #424242;
}
.label-material-grey-900 {
  background-color: #212121;
}
.label-material-grey-A100 {
  background-color: #f5f5f5;
}
.label-material-grey-A200 {
  background-color: #eeeeee;
}
.label-material-grey-A400 {
  background-color: #bdbdbd;
}
.label-material-grey-A700 {
  background-color: #616161;
}
.label-material-blue {
  background-color: #607d8b;
}
.label-material-blue-50 {
  background-color: #eceff1;
}
.label-material-blue-100 {
  background-color: #cfd8dc;
}
.label-material-blue-200 {
  background-color: #b0bec5;
}
.label-material-blue-300 {
  background-color: #90a4ae;
}
.label-material-blue-400 {
  background-color: #78909c;
}
.label-material-blue-500 {
  background-color: #607d8b;
}
.label-material-blue-600 {
  background-color: #546e7a;
}
.label-material-blue-700 {
  background-color: #455a64;
}
.label-material-blue-800 {
  background-color: #37474f;
}
.label-material-blue-900 {
  background-color: #263238;
}
.label-material-blue-A100 {
  background-color: #cfd8dc;
}
.label-material-blue-A200 {
  background-color: #b0bec5;
}
.label-material-blue-A400 {
  background-color: #78909c;
}
.label-material-blue-A700 {
  background-color: #455a64;
}
.panel {
  border-radius: 2px;
  border: 0;
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
}
.panel > .panel-heading,
.panel-default > .panel-heading {
  background-color: #ececec;
}
.panel-primary > .panel-heading {
  background-color: #009587;
}
.panel-success > .panel-heading {
  background-color: #0f9d58;
}
.panel-info > .panel-heading {
  background-color: #03a9f4;
}
.panel-warning > .panel-heading {
  background-color: #ff5722;
}
.panel-danger > .panel-heading {
  background-color: #f44336;
}
.panel-material-red > .panel-heading {
  background-color: #f44336;
}
.panel-material-red-50 > .panel-heading {
  background-color: #ffebee;
}
.panel-material-red-100 > .panel-heading {
  background-color: #ffcdd2;
}
.panel-material-red-200 > .panel-heading {
  background-color: #ef9a9a;
}
.panel-material-red-300 > .panel-heading {
  background-color: #e57373;
}
.panel-material-red-400 > .panel-heading {
  background-color: #ef5350;
}
.panel-material-red-500 > .panel-heading {
  background-color: #f44336;
}
.panel-material-red-600 > .panel-heading {
  background-color: #e53935;
}
.panel-material-red-700 > .panel-heading {
  background-color: #d32f2f;
}
.panel-material-red-800 > .panel-heading {
  background-color: #c62828;
}
.panel-material-red-900 > .panel-heading {
  background-color: #b71c1c;
}
.panel-material-red-A100 > .panel-heading {
  background-color: #ff8a80;
}
.panel-material-red-A200 > .panel-heading {
  background-color: #ff5252;
}
.panel-material-red-A400 > .panel-heading {
  background-color: #ff1744;
}
.panel-material-red-A700 > .panel-heading {
  background-color: #d50000;
}
.panel-material-pink > .panel-heading {
  background-color: #e91e63;
}
.panel-material-pink-50 > .panel-heading {
  background-color: #fce4ec;
}
.panel-material-pink-100 > .panel-heading {
  background-color: #f8bbd0;
}
.panel-material-pink-200 > .panel-heading {
  background-color: #f48fb1;
}
.panel-material-pink-300 > .panel-heading {
  background-color: #f06292;
}
.panel-material-pink-400 > .panel-heading {
  background-color: #ec407a;
}
.panel-material-pink-500 > .panel-heading {
  background-color: #e91e63;
}
.panel-material-pink-600 > .panel-heading {
  background-color: #d81b60;
}
.panel-material-pink-700 > .panel-heading {
  background-color: #c2185b;
}
.panel-material-pink-800 > .panel-heading {
  background-color: #ad1457;
}
.panel-material-pink-900 > .panel-heading {
  background-color: #880e4f;
}
.panel-material-pink-A100 > .panel-heading {
  background-color: #ff80ab;
}
.panel-material-pink-A200 > .panel-heading {
  background-color: #ff4081;
}
.panel-material-pink-A400 > .panel-heading {
  background-color: #f50057;
}
.panel-material-pink-A700 > .panel-heading {
  background-color: #c51162;
}
.panel-material-purple > .panel-heading {
  background-color: #9c27b0;
}
.panel-material-purple-50 > .panel-heading {
  background-color: #f3e5f5;
}
.panel-material-purple-100 > .panel-heading {
  background-color: #e1bee7;
}
.panel-material-purple-200 > .panel-heading {
  background-color: #ce93d8;
}
.panel-material-purple-300 > .panel-heading {
  background-color: #ba68c8;
}
.panel-material-purple-400 > .panel-heading {
  background-color: #ab47bc;
}
.panel-material-purple-500 > .panel-heading {
  background-color: #9c27b0;
}
.panel-material-purple-600 > .panel-heading {
  background-color: #8e24aa;
}
.panel-material-purple-700 > .panel-heading {
  background-color: #7b1fa2;
}
.panel-material-purple-800 > .panel-heading {
  background-color: #6a1b9a;
}
.panel-material-purple-900 > .panel-heading {
  background-color: #4a148c;
}
.panel-material-purple-A100 > .panel-heading {
  background-color: #ea80fc;
}
.panel-material-purple-A200 > .panel-heading {
  background-color: #e040fb;
}
.panel-material-purple-A400 > .panel-heading {
  background-color: #d500f9;
}
.panel-material-purple-A700 > .panel-heading {
  background-color: #aa00ff;
}
.panel-material-deep-purple > .panel-heading {
  background-color: #673ab7;
}
.panel-material-deep-purple-50 > .panel-heading {
  background-color: #ede7f6;
}
.panel-material-deep-purple-100 > .panel-heading {
  background-color: #d1c4e9;
}
.panel-material-deep-purple-200 > .panel-heading {
  background-color: #b39ddb;
}
.panel-material-deep-purple-300 > .panel-heading {
  background-color: #9575cd;
}
.panel-material-deep-purple-400 > .panel-heading {
  background-color: #7e57c2;
}
.panel-material-deep-purple-500 > .panel-heading {
  background-color: #673ab7;
}
.panel-material-deep-purple-600 > .panel-heading {
  background-color: #5e35b1;
}
.panel-material-deep-purple-700 > .panel-heading {
  background-color: #512da8;
}
.panel-material-deep-purple-800 > .panel-heading {
  background-color: #4527a0;
}
.panel-material-deep-purple-900 > .panel-heading {
  background-color: #311b92;
}
.panel-material-deep-purple-A100 > .panel-heading {
  background-color: #b388ff;
}
.panel-material-deep-purple-A200 > .panel-heading {
  background-color: #7c4dff;
}
.panel-material-deep-purple-A400 > .panel-heading {
  background-color: #651fff;
}
.panel-material-deep-purple-A700 > .panel-heading {
  background-color: #6200ea;
}
.panel-material-indigo > .panel-heading {
  background-color: #3f51b5;
}
.panel-material-indigo-50 > .panel-heading {
  background-color: #e8eaf6;
}
.panel-material-indigo-100 > .panel-heading {
  background-color: #c5cae9;
}
.panel-material-indigo-200 > .panel-heading {
  background-color: #9fa8da;
}
.panel-material-indigo-300 > .panel-heading {
  background-color: #7986cb;
}
.panel-material-indigo-400 > .panel-heading {
  background-color: #5c6bc0;
}
.panel-material-indigo-500 > .panel-heading {
  background-color: #3f51b5;
}
.panel-material-indigo-600 > .panel-heading {
  background-color: #3949ab;
}
.panel-material-indigo-700 > .panel-heading {
  background-color: #303f9f;
}
.panel-material-indigo-800 > .panel-heading {
  background-color: #283593;
}
.panel-material-indigo-900 > .panel-heading {
  background-color: #1a237e;
}
.panel-material-indigo-A100 > .panel-heading {
  background-color: #8c9eff;
}
.panel-material-indigo-A200 > .panel-heading {
  background-color: #536dfe;
}
.panel-material-indigo-A400 > .panel-heading {
  background-color: #3d5afe;
}
.panel-material-indigo-A700 > .panel-heading {
  background-color: #304ffe;
}
.panel-material-blue > .panel-heading {
  background-color: #2196f3;
}
.panel-material-blue-50 > .panel-heading {
  background-color: #e3f2fd;
}
.panel-material-blue-100 > .panel-heading {
  background-color: #bbdefb;
}
.panel-material-blue-200 > .panel-heading {
  background-color: #90caf9;
}
.panel-material-blue-300 > .panel-heading {
  background-color: #64b5f6;
}
.panel-material-blue-400 > .panel-heading {
  background-color: #42a5f5;
}
.panel-material-blue-500 > .panel-heading {
  background-color: #2196f3;
}
.panel-material-blue-600 > .panel-heading {
  background-color: #1e88e5;
}
.panel-material-blue-700 > .panel-heading {
  background-color: #1976d2;
}
.panel-material-blue-800 > .panel-heading {
  background-color: #1565c0;
}
.panel-material-blue-900 > .panel-heading {
  background-color: #0d47a1;
}
.panel-material-blue-A100 > .panel-heading {
  background-color: #82b1ff;
}
.panel-material-blue-A200 > .panel-heading {
  background-color: #448aff;
}
.panel-material-blue-A400 > .panel-heading {
  background-color: #2979ff;
}
.panel-material-blue-A700 > .panel-heading {
  background-color: #2962ff;
}
.panel-material-light-blue > .panel-heading {
  background-color: #03a9f4;
}
.panel-material-light-blue-50 > .panel-heading {
  background-color: #e1f5fe;
}
.panel-material-light-blue-100 > .panel-heading {
  background-color: #b3e5fc;
}
.panel-material-light-blue-200 > .panel-heading {
  background-color: #81d4fa;
}
.panel-material-light-blue-300 > .panel-heading {
  background-color: #4fc3f7;
}
.panel-material-light-blue-400 > .panel-heading {
  background-color: #29b6f6;
}
.panel-material-light-blue-500 > .panel-heading {
  background-color: #03a9f4;
}
.panel-material-light-blue-600 > .panel-heading {
  background-color: #039be5;
}
.panel-material-light-blue-700 > .panel-heading {
  background-color: #0288d1;
}
.panel-material-light-blue-800 > .panel-heading {
  background-color: #0277bd;
}
.panel-material-light-blue-900 > .panel-heading {
  background-color: #01579b;
}
.panel-material-light-blue-A100 > .panel-heading {
  background-color: #80d8ff;
}
.panel-material-light-blue-A200 > .panel-heading {
  background-color: #40c4ff;
}
.panel-material-light-blue-A400 > .panel-heading {
  background-color: #00b0ff;
}
.panel-material-light-blue-A700 > .panel-heading {
  background-color: #0091ea;
}
.panel-material-cyan > .panel-heading {
  background-color: #00bcd4;
}
.panel-material-cyan-50 > .panel-heading {
  background-color: #e0f7fa;
}
.panel-material-cyan-100 > .panel-heading {
  background-color: #b2ebf2;
}
.panel-material-cyan-200 > .panel-heading {
  background-color: #80deea;
}
.panel-material-cyan-300 > .panel-heading {
  background-color: #4dd0e1;
}
.panel-material-cyan-400 > .panel-heading {
  background-color: #26c6da;
}
.panel-material-cyan-500 > .panel-heading {
  background-color: #00bcd4;
}
.panel-material-cyan-600 > .panel-heading {
  background-color: #00acc1;
}
.panel-material-cyan-700 > .panel-heading {
  background-color: #0097a7;
}
.panel-material-cyan-800 > .panel-heading {
  background-color: #00838f;
}
.panel-material-cyan-900 > .panel-heading {
  background-color: #006064;
}
.panel-material-cyan-A100 > .panel-heading {
  background-color: #84ffff;
}
.panel-material-cyan-A200 > .panel-heading {
  background-color: #18ffff;
}
.panel-material-cyan-A400 > .panel-heading {
  background-color: #00e5ff;
}
.panel-material-cyan-A700 > .panel-heading {
  background-color: #00b8d4;
}
.panel-material-teal > .panel-heading {
  background-color: #009688;
}
.panel-material-teal-50 > .panel-heading {
  background-color: #e0f2f1;
}
.panel-material-teal-100 > .panel-heading {
  background-color: #b2dfdb;
}
.panel-material-teal-200 > .panel-heading {
  background-color: #80cbc4;
}
.panel-material-teal-300 > .panel-heading {
  background-color: #4db6ac;
}
.panel-material-teal-400 > .panel-heading {
  background-color: #26a69a;
}
.panel-material-teal-500 > .panel-heading {
  background-color: #009688;
}
.panel-material-teal-600 > .panel-heading {
  background-color: #00897b;
}
.panel-material-teal-700 > .panel-heading {
  background-color: #00796b;
}
.panel-material-teal-800 > .panel-heading {
  background-color: #00695c;
}
.panel-material-teal-900 > .panel-heading {
  background-color: #004d40;
}
.panel-material-teal-A100 > .panel-heading {
  background-color: #a7ffeb;
}
.panel-material-teal-A200 > .panel-heading {
  background-color: #64ffda;
}
.panel-material-teal-A400 > .panel-heading {
  background-color: #1de9b6;
}
.panel-material-teal-A700 > .panel-heading {
  background-color: #00bfa5;
}
.panel-material-green > .panel-heading {
  background-color: #4caf50;
}
.panel-material-green-50 > .panel-heading {
  background-color: #e8f5e9;
}
.panel-material-green-100 > .panel-heading {
  background-color: #c8e6c9;
}
.panel-material-green-200 > .panel-heading {
  background-color: #a5d6a7;
}
.panel-material-green-300 > .panel-heading {
  background-color: #81c784;
}
.panel-material-green-400 > .panel-heading {
  background-color: #66bb6a;
}
.panel-material-green-500 > .panel-heading {
  background-color: #4caf50;
}
.panel-material-green-600 > .panel-heading {
  background-color: #43a047;
}
.panel-material-green-700 > .panel-heading {
  background-color: #388e3c;
}
.panel-material-green-800 > .panel-heading {
  background-color: #2e7d32;
}
.panel-material-green-900 > .panel-heading {
  background-color: #1b5e20;
}
.panel-material-green-A100 > .panel-heading {
  background-color: #b9f6ca;
}
.panel-material-green-A200 > .panel-heading {
  background-color: #69f0ae;
}
.panel-material-green-A400 > .panel-heading {
  background-color: #00e676;
}
.panel-material-green-A700 > .panel-heading {
  background-color: #00c853;
}
.panel-material-light-green > .panel-heading {
  background-color: #8bc34a;
}
.panel-material-light-green-50 > .panel-heading {
  background-color: #f1f8e9;
}
.panel-material-light-green-100 > .panel-heading {
  background-color: #dcedc8;
}
.panel-material-light-green-200 > .panel-heading {
  background-color: #c5e1a5;
}
.panel-material-light-green-300 > .panel-heading {
  background-color: #aed581;
}
.panel-material-light-green-400 > .panel-heading {
  background-color: #9ccc65;
}
.panel-material-light-green-500 > .panel-heading {
  background-color: #8bc34a;
}
.panel-material-light-green-600 > .panel-heading {
  background-color: #7cb342;
}
.panel-material-light-green-700 > .panel-heading {
  background-color: #689f38;
}
.panel-material-light-green-800 > .panel-heading {
  background-color: #558b2f;
}
.panel-material-light-green-900 > .panel-heading {
  background-color: #33691e;
}
.panel-material-light-green-A100 > .panel-heading {
  background-color: #ccff90;
}
.panel-material-light-green-A200 > .panel-heading {
  background-color: #b2ff59;
}
.panel-material-light-green-A400 > .panel-heading {
  background-color: #76ff03;
}
.panel-material-light-green-A700 > .panel-heading {
  background-color: #64dd17;
}
.panel-material-lime > .panel-heading {
  background-color: #cddc39;
}
.panel-material-lime-50 > .panel-heading {
  background-color: #f9fbe7;
}
.panel-material-lime-100 > .panel-heading {
  background-color: #f0f4c3;
}
.panel-material-lime-200 > .panel-heading {
  background-color: #e6ee9c;
}
.panel-material-lime-300 > .panel-heading {
  background-color: #dce775;
}
.panel-material-lime-400 > .panel-heading {
  background-color: #d4e157;
}
.panel-material-lime-500 > .panel-heading {
  background-color: #cddc39;
}
.panel-material-lime-600 > .panel-heading {
  background-color: #c0ca33;
}
.panel-material-lime-700 > .panel-heading {
  background-color: #afb42b;
}
.panel-material-lime-800 > .panel-heading {
  background-color: #9e9d24;
}
.panel-material-lime-900 > .panel-heading {
  background-color: #827717;
}
.panel-material-lime-A100 > .panel-heading {
  background-color: #f4ff81;
}
.panel-material-lime-A200 > .panel-heading {
  background-color: #eeff41;
}
.panel-material-lime-A400 > .panel-heading {
  background-color: #c6ff00;
}
.panel-material-lime-A700 > .panel-heading {
  background-color: #aeea00;
}
.panel-material-yellow > .panel-heading {
  background-color: #ffeb3b;
}
.panel-material-yellow-50 > .panel-heading {
  background-color: #fffde7;
}
.panel-material-yellow-100 > .panel-heading {
  background-color: #fff9c4;
}
.panel-material-yellow-200 > .panel-heading {
  background-color: #fff59d;
}
.panel-material-yellow-300 > .panel-heading {
  background-color: #fff176;
}
.panel-material-yellow-400 > .panel-heading {
  background-color: #ffee58;
}
.panel-material-yellow-500 > .panel-heading {
  background-color: #ffeb3b;
}
.panel-material-yellow-600 > .panel-heading {
  background-color: #fdd835;
}
.panel-material-yellow-700 > .panel-heading {
  background-color: #fbc02d;
}
.panel-material-yellow-800 > .panel-heading {
  background-color: #f9a825;
}
.panel-material-yellow-900 > .panel-heading {
  background-color: #f57f17;
}
.panel-material-yellow-A100 > .panel-heading {
  background-color: #ffff8d;
}
.panel-material-yellow-A200 > .panel-heading {
  background-color: #ffff00;
}
.panel-material-yellow-A400 > .panel-heading {
  background-color: #ffea00;
}
.panel-material-yellow-A700 > .panel-heading {
  background-color: #ffd600;
}
.panel-material-amber > .panel-heading {
  background-color: #ffc107;
}
.panel-material-amber-50 > .panel-heading {
  background-color: #fff8e1;
}
.panel-material-amber-100 > .panel-heading {
  background-color: #ffecb3;
}
.panel-material-amber-200 > .panel-heading {
  background-color: #ffe082;
}
.panel-material-amber-300 > .panel-heading {
  background-color: #ffd54f;
}
.panel-material-amber-400 > .panel-heading {
  background-color: #ffca28;
}
.panel-material-amber-500 > .panel-heading {
  background-color: #ffc107;
}
.panel-material-amber-600 > .panel-heading {
  background-color: #ffb300;
}
.panel-material-amber-700 > .panel-heading {
  background-color: #ffa000;
}
.panel-material-amber-800 > .panel-heading {
  background-color: #ff8f00;
}
.panel-material-amber-900 > .panel-heading {
  background-color: #ff6f00;
}
.panel-material-amber-A100 > .panel-heading {
  background-color: #ffe57f;
}
.panel-material-amber-A200 > .panel-heading {
  background-color: #ffd740;
}
.panel-material-amber-A400 > .panel-heading {
  background-color: #ffc400;
}
.panel-material-amber-A700 > .panel-heading {
  background-color: #ffab00;
}
.panel-material-orange > .panel-heading {
  background-color: #ff9800;
}
.panel-material-orange-50 > .panel-heading {
  background-color: #fff3e0;
}
.panel-material-orange-100 > .panel-heading {
  background-color: #ffe0b2;
}
.panel-material-orange-200 > .panel-heading {
  background-color: #ffcc80;
}
.panel-material-orange-300 > .panel-heading {
  background-color: #ffb74d;
}
.panel-material-orange-400 > .panel-heading {
  background-color: #ffa726;
}
.panel-material-orange-500 > .panel-heading {
  background-color: #ff9800;
}
.panel-material-orange-600 > .panel-heading {
  background-color: #fb8c00;
}
.panel-material-orange-700 > .panel-heading {
  background-color: #f57c00;
}
.panel-material-orange-800 > .panel-heading {
  background-color: #ef6c00;
}
.panel-material-orange-900 > .panel-heading {
  background-color: #e65100;
}
.panel-material-orange-A100 > .panel-heading {
  background-color: #ffd180;
}
.panel-material-orange-A200 > .panel-heading {
  background-color: #ffab40;
}
.panel-material-orange-A400 > .panel-heading {
  background-color: #ff9100;
}
.panel-material-orange-A700 > .panel-heading {
  background-color: #ff6d00;
}
.panel-material-deep-orange > .panel-heading {
  background-color: #ff5722;
}
.panel-material-deep-orange-50 > .panel-heading {
  background-color: #fbe9e7;
}
.panel-material-deep-orange-100 > .panel-heading {
  background-color: #ffccbc;
}
.panel-material-deep-orange-200 > .panel-heading {
  background-color: #ffab91;
}
.panel-material-deep-orange-300 > .panel-heading {
  background-color: #ff8a65;
}
.panel-material-deep-orange-400 > .panel-heading {
  background-color: #ff7043;
}
.panel-material-deep-orange-500 > .panel-heading {
  background-color: #ff5722;
}
.panel-material-deep-orange-600 > .panel-heading {
  background-color: #f4511e;
}
.panel-material-deep-orange-700 > .panel-heading {
  background-color: #e64a19;
}
.panel-material-deep-orange-800 > .panel-heading {
  background-color: #d84315;
}
.panel-material-deep-orange-900 > .panel-heading {
  background-color: #bf360c;
}
.panel-material-deep-orange-A100 > .panel-heading {
  background-color: #ff9e80;
}
.panel-material-deep-orange-A200 > .panel-heading {
  background-color: #ff6e40;
}
.panel-material-deep-orange-A400 > .panel-heading {
  background-color: #ff3d00;
}
.panel-material-deep-orange-A700 > .panel-heading {
  background-color: #dd2c00;
}
.panel-material-brown > .panel-heading {
  background-color: #795548;
}
.panel-material-brown-50 > .panel-heading {
  background-color: #efebe9;
}
.panel-material-brown-100 > .panel-heading {
  background-color: #d7ccc8;
}
.panel-material-brown-200 > .panel-heading {
  background-color: #bcaaa4;
}
.panel-material-brown-300 > .panel-heading {
  background-color: #a1887f;
}
.panel-material-brown-400 > .panel-heading {
  background-color: #8d6e63;
}
.panel-material-brown-500 > .panel-heading {
  background-color: #795548;
}
.panel-material-brown-600 > .panel-heading {
  background-color: #6d4c41;
}
.panel-material-brown-700 > .panel-heading {
  background-color: #5d4037;
}
.panel-material-brown-800 > .panel-heading {
  background-color: #4e342e;
}
.panel-material-brown-900 > .panel-heading {
  background-color: #3e2723;
}
.panel-material-brown-A100 > .panel-heading {
  background-color: #d7ccc8;
}
.panel-material-brown-A200 > .panel-heading {
  background-color: #bcaaa4;
}
.panel-material-brown-A400 > .panel-heading {
  background-color: #8d6e63;
}
.panel-material-brown-A700 > .panel-heading {
  background-color: #5d4037;
}
.panel-material-grey > .panel-heading {
  background-color: #9e9e9e;
}
.panel-material-grey-50 > .panel-heading {
  background-color: #fafafa;
}
.panel-material-grey-100 > .panel-heading {
  background-color: #f5f5f5;
}
.panel-material-grey-200 > .panel-heading {
  background-color: #eeeeee;
}
.panel-material-grey-300 > .panel-heading {
  background-color: #e0e0e0;
}
.panel-material-grey-400 > .panel-heading {
  background-color: #bdbdbd;
}
.panel-material-grey-500 > .panel-heading {
  background-color: #9e9e9e;
}
.panel-material-grey-600 > .panel-heading {
  background-color: #757575;
}
.panel-material-grey-700 > .panel-heading {
  background-color: #616161;
}
.panel-material-grey-800 > .panel-heading {
  background-color: #424242;
}
.panel-material-grey-900 > .panel-heading {
  background-color: #212121;
}
.panel-material-grey-A100 > .panel-heading {
  background-color: #f5f5f5;
}
.panel-material-grey-A200 > .panel-heading {
  background-color: #eeeeee;
}
.panel-material-grey-A400 > .panel-heading {
  background-color: #bdbdbd;
}
.panel-material-grey-A700 > .panel-heading {
  background-color: #616161;
}
.panel-material-blue > .panel-heading {
  background-color: #607d8b;
}
.panel-material-blue-50 > .panel-heading {
  background-color: #eceff1;
}
.panel-material-blue-100 > .panel-heading {
  background-color: #cfd8dc;
}
.panel-material-blue-200 > .panel-heading {
  background-color: #b0bec5;
}
.panel-material-blue-300 > .panel-heading {
  background-color: #90a4ae;
}
.panel-material-blue-400 > .panel-heading {
  background-color: #78909c;
}
.panel-material-blue-500 > .panel-heading {
  background-color: #607d8b;
}
.panel-material-blue-600 > .panel-heading {
  background-color: #546e7a;
}
.panel-material-blue-700 > .panel-heading {
  background-color: #455a64;
}
.panel-material-blue-800 > .panel-heading {
  background-color: #37474f;
}
.panel-material-blue-900 > .panel-heading {
  background-color: #263238;
}
.panel-material-blue-A100 > .panel-heading {
  background-color: #cfd8dc;
}
.panel-material-blue-A200 > .panel-heading {
  background-color: #b0bec5;
}
.panel-material-blue-A400 > .panel-heading {
  background-color: #78909c;
}
.panel-material-blue-A700 > .panel-heading {
  background-color: #455a64;
}
[class*="panel-"] > .panel-heading {
  color: rgba(255, 255, 255, 0.84);
}
.panel-default > .panel-heading,
.panel:not([class*="panel-"]) > .panel-heading {
  color: rgba(0, 0, 0, 0.84);
}
.panel-footer {
  background-color: #ececec;
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: 0;
}
.snackbar {
  background-color: #323232;
  color: rgba(255, 255, 255, 0.84);
  font-size: 14px;
  border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
  height: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0 linear 0.2s, padding 0 linear 0.2s, height 0 linear 0.2s;
  transform: translateY(200%);
}
.snackbar.snackbar-opened {
  padding: 14px 15px;
  margin-bottom: 20px;
  height: auto;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0 linear 0.2s, height 0 linear 0.2s;
  transform: none;
}
.snackbar.toast {
  border-radius: 200px;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  user-select: none;
  box-sizing: border-box;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-stacking + .noUi-origin {
  *z-index: -1;
}
.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-horizontal {
  height: 10px;
}
.noUi-handle {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  left: -10px;
  top: -5px;
  cursor: ew-resize;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
}
.noUi-vertical .noUi-handle {
  margin-left: 5px;
  cursor: ns-resize;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-background {
  height: 2px;
  margin: 20px 0;
}
.noUi-origin {
  margin: 0;
  border-radius: 0;
  height: 2px;
  background: #c8c8c8;
}
.noUi-origin[style^="left: 0"] .noUi-handle {
  background-color: #fff;
  border: 2px solid #c8c8c8;
}
.noUi-origin[style^="left: 0"] .noUi-handle.noUi-active {
  border-width: 1px;
}
.noUi-target {
  border-radius: 2px;
}
.noUi-horizontal {
  height: 2px;
  margin: 15px 0;
}
.noUi-vertical {
  height: 100%;
  width: 2px;
  margin: 0 15px;
  display: inline-block;
}
.noUi-handle.noUi-active {
  transform: scale(2.5);
}
[disabled].noUi-slider {
  opacity: 0.5;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.slider {
  background: #c8c8c8;
}
.slider {
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
  /*
    // Material shades
    &-material-red@{extra} {
        @{property}: @red;
    }
    &-material-pink@{extra} {
        @{property}: @pink;
    }
    &-material-purple@{extra} {
        @{property}: @purple;
    }
    &-material-deeppurple@{extra} {
        @{property}: @deeppurple;
    }
    &-material-indigo@{extra} {
        @{property}: @indigo;
    }
    &-material-blue@{extra} {
        @{property}: @blue;
    }
    &-material-lightblue@{extra} {
        @{property}: @lightblue;
    }
    &-material-cyan@{extra} {
        @{property}: @cyan;
    }
    &-material-teal@{extra} {
        @{property}: @teal;
    }
    &-material-lightgreen@{extra} {
        @{property}: @lightgreen;
    }
    &-material-lime@{extra} {
        @{property}: @lime;
    }
    &-material-lightyellow@{extra} {
        @{property}: @lightyellow;
    }
    &-material-orange@{extra} {
        @{property}: @orange;
    }
    &-material-deeporange@{extra} {
        @{property}: @deeporange;
    }
    &-material-grey@{extra} {
        @{property}: @grey;
    }
    &-material-bluegrey@{extra} {
        @{property}: @bluegrey;
    }
    &-material-brown@{extra} {
        @{property}: @brown;
    }
    &-material-lightgrey@{extra} {
        @{property}: @lightgrey;
    }
  */
}
.slider.noUi-connect,
.slider-default.noUi-connect {
  background-color: #009587;
}
.slider-primary.noUi-connect {
  background-color: #009587;
}
.slider-success.noUi-connect {
  background-color: #0f9d58;
}
.slider-info.noUi-connect {
  background-color: #03a9f4;
}
.slider-warning.noUi-connect {
  background-color: #ff5722;
}
.slider-danger.noUi-connect {
  background-color: #f44336;
}
.slider-material-red.noUi-connect {
  background-color: #f44336;
}
.slider-material-red-50.noUi-connect {
  background-color: #ffebee;
}
.slider-material-red-100.noUi-connect {
  background-color: #ffcdd2;
}
.slider-material-red-200.noUi-connect {
  background-color: #ef9a9a;
}
.slider-material-red-300.noUi-connect {
  background-color: #e57373;
}
.slider-material-red-400.noUi-connect {
  background-color: #ef5350;
}
.slider-material-red-500.noUi-connect {
  background-color: #f44336;
}
.slider-material-red-600.noUi-connect {
  background-color: #e53935;
}
.slider-material-red-700.noUi-connect {
  background-color: #d32f2f;
}
.slider-material-red-800.noUi-connect {
  background-color: #c62828;
}
.slider-material-red-900.noUi-connect {
  background-color: #b71c1c;
}
.slider-material-red-A100.noUi-connect {
  background-color: #ff8a80;
}
.slider-material-red-A200.noUi-connect {
  background-color: #ff5252;
}
.slider-material-red-A400.noUi-connect {
  background-color: #ff1744;
}
.slider-material-red-A700.noUi-connect {
  background-color: #d50000;
}
.slider-material-pink.noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-50.noUi-connect {
  background-color: #fce4ec;
}
.slider-material-pink-100.noUi-connect {
  background-color: #f8bbd0;
}
.slider-material-pink-200.noUi-connect {
  background-color: #f48fb1;
}
.slider-material-pink-300.noUi-connect {
  background-color: #f06292;
}
.slider-material-pink-400.noUi-connect {
  background-color: #ec407a;
}
.slider-material-pink-500.noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-600.noUi-connect {
  background-color: #d81b60;
}
.slider-material-pink-700.noUi-connect {
  background-color: #c2185b;
}
.slider-material-pink-800.noUi-connect {
  background-color: #ad1457;
}
.slider-material-pink-900.noUi-connect {
  background-color: #880e4f;
}
.slider-material-pink-A100.noUi-connect {
  background-color: #ff80ab;
}
.slider-material-pink-A200.noUi-connect {
  background-color: #ff4081;
}
.slider-material-pink-A400.noUi-connect {
  background-color: #f50057;
}
.slider-material-pink-A700.noUi-connect {
  background-color: #c51162;
}
.slider-material-purple.noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-50.noUi-connect {
  background-color: #f3e5f5;
}
.slider-material-purple-100.noUi-connect {
  background-color: #e1bee7;
}
.slider-material-purple-200.noUi-connect {
  background-color: #ce93d8;
}
.slider-material-purple-300.noUi-connect {
  background-color: #ba68c8;
}
.slider-material-purple-400.noUi-connect {
  background-color: #ab47bc;
}
.slider-material-purple-500.noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-600.noUi-connect {
  background-color: #8e24aa;
}
.slider-material-purple-700.noUi-connect {
  background-color: #7b1fa2;
}
.slider-material-purple-800.noUi-connect {
  background-color: #6a1b9a;
}
.slider-material-purple-900.noUi-connect {
  background-color: #4a148c;
}
.slider-material-purple-A100.noUi-connect {
  background-color: #ea80fc;
}
.slider-material-purple-A200.noUi-connect {
  background-color: #e040fb;
}
.slider-material-purple-A400.noUi-connect {
  background-color: #d500f9;
}
.slider-material-purple-A700.noUi-connect {
  background-color: #aa00ff;
}
.slider-material-deep-purple.noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-50.noUi-connect {
  background-color: #ede7f6;
}
.slider-material-deep-purple-100.noUi-connect {
  background-color: #d1c4e9;
}
.slider-material-deep-purple-200.noUi-connect {
  background-color: #b39ddb;
}
.slider-material-deep-purple-300.noUi-connect {
  background-color: #9575cd;
}
.slider-material-deep-purple-400.noUi-connect {
  background-color: #7e57c2;
}
.slider-material-deep-purple-500.noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-600.noUi-connect {
  background-color: #5e35b1;
}
.slider-material-deep-purple-700.noUi-connect {
  background-color: #512da8;
}
.slider-material-deep-purple-800.noUi-connect {
  background-color: #4527a0;
}
.slider-material-deep-purple-900.noUi-connect {
  background-color: #311b92;
}
.slider-material-deep-purple-A100.noUi-connect {
  background-color: #b388ff;
}
.slider-material-deep-purple-A200.noUi-connect {
  background-color: #7c4dff;
}
.slider-material-deep-purple-A400.noUi-connect {
  background-color: #651fff;
}
.slider-material-deep-purple-A700.noUi-connect {
  background-color: #6200ea;
}
.slider-material-indigo.noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-50.noUi-connect {
  background-color: #e8eaf6;
}
.slider-material-indigo-100.noUi-connect {
  background-color: #c5cae9;
}
.slider-material-indigo-200.noUi-connect {
  background-color: #9fa8da;
}
.slider-material-indigo-300.noUi-connect {
  background-color: #7986cb;
}
.slider-material-indigo-400.noUi-connect {
  background-color: #5c6bc0;
}
.slider-material-indigo-500.noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-600.noUi-connect {
  background-color: #3949ab;
}
.slider-material-indigo-700.noUi-connect {
  background-color: #303f9f;
}
.slider-material-indigo-800.noUi-connect {
  background-color: #283593;
}
.slider-material-indigo-900.noUi-connect {
  background-color: #1a237e;
}
.slider-material-indigo-A100.noUi-connect {
  background-color: #8c9eff;
}
.slider-material-indigo-A200.noUi-connect {
  background-color: #536dfe;
}
.slider-material-indigo-A400.noUi-connect {
  background-color: #3d5afe;
}
.slider-material-indigo-A700.noUi-connect {
  background-color: #304ffe;
}
.slider-material-blue.noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-50.noUi-connect {
  background-color: #e3f2fd;
}
.slider-material-blue-100.noUi-connect {
  background-color: #bbdefb;
}
.slider-material-blue-200.noUi-connect {
  background-color: #90caf9;
}
.slider-material-blue-300.noUi-connect {
  background-color: #64b5f6;
}
.slider-material-blue-400.noUi-connect {
  background-color: #42a5f5;
}
.slider-material-blue-500.noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-600.noUi-connect {
  background-color: #1e88e5;
}
.slider-material-blue-700.noUi-connect {
  background-color: #1976d2;
}
.slider-material-blue-800.noUi-connect {
  background-color: #1565c0;
}
.slider-material-blue-900.noUi-connect {
  background-color: #0d47a1;
}
.slider-material-blue-A100.noUi-connect {
  background-color: #82b1ff;
}
.slider-material-blue-A200.noUi-connect {
  background-color: #448aff;
}
.slider-material-blue-A400.noUi-connect {
  background-color: #2979ff;
}
.slider-material-blue-A700.noUi-connect {
  background-color: #2962ff;
}
.slider-material-light-blue.noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-50.noUi-connect {
  background-color: #e1f5fe;
}
.slider-material-light-blue-100.noUi-connect {
  background-color: #b3e5fc;
}
.slider-material-light-blue-200.noUi-connect {
  background-color: #81d4fa;
}
.slider-material-light-blue-300.noUi-connect {
  background-color: #4fc3f7;
}
.slider-material-light-blue-400.noUi-connect {
  background-color: #29b6f6;
}
.slider-material-light-blue-500.noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-600.noUi-connect {
  background-color: #039be5;
}
.slider-material-light-blue-700.noUi-connect {
  background-color: #0288d1;
}
.slider-material-light-blue-800.noUi-connect {
  background-color: #0277bd;
}
.slider-material-light-blue-900.noUi-connect {
  background-color: #01579b;
}
.slider-material-light-blue-A100.noUi-connect {
  background-color: #80d8ff;
}
.slider-material-light-blue-A200.noUi-connect {
  background-color: #40c4ff;
}
.slider-material-light-blue-A400.noUi-connect {
  background-color: #00b0ff;
}
.slider-material-light-blue-A700.noUi-connect {
  background-color: #0091ea;
}
.slider-material-cyan.noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-50.noUi-connect {
  background-color: #e0f7fa;
}
.slider-material-cyan-100.noUi-connect {
  background-color: #b2ebf2;
}
.slider-material-cyan-200.noUi-connect {
  background-color: #80deea;
}
.slider-material-cyan-300.noUi-connect {
  background-color: #4dd0e1;
}
.slider-material-cyan-400.noUi-connect {
  background-color: #26c6da;
}
.slider-material-cyan-500.noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-600.noUi-connect {
  background-color: #00acc1;
}
.slider-material-cyan-700.noUi-connect {
  background-color: #0097a7;
}
.slider-material-cyan-800.noUi-connect {
  background-color: #00838f;
}
.slider-material-cyan-900.noUi-connect {
  background-color: #006064;
}
.slider-material-cyan-A100.noUi-connect {
  background-color: #84ffff;
}
.slider-material-cyan-A200.noUi-connect {
  background-color: #18ffff;
}
.slider-material-cyan-A400.noUi-connect {
  background-color: #00e5ff;
}
.slider-material-cyan-A700.noUi-connect {
  background-color: #00b8d4;
}
.slider-material-teal.noUi-connect {
  background-color: #009688;
}
.slider-material-teal-50.noUi-connect {
  background-color: #e0f2f1;
}
.slider-material-teal-100.noUi-connect {
  background-color: #b2dfdb;
}
.slider-material-teal-200.noUi-connect {
  background-color: #80cbc4;
}
.slider-material-teal-300.noUi-connect {
  background-color: #4db6ac;
}
.slider-material-teal-400.noUi-connect {
  background-color: #26a69a;
}
.slider-material-teal-500.noUi-connect {
  background-color: #009688;
}
.slider-material-teal-600.noUi-connect {
  background-color: #00897b;
}
.slider-material-teal-700.noUi-connect {
  background-color: #00796b;
}
.slider-material-teal-800.noUi-connect {
  background-color: #00695c;
}
.slider-material-teal-900.noUi-connect {
  background-color: #004d40;
}
.slider-material-teal-A100.noUi-connect {
  background-color: #a7ffeb;
}
.slider-material-teal-A200.noUi-connect {
  background-color: #64ffda;
}
.slider-material-teal-A400.noUi-connect {
  background-color: #1de9b6;
}
.slider-material-teal-A700.noUi-connect {
  background-color: #00bfa5;
}
.slider-material-green.noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-50.noUi-connect {
  background-color: #e8f5e9;
}
.slider-material-green-100.noUi-connect {
  background-color: #c8e6c9;
}
.slider-material-green-200.noUi-connect {
  background-color: #a5d6a7;
}
.slider-material-green-300.noUi-connect {
  background-color: #81c784;
}
.slider-material-green-400.noUi-connect {
  background-color: #66bb6a;
}
.slider-material-green-500.noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-600.noUi-connect {
  background-color: #43a047;
}
.slider-material-green-700.noUi-connect {
  background-color: #388e3c;
}
.slider-material-green-800.noUi-connect {
  background-color: #2e7d32;
}
.slider-material-green-900.noUi-connect {
  background-color: #1b5e20;
}
.slider-material-green-A100.noUi-connect {
  background-color: #b9f6ca;
}
.slider-material-green-A200.noUi-connect {
  background-color: #69f0ae;
}
.slider-material-green-A400.noUi-connect {
  background-color: #00e676;
}
.slider-material-green-A700.noUi-connect {
  background-color: #00c853;
}
.slider-material-light-green.noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-50.noUi-connect {
  background-color: #f1f8e9;
}
.slider-material-light-green-100.noUi-connect {
  background-color: #dcedc8;
}
.slider-material-light-green-200.noUi-connect {
  background-color: #c5e1a5;
}
.slider-material-light-green-300.noUi-connect {
  background-color: #aed581;
}
.slider-material-light-green-400.noUi-connect {
  background-color: #9ccc65;
}
.slider-material-light-green-500.noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-600.noUi-connect {
  background-color: #7cb342;
}
.slider-material-light-green-700.noUi-connect {
  background-color: #689f38;
}
.slider-material-light-green-800.noUi-connect {
  background-color: #558b2f;
}
.slider-material-light-green-900.noUi-connect {
  background-color: #33691e;
}
.slider-material-light-green-A100.noUi-connect {
  background-color: #ccff90;
}
.slider-material-light-green-A200.noUi-connect {
  background-color: #b2ff59;
}
.slider-material-light-green-A400.noUi-connect {
  background-color: #76ff03;
}
.slider-material-light-green-A700.noUi-connect {
  background-color: #64dd17;
}
.slider-material-lime.noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-50.noUi-connect {
  background-color: #f9fbe7;
}
.slider-material-lime-100.noUi-connect {
  background-color: #f0f4c3;
}
.slider-material-lime-200.noUi-connect {
  background-color: #e6ee9c;
}
.slider-material-lime-300.noUi-connect {
  background-color: #dce775;
}
.slider-material-lime-400.noUi-connect {
  background-color: #d4e157;
}
.slider-material-lime-500.noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-600.noUi-connect {
  background-color: #c0ca33;
}
.slider-material-lime-700.noUi-connect {
  background-color: #afb42b;
}
.slider-material-lime-800.noUi-connect {
  background-color: #9e9d24;
}
.slider-material-lime-900.noUi-connect {
  background-color: #827717;
}
.slider-material-lime-A100.noUi-connect {
  background-color: #f4ff81;
}
.slider-material-lime-A200.noUi-connect {
  background-color: #eeff41;
}
.slider-material-lime-A400.noUi-connect {
  background-color: #c6ff00;
}
.slider-material-lime-A700.noUi-connect {
  background-color: #aeea00;
}
.slider-material-yellow.noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-50.noUi-connect {
  background-color: #fffde7;
}
.slider-material-yellow-100.noUi-connect {
  background-color: #fff9c4;
}
.slider-material-yellow-200.noUi-connect {
  background-color: #fff59d;
}
.slider-material-yellow-300.noUi-connect {
  background-color: #fff176;
}
.slider-material-yellow-400.noUi-connect {
  background-color: #ffee58;
}
.slider-material-yellow-500.noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-600.noUi-connect {
  background-color: #fdd835;
}
.slider-material-yellow-700.noUi-connect {
  background-color: #fbc02d;
}
.slider-material-yellow-800.noUi-connect {
  background-color: #f9a825;
}
.slider-material-yellow-900.noUi-connect {
  background-color: #f57f17;
}
.slider-material-yellow-A100.noUi-connect {
  background-color: #ffff8d;
}
.slider-material-yellow-A200.noUi-connect {
  background-color: #ffff00;
}
.slider-material-yellow-A400.noUi-connect {
  background-color: #ffea00;
}
.slider-material-yellow-A700.noUi-connect {
  background-color: #ffd600;
}
.slider-material-amber.noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-50.noUi-connect {
  background-color: #fff8e1;
}
.slider-material-amber-100.noUi-connect {
  background-color: #ffecb3;
}
.slider-material-amber-200.noUi-connect {
  background-color: #ffe082;
}
.slider-material-amber-300.noUi-connect {
  background-color: #ffd54f;
}
.slider-material-amber-400.noUi-connect {
  background-color: #ffca28;
}
.slider-material-amber-500.noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-600.noUi-connect {
  background-color: #ffb300;
}
.slider-material-amber-700.noUi-connect {
  background-color: #ffa000;
}
.slider-material-amber-800.noUi-connect {
  background-color: #ff8f00;
}
.slider-material-amber-900.noUi-connect {
  background-color: #ff6f00;
}
.slider-material-amber-A100.noUi-connect {
  background-color: #ffe57f;
}
.slider-material-amber-A200.noUi-connect {
  background-color: #ffd740;
}
.slider-material-amber-A400.noUi-connect {
  background-color: #ffc400;
}
.slider-material-amber-A700.noUi-connect {
  background-color: #ffab00;
}
.slider-material-orange.noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-50.noUi-connect {
  background-color: #fff3e0;
}
.slider-material-orange-100.noUi-connect {
  background-color: #ffe0b2;
}
.slider-material-orange-200.noUi-connect {
  background-color: #ffcc80;
}
.slider-material-orange-300.noUi-connect {
  background-color: #ffb74d;
}
.slider-material-orange-400.noUi-connect {
  background-color: #ffa726;
}
.slider-material-orange-500.noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-600.noUi-connect {
  background-color: #fb8c00;
}
.slider-material-orange-700.noUi-connect {
  background-color: #f57c00;
}
.slider-material-orange-800.noUi-connect {
  background-color: #ef6c00;
}
.slider-material-orange-900.noUi-connect {
  background-color: #e65100;
}
.slider-material-orange-A100.noUi-connect {
  background-color: #ffd180;
}
.slider-material-orange-A200.noUi-connect {
  background-color: #ffab40;
}
.slider-material-orange-A400.noUi-connect {
  background-color: #ff9100;
}
.slider-material-orange-A700.noUi-connect {
  background-color: #ff6d00;
}
.slider-material-deep-orange.noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-50.noUi-connect {
  background-color: #fbe9e7;
}
.slider-material-deep-orange-100.noUi-connect {
  background-color: #ffccbc;
}
.slider-material-deep-orange-200.noUi-connect {
  background-color: #ffab91;
}
.slider-material-deep-orange-300.noUi-connect {
  background-color: #ff8a65;
}
.slider-material-deep-orange-400.noUi-connect {
  background-color: #ff7043;
}
.slider-material-deep-orange-500.noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-600.noUi-connect {
  background-color: #f4511e;
}
.slider-material-deep-orange-700.noUi-connect {
  background-color: #e64a19;
}
.slider-material-deep-orange-800.noUi-connect {
  background-color: #d84315;
}
.slider-material-deep-orange-900.noUi-connect {
  background-color: #bf360c;
}
.slider-material-deep-orange-A100.noUi-connect {
  background-color: #ff9e80;
}
.slider-material-deep-orange-A200.noUi-connect {
  background-color: #ff6e40;
}
.slider-material-deep-orange-A400.noUi-connect {
  background-color: #ff3d00;
}
.slider-material-deep-orange-A700.noUi-connect {
  background-color: #dd2c00;
}
.slider-material-brown.noUi-connect {
  background-color: #795548;
}
.slider-material-brown-50.noUi-connect {
  background-color: #efebe9;
}
.slider-material-brown-100.noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-200.noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-300.noUi-connect {
  background-color: #a1887f;
}
.slider-material-brown-400.noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-500.noUi-connect {
  background-color: #795548;
}
.slider-material-brown-600.noUi-connect {
  background-color: #6d4c41;
}
.slider-material-brown-700.noUi-connect {
  background-color: #5d4037;
}
.slider-material-brown-800.noUi-connect {
  background-color: #4e342e;
}
.slider-material-brown-900.noUi-connect {
  background-color: #3e2723;
}
.slider-material-brown-A100.noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-A200.noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-A400.noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-A700.noUi-connect {
  background-color: #5d4037;
}
.slider-material-grey.noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-50.noUi-connect {
  background-color: #fafafa;
}
.slider-material-grey-100.noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-200.noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-300.noUi-connect {
  background-color: #e0e0e0;
}
.slider-material-grey-400.noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-500.noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-600.noUi-connect {
  background-color: #757575;
}
.slider-material-grey-700.noUi-connect {
  background-color: #616161;
}
.slider-material-grey-800.noUi-connect {
  background-color: #424242;
}
.slider-material-grey-900.noUi-connect {
  background-color: #212121;
}
.slider-material-grey-A100.noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-A200.noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-A400.noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-A700.noUi-connect {
  background-color: #616161;
}
.slider-material-blue.noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-50.noUi-connect {
  background-color: #eceff1;
}
.slider-material-blue-100.noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-200.noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-300.noUi-connect {
  background-color: #90a4ae;
}
.slider-material-blue-400.noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-500.noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-600.noUi-connect {
  background-color: #546e7a;
}
.slider-material-blue-700.noUi-connect {
  background-color: #455a64;
}
.slider-material-blue-800.noUi-connect {
  background-color: #37474f;
}
.slider-material-blue-900.noUi-connect {
  background-color: #263238;
}
.slider-material-blue-A100.noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-A200.noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-A400.noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-A700.noUi-connect {
  background-color: #455a64;
}
.slider .noUi-connect,
.slider-default .noUi-connect {
  background-color: #009587;
}
.slider-primary .noUi-connect {
  background-color: #009587;
}
.slider-success .noUi-connect {
  background-color: #0f9d58;
}
.slider-info .noUi-connect {
  background-color: #03a9f4;
}
.slider-warning .noUi-connect {
  background-color: #ff5722;
}
.slider-danger .noUi-connect {
  background-color: #f44336;
}
.slider-material-red .noUi-connect {
  background-color: #f44336;
}
.slider-material-red-50 .noUi-connect {
  background-color: #ffebee;
}
.slider-material-red-100 .noUi-connect {
  background-color: #ffcdd2;
}
.slider-material-red-200 .noUi-connect {
  background-color: #ef9a9a;
}
.slider-material-red-300 .noUi-connect {
  background-color: #e57373;
}
.slider-material-red-400 .noUi-connect {
  background-color: #ef5350;
}
.slider-material-red-500 .noUi-connect {
  background-color: #f44336;
}
.slider-material-red-600 .noUi-connect {
  background-color: #e53935;
}
.slider-material-red-700 .noUi-connect {
  background-color: #d32f2f;
}
.slider-material-red-800 .noUi-connect {
  background-color: #c62828;
}
.slider-material-red-900 .noUi-connect {
  background-color: #b71c1c;
}
.slider-material-red-A100 .noUi-connect {
  background-color: #ff8a80;
}
.slider-material-red-A200 .noUi-connect {
  background-color: #ff5252;
}
.slider-material-red-A400 .noUi-connect {
  background-color: #ff1744;
}
.slider-material-red-A700 .noUi-connect {
  background-color: #d50000;
}
.slider-material-pink .noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-50 .noUi-connect {
  background-color: #fce4ec;
}
.slider-material-pink-100 .noUi-connect {
  background-color: #f8bbd0;
}
.slider-material-pink-200 .noUi-connect {
  background-color: #f48fb1;
}
.slider-material-pink-300 .noUi-connect {
  background-color: #f06292;
}
.slider-material-pink-400 .noUi-connect {
  background-color: #ec407a;
}
.slider-material-pink-500 .noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-600 .noUi-connect {
  background-color: #d81b60;
}
.slider-material-pink-700 .noUi-connect {
  background-color: #c2185b;
}
.slider-material-pink-800 .noUi-connect {
  background-color: #ad1457;
}
.slider-material-pink-900 .noUi-connect {
  background-color: #880e4f;
}
.slider-material-pink-A100 .noUi-connect {
  background-color: #ff80ab;
}
.slider-material-pink-A200 .noUi-connect {
  background-color: #ff4081;
}
.slider-material-pink-A400 .noUi-connect {
  background-color: #f50057;
}
.slider-material-pink-A700 .noUi-connect {
  background-color: #c51162;
}
.slider-material-purple .noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-50 .noUi-connect {
  background-color: #f3e5f5;
}
.slider-material-purple-100 .noUi-connect {
  background-color: #e1bee7;
}
.slider-material-purple-200 .noUi-connect {
  background-color: #ce93d8;
}
.slider-material-purple-300 .noUi-connect {
  background-color: #ba68c8;
}
.slider-material-purple-400 .noUi-connect {
  background-color: #ab47bc;
}
.slider-material-purple-500 .noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-600 .noUi-connect {
  background-color: #8e24aa;
}
.slider-material-purple-700 .noUi-connect {
  background-color: #7b1fa2;
}
.slider-material-purple-800 .noUi-connect {
  background-color: #6a1b9a;
}
.slider-material-purple-900 .noUi-connect {
  background-color: #4a148c;
}
.slider-material-purple-A100 .noUi-connect {
  background-color: #ea80fc;
}
.slider-material-purple-A200 .noUi-connect {
  background-color: #e040fb;
}
.slider-material-purple-A400 .noUi-connect {
  background-color: #d500f9;
}
.slider-material-purple-A700 .noUi-connect {
  background-color: #aa00ff;
}
.slider-material-deep-purple .noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-50 .noUi-connect {
  background-color: #ede7f6;
}
.slider-material-deep-purple-100 .noUi-connect {
  background-color: #d1c4e9;
}
.slider-material-deep-purple-200 .noUi-connect {
  background-color: #b39ddb;
}
.slider-material-deep-purple-300 .noUi-connect {
  background-color: #9575cd;
}
.slider-material-deep-purple-400 .noUi-connect {
  background-color: #7e57c2;
}
.slider-material-deep-purple-500 .noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-600 .noUi-connect {
  background-color: #5e35b1;
}
.slider-material-deep-purple-700 .noUi-connect {
  background-color: #512da8;
}
.slider-material-deep-purple-800 .noUi-connect {
  background-color: #4527a0;
}
.slider-material-deep-purple-900 .noUi-connect {
  background-color: #311b92;
}
.slider-material-deep-purple-A100 .noUi-connect {
  background-color: #b388ff;
}
.slider-material-deep-purple-A200 .noUi-connect {
  background-color: #7c4dff;
}
.slider-material-deep-purple-A400 .noUi-connect {
  background-color: #651fff;
}
.slider-material-deep-purple-A700 .noUi-connect {
  background-color: #6200ea;
}
.slider-material-indigo .noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-50 .noUi-connect {
  background-color: #e8eaf6;
}
.slider-material-indigo-100 .noUi-connect {
  background-color: #c5cae9;
}
.slider-material-indigo-200 .noUi-connect {
  background-color: #9fa8da;
}
.slider-material-indigo-300 .noUi-connect {
  background-color: #7986cb;
}
.slider-material-indigo-400 .noUi-connect {
  background-color: #5c6bc0;
}
.slider-material-indigo-500 .noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-600 .noUi-connect {
  background-color: #3949ab;
}
.slider-material-indigo-700 .noUi-connect {
  background-color: #303f9f;
}
.slider-material-indigo-800 .noUi-connect {
  background-color: #283593;
}
.slider-material-indigo-900 .noUi-connect {
  background-color: #1a237e;
}
.slider-material-indigo-A100 .noUi-connect {
  background-color: #8c9eff;
}
.slider-material-indigo-A200 .noUi-connect {
  background-color: #536dfe;
}
.slider-material-indigo-A400 .noUi-connect {
  background-color: #3d5afe;
}
.slider-material-indigo-A700 .noUi-connect {
  background-color: #304ffe;
}
.slider-material-blue .noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-50 .noUi-connect {
  background-color: #e3f2fd;
}
.slider-material-blue-100 .noUi-connect {
  background-color: #bbdefb;
}
.slider-material-blue-200 .noUi-connect {
  background-color: #90caf9;
}
.slider-material-blue-300 .noUi-connect {
  background-color: #64b5f6;
}
.slider-material-blue-400 .noUi-connect {
  background-color: #42a5f5;
}
.slider-material-blue-500 .noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-600 .noUi-connect {
  background-color: #1e88e5;
}
.slider-material-blue-700 .noUi-connect {
  background-color: #1976d2;
}
.slider-material-blue-800 .noUi-connect {
  background-color: #1565c0;
}
.slider-material-blue-900 .noUi-connect {
  background-color: #0d47a1;
}
.slider-material-blue-A100 .noUi-connect {
  background-color: #82b1ff;
}
.slider-material-blue-A200 .noUi-connect {
  background-color: #448aff;
}
.slider-material-blue-A400 .noUi-connect {
  background-color: #2979ff;
}
.slider-material-blue-A700 .noUi-connect {
  background-color: #2962ff;
}
.slider-material-light-blue .noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-50 .noUi-connect {
  background-color: #e1f5fe;
}
.slider-material-light-blue-100 .noUi-connect {
  background-color: #b3e5fc;
}
.slider-material-light-blue-200 .noUi-connect {
  background-color: #81d4fa;
}
.slider-material-light-blue-300 .noUi-connect {
  background-color: #4fc3f7;
}
.slider-material-light-blue-400 .noUi-connect {
  background-color: #29b6f6;
}
.slider-material-light-blue-500 .noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-600 .noUi-connect {
  background-color: #039be5;
}
.slider-material-light-blue-700 .noUi-connect {
  background-color: #0288d1;
}
.slider-material-light-blue-800 .noUi-connect {
  background-color: #0277bd;
}
.slider-material-light-blue-900 .noUi-connect {
  background-color: #01579b;
}
.slider-material-light-blue-A100 .noUi-connect {
  background-color: #80d8ff;
}
.slider-material-light-blue-A200 .noUi-connect {
  background-color: #40c4ff;
}
.slider-material-light-blue-A400 .noUi-connect {
  background-color: #00b0ff;
}
.slider-material-light-blue-A700 .noUi-connect {
  background-color: #0091ea;
}
.slider-material-cyan .noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-50 .noUi-connect {
  background-color: #e0f7fa;
}
.slider-material-cyan-100 .noUi-connect {
  background-color: #b2ebf2;
}
.slider-material-cyan-200 .noUi-connect {
  background-color: #80deea;
}
.slider-material-cyan-300 .noUi-connect {
  background-color: #4dd0e1;
}
.slider-material-cyan-400 .noUi-connect {
  background-color: #26c6da;
}
.slider-material-cyan-500 .noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-600 .noUi-connect {
  background-color: #00acc1;
}
.slider-material-cyan-700 .noUi-connect {
  background-color: #0097a7;
}
.slider-material-cyan-800 .noUi-connect {
  background-color: #00838f;
}
.slider-material-cyan-900 .noUi-connect {
  background-color: #006064;
}
.slider-material-cyan-A100 .noUi-connect {
  background-color: #84ffff;
}
.slider-material-cyan-A200 .noUi-connect {
  background-color: #18ffff;
}
.slider-material-cyan-A400 .noUi-connect {
  background-color: #00e5ff;
}
.slider-material-cyan-A700 .noUi-connect {
  background-color: #00b8d4;
}
.slider-material-teal .noUi-connect {
  background-color: #009688;
}
.slider-material-teal-50 .noUi-connect {
  background-color: #e0f2f1;
}
.slider-material-teal-100 .noUi-connect {
  background-color: #b2dfdb;
}
.slider-material-teal-200 .noUi-connect {
  background-color: #80cbc4;
}
.slider-material-teal-300 .noUi-connect {
  background-color: #4db6ac;
}
.slider-material-teal-400 .noUi-connect {
  background-color: #26a69a;
}
.slider-material-teal-500 .noUi-connect {
  background-color: #009688;
}
.slider-material-teal-600 .noUi-connect {
  background-color: #00897b;
}
.slider-material-teal-700 .noUi-connect {
  background-color: #00796b;
}
.slider-material-teal-800 .noUi-connect {
  background-color: #00695c;
}
.slider-material-teal-900 .noUi-connect {
  background-color: #004d40;
}
.slider-material-teal-A100 .noUi-connect {
  background-color: #a7ffeb;
}
.slider-material-teal-A200 .noUi-connect {
  background-color: #64ffda;
}
.slider-material-teal-A400 .noUi-connect {
  background-color: #1de9b6;
}
.slider-material-teal-A700 .noUi-connect {
  background-color: #00bfa5;
}
.slider-material-green .noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-50 .noUi-connect {
  background-color: #e8f5e9;
}
.slider-material-green-100 .noUi-connect {
  background-color: #c8e6c9;
}
.slider-material-green-200 .noUi-connect {
  background-color: #a5d6a7;
}
.slider-material-green-300 .noUi-connect {
  background-color: #81c784;
}
.slider-material-green-400 .noUi-connect {
  background-color: #66bb6a;
}
.slider-material-green-500 .noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-600 .noUi-connect {
  background-color: #43a047;
}
.slider-material-green-700 .noUi-connect {
  background-color: #388e3c;
}
.slider-material-green-800 .noUi-connect {
  background-color: #2e7d32;
}
.slider-material-green-900 .noUi-connect {
  background-color: #1b5e20;
}
.slider-material-green-A100 .noUi-connect {
  background-color: #b9f6ca;
}
.slider-material-green-A200 .noUi-connect {
  background-color: #69f0ae;
}
.slider-material-green-A400 .noUi-connect {
  background-color: #00e676;
}
.slider-material-green-A700 .noUi-connect {
  background-color: #00c853;
}
.slider-material-light-green .noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-50 .noUi-connect {
  background-color: #f1f8e9;
}
.slider-material-light-green-100 .noUi-connect {
  background-color: #dcedc8;
}
.slider-material-light-green-200 .noUi-connect {
  background-color: #c5e1a5;
}
.slider-material-light-green-300 .noUi-connect {
  background-color: #aed581;
}
.slider-material-light-green-400 .noUi-connect {
  background-color: #9ccc65;
}
.slider-material-light-green-500 .noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-600 .noUi-connect {
  background-color: #7cb342;
}
.slider-material-light-green-700 .noUi-connect {
  background-color: #689f38;
}
.slider-material-light-green-800 .noUi-connect {
  background-color: #558b2f;
}
.slider-material-light-green-900 .noUi-connect {
  background-color: #33691e;
}
.slider-material-light-green-A100 .noUi-connect {
  background-color: #ccff90;
}
.slider-material-light-green-A200 .noUi-connect {
  background-color: #b2ff59;
}
.slider-material-light-green-A400 .noUi-connect {
  background-color: #76ff03;
}
.slider-material-light-green-A700 .noUi-connect {
  background-color: #64dd17;
}
.slider-material-lime .noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-50 .noUi-connect {
  background-color: #f9fbe7;
}
.slider-material-lime-100 .noUi-connect {
  background-color: #f0f4c3;
}
.slider-material-lime-200 .noUi-connect {
  background-color: #e6ee9c;
}
.slider-material-lime-300 .noUi-connect {
  background-color: #dce775;
}
.slider-material-lime-400 .noUi-connect {
  background-color: #d4e157;
}
.slider-material-lime-500 .noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-600 .noUi-connect {
  background-color: #c0ca33;
}
.slider-material-lime-700 .noUi-connect {
  background-color: #afb42b;
}
.slider-material-lime-800 .noUi-connect {
  background-color: #9e9d24;
}
.slider-material-lime-900 .noUi-connect {
  background-color: #827717;
}
.slider-material-lime-A100 .noUi-connect {
  background-color: #f4ff81;
}
.slider-material-lime-A200 .noUi-connect {
  background-color: #eeff41;
}
.slider-material-lime-A400 .noUi-connect {
  background-color: #c6ff00;
}
.slider-material-lime-A700 .noUi-connect {
  background-color: #aeea00;
}
.slider-material-yellow .noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-50 .noUi-connect {
  background-color: #fffde7;
}
.slider-material-yellow-100 .noUi-connect {
  background-color: #fff9c4;
}
.slider-material-yellow-200 .noUi-connect {
  background-color: #fff59d;
}
.slider-material-yellow-300 .noUi-connect {
  background-color: #fff176;
}
.slider-material-yellow-400 .noUi-connect {
  background-color: #ffee58;
}
.slider-material-yellow-500 .noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-600 .noUi-connect {
  background-color: #fdd835;
}
.slider-material-yellow-700 .noUi-connect {
  background-color: #fbc02d;
}
.slider-material-yellow-800 .noUi-connect {
  background-color: #f9a825;
}
.slider-material-yellow-900 .noUi-connect {
  background-color: #f57f17;
}
.slider-material-yellow-A100 .noUi-connect {
  background-color: #ffff8d;
}
.slider-material-yellow-A200 .noUi-connect {
  background-color: #ffff00;
}
.slider-material-yellow-A400 .noUi-connect {
  background-color: #ffea00;
}
.slider-material-yellow-A700 .noUi-connect {
  background-color: #ffd600;
}
.slider-material-amber .noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-50 .noUi-connect {
  background-color: #fff8e1;
}
.slider-material-amber-100 .noUi-connect {
  background-color: #ffecb3;
}
.slider-material-amber-200 .noUi-connect {
  background-color: #ffe082;
}
.slider-material-amber-300 .noUi-connect {
  background-color: #ffd54f;
}
.slider-material-amber-400 .noUi-connect {
  background-color: #ffca28;
}
.slider-material-amber-500 .noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-600 .noUi-connect {
  background-color: #ffb300;
}
.slider-material-amber-700 .noUi-connect {
  background-color: #ffa000;
}
.slider-material-amber-800 .noUi-connect {
  background-color: #ff8f00;
}
.slider-material-amber-900 .noUi-connect {
  background-color: #ff6f00;
}
.slider-material-amber-A100 .noUi-connect {
  background-color: #ffe57f;
}
.slider-material-amber-A200 .noUi-connect {
  background-color: #ffd740;
}
.slider-material-amber-A400 .noUi-connect {
  background-color: #ffc400;
}
.slider-material-amber-A700 .noUi-connect {
  background-color: #ffab00;
}
.slider-material-orange .noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-50 .noUi-connect {
  background-color: #fff3e0;
}
.slider-material-orange-100 .noUi-connect {
  background-color: #ffe0b2;
}
.slider-material-orange-200 .noUi-connect {
  background-color: #ffcc80;
}
.slider-material-orange-300 .noUi-connect {
  background-color: #ffb74d;
}
.slider-material-orange-400 .noUi-connect {
  background-color: #ffa726;
}
.slider-material-orange-500 .noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-600 .noUi-connect {
  background-color: #fb8c00;
}
.slider-material-orange-700 .noUi-connect {
  background-color: #f57c00;
}
.slider-material-orange-800 .noUi-connect {
  background-color: #ef6c00;
}
.slider-material-orange-900 .noUi-connect {
  background-color: #e65100;
}
.slider-material-orange-A100 .noUi-connect {
  background-color: #ffd180;
}
.slider-material-orange-A200 .noUi-connect {
  background-color: #ffab40;
}
.slider-material-orange-A400 .noUi-connect {
  background-color: #ff9100;
}
.slider-material-orange-A700 .noUi-connect {
  background-color: #ff6d00;
}
.slider-material-deep-orange .noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-50 .noUi-connect {
  background-color: #fbe9e7;
}
.slider-material-deep-orange-100 .noUi-connect {
  background-color: #ffccbc;
}
.slider-material-deep-orange-200 .noUi-connect {
  background-color: #ffab91;
}
.slider-material-deep-orange-300 .noUi-connect {
  background-color: #ff8a65;
}
.slider-material-deep-orange-400 .noUi-connect {
  background-color: #ff7043;
}
.slider-material-deep-orange-500 .noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-600 .noUi-connect {
  background-color: #f4511e;
}
.slider-material-deep-orange-700 .noUi-connect {
  background-color: #e64a19;
}
.slider-material-deep-orange-800 .noUi-connect {
  background-color: #d84315;
}
.slider-material-deep-orange-900 .noUi-connect {
  background-color: #bf360c;
}
.slider-material-deep-orange-A100 .noUi-connect {
  background-color: #ff9e80;
}
.slider-material-deep-orange-A200 .noUi-connect {
  background-color: #ff6e40;
}
.slider-material-deep-orange-A400 .noUi-connect {
  background-color: #ff3d00;
}
.slider-material-deep-orange-A700 .noUi-connect {
  background-color: #dd2c00;
}
.slider-material-brown .noUi-connect {
  background-color: #795548;
}
.slider-material-brown-50 .noUi-connect {
  background-color: #efebe9;
}
.slider-material-brown-100 .noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-200 .noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-300 .noUi-connect {
  background-color: #a1887f;
}
.slider-material-brown-400 .noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-500 .noUi-connect {
  background-color: #795548;
}
.slider-material-brown-600 .noUi-connect {
  background-color: #6d4c41;
}
.slider-material-brown-700 .noUi-connect {
  background-color: #5d4037;
}
.slider-material-brown-800 .noUi-connect {
  background-color: #4e342e;
}
.slider-material-brown-900 .noUi-connect {
  background-color: #3e2723;
}
.slider-material-brown-A100 .noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-A200 .noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-A400 .noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-A700 .noUi-connect {
  background-color: #5d4037;
}
.slider-material-grey .noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-50 .noUi-connect {
  background-color: #fafafa;
}
.slider-material-grey-100 .noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-200 .noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-300 .noUi-connect {
  background-color: #e0e0e0;
}
.slider-material-grey-400 .noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-500 .noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-600 .noUi-connect {
  background-color: #757575;
}
.slider-material-grey-700 .noUi-connect {
  background-color: #616161;
}
.slider-material-grey-800 .noUi-connect {
  background-color: #424242;
}
.slider-material-grey-900 .noUi-connect {
  background-color: #212121;
}
.slider-material-grey-A100 .noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-A200 .noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-A400 .noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-A700 .noUi-connect {
  background-color: #616161;
}
.slider-material-blue .noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-50 .noUi-connect {
  background-color: #eceff1;
}
.slider-material-blue-100 .noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-200 .noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-300 .noUi-connect {
  background-color: #90a4ae;
}
.slider-material-blue-400 .noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-500 .noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-600 .noUi-connect {
  background-color: #546e7a;
}
.slider-material-blue-700 .noUi-connect {
  background-color: #455a64;
}
.slider-material-blue-800 .noUi-connect {
  background-color: #37474f;
}
.slider-material-blue-900 .noUi-connect {
  background-color: #263238;
}
.slider-material-blue-A100 .noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-A200 .noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-A400 .noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-A700 .noUi-connect {
  background-color: #455a64;
}
.slider .noUi-handle,
.slider-default .noUi-handle {
  background-color: #009587;
}
.slider-primary .noUi-handle {
  background-color: #009587;
}
.slider-success .noUi-handle {
  background-color: #0f9d58;
}
.slider-info .noUi-handle {
  background-color: #03a9f4;
}
.slider-warning .noUi-handle {
  background-color: #ff5722;
}
.slider-danger .noUi-handle {
  background-color: #f44336;
}
.slider-material-red .noUi-handle {
  background-color: #f44336;
}
.slider-material-red-50 .noUi-handle {
  background-color: #ffebee;
}
.slider-material-red-100 .noUi-handle {
  background-color: #ffcdd2;
}
.slider-material-red-200 .noUi-handle {
  background-color: #ef9a9a;
}
.slider-material-red-300 .noUi-handle {
  background-color: #e57373;
}
.slider-material-red-400 .noUi-handle {
  background-color: #ef5350;
}
.slider-material-red-500 .noUi-handle {
  background-color: #f44336;
}
.slider-material-red-600 .noUi-handle {
  background-color: #e53935;
}
.slider-material-red-700 .noUi-handle {
  background-color: #d32f2f;
}
.slider-material-red-800 .noUi-handle {
  background-color: #c62828;
}
.slider-material-red-900 .noUi-handle {
  background-color: #b71c1c;
}
.slider-material-red-A100 .noUi-handle {
  background-color: #ff8a80;
}
.slider-material-red-A200 .noUi-handle {
  background-color: #ff5252;
}
.slider-material-red-A400 .noUi-handle {
  background-color: #ff1744;
}
.slider-material-red-A700 .noUi-handle {
  background-color: #d50000;
}
.slider-material-pink .noUi-handle {
  background-color: #e91e63;
}
.slider-material-pink-50 .noUi-handle {
  background-color: #fce4ec;
}
.slider-material-pink-100 .noUi-handle {
  background-color: #f8bbd0;
}
.slider-material-pink-200 .noUi-handle {
  background-color: #f48fb1;
}
.slider-material-pink-300 .noUi-handle {
  background-color: #f06292;
}
.slider-material-pink-400 .noUi-handle {
  background-color: #ec407a;
}
.slider-material-pink-500 .noUi-handle {
  background-color: #e91e63;
}
.slider-material-pink-600 .noUi-handle {
  background-color: #d81b60;
}
.slider-material-pink-700 .noUi-handle {
  background-color: #c2185b;
}
.slider-material-pink-800 .noUi-handle {
  background-color: #ad1457;
}
.slider-material-pink-900 .noUi-handle {
  background-color: #880e4f;
}
.slider-material-pink-A100 .noUi-handle {
  background-color: #ff80ab;
}
.slider-material-pink-A200 .noUi-handle {
  background-color: #ff4081;
}
.slider-material-pink-A400 .noUi-handle {
  background-color: #f50057;
}
.slider-material-pink-A700 .noUi-handle {
  background-color: #c51162;
}
.slider-material-purple .noUi-handle {
  background-color: #9c27b0;
}
.slider-material-purple-50 .noUi-handle {
  background-color: #f3e5f5;
}
.slider-material-purple-100 .noUi-handle {
  background-color: #e1bee7;
}
.slider-material-purple-200 .noUi-handle {
  background-color: #ce93d8;
}
.slider-material-purple-300 .noUi-handle {
  background-color: #ba68c8;
}
.slider-material-purple-400 .noUi-handle {
  background-color: #ab47bc;
}
.slider-material-purple-500 .noUi-handle {
  background-color: #9c27b0;
}
.slider-material-purple-600 .noUi-handle {
  background-color: #8e24aa;
}
.slider-material-purple-700 .noUi-handle {
  background-color: #7b1fa2;
}
.slider-material-purple-800 .noUi-handle {
  background-color: #6a1b9a;
}
.slider-material-purple-900 .noUi-handle {
  background-color: #4a148c;
}
.slider-material-purple-A100 .noUi-handle {
  background-color: #ea80fc;
}
.slider-material-purple-A200 .noUi-handle {
  background-color: #e040fb;
}
.slider-material-purple-A400 .noUi-handle {
  background-color: #d500f9;
}
.slider-material-purple-A700 .noUi-handle {
  background-color: #aa00ff;
}
.slider-material-deep-purple .noUi-handle {
  background-color: #673ab7;
}
.slider-material-deep-purple-50 .noUi-handle {
  background-color: #ede7f6;
}
.slider-material-deep-purple-100 .noUi-handle {
  background-color: #d1c4e9;
}
.slider-material-deep-purple-200 .noUi-handle {
  background-color: #b39ddb;
}
.slider-material-deep-purple-300 .noUi-handle {
  background-color: #9575cd;
}
.slider-material-deep-purple-400 .noUi-handle {
  background-color: #7e57c2;
}
.slider-material-deep-purple-500 .noUi-handle {
  background-color: #673ab7;
}
.slider-material-deep-purple-600 .noUi-handle {
  background-color: #5e35b1;
}
.slider-material-deep-purple-700 .noUi-handle {
  background-color: #512da8;
}
.slider-material-deep-purple-800 .noUi-handle {
  background-color: #4527a0;
}
.slider-material-deep-purple-900 .noUi-handle {
  background-color: #311b92;
}
.slider-material-deep-purple-A100 .noUi-handle {
  background-color: #b388ff;
}
.slider-material-deep-purple-A200 .noUi-handle {
  background-color: #7c4dff;
}
.slider-material-deep-purple-A400 .noUi-handle {
  background-color: #651fff;
}
.slider-material-deep-purple-A700 .noUi-handle {
  background-color: #6200ea;
}
.slider-material-indigo .noUi-handle {
  background-color: #3f51b5;
}
.slider-material-indigo-50 .noUi-handle {
  background-color: #e8eaf6;
}
.slider-material-indigo-100 .noUi-handle {
  background-color: #c5cae9;
}
.slider-material-indigo-200 .noUi-handle {
  background-color: #9fa8da;
}
.slider-material-indigo-300 .noUi-handle {
  background-color: #7986cb;
}
.slider-material-indigo-400 .noUi-handle {
  background-color: #5c6bc0;
}
.slider-material-indigo-500 .noUi-handle {
  background-color: #3f51b5;
}
.slider-material-indigo-600 .noUi-handle {
  background-color: #3949ab;
}
.slider-material-indigo-700 .noUi-handle {
  background-color: #303f9f;
}
.slider-material-indigo-800 .noUi-handle {
  background-color: #283593;
}
.slider-material-indigo-900 .noUi-handle {
  background-color: #1a237e;
}
.slider-material-indigo-A100 .noUi-handle {
  background-color: #8c9eff;
}
.slider-material-indigo-A200 .noUi-handle {
  background-color: #536dfe;
}
.slider-material-indigo-A400 .noUi-handle {
  background-color: #3d5afe;
}
.slider-material-indigo-A700 .noUi-handle {
  background-color: #304ffe;
}
.slider-material-blue .noUi-handle {
  background-color: #2196f3;
}
.slider-material-blue-50 .noUi-handle {
  background-color: #e3f2fd;
}
.slider-material-blue-100 .noUi-handle {
  background-color: #bbdefb;
}
.slider-material-blue-200 .noUi-handle {
  background-color: #90caf9;
}
.slider-material-blue-300 .noUi-handle {
  background-color: #64b5f6;
}
.slider-material-blue-400 .noUi-handle {
  background-color: #42a5f5;
}
.slider-material-blue-500 .noUi-handle {
  background-color: #2196f3;
}
.slider-material-blue-600 .noUi-handle {
  background-color: #1e88e5;
}
.slider-material-blue-700 .noUi-handle {
  background-color: #1976d2;
}
.slider-material-blue-800 .noUi-handle {
  background-color: #1565c0;
}
.slider-material-blue-900 .noUi-handle {
  background-color: #0d47a1;
}
.slider-material-blue-A100 .noUi-handle {
  background-color: #82b1ff;
}
.slider-material-blue-A200 .noUi-handle {
  background-color: #448aff;
}
.slider-material-blue-A400 .noUi-handle {
  background-color: #2979ff;
}
.slider-material-blue-A700 .noUi-handle {
  background-color: #2962ff;
}
.slider-material-light-blue .noUi-handle {
  background-color: #03a9f4;
}
.slider-material-light-blue-50 .noUi-handle {
  background-color: #e1f5fe;
}
.slider-material-light-blue-100 .noUi-handle {
  background-color: #b3e5fc;
}
.slider-material-light-blue-200 .noUi-handle {
  background-color: #81d4fa;
}
.slider-material-light-blue-300 .noUi-handle {
  background-color: #4fc3f7;
}
.slider-material-light-blue-400 .noUi-handle {
  background-color: #29b6f6;
}
.slider-material-light-blue-500 .noUi-handle {
  background-color: #03a9f4;
}
.slider-material-light-blue-600 .noUi-handle {
  background-color: #039be5;
}
.slider-material-light-blue-700 .noUi-handle {
  background-color: #0288d1;
}
.slider-material-light-blue-800 .noUi-handle {
  background-color: #0277bd;
}
.slider-material-light-blue-900 .noUi-handle {
  background-color: #01579b;
}
.slider-material-light-blue-A100 .noUi-handle {
  background-color: #80d8ff;
}
.slider-material-light-blue-A200 .noUi-handle {
  background-color: #40c4ff;
}
.slider-material-light-blue-A400 .noUi-handle {
  background-color: #00b0ff;
}
.slider-material-light-blue-A700 .noUi-handle {
  background-color: #0091ea;
}
.slider-material-cyan .noUi-handle {
  background-color: #00bcd4;
}
.slider-material-cyan-50 .noUi-handle {
  background-color: #e0f7fa;
}
.slider-material-cyan-100 .noUi-handle {
  background-color: #b2ebf2;
}
.slider-material-cyan-200 .noUi-handle {
  background-color: #80deea;
}
.slider-material-cyan-300 .noUi-handle {
  background-color: #4dd0e1;
}
.slider-material-cyan-400 .noUi-handle {
  background-color: #26c6da;
}
.slider-material-cyan-500 .noUi-handle {
  background-color: #00bcd4;
}
.slider-material-cyan-600 .noUi-handle {
  background-color: #00acc1;
}
.slider-material-cyan-700 .noUi-handle {
  background-color: #0097a7;
}
.slider-material-cyan-800 .noUi-handle {
  background-color: #00838f;
}
.slider-material-cyan-900 .noUi-handle {
  background-color: #006064;
}
.slider-material-cyan-A100 .noUi-handle {
  background-color: #84ffff;
}
.slider-material-cyan-A200 .noUi-handle {
  background-color: #18ffff;
}
.slider-material-cyan-A400 .noUi-handle {
  background-color: #00e5ff;
}
.slider-material-cyan-A700 .noUi-handle {
  background-color: #00b8d4;
}
.slider-material-teal .noUi-handle {
  background-color: #009688;
}
.slider-material-teal-50 .noUi-handle {
  background-color: #e0f2f1;
}
.slider-material-teal-100 .noUi-handle {
  background-color: #b2dfdb;
}
.slider-material-teal-200 .noUi-handle {
  background-color: #80cbc4;
}
.slider-material-teal-300 .noUi-handle {
  background-color: #4db6ac;
}
.slider-material-teal-400 .noUi-handle {
  background-color: #26a69a;
}
.slider-material-teal-500 .noUi-handle {
  background-color: #009688;
}
.slider-material-teal-600 .noUi-handle {
  background-color: #00897b;
}
.slider-material-teal-700 .noUi-handle {
  background-color: #00796b;
}
.slider-material-teal-800 .noUi-handle {
  background-color: #00695c;
}
.slider-material-teal-900 .noUi-handle {
  background-color: #004d40;
}
.slider-material-teal-A100 .noUi-handle {
  background-color: #a7ffeb;
}
.slider-material-teal-A200 .noUi-handle {
  background-color: #64ffda;
}
.slider-material-teal-A400 .noUi-handle {
  background-color: #1de9b6;
}
.slider-material-teal-A700 .noUi-handle {
  background-color: #00bfa5;
}
.slider-material-green .noUi-handle {
  background-color: #4caf50;
}
.slider-material-green-50 .noUi-handle {
  background-color: #e8f5e9;
}
.slider-material-green-100 .noUi-handle {
  background-color: #c8e6c9;
}
.slider-material-green-200 .noUi-handle {
  background-color: #a5d6a7;
}
.slider-material-green-300 .noUi-handle {
  background-color: #81c784;
}
.slider-material-green-400 .noUi-handle {
  background-color: #66bb6a;
}
.slider-material-green-500 .noUi-handle {
  background-color: #4caf50;
}
.slider-material-green-600 .noUi-handle {
  background-color: #43a047;
}
.slider-material-green-700 .noUi-handle {
  background-color: #388e3c;
}
.slider-material-green-800 .noUi-handle {
  background-color: #2e7d32;
}
.slider-material-green-900 .noUi-handle {
  background-color: #1b5e20;
}
.slider-material-green-A100 .noUi-handle {
  background-color: #b9f6ca;
}
.slider-material-green-A200 .noUi-handle {
  background-color: #69f0ae;
}
.slider-material-green-A400 .noUi-handle {
  background-color: #00e676;
}
.slider-material-green-A700 .noUi-handle {
  background-color: #00c853;
}
.slider-material-light-green .noUi-handle {
  background-color: #8bc34a;
}
.slider-material-light-green-50 .noUi-handle {
  background-color: #f1f8e9;
}
.slider-material-light-green-100 .noUi-handle {
  background-color: #dcedc8;
}
.slider-material-light-green-200 .noUi-handle {
  background-color: #c5e1a5;
}
.slider-material-light-green-300 .noUi-handle {
  background-color: #aed581;
}
.slider-material-light-green-400 .noUi-handle {
  background-color: #9ccc65;
}
.slider-material-light-green-500 .noUi-handle {
  background-color: #8bc34a;
}
.slider-material-light-green-600 .noUi-handle {
  background-color: #7cb342;
}
.slider-material-light-green-700 .noUi-handle {
  background-color: #689f38;
}
.slider-material-light-green-800 .noUi-handle {
  background-color: #558b2f;
}
.slider-material-light-green-900 .noUi-handle {
  background-color: #33691e;
}
.slider-material-light-green-A100 .noUi-handle {
  background-color: #ccff90;
}
.slider-material-light-green-A200 .noUi-handle {
  background-color: #b2ff59;
}
.slider-material-light-green-A400 .noUi-handle {
  background-color: #76ff03;
}
.slider-material-light-green-A700 .noUi-handle {
  background-color: #64dd17;
}
.slider-material-lime .noUi-handle {
  background-color: #cddc39;
}
.slider-material-lime-50 .noUi-handle {
  background-color: #f9fbe7;
}
.slider-material-lime-100 .noUi-handle {
  background-color: #f0f4c3;
}
.slider-material-lime-200 .noUi-handle {
  background-color: #e6ee9c;
}
.slider-material-lime-300 .noUi-handle {
  background-color: #dce775;
}
.slider-material-lime-400 .noUi-handle {
  background-color: #d4e157;
}
.slider-material-lime-500 .noUi-handle {
  background-color: #cddc39;
}
.slider-material-lime-600 .noUi-handle {
  background-color: #c0ca33;
}
.slider-material-lime-700 .noUi-handle {
  background-color: #afb42b;
}
.slider-material-lime-800 .noUi-handle {
  background-color: #9e9d24;
}
.slider-material-lime-900 .noUi-handle {
  background-color: #827717;
}
.slider-material-lime-A100 .noUi-handle {
  background-color: #f4ff81;
}
.slider-material-lime-A200 .noUi-handle {
  background-color: #eeff41;
}
.slider-material-lime-A400 .noUi-handle {
  background-color: #c6ff00;
}
.slider-material-lime-A700 .noUi-handle {
  background-color: #aeea00;
}
.slider-material-yellow .noUi-handle {
  background-color: #ffeb3b;
}
.slider-material-yellow-50 .noUi-handle {
  background-color: #fffde7;
}
.slider-material-yellow-100 .noUi-handle {
  background-color: #fff9c4;
}
.slider-material-yellow-200 .noUi-handle {
  background-color: #fff59d;
}
.slider-material-yellow-300 .noUi-handle {
  background-color: #fff176;
}
.slider-material-yellow-400 .noUi-handle {
  background-color: #ffee58;
}
.slider-material-yellow-500 .noUi-handle {
  background-color: #ffeb3b;
}
.slider-material-yellow-600 .noUi-handle {
  background-color: #fdd835;
}
.slider-material-yellow-700 .noUi-handle {
  background-color: #fbc02d;
}
.slider-material-yellow-800 .noUi-handle {
  background-color: #f9a825;
}
.slider-material-yellow-900 .noUi-handle {
  background-color: #f57f17;
}
.slider-material-yellow-A100 .noUi-handle {
  background-color: #ffff8d;
}
.slider-material-yellow-A200 .noUi-handle {
  background-color: #ffff00;
}
.slider-material-yellow-A400 .noUi-handle {
  background-color: #ffea00;
}
.slider-material-yellow-A700 .noUi-handle {
  background-color: #ffd600;
}
.slider-material-amber .noUi-handle {
  background-color: #ffc107;
}
.slider-material-amber-50 .noUi-handle {
  background-color: #fff8e1;
}
.slider-material-amber-100 .noUi-handle {
  background-color: #ffecb3;
}
.slider-material-amber-200 .noUi-handle {
  background-color: #ffe082;
}
.slider-material-amber-300 .noUi-handle {
  background-color: #ffd54f;
}
.slider-material-amber-400 .noUi-handle {
  background-color: #ffca28;
}
.slider-material-amber-500 .noUi-handle {
  background-color: #ffc107;
}
.slider-material-amber-600 .noUi-handle {
  background-color: #ffb300;
}
.slider-material-amber-700 .noUi-handle {
  background-color: #ffa000;
}
.slider-material-amber-800 .noUi-handle {
  background-color: #ff8f00;
}
.slider-material-amber-900 .noUi-handle {
  background-color: #ff6f00;
}
.slider-material-amber-A100 .noUi-handle {
  background-color: #ffe57f;
}
.slider-material-amber-A200 .noUi-handle {
  background-color: #ffd740;
}
.slider-material-amber-A400 .noUi-handle {
  background-color: #ffc400;
}
.slider-material-amber-A700 .noUi-handle {
  background-color: #ffab00;
}
.slider-material-orange .noUi-handle {
  background-color: #ff9800;
}
.slider-material-orange-50 .noUi-handle {
  background-color: #fff3e0;
}
.slider-material-orange-100 .noUi-handle {
  background-color: #ffe0b2;
}
.slider-material-orange-200 .noUi-handle {
  background-color: #ffcc80;
}
.slider-material-orange-300 .noUi-handle {
  background-color: #ffb74d;
}
.slider-material-orange-400 .noUi-handle {
  background-color: #ffa726;
}
.slider-material-orange-500 .noUi-handle {
  background-color: #ff9800;
}
.slider-material-orange-600 .noUi-handle {
  background-color: #fb8c00;
}
.slider-material-orange-700 .noUi-handle {
  background-color: #f57c00;
}
.slider-material-orange-800 .noUi-handle {
  background-color: #ef6c00;
}
.slider-material-orange-900 .noUi-handle {
  background-color: #e65100;
}
.slider-material-orange-A100 .noUi-handle {
  background-color: #ffd180;
}
.slider-material-orange-A200 .noUi-handle {
  background-color: #ffab40;
}
.slider-material-orange-A400 .noUi-handle {
  background-color: #ff9100;
}
.slider-material-orange-A700 .noUi-handle {
  background-color: #ff6d00;
}
.slider-material-deep-orange .noUi-handle {
  background-color: #ff5722;
}
.slider-material-deep-orange-50 .noUi-handle {
  background-color: #fbe9e7;
}
.slider-material-deep-orange-100 .noUi-handle {
  background-color: #ffccbc;
}
.slider-material-deep-orange-200 .noUi-handle {
  background-color: #ffab91;
}
.slider-material-deep-orange-300 .noUi-handle {
  background-color: #ff8a65;
}
.slider-material-deep-orange-400 .noUi-handle {
  background-color: #ff7043;
}
.slider-material-deep-orange-500 .noUi-handle {
  background-color: #ff5722;
}
.slider-material-deep-orange-600 .noUi-handle {
  background-color: #f4511e;
}
.slider-material-deep-orange-700 .noUi-handle {
  background-color: #e64a19;
}
.slider-material-deep-orange-800 .noUi-handle {
  background-color: #d84315;
}
.slider-material-deep-orange-900 .noUi-handle {
  background-color: #bf360c;
}
.slider-material-deep-orange-A100 .noUi-handle {
  background-color: #ff9e80;
}
.slider-material-deep-orange-A200 .noUi-handle {
  background-color: #ff6e40;
}
.slider-material-deep-orange-A400 .noUi-handle {
  background-color: #ff3d00;
}
.slider-material-deep-orange-A700 .noUi-handle {
  background-color: #dd2c00;
}
.slider-material-brown .noUi-handle {
  background-color: #795548;
}
.slider-material-brown-50 .noUi-handle {
  background-color: #efebe9;
}
.slider-material-brown-100 .noUi-handle {
  background-color: #d7ccc8;
}
.slider-material-brown-200 .noUi-handle {
  background-color: #bcaaa4;
}
.slider-material-brown-300 .noUi-handle {
  background-color: #a1887f;
}
.slider-material-brown-400 .noUi-handle {
  background-color: #8d6e63;
}
.slider-material-brown-500 .noUi-handle {
  background-color: #795548;
}
.slider-material-brown-600 .noUi-handle {
  background-color: #6d4c41;
}
.slider-material-brown-700 .noUi-handle {
  background-color: #5d4037;
}
.slider-material-brown-800 .noUi-handle {
  background-color: #4e342e;
}
.slider-material-brown-900 .noUi-handle {
  background-color: #3e2723;
}
.slider-material-brown-A100 .noUi-handle {
  background-color: #d7ccc8;
}
.slider-material-brown-A200 .noUi-handle {
  background-color: #bcaaa4;
}
.slider-material-brown-A400 .noUi-handle {
  background-color: #8d6e63;
}
.slider-material-brown-A700 .noUi-handle {
  background-color: #5d4037;
}
.slider-material-grey .noUi-handle {
  background-color: #9e9e9e;
}
.slider-material-grey-50 .noUi-handle {
  background-color: #fafafa;
}
.slider-material-grey-100 .noUi-handle {
  background-color: #f5f5f5;
}
.slider-material-grey-200 .noUi-handle {
  background-color: #eeeeee;
}
.slider-material-grey-300 .noUi-handle {
  background-color: #e0e0e0;
}
.slider-material-grey-400 .noUi-handle {
  background-color: #bdbdbd;
}
.slider-material-grey-500 .noUi-handle {
  background-color: #9e9e9e;
}
.slider-material-grey-600 .noUi-handle {
  background-color: #757575;
}
.slider-material-grey-700 .noUi-handle {
  background-color: #616161;
}
.slider-material-grey-800 .noUi-handle {
  background-color: #424242;
}
.slider-material-grey-900 .noUi-handle {
  background-color: #212121;
}
.slider-material-grey-A100 .noUi-handle {
  background-color: #f5f5f5;
}
.slider-material-grey-A200 .noUi-handle {
  background-color: #eeeeee;
}
.slider-material-grey-A400 .noUi-handle {
  background-color: #bdbdbd;
}
.slider-material-grey-A700 .noUi-handle {
  background-color: #616161;
}
.slider-material-blue .noUi-handle {
  background-color: #607d8b;
}
.slider-material-blue-50 .noUi-handle {
  background-color: #eceff1;
}
.slider-material-blue-100 .noUi-handle {
  background-color: #cfd8dc;
}
.slider-material-blue-200 .noUi-handle {
  background-color: #b0bec5;
}
.slider-material-blue-300 .noUi-handle {
  background-color: #90a4ae;
}
.slider-material-blue-400 .noUi-handle {
  background-color: #78909c;
}
.slider-material-blue-500 .noUi-handle {
  background-color: #607d8b;
}
.slider-material-blue-600 .noUi-handle {
  background-color: #546e7a;
}
.slider-material-blue-700 .noUi-handle {
  background-color: #455a64;
}
.slider-material-blue-800 .noUi-handle {
  background-color: #37474f;
}
.slider-material-blue-900 .noUi-handle {
  background-color: #263238;
}
.slider-material-blue-A100 .noUi-handle {
  background-color: #cfd8dc;
}
.slider-material-blue-A200 .noUi-handle {
  background-color: #b0bec5;
}
.slider-material-blue-A400 .noUi-handle {
  background-color: #78909c;
}
.slider-material-blue-A700 .noUi-handle {
  background-color: #455a64;
}
.slider .noUi-handle,
.slider-default .noUi-handle {
  border-color: #009587;
}
.slider-primary .noUi-handle {
  border-color: #009587;
}
.slider-success .noUi-handle {
  border-color: #0f9d58;
}
.slider-info .noUi-handle {
  border-color: #03a9f4;
}
.slider-warning .noUi-handle {
  border-color: #ff5722;
}
.slider-danger .noUi-handle {
  border-color: #f44336;
}
.slider-material-red .noUi-handle {
  border-color: #f44336;
}
.slider-material-red-50 .noUi-handle {
  border-color: #ffebee;
}
.slider-material-red-100 .noUi-handle {
  border-color: #ffcdd2;
}
.slider-material-red-200 .noUi-handle {
  border-color: #ef9a9a;
}
.slider-material-red-300 .noUi-handle {
  border-color: #e57373;
}
.slider-material-red-400 .noUi-handle {
  border-color: #ef5350;
}
.slider-material-red-500 .noUi-handle {
  border-color: #f44336;
}
.slider-material-red-600 .noUi-handle {
  border-color: #e53935;
}
.slider-material-red-700 .noUi-handle {
  border-color: #d32f2f;
}
.slider-material-red-800 .noUi-handle {
  border-color: #c62828;
}
.slider-material-red-900 .noUi-handle {
  border-color: #b71c1c;
}
.slider-material-red-A100 .noUi-handle {
  border-color: #ff8a80;
}
.slider-material-red-A200 .noUi-handle {
  border-color: #ff5252;
}
.slider-material-red-A400 .noUi-handle {
  border-color: #ff1744;
}
.slider-material-red-A700 .noUi-handle {
  border-color: #d50000;
}
.slider-material-pink .noUi-handle {
  border-color: #e91e63;
}
.slider-material-pink-50 .noUi-handle {
  border-color: #fce4ec;
}
.slider-material-pink-100 .noUi-handle {
  border-color: #f8bbd0;
}
.slider-material-pink-200 .noUi-handle {
  border-color: #f48fb1;
}
.slider-material-pink-300 .noUi-handle {
  border-color: #f06292;
}
.slider-material-pink-400 .noUi-handle {
  border-color: #ec407a;
}
.slider-material-pink-500 .noUi-handle {
  border-color: #e91e63;
}
.slider-material-pink-600 .noUi-handle {
  border-color: #d81b60;
}
.slider-material-pink-700 .noUi-handle {
  border-color: #c2185b;
}
.slider-material-pink-800 .noUi-handle {
  border-color: #ad1457;
}
.slider-material-pink-900 .noUi-handle {
  border-color: #880e4f;
}
.slider-material-pink-A100 .noUi-handle {
  border-color: #ff80ab;
}
.slider-material-pink-A200 .noUi-handle {
  border-color: #ff4081;
}
.slider-material-pink-A400 .noUi-handle {
  border-color: #f50057;
}
.slider-material-pink-A700 .noUi-handle {
  border-color: #c51162;
}
.slider-material-purple .noUi-handle {
  border-color: #9c27b0;
}
.slider-material-purple-50 .noUi-handle {
  border-color: #f3e5f5;
}
.slider-material-purple-100 .noUi-handle {
  border-color: #e1bee7;
}
.slider-material-purple-200 .noUi-handle {
  border-color: #ce93d8;
}
.slider-material-purple-300 .noUi-handle {
  border-color: #ba68c8;
}
.slider-material-purple-400 .noUi-handle {
  border-color: #ab47bc;
}
.slider-material-purple-500 .noUi-handle {
  border-color: #9c27b0;
}
.slider-material-purple-600 .noUi-handle {
  border-color: #8e24aa;
}
.slider-material-purple-700 .noUi-handle {
  border-color: #7b1fa2;
}
.slider-material-purple-800 .noUi-handle {
  border-color: #6a1b9a;
}
.slider-material-purple-900 .noUi-handle {
  border-color: #4a148c;
}
.slider-material-purple-A100 .noUi-handle {
  border-color: #ea80fc;
}
.slider-material-purple-A200 .noUi-handle {
  border-color: #e040fb;
}
.slider-material-purple-A400 .noUi-handle {
  border-color: #d500f9;
}
.slider-material-purple-A700 .noUi-handle {
  border-color: #aa00ff;
}
.slider-material-deep-purple .noUi-handle {
  border-color: #673ab7;
}
.slider-material-deep-purple-50 .noUi-handle {
  border-color: #ede7f6;
}
.slider-material-deep-purple-100 .noUi-handle {
  border-color: #d1c4e9;
}
.slider-material-deep-purple-200 .noUi-handle {
  border-color: #b39ddb;
}
.slider-material-deep-purple-300 .noUi-handle {
  border-color: #9575cd;
}
.slider-material-deep-purple-400 .noUi-handle {
  border-color: #7e57c2;
}
.slider-material-deep-purple-500 .noUi-handle {
  border-color: #673ab7;
}
.slider-material-deep-purple-600 .noUi-handle {
  border-color: #5e35b1;
}
.slider-material-deep-purple-700 .noUi-handle {
  border-color: #512da8;
}
.slider-material-deep-purple-800 .noUi-handle {
  border-color: #4527a0;
}
.slider-material-deep-purple-900 .noUi-handle {
  border-color: #311b92;
}
.slider-material-deep-purple-A100 .noUi-handle {
  border-color: #b388ff;
}
.slider-material-deep-purple-A200 .noUi-handle {
  border-color: #7c4dff;
}
.slider-material-deep-purple-A400 .noUi-handle {
  border-color: #651fff;
}
.slider-material-deep-purple-A700 .noUi-handle {
  border-color: #6200ea;
}
.slider-material-indigo .noUi-handle {
  border-color: #3f51b5;
}
.slider-material-indigo-50 .noUi-handle {
  border-color: #e8eaf6;
}
.slider-material-indigo-100 .noUi-handle {
  border-color: #c5cae9;
}
.slider-material-indigo-200 .noUi-handle {
  border-color: #9fa8da;
}
.slider-material-indigo-300 .noUi-handle {
  border-color: #7986cb;
}
.slider-material-indigo-400 .noUi-handle {
  border-color: #5c6bc0;
}
.slider-material-indigo-500 .noUi-handle {
  border-color: #3f51b5;
}
.slider-material-indigo-600 .noUi-handle {
  border-color: #3949ab;
}
.slider-material-indigo-700 .noUi-handle {
  border-color: #303f9f;
}
.slider-material-indigo-800 .noUi-handle {
  border-color: #283593;
}
.slider-material-indigo-900 .noUi-handle {
  border-color: #1a237e;
}
.slider-material-indigo-A100 .noUi-handle {
  border-color: #8c9eff;
}
.slider-material-indigo-A200 .noUi-handle {
  border-color: #536dfe;
}
.slider-material-indigo-A400 .noUi-handle {
  border-color: #3d5afe;
}
.slider-material-indigo-A700 .noUi-handle {
  border-color: #304ffe;
}
.slider-material-blue .noUi-handle {
  border-color: #2196f3;
}
.slider-material-blue-50 .noUi-handle {
  border-color: #e3f2fd;
}
.slider-material-blue-100 .noUi-handle {
  border-color: #bbdefb;
}
.slider-material-blue-200 .noUi-handle {
  border-color: #90caf9;
}
.slider-material-blue-300 .noUi-handle {
  border-color: #64b5f6;
}
.slider-material-blue-400 .noUi-handle {
  border-color: #42a5f5;
}
.slider-material-blue-500 .noUi-handle {
  border-color: #2196f3;
}
.slider-material-blue-600 .noUi-handle {
  border-color: #1e88e5;
}
.slider-material-blue-700 .noUi-handle {
  border-color: #1976d2;
}
.slider-material-blue-800 .noUi-handle {
  border-color: #1565c0;
}
.slider-material-blue-900 .noUi-handle {
  border-color: #0d47a1;
}
.slider-material-blue-A100 .noUi-handle {
  border-color: #82b1ff;
}
.slider-material-blue-A200 .noUi-handle {
  border-color: #448aff;
}
.slider-material-blue-A400 .noUi-handle {
  border-color: #2979ff;
}
.slider-material-blue-A700 .noUi-handle {
  border-color: #2962ff;
}
.slider-material-light-blue .noUi-handle {
  border-color: #03a9f4;
}
.slider-material-light-blue-50 .noUi-handle {
  border-color: #e1f5fe;
}
.slider-material-light-blue-100 .noUi-handle {
  border-color: #b3e5fc;
}
.slider-material-light-blue-200 .noUi-handle {
  border-color: #81d4fa;
}
.slider-material-light-blue-300 .noUi-handle {
  border-color: #4fc3f7;
}
.slider-material-light-blue-400 .noUi-handle {
  border-color: #29b6f6;
}
.slider-material-light-blue-500 .noUi-handle {
  border-color: #03a9f4;
}
.slider-material-light-blue-600 .noUi-handle {
  border-color: #039be5;
}
.slider-material-light-blue-700 .noUi-handle {
  border-color: #0288d1;
}
.slider-material-light-blue-800 .noUi-handle {
  border-color: #0277bd;
}
.slider-material-light-blue-900 .noUi-handle {
  border-color: #01579b;
}
.slider-material-light-blue-A100 .noUi-handle {
  border-color: #80d8ff;
}
.slider-material-light-blue-A200 .noUi-handle {
  border-color: #40c4ff;
}
.slider-material-light-blue-A400 .noUi-handle {
  border-color: #00b0ff;
}
.slider-material-light-blue-A700 .noUi-handle {
  border-color: #0091ea;
}
.slider-material-cyan .noUi-handle {
  border-color: #00bcd4;
}
.slider-material-cyan-50 .noUi-handle {
  border-color: #e0f7fa;
}
.slider-material-cyan-100 .noUi-handle {
  border-color: #b2ebf2;
}
.slider-material-cyan-200 .noUi-handle {
  border-color: #80deea;
}
.slider-material-cyan-300 .noUi-handle {
  border-color: #4dd0e1;
}
.slider-material-cyan-400 .noUi-handle {
  border-color: #26c6da;
}
.slider-material-cyan-500 .noUi-handle {
  border-color: #00bcd4;
}
.slider-material-cyan-600 .noUi-handle {
  border-color: #00acc1;
}
.slider-material-cyan-700 .noUi-handle {
  border-color: #0097a7;
}
.slider-material-cyan-800 .noUi-handle {
  border-color: #00838f;
}
.slider-material-cyan-900 .noUi-handle {
  border-color: #006064;
}
.slider-material-cyan-A100 .noUi-handle {
  border-color: #84ffff;
}
.slider-material-cyan-A200 .noUi-handle {
  border-color: #18ffff;
}
.slider-material-cyan-A400 .noUi-handle {
  border-color: #00e5ff;
}
.slider-material-cyan-A700 .noUi-handle {
  border-color: #00b8d4;
}
.slider-material-teal .noUi-handle {
  border-color: #009688;
}
.slider-material-teal-50 .noUi-handle {
  border-color: #e0f2f1;
}
.slider-material-teal-100 .noUi-handle {
  border-color: #b2dfdb;
}
.slider-material-teal-200 .noUi-handle {
  border-color: #80cbc4;
}
.slider-material-teal-300 .noUi-handle {
  border-color: #4db6ac;
}
.slider-material-teal-400 .noUi-handle {
  border-color: #26a69a;
}
.slider-material-teal-500 .noUi-handle {
  border-color: #009688;
}
.slider-material-teal-600 .noUi-handle {
  border-color: #00897b;
}
.slider-material-teal-700 .noUi-handle {
  border-color: #00796b;
}
.slider-material-teal-800 .noUi-handle {
  border-color: #00695c;
}
.slider-material-teal-900 .noUi-handle {
  border-color: #004d40;
}
.slider-material-teal-A100 .noUi-handle {
  border-color: #a7ffeb;
}
.slider-material-teal-A200 .noUi-handle {
  border-color: #64ffda;
}
.slider-material-teal-A400 .noUi-handle {
  border-color: #1de9b6;
}
.slider-material-teal-A700 .noUi-handle {
  border-color: #00bfa5;
}
.slider-material-green .noUi-handle {
  border-color: #4caf50;
}
.slider-material-green-50 .noUi-handle {
  border-color: #e8f5e9;
}
.slider-material-green-100 .noUi-handle {
  border-color: #c8e6c9;
}
.slider-material-green-200 .noUi-handle {
  border-color: #a5d6a7;
}
.slider-material-green-300 .noUi-handle {
  border-color: #81c784;
}
.slider-material-green-400 .noUi-handle {
  border-color: #66bb6a;
}
.slider-material-green-500 .noUi-handle {
  border-color: #4caf50;
}
.slider-material-green-600 .noUi-handle {
  border-color: #43a047;
}
.slider-material-green-700 .noUi-handle {
  border-color: #388e3c;
}
.slider-material-green-800 .noUi-handle {
  border-color: #2e7d32;
}
.slider-material-green-900 .noUi-handle {
  border-color: #1b5e20;
}
.slider-material-green-A100 .noUi-handle {
  border-color: #b9f6ca;
}
.slider-material-green-A200 .noUi-handle {
  border-color: #69f0ae;
}
.slider-material-green-A400 .noUi-handle {
  border-color: #00e676;
}
.slider-material-green-A700 .noUi-handle {
  border-color: #00c853;
}
.slider-material-light-green .noUi-handle {
  border-color: #8bc34a;
}
.slider-material-light-green-50 .noUi-handle {
  border-color: #f1f8e9;
}
.slider-material-light-green-100 .noUi-handle {
  border-color: #dcedc8;
}
.slider-material-light-green-200 .noUi-handle {
  border-color: #c5e1a5;
}
.slider-material-light-green-300 .noUi-handle {
  border-color: #aed581;
}
.slider-material-light-green-400 .noUi-handle {
  border-color: #9ccc65;
}
.slider-material-light-green-500 .noUi-handle {
  border-color: #8bc34a;
}
.slider-material-light-green-600 .noUi-handle {
  border-color: #7cb342;
}
.slider-material-light-green-700 .noUi-handle {
  border-color: #689f38;
}
.slider-material-light-green-800 .noUi-handle {
  border-color: #558b2f;
}
.slider-material-light-green-900 .noUi-handle {
  border-color: #33691e;
}
.slider-material-light-green-A100 .noUi-handle {
  border-color: #ccff90;
}
.slider-material-light-green-A200 .noUi-handle {
  border-color: #b2ff59;
}
.slider-material-light-green-A400 .noUi-handle {
  border-color: #76ff03;
}
.slider-material-light-green-A700 .noUi-handle {
  border-color: #64dd17;
}
.slider-material-lime .noUi-handle {
  border-color: #cddc39;
}
.slider-material-lime-50 .noUi-handle {
  border-color: #f9fbe7;
}
.slider-material-lime-100 .noUi-handle {
  border-color: #f0f4c3;
}
.slider-material-lime-200 .noUi-handle {
  border-color: #e6ee9c;
}
.slider-material-lime-300 .noUi-handle {
  border-color: #dce775;
}
.slider-material-lime-400 .noUi-handle {
  border-color: #d4e157;
}
.slider-material-lime-500 .noUi-handle {
  border-color: #cddc39;
}
.slider-material-lime-600 .noUi-handle {
  border-color: #c0ca33;
}
.slider-material-lime-700 .noUi-handle {
  border-color: #afb42b;
}
.slider-material-lime-800 .noUi-handle {
  border-color: #9e9d24;
}
.slider-material-lime-900 .noUi-handle {
  border-color: #827717;
}
.slider-material-lime-A100 .noUi-handle {
  border-color: #f4ff81;
}
.slider-material-lime-A200 .noUi-handle {
  border-color: #eeff41;
}
.slider-material-lime-A400 .noUi-handle {
  border-color: #c6ff00;
}
.slider-material-lime-A700 .noUi-handle {
  border-color: #aeea00;
}
.slider-material-yellow .noUi-handle {
  border-color: #ffeb3b;
}
.slider-material-yellow-50 .noUi-handle {
  border-color: #fffde7;
}
.slider-material-yellow-100 .noUi-handle {
  border-color: #fff9c4;
}
.slider-material-yellow-200 .noUi-handle {
  border-color: #fff59d;
}
.slider-material-yellow-300 .noUi-handle {
  border-color: #fff176;
}
.slider-material-yellow-400 .noUi-handle {
  border-color: #ffee58;
}
.slider-material-yellow-500 .noUi-handle {
  border-color: #ffeb3b;
}
.slider-material-yellow-600 .noUi-handle {
  border-color: #fdd835;
}
.slider-material-yellow-700 .noUi-handle {
  border-color: #fbc02d;
}
.slider-material-yellow-800 .noUi-handle {
  border-color: #f9a825;
}
.slider-material-yellow-900 .noUi-handle {
  border-color: #f57f17;
}
.slider-material-yellow-A100 .noUi-handle {
  border-color: #ffff8d;
}
.slider-material-yellow-A200 .noUi-handle {
  border-color: #ffff00;
}
.slider-material-yellow-A400 .noUi-handle {
  border-color: #ffea00;
}
.slider-material-yellow-A700 .noUi-handle {
  border-color: #ffd600;
}
.slider-material-amber .noUi-handle {
  border-color: #ffc107;
}
.slider-material-amber-50 .noUi-handle {
  border-color: #fff8e1;
}
.slider-material-amber-100 .noUi-handle {
  border-color: #ffecb3;
}
.slider-material-amber-200 .noUi-handle {
  border-color: #ffe082;
}
.slider-material-amber-300 .noUi-handle {
  border-color: #ffd54f;
}
.slider-material-amber-400 .noUi-handle {
  border-color: #ffca28;
}
.slider-material-amber-500 .noUi-handle {
  border-color: #ffc107;
}
.slider-material-amber-600 .noUi-handle {
  border-color: #ffb300;
}
.slider-material-amber-700 .noUi-handle {
  border-color: #ffa000;
}
.slider-material-amber-800 .noUi-handle {
  border-color: #ff8f00;
}
.slider-material-amber-900 .noUi-handle {
  border-color: #ff6f00;
}
.slider-material-amber-A100 .noUi-handle {
  border-color: #ffe57f;
}
.slider-material-amber-A200 .noUi-handle {
  border-color: #ffd740;
}
.slider-material-amber-A400 .noUi-handle {
  border-color: #ffc400;
}
.slider-material-amber-A700 .noUi-handle {
  border-color: #ffab00;
}
.slider-material-orange .noUi-handle {
  border-color: #ff9800;
}
.slider-material-orange-50 .noUi-handle {
  border-color: #fff3e0;
}
.slider-material-orange-100 .noUi-handle {
  border-color: #ffe0b2;
}
.slider-material-orange-200 .noUi-handle {
  border-color: #ffcc80;
}
.slider-material-orange-300 .noUi-handle {
  border-color: #ffb74d;
}
.slider-material-orange-400 .noUi-handle {
  border-color: #ffa726;
}
.slider-material-orange-500 .noUi-handle {
  border-color: #ff9800;
}
.slider-material-orange-600 .noUi-handle {
  border-color: #fb8c00;
}
.slider-material-orange-700 .noUi-handle {
  border-color: #f57c00;
}
.slider-material-orange-800 .noUi-handle {
  border-color: #ef6c00;
}
.slider-material-orange-900 .noUi-handle {
  border-color: #e65100;
}
.slider-material-orange-A100 .noUi-handle {
  border-color: #ffd180;
}
.slider-material-orange-A200 .noUi-handle {
  border-color: #ffab40;
}
.slider-material-orange-A400 .noUi-handle {
  border-color: #ff9100;
}
.slider-material-orange-A700 .noUi-handle {
  border-color: #ff6d00;
}
.slider-material-deep-orange .noUi-handle {
  border-color: #ff5722;
}
.slider-material-deep-orange-50 .noUi-handle {
  border-color: #fbe9e7;
}
.slider-material-deep-orange-100 .noUi-handle {
  border-color: #ffccbc;
}
.slider-material-deep-orange-200 .noUi-handle {
  border-color: #ffab91;
}
.slider-material-deep-orange-300 .noUi-handle {
  border-color: #ff8a65;
}
.slider-material-deep-orange-400 .noUi-handle {
  border-color: #ff7043;
}
.slider-material-deep-orange-500 .noUi-handle {
  border-color: #ff5722;
}
.slider-material-deep-orange-600 .noUi-handle {
  border-color: #f4511e;
}
.slider-material-deep-orange-700 .noUi-handle {
  border-color: #e64a19;
}
.slider-material-deep-orange-800 .noUi-handle {
  border-color: #d84315;
}
.slider-material-deep-orange-900 .noUi-handle {
  border-color: #bf360c;
}
.slider-material-deep-orange-A100 .noUi-handle {
  border-color: #ff9e80;
}
.slider-material-deep-orange-A200 .noUi-handle {
  border-color: #ff6e40;
}
.slider-material-deep-orange-A400 .noUi-handle {
  border-color: #ff3d00;
}
.slider-material-deep-orange-A700 .noUi-handle {
  border-color: #dd2c00;
}
.slider-material-brown .noUi-handle {
  border-color: #795548;
}
.slider-material-brown-50 .noUi-handle {
  border-color: #efebe9;
}
.slider-material-brown-100 .noUi-handle {
  border-color: #d7ccc8;
}
.slider-material-brown-200 .noUi-handle {
  border-color: #bcaaa4;
}
.slider-material-brown-300 .noUi-handle {
  border-color: #a1887f;
}
.slider-material-brown-400 .noUi-handle {
  border-color: #8d6e63;
}
.slider-material-brown-500 .noUi-handle {
  border-color: #795548;
}
.slider-material-brown-600 .noUi-handle {
  border-color: #6d4c41;
}
.slider-material-brown-700 .noUi-handle {
  border-color: #5d4037;
}
.slider-material-brown-800 .noUi-handle {
  border-color: #4e342e;
}
.slider-material-brown-900 .noUi-handle {
  border-color: #3e2723;
}
.slider-material-brown-A100 .noUi-handle {
  border-color: #d7ccc8;
}
.slider-material-brown-A200 .noUi-handle {
  border-color: #bcaaa4;
}
.slider-material-brown-A400 .noUi-handle {
  border-color: #8d6e63;
}
.slider-material-brown-A700 .noUi-handle {
  border-color: #5d4037;
}
.slider-material-grey .noUi-handle {
  border-color: #9e9e9e;
}
.slider-material-grey-50 .noUi-handle {
  border-color: #fafafa;
}
.slider-material-grey-100 .noUi-handle {
  border-color: #f5f5f5;
}
.slider-material-grey-200 .noUi-handle {
  border-color: #eeeeee;
}
.slider-material-grey-300 .noUi-handle {
  border-color: #e0e0e0;
}
.slider-material-grey-400 .noUi-handle {
  border-color: #bdbdbd;
}
.slider-material-grey-500 .noUi-handle {
  border-color: #9e9e9e;
}
.slider-material-grey-600 .noUi-handle {
  border-color: #757575;
}
.slider-material-grey-700 .noUi-handle {
  border-color: #616161;
}
.slider-material-grey-800 .noUi-handle {
  border-color: #424242;
}
.slider-material-grey-900 .noUi-handle {
  border-color: #212121;
}
.slider-material-grey-A100 .noUi-handle {
  border-color: #f5f5f5;
}
.slider-material-grey-A200 .noUi-handle {
  border-color: #eeeeee;
}
.slider-material-grey-A400 .noUi-handle {
  border-color: #bdbdbd;
}
.slider-material-grey-A700 .noUi-handle {
  border-color: #616161;
}
.slider-material-blue .noUi-handle {
  border-color: #607d8b;
}
.slider-material-blue-50 .noUi-handle {
  border-color: #eceff1;
}
.slider-material-blue-100 .noUi-handle {
  border-color: #cfd8dc;
}
.slider-material-blue-200 .noUi-handle {
  border-color: #b0bec5;
}
.slider-material-blue-300 .noUi-handle {
  border-color: #90a4ae;
}
.slider-material-blue-400 .noUi-handle {
  border-color: #78909c;
}
.slider-material-blue-500 .noUi-handle {
  border-color: #607d8b;
}
.slider-material-blue-600 .noUi-handle {
  border-color: #546e7a;
}
.slider-material-blue-700 .noUi-handle {
  border-color: #455a64;
}
.slider-material-blue-800 .noUi-handle {
  border-color: #37474f;
}
.slider-material-blue-900 .noUi-handle {
  border-color: #263238;
}
.slider-material-blue-A100 .noUi-handle {
  border-color: #cfd8dc;
}
.slider-material-blue-A200 .noUi-handle {
  border-color: #b0bec5;
}
.slider-material-blue-A400 .noUi-handle {
  border-color: #78909c;
}
.slider-material-blue-A700 .noUi-handle {
  border-color: #455a64;
}
.selectize-control.single,
.selectize-control.multi {
  padding: 0;
}
.selectize-control.single .selectize-input,
.selectize-control.multi .selectize-input,
.selectize-control.single .selectize-input.input-active,
.selectize-control.multi .selectize-input.input-active {
  cursor: text;
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  line-height: 30px;
}
.selectize-control.single .selectize-input .has-items,
.selectize-control.multi .selectize-input .has-items,
.selectize-control.single .selectize-input.input-active .has-items,
.selectize-control.multi .selectize-input.input-active .has-items {
  padding: 0;
}
.selectize-control.single .selectize-input:after,
.selectize-control.multi .selectize-input:after,
.selectize-control.single .selectize-input.input-active:after,
.selectize-control.multi .selectize-input.input-active:after {
  right: 5px;
  position: absolute;
  font-size: 7px;
  content: "\e894";
  font-family: "Material-Design-Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.selectize-control.single .selectize-input input,
.selectize-control.multi .selectize-input input,
.selectize-control.single .selectize-input.input-active input,
.selectize-control.multi .selectize-input.input-active input {
  font-size: 14px;
  outline: 0px;
  border: 0px;
  background: transparent;
}
.selectize-control.single .selectize-input.floating-label-fix input,
.selectize-control.multi .selectize-input.floating-label-fix input,
.selectize-control.single .selectize-input.input-active.floating-label-fix input,
.selectize-control.multi .selectize-input.input-active.floating-label-fix input {
  opacity: 0;
}
.selectize-control.single .selectize-input > div,
.selectize-control.multi .selectize-input > div,
.selectize-control.single .selectize-input.input-active > div,
.selectize-control.multi .selectize-input.input-active > div,
.selectize-control.single .selectize-input > .item,
.selectize-control.multi .selectize-input > .item,
.selectize-control.single .selectize-input.input-active > .item,
.selectize-control.multi .selectize-input.input-active > .item {
  display: inline-block;
  margin: 0 8px 3px 0;
  padding: 0;
  background: transparent;
  border: 0;
}
.selectize-control.single .selectize-input > div:after,
.selectize-control.multi .selectize-input > div:after,
.selectize-control.single .selectize-input.input-active > div:after,
.selectize-control.multi .selectize-input.input-active > div:after,
.selectize-control.single .selectize-input > .item:after,
.selectize-control.multi .selectize-input > .item:after,
.selectize-control.single .selectize-input.input-active > .item:after,
.selectize-control.multi .selectize-input.input-active > .item:after {
  content: ",";
}
.selectize-control.single .selectize-input > div:last-of-type:after,
.selectize-control.multi .selectize-input > div:last-of-type:after,
.selectize-control.single .selectize-input.input-active > div:last-of-type:after,
.selectize-control.multi .selectize-input.input-active > div:last-of-type:after,
.selectize-control.single .selectize-input > .item:last-of-type:after,
.selectize-control.multi .selectize-input > .item:last-of-type:after,
.selectize-control.single .selectize-input.input-active > .item:last-of-type:after,
.selectize-control.multi .selectize-input.input-active > .item:last-of-type:after {
  content: "";
}
.selectize-control.single .selectize-input > div.active,
.selectize-control.multi .selectize-input > div.active,
.selectize-control.single .selectize-input.input-active > div.active,
.selectize-control.multi .selectize-input.input-active > div.active,
.selectize-control.single .selectize-input > .item.active,
.selectize-control.multi .selectize-input > .item.active,
.selectize-control.single .selectize-input.input-active > .item.active,
.selectize-control.multi .selectize-input.input-active > .item.active {
  font-weight: bold;
  background: transparent;
  border: 0;
}
.selectize-control.single .selectize-dropdown,
.selectize-control.multi .selectize-dropdown {
  position: absolute;
  z-index: 1000;
  border: 0;
  width: 100% !important;
  left: 0 !important;
  height: auto;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  padding: 0;
  margin-top: 3px;
}
.selectize-control.single .selectize-dropdown .active,
.selectize-control.multi .selectize-dropdown .active {
  background-color: inherit;
}
.selectize-control.single .selectize-dropdown .highlight,
.selectize-control.multi .selectize-dropdown .highlight {
  background-color: #d5d8ff;
}
.selectize-control.single .selectize-dropdown .selected,
.selectize-control.multi .selectize-dropdown .selected,
.selectize-control.single .selectize-dropdown .selected.active,
.selectize-control.multi .selectize-dropdown .selected.active {
  background-color: #EEEEEE;
}
.selectize-control.single .selectize-dropdown [data-selectable],
.selectize-control.multi .selectize-dropdown [data-selectable],
.selectize-control.single .selectize-dropdown .optgroup-header,
.selectize-control.multi .selectize-dropdown .optgroup-header {
  padding: 10px 20px;
  cursor: pointer;
}
.selectize-control.single .dropdown-active ~ .selectize-dropdown,
.selectize-control.multi .dropdown-active ~ .selectize-dropdown {
  display: block;
}
.dropdownjs:after {
  right: 5px;
  top: 3px;
  font-size: 25px;
  position: absolute;
  content: "\e894";
  font-family: "Material-Design-Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: none;
  color: #757575;
}
/*# sourceMappingURL=material-wfont.css.map */